import { ObjectUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
export class SessionStorageService {
    constructor() {
        this._storage = window.sessionStorage;
    }
    set(key, value) {
        if (this._storage) {
            this._storage.setItem(key, JSON.stringify(value));
            return true;
        }
        return false;
    }
    get(key) {
        if (this._storage) {
            const json = JSON.parse(this._storage.getItem(key));
            return !ObjectUtil.isNull(json) ? json : null;
        }
        return null;
    }
    remove(key) {
        if (this._storage) {
            this._storage.removeItem(key);
            return true;
        }
        return false;
    }
    clear() {
        if (this._storage) {
            this._storage.clear();
            return true;
        }
        return false;
    }
}
SessionStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionStorageService_Factory() { return new SessionStorageService(); }, token: SessionStorageService, providedIn: "root" });
