import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { FinancialPaths } from '@gipi-paths/financial.paths';
import { ArrayUtil, GIPIAbstractCrudService, GIPIBaseService, GIPIPageModel, GIPISortModel, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import { FinancialTypeChargeType } from '../enums/charge-type.enum';
import { FinancialChargeType } from '../models/charge-type.model';
import { FinancialChargeTypeFilterDTO } from '../models/dto/charge-type-filter.dto';


@Injectable({ providedIn: 'root' })
export class FinancialChargeTypeService extends GIPIAbstractCrudService<FinancialChargeType, FinancialChargeTypeFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(FinancialPaths.chargeType, 'v1', baseService);
    }

    findByValue<T = any>(value: string, page: number, size?: number, sort?: GIPISortModel, version?: string, types: FinancialTypeChargeType[] = []): Observable<GIPIPageModel<T>> {
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        let lSort: string = '';
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.field)) {
            lSort = `&sort=${sort.field},${sort.direction}`;
        }
        let lTypes: string = '';
        if (!ArrayUtil.isEmpty(types)) {
            if (types.length === 1) {
                lTypes = `&type=${types[0]}`;
            } else if (types.length > 1) {
                lTypes = `&type=${types.join('&type=')}`;
            }
        }
        return this.baseService.httpClient.get(this.url(`find-by-value?page=${page}&size=${size}${lSort}${lTypes}&value=${value}`, version), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    /**
     * Utiliza o endpoint findByValue
     * @value ''
     * @page 0
     * @size 100
     * @sort description,ASK
     * @version v1
     */
    findByTypes<T = any>(types: FinancialTypeChargeType[] = []): Observable<T> {
        const page: number = 0;
        const size: number = 100;
        const sort: string = `&sort=description,ASC`;
        const version: string = 'v1';

        let lTypes: string = '';
        if (!ArrayUtil.isEmpty(types)) {
            if (types.length === 1) {
                lTypes = `&type=${types[0]}`;
            } else if (types.length > 1) {
                lTypes = `&type=${types.join('&type=')}`;
            }
        }

        return this.baseService.httpClient.get(this.url(`find-by-value?page=${page}&size=${size}${sort}${lTypes}`, version), this.options()).pipe(
            map(json => {
                const page: GIPIPageModel<T> = this.handleMapper(json);
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    return page.content;
                }

                return [];
            }),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

}
