import { GlobalPaths } from '@gipi-paths/global.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class SalePhoneTypeService extends GIPIAbstractCrudService {
    constructor(baseService) {
        // Não tem no sale, então pega do global
        // super(SalePaths.phoneType, 'v1', baseService);
        super(GlobalPaths.phoneType, 'v1', baseService);
        this.baseService = baseService;
    }
}
SalePhoneTypeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SalePhoneTypeService_Factory() { return new SalePhoneTypeService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: SalePhoneTypeService, providedIn: "root" });
