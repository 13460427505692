<gipi-abstract-form id="dialogRepresentativeProvider"
                    name="dialogRepresentativeProvider"
                    class="form-content-representative-provider">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogRepresentativeProvider">
        Representantes do fornecedor
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px"
         class="content-representative-provider"
         (keydown.enter)="isLoad ? null : confirm()">
        <itss-person-form typeRegister="REPRESENTATIVE_PROVIDER"
                          [disabled]="isLoad || loading || isViewing()"
                          [(person)]="representative.person"
                          [(entity)]="representative">
            <ng-template #specificComponents>
                <gipi-expansion-panel panelTitle="Endereços"
                                      id="panelAddress"
                                      svgIcon="person_address"
                                      [iconSize]="20"
                                      [disabled]="isLoad || loading">
                    <itss-address-form [(addressList)]="representative.person.addressList"
                                       [person]="representative.person"
                                       [isRepresentativeProvider]="true"
                                       [isViewing]="isViewing()"
                                       [isCreating]="isCreating()">
                    </itss-address-form>
                </gipi-expansion-panel>

                <gipi-expansion-panel panelTitle="Telefones"
                                      id="panelPhone"
                                      svgIcon="person_phone"
                                      [iconSize]="20"
                                      [disabled]="isLoad || loading">
                    <itss-phone-form [(phoneList)]="representative.person.phoneList"
                                     [person]="representative.person"
                                     [isViewing]="isViewing()"
                                     [isCreating]="isCreating()">
                    </itss-phone-form>
                </gipi-expansion-panel>

                <gipi-expansion-panel panelTitle="E-mails"
                                      id="panelEmail"
                                      svgIcon="person_email"
                                      [iconSize]="20"
                                      [disabled]="isLoad || loading">
                    <itss-email-form [(emailList)]="representative.person.emailList"
                                     [person]="representative.person"
                                     [isViewing]="isViewing()"
                                     [isCreating]="isCreating()">
                    </itss-email-form>
                </gipi-expansion-panel>

                <gipi-expansion-panel panelTitle="Redes sociais"
                                      id="panelSocialNetwork"
                                      svgIcon="person_social_network"
                                      [iconSize]="20"
                                      [disabled]="isLoad || loading">
                    <itss-social-network-form [(socialNetworkList)]="representative.person.socialNetworkList"
                                              [person]="representative.person"
                                              [isViewing]="isViewing()"
                                              [isCreating]="isCreating()">
                    </itss-social-network-form>
                </gipi-expansion-panel>
            </ng-template>
        </itss-person-form>
    </div>

    <div mat-dialog-actions
         class="actions-representative-provider"
         fxLayout="row wrap"
         fxLayout.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Salvar e criar outro"
                     gipi-primary
                     id="btnSaveAndNewRegister"
                     [loading]="loading"
                     (click)="confirm()"
                     [disabled]="isLoad || loading"
                     fxFlex="20"
                     fxFlex.lt-md="100"
                     *ngIf="!isViewing() && (data.typeOperation === 'NEW')">
        </gipi-button>
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     [loading]="loading"
                     (click)="confirm()"
                     [disabled]="isLoad || loading"
                     fxFlex="20"
                     fxFlex.lt-md="100"
                     *ngIf="!isViewing() && (data.typeOperation === 'NEW') || (data.typeOperation === 'EDIT')">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close()"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>
