<gipi-abstract-form id="dialogSaleData"
                    name="dialogSaleData"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogSaleData">
        Dados da venda
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0">
            <gipi-datepicker label="Data da venda"
                             [value]="saleData?.date"
                             [disabled]="true"
                             fxFlex="20"
                             fxFlex.lt-md="100">
            </gipi-datepicker>
            <itss-input label="Cliente"
                        [value]="saleData?.client?.person?.name"
                        [disabled]="true"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Vendedor"
                        [value]="saleData?.seller?.person?.name"
                        [disabled]="true"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
        </div>

        <div class="content-amounts">
            <label> Total </label>
            <div class="card-information"
                 fxLayoutGap="16px"
                 fxLayoutAlign="space-between stretch">
                <div class="card information amount-information"
                     fxFlex>
                    <span>Quantidade de itens: </span>
                    <strong>
                        {{ saleData?.saleItemList?.length }}
                    </strong>
                </div>
                <div class="card information amount-information"
                     fxFlex>
                    <span> Total bruto: </span>
                    <strong [style.color]="getColorByAmount(saleData?.grossAmount)">
                        {{ saleData?.grossAmount | currency:'BRL':'symbol':'1.2-2' }}
                    </strong>
                </div>
                <div class="card information amount-information"
                     fxFlex>
                    <span> Desconto: </span>
                    <strong [style.color]="getColorByAmount(saleData?.discount)">
                        {{ saleData?.discount | currency:'BRL':'symbol':'1.2-2' }}
                    </strong>
                </div>
                <div class="card information amount-information"
                     fxFlex>
                    <span> Acréscimo: </span>
                    <strong [style.color]="getColorByAmount(saleData?.addition)">
                        {{ saleData?.addition | currency:'BRL':'symbol':'1.2-2' }}
                    </strong>
                </div>
                <div class="card information amount-information"
                     fxFlex>
                    <span> Frete: </span>
                    <strong [style.color]="getColorByAmount(saleData?.freight)">
                        {{ saleData?.freight | currency:'BRL':'symbol':'1.2-2' }}
                    </strong>
                </div>
                <div class="card information amount-information"
                     fxFlex>
                    <span> Total líquido: </span>
                    <strong [style.color]="getColorByAmount(saleData?.amountNet)">
                        {{ saleData?.amountNet | currency:'BRL':'symbol':'1.2-2' }}
                    </strong>
                </div>
            </div>
        </div>
        <div class="line"> </div>

        <div class="content-amounts">
            <label> Tipo de cobrança </label>
            <div class="card-information-grid">
                <div *ngFor="let chargeType of chargeTypeList"
                     class="card information amount-information"
                     fxFlex>
                    <span> {{ chargeType.description }}: </span>
                    <strong [style.color]="getColorByAmount(chargeType.totalInstallmentAmount)">
                        {{ chargeType.totalInstallmentAmount | currency: 'R$':'symbol': '1.2-2' }}
                    </strong>
                </div>
            </div>
        </div>
        <div class="line"> </div>

        <itss-table [paginator]="false"
                    [columns]="createTableColumns()"
                    [data]="saleData?.saleItemList"
                    [backendPagination]="false"
                    [loading]="loading">
        </itss-table>
    </div>

    <div mat-dialog-actions
         fxLayout="row wrap"
         fxFlex.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     mat-dialog-close
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>