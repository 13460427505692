import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { FinancialClientService } from '@gipi-financial/client/services/client.service';
import { FinancialConfiguration } from '@gipi-financial/configuration/models/configuration.model';
import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService, GIPIUuid, ObjectUtil, StringUtil, UUIDUtil } from '@gipisistemas/ng-core';
import { RegistrationClient } from '../models/client.model';
import { RegistrationClientFilterDTO } from '../models/dto/client-filter.dto';
import { RegistrationClientSelectDTO } from '../models/dto/client-select.dto';

@Injectable({ providedIn: 'root' })
export class RegistrationClientService extends GIPIAbstractCrudService<RegistrationClient, RegistrationClientFilterDTO> {

    constructor(
        protected baseService: GIPIBaseService,
        private _financialClientService: FinancialClientService,
    ) {
        super(RegistrationPaths.client, 'v1', baseService);
    }

    getDescription(entity: RegistrationClientSelectDTO): string {
        let name: string = entity.name;

        const configuration: FinancialConfiguration = this.baseService.sessionStorageService.get('configuration');
        if (!ObjectUtil.isNull(configuration)) {
            name = configuration.showBusinessNameInReceiptQuery ? (entity.fantasyName ? entity.fantasyName : entity.name) : entity.name;
        }

        if (entity.type === 'NATURAL_PERSON') {
            const cpf: string = !StringUtil.isEmpty(entity.cpfOrCnpj) ? StringUtil.format(entity.cpfOrCnpj, '000.000.000-00') + ' - ' : '';
            return `${cpf}${name}`;
        } else {
            const cnpj: string = !StringUtil.isEmpty(entity.cpfOrCnpj) ? StringUtil.format(entity.cpfOrCnpj, '00.000.000/0000-00') + ' - ' : '';
            return `${cnpj}${name}`;
        }
    }

    findByCpfOrCnpj(cpfOrCnpj: string): Observable<RegistrationClient[]> {
        return this.baseService.httpClient.get(this.url(`find-by-cpf-or-cnpj/${cpfOrCnpj}?page=0&size=50`), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    getCard<T = any>(id: GIPIUuid): Observable<T> {
        return this._financialClientService.getCard<T>(id);
    }

    /**
     * Refers to the block-or-unblock endpoint
     * @type PATCH
     * @path resource/version/block-or-unblock/{id}/{action}
     */
    public blockOrUnblock(id: GIPIUuid, action: 'BLOCK' | 'UNBLOCK', version?: string): Observable<boolean> {
        if (!UUIDUtil.isValid(id)) {
            return of(false);
        }
        return this.baseService.httpClient.patch(this.url(`/block-or-unblock/${id}/${action}`, version), {}, this.options()).pipe(
            map(() => { return true; }),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        )
    }

    /**
     * Refers to the block-or-unblock-all endpoint
     * @type PATCH
     * @path resource/version/block-or-unblock-all/{action}
     */
    public blockOrUnblockAll(idList: GIPIUuid[], action: 'BLOCK' | 'UNBLOCK', version?: string): Observable<boolean> {
        for (let i = 0; i < idList.length; i++) {
            if (!UUIDUtil.isValid(idList[i])) {
                return of(false);
            }
        }

        return this.baseService.httpClient.patch(this.url(`/block-or-unblock-all/${action}`, version), idList, this.options()).pipe(
            map(() => { return true; }),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        )
    }

}
