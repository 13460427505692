import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialBank } from '@gipi-financial/bank/models/bank.model';
import { FinancialCheck } from '@gipi-financial/check/models/check.model';
import { FinancialClient } from '@gipi-financial/client/models/client.model';
import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { GIPIUuid } from '@gipisistemas/ng-core';

export class FinancialCheckReceived extends GIPIBaseAbstractModel {

    client: FinancialClient;

    check: FinancialCheck;

    issuer: string;

    conciled: boolean;

    cpfOrCnpjIssuer: string;

    createdByConciliation: boolean;

    phone: string;

    bank: FinancialBank;

    account: string;

    accountDigit: string;

    agency: string;

    agencyDigit: string;

    comments: string;

    bankAccount: FinancialBankAccount;

    bankAccountIdReversal: GIPIUuid;

    saleCheckReceivedId: GIPIUuid;

    allowModification: boolean;

}
