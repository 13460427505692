import { FinancialClient } from '@gipi-financial/client/models/client.model';
import { FinancialProvider } from '@gipi-financial/provider/models/provider.model';
import { BaseModel } from '@gipi-shared/models/base.model';
import { GIPIUuid } from '@gipisistemas/ng-core';
import { FinancialBillType } from '../enums/bill-type.enum';
import { FinancialPeriodicity } from '../enums/periodicity.enum';
import { FinancialBillInstallmentRenegotiation } from './bill-installment-renegotiation.model';
import { FinancialBillInstallment } from './bill-installment.model';

export class FinancialBill extends BaseModel {

    type: FinancialBillType;

    periodicity: FinancialPeriodicity;

    // NotNull when payable
    provider: FinancialProvider;

    // NotNull when receivable
    client: FinancialClient;

    billInstallmentRenegotiation: FinancialBillInstallmentRenegotiation;

    allowModification: boolean;

    quantityOfInstallments: number;

    saleBillId: GIPIUuid;

    billInstallmentList: FinancialBillInstallment[] = [];

    constructor() {
        super();
        this.type = 'RECEIVABLE';
        this.periodicity = 'MONTHLY';
    }
}
