import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { FinancialConfiguration } from '@gipi-financial/configuration/models/configuration.model';
import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { SessionStorageService } from '@gipi-shared/services/session-storage.service';
import { GIPIAbstractCrudService, GIPIBaseService, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import { RegistrationProviderFilterDTO } from '../models/dto/provider-filter.dto';
import { RegistrationProvider } from '../models/provider.model';

@Injectable({ providedIn: 'root' })
export class RegistrationProviderService extends GIPIAbstractCrudService<RegistrationProvider, RegistrationProviderFilterDTO> {

    constructor(
        private _sessionStorageService: SessionStorageService<any>,
        protected baseService: GIPIBaseService,
    ) {
        super(RegistrationPaths.provider, 'v1', baseService);
    }

    getDescription(entity: RegistrationProvider): string {
        let name: string = entity.person.name;

        const configuration: FinancialConfiguration = this._sessionStorageService.get('configuration');
        if (!ObjectUtil.isNull(configuration)) {
            name = configuration.showBusinessNameInReceiptQuery ? (entity.person.fantasyName ? entity.person.fantasyName : entity.person.name) : entity.person.name;
        }

        if (ObjectUtil.isNewModel(entity.person.legalPerson) || ObjectUtil.isNull(entity.person.legalPerson)) {
            const cpf: string = !StringUtil.isEmpty(entity.person.naturalPerson.cpf) ? StringUtil.format(entity.person.naturalPerson.cpf, '000.000.000-00') + ' - ' : '';
            return `${cpf}${name}`;
        } else {
            const cnpj: string = !StringUtil.isEmpty(entity.person.legalPerson.cnpj) ? StringUtil.format(entity.person.legalPerson.cnpj, '00.000.000/0000-00') + ' - ' : '';
            return `${cnpj}${name}`;
        }
    }

    findByCpfOrCnpj(cpfOrCnpj: string): Observable<RegistrationProvider[]> {
        return this.baseService.httpClient.get(this.url(`find-by-cpf-or-cnpj/${cpfOrCnpj}?page=0&size=1`), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

}
