import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { RegistrationPerson } from '@gipi-registration/person/models/person.model';
import { RegistrationSocialNetwork } from '@gipi-registration/person/models/social-network.model';
import { RegistrationSocialNetworkType } from '@gipi-registration/social-network-type/models/social-network-type.model';
import { RegistrationSocialNetworkTypeService } from '@gipi-registration/social-network-type/services/social-network-type.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ArrayUtil, ObjectUtil, UUIDUtil } from '@gipisistemas/ng-core';

@Component({
    selector: 'itss-social-network-form',
    templateUrl: './social-network-form.component.html',
    styleUrls: ['./social-network-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SocialNetworkFormComponent),
            multi: true
        }
    ],
})
export class SocialNetworkFormComponent extends AbstractComponent implements OnInit {

    socialNetworkTypeList: RegistrationSocialNetworkType[] = [];

    socialNetworkListValue: RegistrationSocialNetwork[] = [];
    @Input() get socialNetworkList(): RegistrationSocialNetwork[] {
        return this.socialNetworkListValue;
    }

    set socialNetworkList(socialNetworkList: RegistrationSocialNetwork[]) {
        this.socialNetworkListValue = socialNetworkList;
        this.socialNetworkListChange.emit(this.socialNetworkListValue);
    }

    @Output() socialNetworkListChange: EventEmitter<RegistrationSocialNetwork[]> = new EventEmitter<RegistrationSocialNetwork[]>();

    @Input() person: RegistrationPerson = new RegistrationPerson();

    @Input() isCreating: boolean = false;

    @Input() isViewing: boolean = false;

    constructor(
        private _socialNetworkTypeService: RegistrationSocialNetworkTypeService,
        messageService: CustomMessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();
        this.loading = true;

        await this.getSocialNetworkType();

        setTimeout(async () => {
            if (ArrayUtil.isEmpty(this.socialNetworkList)) {
                this.socialNetworkList = [];
                // this.addNewSocialNetwork();
            }
        });
        this.loading = false;
    }

    private async getSocialNetworkType(): Promise<void> {
        try {
            await this._socialNetworkTypeService.findAllEnabled(0, 50).toPromise().then(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    this.socialNetworkTypeList = page.content;
                }
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    deleteSocialNetwork(socialNetwork: RegistrationSocialNetwork): void {
        if (UUIDUtil.isValid(socialNetwork.id)) {
            this.socialNetworkList.splice(this.socialNetworkList.findIndex(a => a.id === socialNetwork.id), 1);
        } else {
            this.socialNetworkList.splice(this.socialNetworkList.findIndex(a => a.key === socialNetwork.key), 1);
        }
    }

    addNewSocialNetwork(): void {
        const socialNetwork = new RegistrationSocialNetwork();
        socialNetwork.type = new RegistrationSocialNetworkType();
        socialNetwork.key = this.socialNetworkList.length;

        this.socialNetworkList.push(socialNetwork);
    }

}
