import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { AbstractCrudService, ArrayUtil, ObjectUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class RegistrationCountryService extends AbstractCrudService {
    constructor(http) {
        super(RegistrationPaths.country, http);
    }
    findByCode(code) {
        return this.findByValue(code, 0, 10, { property: 'name', direction: 'asc' }).pipe(map(page => {
            if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                return page.content[0];
            }
            return null;
        }), catchError(this.handleError));
    }
}
RegistrationCountryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationCountryService_Factory() { return new RegistrationCountryService(i0.ɵɵinject(i1.HttpClient)); }, token: RegistrationCountryService, providedIn: "root" });
