import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import * as moment_ from 'moment';
const moment = moment_;

import { FinancialCompany } from '@gipi-financial/company/models/company.model';
import { FinancialCompanyService } from '@gipi-financial/company/services/company.service';
import { OAuthUser } from '@gipi-financial/user/models/user.model';
import { NotificationCustomerNotificationConfig } from '@gipi-notification/customer-notification-config/models/customer-notification-config.model';
import { NotificationCustomerNotificationConfigService } from '@gipi-notification/customer-notification-config/services/customer-notification-config.service';
import { NotificationNotificationStatementDTO } from '@gipi-notification/invoice-integrations/models/dto/notification-statement.dto';
import { NotificationInvoiceIntegrationsService } from '@gipi-notification/invoice-integrations/services/invoice-integrations.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { APP_MESSAGES, ArrayUtil, EmailUtil, GIPIAbstractComponent, GIPIBaseService, INJECTOR, InputComponent, MonthYear, ObjectUtil, StringUtil, TokenDTO } from '@gipisistemas/ng-core';

type Options = {
    key: string;
    title: string;
    description: string;
    selected: boolean;
};

@Component({
    templateUrl: './customer-notification-config-dialog.component.html',
    styleUrls: ['./customer-notification-config-dialog.component.scss'],
})
export class CustomerNotificationConfigDialogComponent extends GIPIAbstractComponent implements OnInit {

    private _now: Date = new Date()

    public entity: NotificationCustomerNotificationConfig = new NotificationCustomerNotificationConfig();

    public panelTwilioOptions: Options[] = [
        // { key: 'accountPayableNotificationEnabled', title: 'Contas a pagar', description: 'Envie mensagens em massa na tela contas a pagar.', selected: false },
        { key: 'accountReceivableNotificationEnabled', title: 'Contas a receber', description: 'Envie mensagens em massa na tela contas a receber.', selected: false },
        { key: 'birthdayNotificationEnabled', title: 'Aniversários', description: 'Envie mensagens para aniversariantes do mês.', selected: false },
        { key: 'inactiveCustomerNotificationEnabled', title: 'Clientes potenciais', description: 'Envie mensagens para clientes que nunca compraram.', selected: false },
        { key: 'paymentKeyNotificationEnabled', title: 'Boletos', description: 'Envie mensagens com chaves de pagamento de boletos.', selected: false },
        // { key: 'paymentReminderNotificationEnabled', title: 'Lembretes de pagamento', description: 'Envie lembretes de pagamento via WhatsApp.', selected: false },
        // { key: 'promotionsAndSuggestionsNotificationEnabled', title: 'Promoções', description: 'Envie mensagens promocionais para os clientes.', selected: false },
    ];

    public panelTwilioPeriodicity: MonthYear = new MonthYear(this._now.getMonth(), this._now.getFullYear());

    public panelTwilioNotificationStatement: NotificationNotificationStatementDTO = null;

    // Connect
    public panelConnectTypeVersionList: { label: string, type: 'V1' | 'V8' }[] = [
        { label: 'Versão 1', type: 'V1' },
        { label: 'Versão 8', type: 'V8' }
    ];

    public panelConnectTypeVersion: 'V1' | 'V8' = 'V1';

    public panelConnectClientId: string = '';
    public panelConnectLogin: string = '';
    public panelConnectPassword: string = '';

    constructor(
        protected service: NotificationCustomerNotificationConfigService,
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _authenticationService: CustomAuthenticationService,
        private _companyService: FinancialCompanyService,
        private _invoiceIntegrationsService: NotificationInvoiceIntegrationsService,
        public dialogRef: MatDialogRef<CustomerNotificationConfigDialogComponent>,
    ) {
        super(baseService, activatedRoute);
        this.dialogRef.disableClose = true;
        this._findCustomerNotificationConfig();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    private _findCustomerNotificationConfig(): void {
        try {
            this.loading = true;
            this.service.findAllEnabled(0, 50).toPromise().then(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    this.entity = ObjectUtil.clone(page.content[0]);

                    for (const option of this.panelTwilioOptions) {
                        option.selected = this.entity[option.key];
                    }

                    this.findNotificationStatement();
                } else {
                    this._setCnpjCompany();
                }
                this.loading = false;
            }).catch(e => {
                this.loading = false;
                this.handleError(e);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    private _setCnpjCompany(): void {
        if (ObjectUtil.isNull(this.entity)) {
            return;
        }

        const token: TokenDTO<OAuthUser> = this._authenticationService.tokenValue;
        if (this._authenticationService.isValidToken(token)) {
            this._companyService.getOne<FinancialCompany>(token.user.currentCompany).subscribe(company => {
                if (!ObjectUtil.isNull(company) && !ObjectUtil.isNull(company.person)) {
                    const cpfOrCnpj: string = !ObjectUtil.isNull(company.person.legalPerson)
                        ? company.person.legalPerson.cnpj
                        : company.person.naturalPerson.cpf;

                    this.entity.cnpj = cpfOrCnpj;

                    if (!ArrayUtil.isEmpty(company.person.emailList)) {
                        this.entity.emailCustomer = company.person.emailList[0].email;
                    }
                }
            }, error => {
                this.loading = false;
                this.addErrorMessage(error);
                return null;
            });
        }
    }

    private _isValid(): boolean {
        if (ObjectUtil.isNull(this.entity)) {
            return false;
        }
        if (StringUtil.isEmpty(this.entity.emailCustomer)) {
            this.addWarningMessage('Campo e-mail é obrigatório e não foi informado');
            return false;
        }
        if (StringUtil.isEmpty(this.entity.phoneNumber)) {
            this.addWarningMessage('Campo e-mail é obrigatório e não foi informado');
            return false;
        }
        return true;
    }

    public confirm(): void {
        try {
            if (!this._isValid()) {
                return;
            }
            this.loading = true;

            for (const option of this.panelTwilioOptions) {
                this.entity[option.key] = option.selected;
            }

            // Passa null pra sempre gerar uma nova configuração
            this.entity.id = null;

            this.service.save(this.entity).toPromise().then(_ => {
                this.loading = false;
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);

                if (ObjectUtil.isNull(this.service.updated$)) {
                    this.service.updated$ = new BehaviorSubject(false);
                }
                this.service.updated$.next(true);

                this.close();
            }).catch(e => {
                this.loading = false;
                this.handleError(e);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public close(): void {
        this.dialogRef.close();
    }

    public setCaretEndNumber(event: KeyboardEvent, phoneNumber: InputComponent): void {
        const keysPermitted: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        if (keysPermitted.indexOf(event.key) >= 0) {
            phoneNumber._elementRef.nativeElement.setSelectionRange(100000, 100000);
        }
    }

    public validateEmail(email: string): void {
        if (!StringUtil.isEmpty(email) && !EmailUtil.isValid(email)) {
            this.addWarningMessage('O e-mail informado é inválido');
        }
    }

    public findNotificationStatement(): void {
        if (
            ObjectUtil.isNull(this.entity) ||
            (!ObjectUtil.isNull(this.entity) && !this.entity.paymentTerm)
        ) {
            return;
        }

        this.loading = true;
        const invoiceDate: moment.Moment = moment().year(this.panelTwilioPeriodicity.year).month(this.panelTwilioPeriodicity.month).date(this.entity.paymentTerm);
        if (invoiceDate && invoiceDate.isValid()) {
            const startDate: Date = moment(invoiceDate).subtract(30, 'day').startOf('day').toDate();
            const endDate: Date = invoiceDate.endOf('day').toDate();
            this._invoiceIntegrationsService.findNotificationStatement(startDate, endDate).toPromise()
                .then(notificationStatement => {
                    if (!ObjectUtil.isNull(notificationStatement)) {
                        this.panelTwilioNotificationStatement = notificationStatement;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.handleError(error);
                });
        }
    }

}
