import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';

export class RegistrationBenefitClub extends GIPIBaseAbstractModel {

    /**
     * @Required Descrição
     * @Min 3 characters
     * @Max 80 characters
     */
    description: string;

    /** @Transient */
    selected: boolean;

}
