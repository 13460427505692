import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { ArrayUtil, DateUtil, NumberUtil, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import { FinancialPaths } from '../../../paths/financial.paths';
import { FinancialBillInstallmentRenegotiation } from '../models/bill-installment-renegotiation.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/services/custom-authentication.service";
export class FinancialBillService extends BaseCrudService {
    constructor(http, authenticationService) {
        super(FinancialPaths.bill, http, authenticationService);
    }
    validate(entity) {
        super.validate(entity);
        if (entity.type === 'RECEIVABLE' && ObjectUtil.isNewModel(entity.client)) {
            throw new Error('Cliente é obrigatório e não foi informado');
        }
        if (entity.type === 'PAYABLE' && ObjectUtil.isNewModel(entity.provider)) {
            throw new Error('Fornecedor é obrigatório e não foi informado');
        }
        entity.billInstallmentList.forEach((bi) => {
            if (StringUtil.isEmpty(bi.description)) {
                throw new Error('Descrição é obrigatório e não foi informado');
            }
            if (ObjectUtil.isNewModel(bi.bankAccount)) {
                throw new Error('Conta bancária é obrigatório e não foi informado');
            }
            if (ObjectUtil.isNewModel(bi.postingCategory)) {
                throw new Error('Categoria é obrigatório e não foi informado');
            }
            if (ObjectUtil.isNewModel(bi.chargeType)) {
                throw new Error('Tipo de cobrança é obrigatório e não foi informado');
            }
            if (ObjectUtil.isNull(bi.dueDate)) {
                throw new Error('Data de vencimento é obrigatório e não foi informado');
            }
            if (ObjectUtil.isNull(bi.issuanceDate)) {
                throw new Error('Data de emissão é obrigatório e não foi informado');
            }
            if ((bi.status === 'RECEIVABLE' || bi.status === 'RECEIVABLE_OVERDUE' || bi.status === 'PAYABLE' || bi.status === 'PAYABLE_OVERDUE')
                && (!NumberUtil.isPositive(bi.amount) || bi.amount === 0)) {
                throw new Error('Valor da conta é obrigatório e não foi informado');
            }
            const dueDate = new Date(moment(bi.dueDate).format('yyyy/MM/DD'));
            const issuanceDate = new Date(bi.issuanceDate);
            if (DateUtil.isLessThan(new Date(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate()), new Date(issuanceDate.getFullYear(), issuanceDate.getMonth(), issuanceDate.getDate()))) {
                throw new Error('Data de vencimento não pode ser menor que a data de emissão');
            }
            this.validateAssessment(bi);
            this.loadStatus(bi, entity.type);
        });
    }
    validateAssessment(entity) {
        if (!ArrayUtil.isEmpty(entity.assessmentList) && entity.assessmentList.reduce((a, b) => +a + b.percentage, 0) < 100) {
            throw new Error('Verifique o rateio para o centro de custo, ele não está completo');
        }
    }
    loadStatus(entity, type) {
        if (type === 'RECEIVABLE' && ObjectUtil.isNewModel(entity) && StringUtil.isEmpty(entity.status)) {
            entity.status = 'RECEIVABLE';
        }
        else if (type === 'PAYABLE' && ObjectUtil.isNewModel(entity) && StringUtil.isEmpty(entity.status)) {
            entity.status = 'PAYABLE';
        }
    }
    generateRenegotiationList(billNew, billInstallmentOldList, userLoggedId) {
        const billInstallmentRenegotiation = new FinancialBillInstallmentRenegotiation();
        billInstallmentRenegotiation.userId = userLoggedId;
        billInstallmentOldList.forEach(installment => {
            billInstallmentRenegotiation.renegotiatedBillInstallment.push(Number(installment.id));
        });
        billNew.billInstallmentRenegotiation = billInstallmentRenegotiation;
        return Promise.resolve();
    }
}
FinancialBillService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialBillService_Factory() { return new FinancialBillService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CustomAuthenticationService)); }, token: FinancialBillService, providedIn: "root" });
