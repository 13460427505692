import { SalePaths } from '@gipi-paths/sale.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class SaleReasonDevolutionService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(SalePaths.reasonDevolution, 'v1', baseService);
        this.baseService = baseService;
    }
}
SaleReasonDevolutionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SaleReasonDevolutionService_Factory() { return new SaleReasonDevolutionService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: SaleReasonDevolutionService, providedIn: "root" });
