import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationEmail } from '@gipi-registration/person/models/email.model';
import { RegistrationPerson } from '@gipi-registration/person/models/person.model';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ArrayUtil, EmailUtil, StringUtil, UUIDUtil } from '@gipisistemas/ng-core';
export class EmailFormComponent extends AbstractComponent {
    constructor(messageService, router, activatedRoute) {
        super(messageService, router, activatedRoute);
        this.emailListValue = [];
        this.emailListChange = new EventEmitter();
        this.person = new RegistrationPerson();
        this.isCreating = false;
        this.isViewing = false;
    }
    get emailList() {
        return this.emailListValue;
    }
    set emailList(emailList) {
        this.emailListValue = emailList;
        this.emailListChange.emit(this.emailListValue);
    }
    ngOnInit() {
        super.ngOnInit();
        this.loading = true;
        setTimeout(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (ArrayUtil.isEmpty(this.emailList)) {
                this.emailList = [];
                // this.addNewEmail();
            }
        }));
        this.loading = false;
    }
    deleteEmail(email) {
        if (UUIDUtil.isValid(email.id)) {
            this.emailList.splice(this.emailList.findIndex(e => e.id === email.id), 1);
        }
        else {
            this.emailList.splice(this.emailList.findIndex(e => e.key === email.key), 1);
        }
    }
    addNewEmail() {
        const email = new RegistrationEmail();
        email.key = this.emailList.length;
        this.emailList.push(email);
    }
    validateEmail(email) {
        if (!StringUtil.isEmpty(email.email) && !EmailUtil.isValid(email.email)) {
            this.addErrorMessage('E-mail inválido');
        }
    }
}
