import { RegistrationPerson } from "@gipi-registration/person/models/person.model";
import { GIPIBaseAbstractModel } from "@gipi-shared/models/base-abstract.model";
import { GIPIUuid } from "@gipisistemas/ng-core";

export class RegistrationProviderRepresentative extends GIPIBaseAbstractModel {

    person: RegistrationPerson;

    registrationProviderRepresentativeId: GIPIUuid;

    /** @Transient */
    key: number;

    constructor() {
        super();
        this.person = new RegistrationPerson();
    }

}
