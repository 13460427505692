import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';

import { WhatsappContact } from '@gipi-shared/models/whatsapp-contact.model';
import { GIPIAbstractComponent, GIPIBaseService, ObjectUtil, PhoneUtil, StringUtil } from '@gipisistemas/ng-core';

@Component({
    selector: 'gipi-whatsapp-contact-dialog',
    templateUrl: './whatsapp-contact-dialog.component.html',
    styleUrls: ['./whatsapp-contact-dialog.component.scss']
})
export class WhatsappContactDialogComponent extends GIPIAbstractComponent implements OnInit {

    public entity: WhatsappContact = new WhatsappContact();

    public get recipientInitial(): Observable<string> {
        if (!ObjectUtil.isNull(this.entity) && StringUtil.isEmpty(this.entity.recipientName)) {
            return of('?');
        }
        return of(this.entity.recipientName.charAt(0).toUpperCase());
    }

    public get recipientFirstName(): Observable<string> {
        if (!ObjectUtil.isNull(this.entity) && StringUtil.isEmpty(this.entity.recipientName)) {
            return of('');
        }
        return of(this.entity.recipientName.split(' ')[0]);
    }

    public get recipientPhone(): Observable<string> {
        if (!ObjectUtil.isNull(this.entity) && StringUtil.isEmpty(this.entity.recipientPhone)) {
            return of('WhatsApp não informado');
        }
        return of(PhoneUtil.format(this.entity.recipientPhone));
    }

    constructor(
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        public dialogRef: MatDialogRef<WhatsappContactDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: WhatsappContact,
    ) {
        super(baseService, activatedRoute);
        this.dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (!ObjectUtil.isNull(this.data)) {
            this.entity = ObjectUtil.clone(this.data);
        }
    }

    public setMessageDefault(): void {
        this.entity.message = this.data.defaultMessage ? this.data.defaultMessage : '';
    }

    public clearMessageDefault(): void {
        this.entity.message = '';
    }

    public sendMessage(): void {
        try {
            if (StringUtil.isEmpty(this.entity.message)) {
                this.addWarningMessage('Campo mensagem é obrigatório e não foi informado');
                return;
            }

            const encodedMessage: string = encodeURIComponent(this.entity.message);
            const phone: string = !StringUtil.isEmpty(this.entity.recipientPhone) ? `55${this.entity.recipientPhone}` : '';

            /**
             * Se mudar a url tem que verificar no sistema legado pois
             * la é utilizado para verificações o seguinte trecho: "https://wa.me"
             */
            let urlWhatsapp: string = `https://wa.me/${phone}`;
            if (!StringUtil.isEmpty(encodedMessage)) {
                urlWhatsapp += `?text=${encodedMessage}&type=phone_number&app_absent=0`;
            }

            // Abre uma nova janela para enviar a mensagem no WhatsApp
            window.open(urlWhatsapp, '_blank');
            this.dialogRef.close(this.entity.message);
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public close(): void {
        this.dialogRef.close();
    }

    public static saveMessage(key: string, message: string): void {
        const encryptedMessage = StringUtil.encodeBase64(message);
        localStorage.setItem(key, encryptedMessage);
    }

    public static getMessage(key: string): string {
        const encryptedMessage = localStorage.getItem(key);
        return encryptedMessage ? StringUtil.decodeBase64(encryptedMessage) : '';
    }

}
