import { Injectable } from '@angular/core';

import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService, StringUtil } from '@gipisistemas/ng-core';
import { RegistrationManufacturerFilterDTO } from '../models/dto/manufacturer-filter.dto';
import { RegistrationManufacturerSelectDTO } from '../models/dto/manufacturer-select.dto';
import { RegistrationManufacturer } from '../models/manufacturer.model';

@Injectable({ providedIn: 'root' })
export class RegistrationManufacturerService extends GIPIAbstractCrudService<RegistrationManufacturer, RegistrationManufacturerFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(RegistrationPaths.manufacturer, 'v1', baseService);
    }

    getDescription(entity: RegistrationManufacturerSelectDTO): string {
        const cnpj: string = !StringUtil.isEmpty(entity.cnpj) ? StringUtil.format(entity.cnpj, '00.000.000/0000-00') + ' - ' : '';
        return `${cnpj}${entity.name}`;
    }

}
