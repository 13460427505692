import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialCheckStatus } from '@gipi-financial/check/enums/check-status.enum';
import { FinancialClient } from '@gipi-financial/client/models/client.model';
import { FinancialPostingCategory } from '@gipi-financial/posting-category/models/posting-category.model';
import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';
import { GIPIUuid } from '@gipisistemas/ng-core';

export class FinancialCheckReceivedFilterDTO extends GIPIBaseAbstractFilterModel {

    amount: number;

    bankAccount: FinancialBankAccount;

    bankAccountName?: string;

    begin: Date;

    category?: FinancialPostingCategory;

    categoryName?: string;

    checkNumber: number;

    checkReceivedIdsExcluded: GIPIUuid[];

    client: FinancialClient;

    createdDate: Date;

    end: Date;

    issuer?: string;

    statusList: FinancialCheckStatus[] = [];

}
