<gipi-abstract-form id="dialogAddCheckReceived"
                    name="dialogAddCheckReceived"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogAddCheckReceived"
                  name="toolbarDialogAddCheckReceived">
        Adicionar cheque recebido
    </gipi-toolbar>
    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="10px">
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxLayout.lt-md="column">
            <itss-select-entity-paged label="Cliente"
                                      id="checkReceivedClient"
                                      name="checkReceivedClient"
                                      #firstInput
                                      [disabled]="loading || (data.checkReceivedType === 'INTEREST_AND_FINE')"
                                      [(model)]="entity.client"
                                      [propertyFn]="clientFn"
                                      [nextBatchFn]="clientFindByValueFn"
                                      fxFlex>
            </itss-select-entity-paged>
        </div>
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxLayout.lt-md="column">
            <itss-input label="CPF/CNPJ"
                        id="checkReceivedCpfOrCnpjIssuer"
                        name="checkReceivedCpfOrCnpjIssuer"
                        [required]="true"
                        [disabled]="loading"
                        [mask]="'000.000.000-00||00.000.000/0000-00'"
                        [(ngModel)]="entity.cpfOrCnpjIssuer"
                        fxFlex="23.6"
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Emitente"
                        id="checkReceivedIssuer"
                        name="checkReceivedIssuer"
                        [required]="true"
                        [disabled]="loading"
                        [(ngModel)]="entity.issuer"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Telefone"
                        id="checkReceivedNumberPhone"
                        name="checkReceivedNumberPhone"
                        #numberPhone
                        [placeholder]="'(00) 0 0000-0000'"
                        [disabled]="loading"
                        [max]="12"
                        [phone]="true"
                        [mask]="''"
                        [(ngModel)]="entity.phone"
                        (keyup)="setCaretEndNumber($event, numberPhone)"
                        fxFlex="23.6"
                        fxFlex.lt-md="100">
            </itss-input>
        </div>
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxLayout.lt-md="column"
             fxFlex>
            <itss-select-entity-paged label="Banco"
                                      id="checkReceivedBank"
                                      name="checkReceivedBank"
                                      placeholder="Selecione"
                                      [disabled]="loading"
                                      [(model)]="entity.bank"
                                      [nextBatchFn]="bankFindByValueFn"
                                      [propertyFn]="bankFn"
                                      fxFlex
                                      fxFlex.lt-md="100">
            </itss-select-entity-paged>
            <itss-input label="Agência"
                        id="checkReceivedAgency"
                        name="checkReceivedAgency"
                        [disabled]="loading"
                        [(ngModel)]="entity.agency"
                        [mask]="'0*'"
                        [maxlength]="5"
                        fxFlex="17"
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Dígito"
                        id="checkReceivedAgencyDigit"
                        name="checkReceivedAgencyDigit"
                        [disabled]="loading"
                        [(ngModel)]="entity.agencyDigit"
                        [mask]="'0*'"
                        [maxlength]="3"
                        fxFlex="5"
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Conta"
                        id="checkReceivedAccount"
                        name="checkReceivedAccount"
                        [disabled]="loading"
                        [(ngModel)]="entity.account"
                        [mask]="'0*'"
                        [maxlength]="20"
                        fxFlex="17"
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Dígito"
                        id="checkReceivedAccountDigit"
                        name="checkReceivedAccountDigit"
                        [disabled]="loading"
                        [(ngModel)]="entity.accountDigit"
                        [mask]="'0*'"
                        [maxlength]="3"
                        fxFlex="5"
                        fxFlex.lt-md="100">
            </itss-input>
        </div>
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxLayout.lt-md="column"
             fxFlex>
            <gipi-datepicker label="Data emissão"
                             id="checkReceivedIssuanceDate"
                             name="checkReceivedIssuanceDate"
                             [required]="true"
                             [disabled]="loading"
                             [max]="compensateDate"
                             [(ngModel)]="entity.issuanceDate"
                             fxFlex
                             fxFlex.lt-md="100">
            </gipi-datepicker>
            <gipi-datepicker label="Data vencimento"
                             id="checkReceivedDueDate"
                             name="checkReceivedDueDate"
                             [required]="true"
                             [disabled]="loading"
                             [min]="entity.issuanceDate"
                             [max]="compensateDate"
                             [(ngModel)]="entity.dueDate"
                             fxFlex
                             fxFlex.lt-md="100">
            </gipi-datepicker>
            <itss-input label="Nº cheque"
                        id="checkReceivedCheckNumber"
                        name="checkReceivedCheckNumber"
                        [required]="true"
                        [disabled]="loading"
                        [(ngModel)]="entity.checkNumber"
                        [mask]="'0*'"
                        [maxlength]="10"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input-currency label="Valor"
                                 id="checkReceivedAmount"
                                 name="checkReceivedAmount"
                                 [placeholder]="'R$ 0,00'"
                                 [required]="true"
                                 [disabled]="true"
                                 [(ngModel)]="entity.amount"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>
        </div>
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxLayout.lt-md="column">
            <itss-select-entity-paged label="Categoria"
                                      id="checkReceivedCategory"
                                      name="checkReceivedCategory"
                                      [required]="true"
                                      [disabled]="loading"
                                      [(model)]="entity.postingCategory"
                                      [nextBatchFn]="postingCategoryFindByValueFn"
                                      [propertyFn]="postingCategoryFn"
                                      fxFlex
                                      fxFlex.lt-md="100">
            </itss-select-entity-paged>
            <itss-input label="Observações"
                        id="checkReceivedComments"
                        name="checkReceivedComments"
                        [disabled]="loading"
                        [(ngModel)]="entity.comments"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
        </div>

        <div class="content-compensate">
            <label for="contentCompensate">
                Compensação
            </label>
            <div id="contentCompensate"
                 fxLayout="column"
                 fxLayoutAlign="start"
                 fxLayoutGap="16px">
                <div fxLayout="row"
                     fxLayoutGap="16px"
                     fxLayout.lt-md="column">
                    <itss-select-entity label="Conta bancária"
                                        id="checkReceivedCompensateBankAccount"
                                        name="checkReceivedCompensateBankAccount"
                                        [disabled]="true"
                                        [entities]="bankAccountList"
                                        [(model)]="entity.bankAccount"
                                        [property]="'description'"
                                        fxFlex
                                        fxFlex.lt-md="100">
                    </itss-select-entity>
                    <gipi-datepicker label="Data"
                                     id="checkReceivedCompensateDate"
                                     name="checkReceivedCompensateDate"
                                     [disabled]="true"
                                     [(ngModel)]="compensateDate"
                                     fxFlex="20"
                                     fxFlex.lt-md="100">
                    </gipi-datepicker>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     [loading]="loading"
                     [disabled]="loading"
                     (click)="saveCheckReceived()"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     fxFlex="20"
                     (click)="close('NONE')"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>
