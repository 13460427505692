import { Injectable } from '@angular/core';

import { GIPIAbstractCrudService, GIPIAbstractFilterModel, GIPIBaseService } from '@gipisistemas/ng-core';
import { SalePaths } from '../../../paths/sale.paths';
import { SaleState } from '../models/state.model';

@Injectable({ providedIn: 'root' })
export class SaleStateService extends GIPIAbstractCrudService<SaleState, GIPIAbstractFilterModel> {

    constructor(protected baseService: GIPIBaseService) {
        super(SalePaths.state, 'v1', baseService);
    }

}
