import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { GIPIUuid, UUIDUtil } from '@gipisistemas/ng-core';

export class RegistrationApplication extends GIPIBaseAbstractModel {

    /**
     * @Required Descrição
     * @Min 5 characters
     * @Max 60 characters
     */
    description: string;

    constructor(id?: GIPIUuid) {
        super();
        if (UUIDUtil.isValid(id)) {
            this.id = id;
        }
    }

}
