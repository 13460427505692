import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FinancialBankAccountService } from '@gipi-financial/bank-account/services/bank-account.service';
import { FinancialCheckReceivedService } from '@gipi-financial/check-received/services/check-received.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, DateUtil, ObjectUtil } from '@gipisistemas/ng-core';
export class ActionsCheckReceivedDialogComponent extends AbstractComponent {
    constructor(dialogRef, data, _checkReceivedService, _bankAccountService, messageService, router, activatedRoute) {
        super(messageService, router, activatedRoute);
        this.dialogRef = dialogRef;
        this.data = data;
        this._checkReceivedService = _checkReceivedService;
        this._bankAccountService = _bankAccountService;
        this.checkReceivedList = [];
        this.bankAccountFindByTypeFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._bankAccountService.findByValue(value, page, 10).toPromise();
            return result;
        });
    }
    ngOnInit() {
        const _super = Object.create(null, {
            ngOnInit: { get: () => super.ngOnInit }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            _super.ngOnInit.call(this);
            this.checkReceivedList = Object.keys(this.data.entityList).map(key => this.data.entityList[key]);
            this.action = this.data.action;
            this.moveDate = new Date();
        });
    }
    setTitle() {
        switch (this.action) {
            case 'DEPOSIT_CHECK': {
                return 'Depositar';
            }
            case 'CUSTODY_CHECK': {
                return 'Enviar para custódia';
            }
            case 'COMPENSATE_DEPOSIT_CHECK': {
                return 'Compensar';
            }
        }
    }
    setLabelDate() {
        switch (this.action) {
            case 'DEPOSIT_CHECK': {
                return 'Data depósito';
            }
            case 'CUSTODY_CHECK': {
                return 'Data envio custódia';
            }
            case 'COMPENSATE_DEPOSIT_CHECK': {
                return 'Data compensação';
            }
        }
    }
    validateMovement() {
        if (!DateUtil.isValid(this.moveDate)) {
            throw new Error(`Campo ${this.setLabelDate()} foi informado incorretamente`);
        }
        if ((ObjectUtil.isNewModel(this.bankAccount)) && ((this.action === 'DEPOSIT_CHECK') || (this.action === 'CUSTODY_CHECK'))) {
            throw new Error('Campo conta bancária é obrigatório e não informado');
        }
    }
    confirm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.validateMovement();
                this.loading = true;
                for (let i = 0; i < this.checkReceivedList.length; i++) {
                    this.checkReceivedList[i].check.moveDate = this.moveDate;
                    switch (this.action) {
                        case 'DEPOSIT_CHECK': {
                            this.checkReceivedList[i].bankAccountIdReversal = Number(this.checkReceivedList[i].bankAccount.id);
                            this.checkReceivedList[i].bankAccount = this.bankAccount;
                            this.checkReceivedList[i].check.status = 'DEPOSITED';
                            break;
                        }
                        case 'CUSTODY_CHECK': {
                            this.checkReceivedList[i].bankAccountIdReversal = Number(this.checkReceivedList[i].bankAccount.id);
                            this.checkReceivedList[i].bankAccount = this.bankAccount;
                            this.checkReceivedList[i].check.status = 'IN_CUSTODY';
                            break;
                        }
                        case 'COMPENSATE_DEPOSIT_CHECK': {
                            this.checkReceivedList[i].check.status = 'COMPENSATED';
                            break;
                        }
                    }
                }
                this._checkReceivedService.moveCheckReceived(this.checkReceivedList, this.action).toPromise().then(() => {
                    this.close('RELOAD_TABLE');
                }, error => {
                    throw new Error(error);
                });
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation) {
        this.dialogRef.close(operation);
    }
}
