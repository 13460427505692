import * as tslib_1 from "tslib";
import { of } from "rxjs";
import { catchError, map, takeUntil } from "rxjs/operators";
import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialChargeTypeService } from "@gipi-financial/charge-type/services/charge-type.service";
import { FinancialPaymentDTO } from "@gipi-financial/payment/models/dto/payment.dto";
import { FinancialReceivementRequestDTO } from "@gipi-financial/receivement/models/dto/receivement-request.dto";
import { FinancialReceivement } from "@gipi-financial/receivement/models/receivement.model";
import { FinancialPaths } from "@gipi-paths/financial.paths";
import { ArrayUtil, GIPIAbstractCrudService, GIPIBaseService, NumberUtil, UUIDUtil } from "@gipisistemas/ng-core";
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
import * as i2 from "../../charge-type/services/charge-type.service";
export class FinancialTransactionConciliationService extends GIPIAbstractCrudService {
    constructor(baseService, _chargeTypeService) {
        super(FinancialPaths.transactionConciliation, 'v1', baseService);
        this.baseService = baseService;
        this._chargeTypeService = _chargeTypeService;
    }
    /**
     * Refers to the cancel-conciliation endpoint
     * @type POST
     * @path resource/version/cancel-conciliation
     * @version v2
     */
    cancelConciliation(idList) {
        for (let i = 0; i < idList.length; i++) {
            if (!UUIDUtil.isValid(idList[i])) {
                return of(false);
            }
        }
        return this.baseService.httpClient.post(this.url('cancel-conciliation', 'v2'), idList, this.options()).pipe(map(() => { return true; }), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    /**
     * Refers to the ignore endpoint
     * @type POST
     * @path resource/version/ignore
     * @version v2
     */
    ignore(idList) {
        for (let i = 0; i < idList.length; i++) {
            if (!UUIDUtil.isValid(idList[i])) {
                return of(false);
            }
        }
        return this.baseService.httpClient.post(this.url('ignore', 'v2'), idList, this.options()).pipe(map(() => { return true; }), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    /**
     * Refers to the restore endpoint
     * @type POST
     * @path resource/version/restore
     * @version v2
     */
    restore(idList) {
        for (let i = 0; i < idList.length; i++) {
            if (!UUIDUtil.isValid(idList[i])) {
                return of(false);
            }
        }
        return this.baseService.httpClient.post(this.url('restore', 'v2'), idList, this.options()).pipe(map(() => { return true; }), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    /**
     * Refers to the conciliation endpoint
     * @type POST
     * @path resource/version/conciliation
     * @version v1
     */
    conciliation(transactionConciliationDTO) {
        return this.baseService.httpClient.post(this.url('conciliation'), transactionConciliationDTO, this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    newReceivement(transactionConciliation) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const receivementDTO = new FinancialReceivementRequestDTO();
            receivementDTO.receivement = new FinancialReceivement();
            this._setAmount(transactionConciliation);
            receivementDTO.receivement.amountReceivable = transactionConciliation.totalInstallmentsValue;
            receivementDTO.receivement.amountReceived = transactionConciliation.totalInstallmentsValue;
            receivementDTO.receivement.interest = transactionConciliation.installmentInterest;
            receivementDTO.receivement.fine = transactionConciliation.installmentFine;
            receivementDTO.receivement.addition = transactionConciliation.installmentAddition;
            receivementDTO.receivement.discount = transactionConciliation.installmentDiscount;
            receivementDTO.receivement.fineChangedOnReceipt = true;
            receivementDTO.receivement.interestChangedOnReceipt = true;
            receivementDTO.billInstallmentList = transactionConciliation.installmentListInternal;
            receivementDTO.receivement.bankAccount = new FinancialBankAccount(transactionConciliation.bankAccountId);
            receivementDTO.receivementCardList = null;
            receivementDTO.useClientCredit = false;
            receivementDTO.receivement.attachment = null;
            receivementDTO.receivement.billInstallment = null;
            receivementDTO.receivement.billetReturn = false;
            if (this._existInstallmentCreatedByConciliation(transactionConciliation)) {
                const billInstallment = transactionConciliation.installmentListInternal.find(i => i.createdByConciliation || i.createdByDifferenceConciliation);
                receivementDTO.receivement.chargeType = billInstallment.chargeTypeReceivement;
            }
            else {
                const chargeTypeDeposit = yield this._chargeTypeService.findByTypes(['DEPOSIT']).toPromise();
                if (!ArrayUtil.isEmpty(chargeTypeDeposit)) {
                    receivementDTO.receivement.chargeType = chargeTypeDeposit[0];
                }
            }
            receivementDTO.receivement.checkReceivedList = null;
            receivementDTO.receivement.comments = null;
            receivementDTO.receivement.creditClientList = null;
            receivementDTO.receivement.usedCustomerCredit = 0;
            receivementDTO.receivement.enabled = true;
            receivementDTO.receivement.historic = null;
            receivementDTO.receivement.receivementDate = this.getDateTransaction(transactionConciliation.date);
            receivementDTO.receivement.shift = null;
            return Promise.resolve(receivementDTO);
        });
    }
    newPaymentList(transactionConciliation) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const chargeTypeMoney = yield this._chargeTypeService.findByTypes(['MONEY']).toPromise();
            const paymentDTOList = [];
            this._setAmount(transactionConciliation);
            for (let i = 0; i < transactionConciliation.installmentListInternal.length; i++) {
                const paymentDTO = new FinancialPaymentDTO();
                paymentDTO.bankAccount = new FinancialBankAccount(transactionConciliation.bankAccountId);
                paymentDTO.billInstallmentList = transactionConciliation.installmentListInternal;
                paymentDTO.paymentDate = this.getDateTransaction(transactionConciliation.date);
                paymentDTO.shift = null;
                if (transactionConciliation.installmentListInternal.length === 1) {
                    paymentDTO.amount = transactionConciliation.totalInstallmentsValue;
                    paymentDTO.amountPaid = transactionConciliation.totalInstallmentsValue;
                    paymentDTO.interest = transactionConciliation.installmentInterest;
                    paymentDTO.fine = transactionConciliation.installmentFine;
                    paymentDTO.discount = transactionConciliation.installmentDiscount;
                }
                else {
                    paymentDTO.amount = transactionConciliation.installmentListInternal[i].amount;
                    paymentDTO.amountPaid = transactionConciliation.installmentListInternal[i].amount;
                    paymentDTO.interest = transactionConciliation.installmentListInternal[i].interest;
                    paymentDTO.fine = transactionConciliation.installmentListInternal[i].fine;
                    paymentDTO.discount = transactionConciliation.installmentListInternal[i].discount;
                }
                if (!ArrayUtil.isEmpty(chargeTypeMoney)) {
                    paymentDTO.chargeType = chargeTypeMoney[0];
                }
                paymentDTOList.push(paymentDTO);
            }
            return Promise.resolve(paymentDTOList);
        });
    }
    getDateTransaction(dateTransactionConciliation) {
        const day = dateTransactionConciliation.toString().substring(8, 10);
        const month = dateTransactionConciliation.toString().substring(5, 7);
        const year = dateTransactionConciliation.toString().substring(0, 4);
        const dateTransaction = `${year}/${month}/${day}`;
        return new Date(dateTransaction);
    }
    _setAmount(transactionConciliation) {
        if (transactionConciliation.differenceIsPartialPayment) {
            transactionConciliation.totalInstallmentsValue = Number(transactionConciliation.value);
        }
        else {
            if (!ArrayUtil.isEmpty(transactionConciliation.installmentListInternal)) {
                const amountAuxCreatedByConciliation = transactionConciliation.installmentListInternal
                    .filter(i => i.createdByConciliation || i.createdByDifferenceConciliation)
                    .reduce((sum, i) => sum += i.amount, 0);
                const amountAuxPartial = transactionConciliation.installmentListInternal
                    .filter(i => i.status === 'RECEIVED_PARTIAL' && !i.createdByConciliation && !i.createdByDifferenceConciliation)
                    .reduce((sum, i) => sum += i.amount, 0);
                const amountAuxReceivable = transactionConciliation.installmentListInternal
                    .filter(i => i.status !== 'RECEIVED' && i.status !== 'PAID' && i.status !== 'RECEIVED_PARTIAL' && !i.createdByConciliation && !i.createdByDifferenceConciliation)
                    .reduce((sum, i) => sum += i.amount, 0);
                const amountAuxReceived = transactionConciliation.installmentListInternal
                    .filter(i => i.status === 'RECEIVED' || i.status === 'PAID' && !i.createdByConciliation && !i.createdByDifferenceConciliation)
                    .reduce((sum, i) => sum += i.amount, 0);
                transactionConciliation.installmentInterest += transactionConciliation.installmentListInternal
                    .filter(i => i.createdByConciliation || i.createdByDifferenceConciliation)
                    .reduce((sum, i) => sum += i.interest, 0);
                transactionConciliation.installmentFine += transactionConciliation.installmentListInternal
                    .filter(i => i.createdByConciliation || i.createdByDifferenceConciliation)
                    .reduce((sum, i) => sum += i.fine, 0);
                transactionConciliation.installmentAddition += transactionConciliation.installmentListInternal
                    .filter(i => i.createdByConciliation || i.createdByDifferenceConciliation)
                    .reduce((sum, i) => sum += i.addition, 0);
                transactionConciliation.installmentDiscount += transactionConciliation.installmentListInternal
                    .filter(i => i.createdByConciliation || i.createdByDifferenceConciliation)
                    .reduce((sum, i) => sum += i.discount, 0);
                let amountAux = Number((amountAuxCreatedByConciliation + amountAuxPartial + amountAuxReceivable + amountAuxReceived).toFixed(2));
                if (NumberUtil.isPositive(transactionConciliation.installmentInterest)) {
                    amountAux += transactionConciliation.installmentInterest;
                }
                if (NumberUtil.isPositive(transactionConciliation.installmentFine)) {
                    amountAux += transactionConciliation.installmentFine;
                }
                if (NumberUtil.isPositive(transactionConciliation.installmentAddition)) {
                    amountAux += transactionConciliation.installmentAddition;
                }
                if (NumberUtil.isPositive(transactionConciliation.installmentDiscount)) {
                    amountAux -= transactionConciliation.installmentDiscount;
                }
                transactionConciliation.totalInstallmentsValue = amountAux;
            }
        }
    }
    _existInstallmentCreatedByConciliation(transactionConciliation) {
        return transactionConciliation.installmentListInternal.filter(i => i.createdByConciliation || i.createdByDifferenceConciliation).length > 0;
    }
}
FinancialTransactionConciliationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialTransactionConciliationService_Factory() { return new FinancialTransactionConciliationService(i0.ɵɵinject(i1.GIPIBaseService), i0.ɵɵinject(i2.FinancialChargeTypeService)); }, token: FinancialTransactionConciliationService, providedIn: "root" });
