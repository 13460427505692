import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { ArrayUtil, GIPIAbstractCrudService, GIPIBaseService, GIPIPageModel, ObjectUtil } from '@gipisistemas/ng-core';
import { RegistrationProductSelectDTO } from '../models/dto/product-select.dto';
import { RegistrationProduct } from '../models/product.model';

@Injectable({ providedIn: 'root' })
export class RegistrationProductService extends GIPIAbstractCrudService<RegistrationProduct, any> {

    constructor(protected baseService: GIPIBaseService) {
        super(RegistrationPaths.product, 'v1', baseService);
    }

    findByBarCode<T = any>(barCode: string, version?: string): Observable<T> {
        return this.baseService.httpClient.get(this.url(`find-by-value?page=0&size=20&value=${barCode}`, version), this.options()).pipe(
            map(json => {
                const page: GIPIPageModel<RegistrationProductSelectDTO> = this.handleMapper(json);
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    return of(page.content[0]);
                }
                return null;
            }),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        )
    }

    findByCodeBalance<T = any>(code: number, version?: string): Observable<T> {
        return this.baseService.httpClient.get(this.url(`find-by-value?page=0&size=20&value=${code}`, version), this.options()).pipe(
            map(json => {
                const page: GIPIPageModel<RegistrationProductSelectDTO> = this.handleMapper(json);
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    return of(page.content[0]);
                }
                return null;
            }),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        )
    }

}
