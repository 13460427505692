import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { GIPIUuid, UUIDUtil } from '@gipisistemas/ng-core';
import { RegistrationProductGroupingType, RegistrationProductGroupingTypeEnum } from '../enums/product-grouping-type.enum';

export class RegistrationProductGrouping extends GIPIBaseAbstractModel {

    /**
     * @Required Descrição
     * @Min 3 characters
     * @Max 80 characters
     */
    description: string;

    /**
     * @Required Tipo: SECTION | GROUP | SUBGROUP
     */
    type: RegistrationProductGroupingTypeEnum | RegistrationProductGroupingType;

    /** Lista de grupos ou subgrupos dependendo do tipo */
    children: RegistrationProductGrouping[];

    /** @Transient */
    selected: boolean;

    /** @Transient */
    key: number;

    constructor(
        id?: GIPIUuid,
        description?: string,
        type?: RegistrationProductGroupingTypeEnum | RegistrationProductGroupingType,
        children?: RegistrationProductGrouping[],
    ) {
        super();
        this.children = [];

        if (UUIDUtil.isValid(id)) {
            this.id = id;
        }
        if (description) {
            this.description = description;
        }
        if (type) {
            this.type = type;
        }
        if (children) {
            this.children = children;
        }
    }

}
