import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { FinancialBankAccountTransfer } from '@gipi-financial/bank-account-transfer/models/bank-account-transfer.model';
import { FinancialBankAccountTransferService } from '@gipi-financial/bank-account-transfer/services/bank-account-transfer.service';
import { FinancialBankAccountService } from '@gipi-financial/bank-account/services/bank-account.service';
import { APP_MESSAGES, DateUtil, GIPIAbstractComponent, GIPIBaseService, INJECTOR, ObjectUtil } from '@gipisistemas/ng-core';
export class BankAccountsTransferDialogComponent extends GIPIAbstractComponent {
    constructor(baseService, activatedRoute, _bankAccountTransferService, _bankAccountService, dialogRef, data = { typeOperation: 'NEW', bankAccountTransfer: null }) {
        super(baseService, activatedRoute);
        this.baseService = baseService;
        this.activatedRoute = activatedRoute;
        this._bankAccountTransferService = _bankAccountTransferService;
        this._bankAccountService = _bankAccountService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.bankAccountTransfer = new FinancialBankAccountTransfer();
        this.amountOrigin$ = new BehaviorSubject(0);
        this.amountDestiny$ = new BehaviorSubject(0);
        this.isLoad = false;
        this.isBillReceivable = false;
        this.isTransactionConciliation = false;
        this.bankAccountOriginFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._bankAccountService.findByValue(value, page, 10).toPromise();
            return result;
        });
        this.bankAccountDestinyFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._bankAccountService.findByValue(value, page, 10).toPromise();
            return result;
        });
        this.dialogRef.disableClose = true;
    }
    ngOnInit() {
        this.isLoad = true;
        super.ngOnInit();
        this.typeOperation = this.data.typeOperation;
        this.isLoad = false;
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this.amountOrigin$.complete();
        this.amountDestiny$.complete();
    }
    onInitForm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.data.typeOperation !== 'NEW') {
                this.bankAccountTransfer = this.data.bankAccountTransfer;
            }
            if (this.data.isTransactionConciliation) {
                this.bankAccountTransfer = this.data.bankAccountTransfer;
                this.isBillReceivable = this.data.isBillReceivable;
                this.isTransactionConciliation = this.data.isTransactionConciliation;
                const idBankAccount = this.isBillReceivable ? this.bankAccountTransfer.destiny.id : this.bankAccountTransfer.origin.id;
                const bankAccount = yield this._bankAccountService.getOne(idBankAccount).toPromise();
                if (this.isBillReceivable) {
                    this.bankAccountTransfer.destiny = ObjectUtil.clone(bankAccount);
                }
                else {
                    this.bankAccountTransfer.origin = ObjectUtil.clone(bankAccount);
                }
            }
            if ((this.data.typeOperation !== 'VIEW') && (this.typeOperation === 'EDIT')) {
                this.bankAccountTransfer.originPreviousBankAccountId = this.bankAccountTransfer.origin.id;
                this.bankAccountTransfer.destinationPreviousBankAccountId = this.bankAccountTransfer.destiny.id;
                this.bankAccountTransfer.previousAmount = this.bankAccountTransfer.amount;
            }
        });
    }
    refreshOriginList() {
        this.amountDestiny$.next(!ObjectUtil.isNewModel(this.bankAccountTransfer.destiny) ? this.bankAccountTransfer.destiny.currentBalance : 0);
    }
    refreshDestinyList() {
        this.amountOrigin$.next(!ObjectUtil.isNewModel(this.bankAccountTransfer.origin) ? this.bankAccountTransfer.origin.currentBalance : 0);
    }
    _isValidTransfer() {
        if (ObjectUtil.isNull(this.bankAccountTransfer.origin) || ObjectUtil.isNewModel(this.bankAccountTransfer.origin)) {
            this.addWarningMessage('Campo conta de origem é obrigatório e não foi informado');
            return false;
        }
        if (this.bankAccountTransfer.origin.currentBalance < 0) {
            this.addWarningMessage('Conta de origem está com saldo negativo, portanto, não é possível realizar a transferência');
            return false;
        }
        if (this.bankAccountTransfer.origin.currentBalance === 0) {
            this.addWarningMessage('Conta de origem está com saldo R$ 0,00, portanto, não é possível realizar a transferência');
            return false;
        }
        if (ObjectUtil.isNull(this.bankAccountTransfer.destiny) || ObjectUtil.isNewModel(this.bankAccountTransfer.destiny)) {
            this.addWarningMessage('Campo conta de destino é obrigatório e não foi informado');
            return false;
        }
        if (this.bankAccountTransfer.amount < 0) {
            this.addWarningMessage('Valor da transferência não pode ser inferior à R$ 0,00');
            return false;
        }
        if (this.bankAccountTransfer.amount === 0) {
            this.addWarningMessage('Valor da transferência não pode ser R$ 0,00');
            return false;
        }
        if (ObjectUtil.isNull(this.bankAccountTransfer.amount)) {
            this.addWarningMessage('Campo valor da transferência é obrigatório e não foi informado');
            return false;
        }
        if (this.bankAccountTransfer.amount > this.bankAccountTransfer.origin.currentBalance) {
            this.addWarningMessage('Valor da transferência não pode ser superior ao saldo da conta de origem');
            return false;
        }
        if (ObjectUtil.isNull(this.bankAccountTransfer.date)) {
            this.addWarningMessage('Campo data é obrigatório e não foi informado');
            return false;
        }
        if (!DateUtil.isValid(this.bankAccountTransfer.date)) {
            this.addWarningMessage('Data da transferência informada não é válida');
            return false;
        }
        if (this.bankAccountTransfer.origin.id === this.bankAccountTransfer.destiny.id) {
            this.addWarningMessage('A conta de origem deve ser diferente da conta de destino');
            return false;
        }
        return true;
    }
    confirm() {
        try {
            if (!this._isValidTransfer()) {
                return;
            }
            this.isLoad = true;
            this.loading = true;
            if (this.isTransactionConciliation) {
                this.close('SAVED');
            }
            else {
                this._bankAccountTransferService.transfer(this.bankAccountTransfer).toPromise().then(() => {
                    this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                    this.close('RELOAD_TABLE');
                }, error => {
                    throw new Error(error);
                });
            }
        }
        catch (e) {
            this.isLoad = false;
            this.loading = false;
            this.handleError(e);
        }
    }
    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation) {
        this.dialogRef.close(operation);
    }
}
