import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { OAuthUserService } from '@gipi-financial/user/services/user.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent } from '@gipisistemas/ng-core';

@Component({
    templateUrl: './authorization-dialog.component.html'
})
export class AuthorizationDialogComponent extends AbstractComponent implements OnInit {

    username: string;

    password: string;

    isViewPassword: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<AuthorizationDialogComponent>,
        private _userService: OAuthUserService,
        messageService: CustomMessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
    }

    async validateAuthorization(): Promise<void> {
        try {
            this.loading = true;
            this.loadingChange.emit(this.loading);
            await this._userService.authorize(this.username, this.password).toPromise().then(authorized => {
                this.dialogRef.close(authorized);
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.loadingChange.emit(this.loading);
            this.handleError(e);
        }
    }

    close(): void {
        this.dialogRef.close(false);
    }

}
