<gipi-abstract-form id="dialogListSale"
                    name="dialogListSale"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogListSale">
        Consulta de vendas
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px"
         style="height: 100%;">

        <div fxLayout="column"
             fxLayoutGap="16px">
            <div fxLayout="row wrap"
                 fxLayout.lt-sm="column"
                 fxLayoutAlign="space-between center"
                 fxLayoutGap="16px">
                <div fxFlex="40"
                     style="width: 100%;"
                     fxLayout="row"
                     fxLayoutGap="16px">
                    <gipi-form-field id="inputSearch"
                                     #search
                                     fxFlex
                                     type="search"
                                     placeholder="Pesquisar"
                                     showSearch="true"
                                     [disabled]="loading"
                                     [(ngModel)]="filter.researchField"
                                     (onKeydownEnter)="find(null)"
                                     (onSearch)="find(null)">
                    </gipi-form-field>

                    <gipi-button tooltip="Filtrar"
                                 gipi-primary-icon
                                 #searchButton
                                 id="btnOptionsSearch"
                                 svgIcon="filter"
                                 [notification]="appliedFilters && (appliedFilters.length > 0)"
                                 [popover]="true"
                                 [disabled]="loading">
                        <div fxLayout="column"
                             fxLayoutGap="12px"
                             style="max-width: 36rem; padding: 8px;">
                            <div fxLayout="row"
                                 fxLayoutGap="12px"
                                 fxFlexAlign="space-between center">
                                <gipi-datepicker label="Data inicial"
                                                 id="inputDatePickerBeginDate"
                                                 name="inputDatePickerBeginDate"
                                                 [(ngModel)]="filter.begin"
                                                 [disabled]="loading"
                                                 fxFlex="48">
                                </gipi-datepicker>
                                <gipi-datepicker label="Data final"
                                                 id="inputDatePickerEndDate"
                                                 name="inputDatePickerEndDate"
                                                 [(ngModel)]="filter.end"
                                                 [disabled]="loading"
                                                 fxFlex="48">
                                </gipi-datepicker>
                            </div>

                            <div fxFlex
                                 fxLayout="row"
                                 fxFlexAlign="space-between center"
                                 fxLayoutGap="12px">
                                <gipi-form-field label="Número da nota fiscal"
                                                 id="inputFilterTaxCouponNumber"
                                                 name="inputFilterTaxCouponNumber"
                                                 type="text"
                                                 mask="0*"
                                                 placeholder="Ex: 1"
                                                 [(ngModel)]="filter.taxCouponNumber"
                                                 [disabled]="loading"
                                                 fxFlex="48">
                                </gipi-form-field>
                                <gipi-form-field label="Série da nota fiscal"
                                                 id="inputFilterTaxCouponSeries"
                                                 name="inputFilterTaxCouponSeries"
                                                 type="text"
                                                 mask="0*"
                                                 placeholder="Ex: 1"
                                                 [(ngModel)]="filter.taxCouponSeries"
                                                 [disabled]="loading"
                                                 fxFlex="48">
                                </gipi-form-field>
                            </div>

                            <div fxFlex
                                 fxLayout="row"
                                 fxFlexAlign="space-between center"
                                 fxLayoutGap="12px">
                                <gipi-form-field label="Documento"
                                                 id="inputFilterDocument"
                                                 name="inputFilterDocument"
                                                 type="text"
                                                 placeholder="Ex: 0001"
                                                 [(ngModel)]="filter.document"
                                                 [disabled]="loading"
                                                 fxFlex="48">
                                </gipi-form-field>
                                <gipi-input-currency label="Valor"
                                                     id="inputFilterTotal"
                                                     name="inputFilterTotal"
                                                     type="currency"
                                                     placeholder="R$ 0,00"
                                                     [(ngModel)]="filter.total"
                                                     [disabled]="loading"
                                                     fxFlex="48">
                                </gipi-input-currency>
                            </div>

                            <gipi-input-select-paged label="Cliente"
                                                     id="inputSelectClient"
                                                     name="inputSelectClient"
                                                     [(model)]="filter.client"
                                                     [nextBatchFn]="clientFindByValueFn"
                                                     [propertyFn]="clientFn"
                                                     [disabled]="loading"
                                                     fxFlex>
                            </gipi-input-select-paged>

                            <div style="margin-top: 12px;"
                                 fxLayout="row"
                                 fxLayoutGap="12px"
                                 fxFlex>
                                <gipi-button label="Pesquisar"
                                             gipi-primary
                                             id="btnFind"
                                             icon="search"
                                             [disabled]="loading"
                                             (click)="find(null); searchButton.popoverTrigger.togglePopover()"
                                             fxFlex>
                                </gipi-button>
                                <gipi-button label="Limpar"
                                             gipi-secondary
                                             id="btnClear"
                                             icon="clear"
                                             [disabled]="loading"
                                             (click)="clear(); searchButton.popoverTrigger.togglePopover()"
                                             fxFlex>
                                </gipi-button>
                            </div>
                        </div>
                    </gipi-button>
                </div>
                <div fxFlex="15"
                     style="width: 100%;"
                     fxLayout="row"
                     fxLayoutGap="16px">
                    <gipi-button label="Exibir"
                                 gipi-tertiary
                                 id="btnDisplay"
                                 #displayButton
                                 icon="expand_more"
                                 [disabled]="loading"
                                 [popover]="true"
                                 fxFlex
                                 fxFlex.lt-md="100">
                        <div class="btn-display-content"
                             style="flex-direction: column !important; min-width: 140px !important; gap: 8px;">
                            <itss-checkbox label="Todos"
                                           color="primary"
                                           [(ngModel)]="allTypesChecked"
                                           fxFlex="4"
                                           [(indeterminate)]="indeterminateType"
                                           (change)="checkAllStatus()">
                            </itss-checkbox>
                            <itss-checkbox label="Finalizadas"
                                           color="primary"
                                           [(ngModel)]="finishedChecked"
                                           fxFlex="4"
                                           (change)="validateAllStatusSelected()">
                            </itss-checkbox>
                            <itss-checkbox label="Devolvida parcialmente"
                                           color="primary"
                                           [(ngModel)]="partialRefoundCheked"
                                           fxFlex="4"
                                           (change)="validateAllStatusSelected()">
                            </itss-checkbox>
                            <div>
                                <gipi-button label="Aplicar"
                                             gipi-primary
                                             id="btnApplyFilter"
                                             [disabled]="loading"
                                             (click)="find(null); displayButton.closePopover()"
                                             fxFlex>
                                </gipi-button>
                            </div>
                        </div>
                    </gipi-button>
                </div>
            </div>
            <gipi-chips label="Filtros aplicados:"
                        style="max-width: 70%;"
                        [(ngModel)]="appliedFilters"
                        [showRemovable]="true"
                        [showRemovableAll]="true"
                        [disabled]="loading"
                        (removeChange)="removeAppliedFilter($event)"
                        (removeAllChange)="clear()">
            </gipi-chips>
        </div>

        <itss-table [paginator]="true"
                    [loading]="loading"
                    [columns]="createTableColumnsSale()"
                    (page)="find($event)"
                    [paginatorLength]="salePage.totalElements"
                    [paginatorPageSize]="5"
                    [paginatorPageIndex]="salePage.number"
                    [data]="salePage.content">
        </itss-table>
    </div>

    <div mat-dialog-actions
         fxLayout="row wrap"
         fxLayout.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnSave"
                     *ngIf="!selectOne"
                     [disabled]="salesSelected.length === 0"
                     (click)="close(true)"
                     fxFlex="15"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close(false)"
                     fxFlex="15"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>

</gipi-abstract-form>

<ng-template #checkboxTemplate
             let-entity>
    <itss-checkbox color="primary"
                   [(ngModel)]="entity.selected"
                   (change)="select(entity, true)"
                   fxFlex="4">
    </itss-checkbox>
</ng-template>

<ng-template #checkAllTemplate>
    <itss-checkbox color="primary"
                   [(ngModel)]="selected"
                   [(indeterminate)]="indeterminate"
                   (change)="checkAll()"
                   fxFlex="4">
    </itss-checkbox>
</ng-template>

<ng-template #statusTemplate
             let-entity>
    <gipi-badge [label]="saleStatusEnum[entity.status]"
                [textColor]="setTextColor(entity)"
                [color]="setColorStatus(entity)"
                style="width: 100%;">
    </gipi-badge>
</ng-template>

<ng-template #actions
             let-data>
    <itss-button svgIcon="details"
                 color="warn"
                 tooltip="Dados da venda"
                 (click)="navigateSaleData(data)"
                 type="icon">
    </itss-button>
</ng-template>
