import { Injectable } from '@angular/core';

import { GlobalPaths } from '@gipi-paths/global.paths';
import { GIPIAbstractCrudService, GIPIAbstractFilterModel, GIPIBaseService } from '@gipisistemas/ng-core';
import { SalePhoneType } from '../models/phone-type.model';

@Injectable({ providedIn: 'root' })
export class SalePhoneTypeService extends GIPIAbstractCrudService<SalePhoneType, GIPIAbstractFilterModel> {

    constructor(protected baseService: GIPIBaseService) {
        // Não tem no sale, então pega do global
        // super(SalePaths.phoneType, 'v1', baseService);
        super(GlobalPaths.phoneType, 'v1', baseService);
    }

}
