import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@gipi-environment/environment';
import { OAuthUser } from '@gipi-financial/user/models/user.model';
import { AuthenticationService, TokenDTO } from '@gipisistemas/ng-core';

@Injectable({ providedIn: 'root' })
export class CustomAuthenticationService extends AuthenticationService<OAuthUser> {

    constructor(
        router: Router,
        http: HttpClient
    ) {
        super(router, http);
    }

    protected buildHeaders(): HttpHeaders {
        return new HttpHeaders()
            .append('Authorization', 'Basic ' + btoa(`${environment.client}:${environment.secret}`))
            .append('Content-Type', 'application/x-www-form-urlencoded')
            .append('X-Requested-With', 'XMLHttpRequest');
    }

    changeCompany(companyId): Observable<TokenDTO<OAuthUser>> {
        const body: HttpParams = new HttpParams()
            .set('grant_type', 'refresh_token')
            .set('refresh_token', this.tokenValue.refreshToken)
            .set('currentCompany', companyId);

        return this.http.post<TokenDTO<OAuthUser>>(
            'api/authenticator/oauth/token',
            body,
            this.options(this.buildParams(), this.buildHeaders().append('skip', String(true)))
        ).pipe(
            map(token => this.setCurrentUser(token))
        );
    }

    async logout(): Promise<void> {
        this.revokeToken(this.tokenValue).subscribe(() => {
            this.removeToken();
            location.reload();
            sessionStorage.clear();
        });
    }

    login(user: OAuthUser): Observable<TokenDTO<OAuthUser>> {
        // Tem que setar null, pois fica aparecendo uma telinha de login no google se o tokenSubject tiver carregado.
        this.tokenSubject.next(null);

        const body: HttpParams = new HttpParams()
            .set('grant_type', 'password')
            .set('username', user.username)
            .set('password', user.password);

        return this.http.post<TokenDTO<OAuthUser>>(
            'api/authenticator/oauth/token',
            body,
            this.options(this.buildParams(), this.buildHeaders().append('skip', String(true)))
        ).pipe(
            map(accessToken => this.setCurrentUser(accessToken))
        );
    }

}
