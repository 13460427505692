import { RegistrationClientClassification } from '@gipi-registration/client-classification/models/client-classification.model';
import { RegistrationPerson } from '@gipi-registration/person/models/person.model';
import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { RegistrationClientBenefitClub } from './client-benefit-club.model';
import { RegistrationClientDiscountProduct } from './client-discount-product.model';
import { RegistrationClientReference } from './client-reference.model';
import { RegistrationClientXmlAuthorizedPerson } from './client-xml-authorized-person.model';
import { RegistrationCreditType } from './credit-type.model';

export class RegistrationClient extends GIPIBaseAbstractModel {

    printPendingBalanceSalesReceipt: boolean;

    blockUseOfCreditOnSale: boolean;

    blocked: boolean = false;

    classification: RegistrationClientClassification;

    clientBenefitClubList: RegistrationClientBenefitClub[];

    comments: string;

    controlClientLimit: boolean = false;

    creditTypeList: RegistrationCreditType[];

    discountProductList: RegistrationClientDiscountProduct[];

    lastSpcConsultationDate: Date;

    maximumDelay: number;

    person: RegistrationPerson;

    referenceList: RegistrationClientReference[];

    ruralProducer: boolean;

    spcRegistrationDate: Date;

    spcRehabilitationDate: Date;

    xmlAuthorizedPersonList: RegistrationClientXmlAuthorizedPerson[];

    icmsTaxpayer: boolean;

    /** @Transient */
    selected: boolean;

    // /** @Transient */
    // storeCreditLimit: number;

    // /** @Transient */
    // bankSlipLimit: number;

    // /** @Transient */
    // checkLimit: number;

}
