import { catchError, map, takeUntil } from 'rxjs/operators';
import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService, ObjectUtil, StringUtil, UUIDUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class RegistrationProductGroupingService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(RegistrationPaths.productGrouping, 'v1', baseService);
        this.baseService = baseService;
    }
    findByValue(value, page, size, sort, version, type, upperProductGroupId) {
        let lValue = value;
        if (value) {
            lValue = StringUtil.removeAccents(value).trim();
        }
        else {
            lValue = '';
        }
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        let lSort = '';
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.field)) {
            lSort = `&sort=${sort.field},${sort.direction}`;
        }
        let lType = '';
        if (!StringUtil.isEmpty(type)) {
            lType = `&type=${type}`;
        }
        let lUpperProductGroupId = '';
        if (!ObjectUtil.isNull(upperProductGroupId) && UUIDUtil.isValid(upperProductGroupId)) {
            lUpperProductGroupId = `&upperProductGroupId=${upperProductGroupId}`;
        }
        return this.baseService.httpClient.get(this.url(`find-by-value?page=${page}&size=${size}${lSort}&value=${lValue}${lType}${lUpperProductGroupId}`, version), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
RegistrationProductGroupingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationProductGroupingService_Factory() { return new RegistrationProductGroupingService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: RegistrationProductGroupingService, providedIn: "root" });
