import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationPerson } from '@gipi-registration/person/models/person.model';
import { RegistrationSocialNetwork } from '@gipi-registration/person/models/social-network.model';
import { RegistrationSocialNetworkType } from '@gipi-registration/social-network-type/models/social-network-type.model';
import { RegistrationSocialNetworkTypeService } from '@gipi-registration/social-network-type/services/social-network-type.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ArrayUtil, ObjectUtil, UUIDUtil } from '@gipisistemas/ng-core';
export class SocialNetworkFormComponent extends AbstractComponent {
    constructor(_socialNetworkTypeService, messageService, router, activatedRoute) {
        super(messageService, router, activatedRoute);
        this._socialNetworkTypeService = _socialNetworkTypeService;
        this.socialNetworkTypeList = [];
        this.socialNetworkListValue = [];
        this.socialNetworkListChange = new EventEmitter();
        this.person = new RegistrationPerson();
        this.isCreating = false;
        this.isViewing = false;
    }
    get socialNetworkList() {
        return this.socialNetworkListValue;
    }
    set socialNetworkList(socialNetworkList) {
        this.socialNetworkListValue = socialNetworkList;
        this.socialNetworkListChange.emit(this.socialNetworkListValue);
    }
    ngOnInit() {
        const _super = Object.create(null, {
            ngOnInit: { get: () => super.ngOnInit }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            _super.ngOnInit.call(this);
            this.loading = true;
            yield this.getSocialNetworkType();
            setTimeout(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (ArrayUtil.isEmpty(this.socialNetworkList)) {
                    this.socialNetworkList = [];
                    // this.addNewSocialNetwork();
                }
            }));
            this.loading = false;
        });
    }
    getSocialNetworkType() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this._socialNetworkTypeService.findAllEnabled(0, 50).toPromise().then(page => {
                    if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                        this.socialNetworkTypeList = page.content;
                    }
                }, error => {
                    throw new Error(error);
                });
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    deleteSocialNetwork(socialNetwork) {
        if (UUIDUtil.isValid(socialNetwork.id)) {
            this.socialNetworkList.splice(this.socialNetworkList.findIndex(a => a.id === socialNetwork.id), 1);
        }
        else {
            this.socialNetworkList.splice(this.socialNetworkList.findIndex(a => a.key === socialNetwork.key), 1);
        }
    }
    addNewSocialNetwork() {
        const socialNetwork = new RegistrationSocialNetwork();
        socialNetwork.type = new RegistrationSocialNetworkType();
        socialNetwork.key = this.socialNetworkList.length;
        this.socialNetworkList.push(socialNetwork);
    }
}
