import { Component, Inject, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface PersonAssociationData {
    title: string;
    message: string;
    showBtnUpdateRecord?: boolean;
}

export type PersonAssociationReturn = 'UPDATE_RECORD' | 'NEW_RECORD' | 'NONE';

@Component({
    selector: `gipi-person-association-dialog`,
    exportAs: 'gipiPersonAssociationDialog',
    templateUrl: './person-association-dialog.component.html',
    styleUrls: ['./person-association-dialog.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof PersonAssociationDialogComponent => PersonAssociationDialogComponent),
            multi: true
        }
    ],
    host: {
        'class': 'gipi-person-association-dialog',
    },
})
export class PersonAssociationDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<PersonAssociationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PersonAssociationData = { title: '', message: '', showBtnUpdateRecord: true },
    ) {
        this.dialogRef.disableClose = true;
    }

    ngOnInit(): void { }

    close(): void {
        this.dialogRef.close('NONE');
    }

}
