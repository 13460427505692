import { Injectable } from '@angular/core';

import { NotificationPaths } from '@gipi-paths/notification.paths';
import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { NotificationCustomerNotificationConfig } from '../models/customer-notification-config.model';

@Injectable({ providedIn: 'root' })
export class NotificationCustomerNotificationConfigService extends GIPIAbstractCrudService<NotificationCustomerNotificationConfig, GIPIBaseAbstractFilterModel> {

    /** Se trocar as permissões enquanto estiver na tela que usa a twilio */
    public updated$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(protected baseService: GIPIBaseService) {
        super(NotificationPaths.customerNotificationConfig, 'v1', baseService);
    }

    findEnabled(version?: string): Observable<NotificationCustomerNotificationConfig> {
        return this.baseService.httpClient.get(this.url(`enabled`, version), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        )
    }

}
