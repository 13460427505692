<gipi-abstract-form id="dialogGenerateBillReceivable"
                    name="dialogGenerateBillReceivable"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogGenerateBillReceivable"
                  name="toolbarDialogGenerateBillReceivable">
        Gerar conta a receber
    </gipi-toolbar>
    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="10px">
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxLayout.lt-md="column">
            <itss-input label="Descrição"
                        id="generateBillReceivableDescription"
                        name="generateBillReceivableDescription"
                        [required]="true"
                        [disabled]="loading"
                        [(ngModel)]="billInstallment.description"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
        </div>
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxLayout.lt-md="column">
            <itss-select-entity label="Cliente"
                                id="generateBillReceivableClient"
                                name="generateBillReceivableClient"
                                [required]="true"
                                [disabled]="(clientList?.length <= 1) || loading"
                                [entities]="clientList"
                                [(model)]="billInstallment.bill.client"
                                [propertyFn]="clientFn"
                                fxFlex
                                fxFlex.lt-md="100">
            </itss-select-entity>
            <itss-select-entity label="Tipo de cobrança"
                                id="generateBillReceivableChargeType"
                                name="generateBillReceivableChargeType"
                                [required]="true"
                                [disabled]="loading"
                                [entities]="chargeTypeList"
                                [(model)]="billInstallment.chargeType"
                                [property]="'description'"
                                fxFlex
                                fxFlex.lt-md="100">
            </itss-select-entity>
        </div>
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxLayout.lt-md="column">
            <itss-select-entity-paged label="Conta bancária para previsão"
                                      id="generateBillReceivableBankAccount"
                                      name="generateBillReceivableBankAccount"
                                      [required]="true"
                                      [disabled]="loading"
                                      [(model)]="billInstallment.bankAccount"
                                      [nextBatchFn]="bankAccountFindByValueFn"
                                      [property]="'description'"
                                      fxFlex
                                      fxFlex.lt-md="100">
            </itss-select-entity-paged>
            <itss-select-entity-paged label="Categoria"
                                      id="generateBillReceivableCategory"
                                      name="generateBillReceivableCategory"
                                      [required]="true"
                                      [disabled]="loading"
                                      [(model)]="billInstallment.postingCategory"
                                      [nextBatchFn]="postingCategoryFindByValueFn"
                                      [propertyFn]="postingCategoryFn"
                                      fxFlex
                                      fxFlex.lt-md="100">
            </itss-select-entity-paged>
        </div>
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxLayout.lt-md="column">
            <gipi-datepicker label="Data emissão"
                             id="generateBillReceivableIssuanceDate"
                             name="generateBillReceivableIssuanceDate"
                             [required]="true"
                             [disabled]="loading"
                             [(ngModel)]="billInstallment.issuanceDate"
                             fxFlex
                             fxFlex.lt-md="100">
            </gipi-datepicker>
            <gipi-datepicker label="Data vencimento"
                             id="generateBillReceivableDueDate"
                             name="generateBillReceivableDueDate"
                             [required]="true"
                             [disabled]="loading"
                             [(ngModel)]="billInstallment.dueDate"
                             fxFlex
                             fxFlex.lt-md="100">
            </gipi-datepicker>
            <itss-input-currency label="Valor"
                                 id="generateBillReceivableAmount"
                                 name="generateBillReceivableAmount"
                                 [placeholder]="'R$ 0,00'"
                                 [required]="true"
                                 [disabled]="loading"
                                 [(ngModel)]="billInstallment.amount"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>
        </div>
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxLayout.lt-md="column">
            <itss-textarea label="Observação"
                           id="generateBillReceivableComments"
                           name="generateBillReceivableComments"
                           [disabled]="loading"
                           [(ngModel)]="billInstallment.comments"
                           [rows]="3"
                           fxFlex
                           fxFlex.lt-md="100">
            </itss-textarea>
        </div>
    </div>
    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     [disabled]="loading"
                     [loading]="loading"
                     (click)="saveBillInstallment()"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close('NONE')"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>