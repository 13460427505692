import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FinancialBankAccountService } from '@gipi-financial/bank-account/services/bank-account.service';
import { FinancialFileService } from '@gipi-financial/file/services/file.service';
import { FinancialReceivementService } from '@gipi-financial/receivement/services/receivement.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, APP_MESSAGES, ArrayUtil, INJECTOR, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
export class ReceivementDepositDialogComponent extends AbstractComponent {
    constructor(dialogRef, data = { receivement: null, installmentList: [], isCashier: false, useClientCredit: false }, _receivementService, _bankAccountService, _fileService, messageService, router, activatedRoute) {
        super(messageService, router, activatedRoute);
        this.dialogRef = dialogRef;
        this.data = data;
        this._receivementService = _receivementService;
        this._bankAccountService = _bankAccountService;
        this._fileService = _fileService;
        this.bankAccountList = [];
        this.extensions = ['.png', '.jpg', '.jpeg', '.pdf'];
        this.maximumSize = 3;
        this.attachmentName = '';
        this.installmentList = [];
        this.isLoadUploadAttachment = false;
        this.isLoadDeleteAttachment = false;
        this.isLoadViewAttachment = false;
        this.isLoad = false;
    }
    ngOnInit() {
        const _super = Object.create(null, {
            ngOnInit: { get: () => super.ngOnInit }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isLoad = true;
            _super.ngOnInit.call(this);
            yield this.initializeBankAccount();
            this.receivement = this.data.receivement;
            this.installmentList = this.data.installmentList;
            this.initializeHistoric();
            this.isLoad = false;
        });
    }
    initializeHistoric() {
        try {
            const historicList = [];
            const initText = 'Recebimento via depósito da(s) conta(s):\n';
            for (let i = 0; i < this.installmentList.length; i++) {
                const element = this.installmentList[i];
                const historic = `${element.documentNumber} - ${element.description}`;
                historicList.push(historic);
            }
            this.receivement.historic = `${initText}${historicList.join('\n')}`;
        }
        catch (e) {
            this.isLoad = false;
            this.handleError(e);
        }
    }
    initializeBankAccount() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.data.isCashier) {
                    this.bankAccountList.push(this.data.receivement.bankAccount);
                }
                else {
                    yield this._bankAccountService.findAllEnabled(0, 50).toPromise().then(page => {
                        if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                            this.bankAccountList = page.content;
                        }
                    });
                }
            }
            catch (e) {
                this.isLoad = false;
                this.handleError(e);
            }
        });
    }
    confirm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.isLoad = true;
                this.loading = true;
                if (!ObjectUtil.isNull(this.receivement.fileAttachment)) {
                    yield this.uploadFile(this.receivement.fileAttachment);
                }
                yield this._receivementService.receive(this.receivement, this.installmentList, [], this.data.useClientCredit).toPromise().then(() => {
                    this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                    this.close(true);
                }, error => {
                    throw new Error(error);
                });
            }
            catch (e) {
                this.isLoad = false;
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    loadFile() {
        try {
            const htmlInputElement = this.fileInput.nativeElement;
            htmlInputElement.onchange = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const file = htmlInputElement.files[0];
                if (file === null || file === undefined) {
                    this.fileInput.nativeElement.value = '';
                    return;
                }
                this.isLoadUploadAttachment = true;
                this.isLoad = true;
                if (!ObjectUtil.isNull(file) && this.validateExtensions(file) && this.validateMaximumSize(file)) {
                    this.receivement.fileAttachment = file;
                    this.attachmentName = file.name;
                    this.fileInput.nativeElement.value = '';
                    this.isLoadUploadAttachment = false;
                    this.isLoad = false;
                }
                else {
                    this.fileInput.nativeElement.value = '';
                    this.isLoadUploadAttachment = false;
                    this.isLoad = false;
                }
            });
            htmlInputElement.click();
        }
        catch (e) {
            this.isLoadUploadAttachment = false;
            this.isLoad = false;
            this.handleError(e);
        }
    }
    uploadFile(file) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.isLoadUploadAttachment = true;
                yield this._fileService.upload('receivement-deposits', file, 'Private').toPromise().then((url) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    this.receivement.attachment = url;
                    this.getAttachmentName();
                    this.isLoadUploadAttachment = false;
                }), () => {
                    throw new Error('Não foi possível anexar o arquivo');
                });
            }
            catch (e) {
                this.isLoadUploadAttachment = false;
                this.handleError(e);
            }
        });
    }
    deleteFile() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.isLoadDeleteAttachment = true;
                this.isLoad = true;
                const htmlInputElement = this.fileInput.nativeElement;
                yield this._fileService.delete(this.receivement.attachment).toPromise().then(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    this.receivement.attachment = '';
                    this.receivement.fileAttachment = null;
                    htmlInputElement.remove();
                    this.isLoadDeleteAttachment = false;
                    this.isLoad = false;
                }), () => {
                    this.fileInput.nativeElement.value = '';
                    this.addErrorMessage('Não foi possível excluir o arquivo');
                    this.isLoadUploadAttachment = false;
                    this.isLoad = false;
                });
            }
            catch (e) {
                this.isLoadUploadAttachment = false;
                this.isLoad = false;
                this.handleError(e);
            }
        });
    }
    viewAttachment() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.isLoadViewAttachment = true;
                if (!ObjectUtil.isNewModel(this.receivement) && !StringUtil.isEmpty(this.receivement.attachment)) {
                    yield this._fileService.download(this.receivement.attachment).toPromise().then(resp => {
                        const file = new Blob([resp.body], { type: resp.body.type });
                        const fileURL = URL.createObjectURL(file);
                        if (!StringUtil.isEmpty(fileURL)) {
                            window.open(fileURL);
                        }
                        this.isLoadViewAttachment = false;
                    }, error => {
                        throw new Error(error);
                    });
                }
                else {
                    const file = new Blob([this.receivement.fileAttachment], { type: this.receivement.fileAttachment.type });
                    const fileURL = URL.createObjectURL(file);
                    if (!StringUtil.isEmpty(fileURL)) {
                        window.open(fileURL);
                        this.isLoadViewAttachment = false;
                    }
                }
            }
            catch (e) {
                this.isLoadViewAttachment = false;
                this.handleError(e);
            }
        });
    }
    getAttachmentName() {
        const attachmentName = (this.receivement.attachment ? this.receivement.attachment.split('/') : []);
        this.attachmentName = attachmentName[1];
    }
    validateExtensions(file) {
        if (this.extensions.filter(extension => file.name.endsWith(extension)).length === 0) {
            super.addErrorMessage(`São permitidos somente arquivos do tipo: ${this.extensions.reduce((previous, current) => previous + ', ' + current)}`);
            return false;
        }
        return true;
    }
    validateMaximumSize(file) {
        if (file.size > this.maximumSize * 1000000) {
            super.addErrorMessage(`O arquivo deve ter no máxmio ${this.maximumSize} MB.`);
            return false;
        }
        return true;
    }
    close(isSaved) {
        this.dialogRef.close(isSaved);
    }
}
