import { catchError, map, takeUntil } from 'rxjs/operators';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { ArrayUtil, GIPIAbstractCrudService, GIPIBaseService, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class FinancialChargeTypeService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(FinancialPaths.chargeType, 'v1', baseService);
        this.baseService = baseService;
    }
    findByValue(value, page, size, sort, version, types = []) {
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        let lSort = '';
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.field)) {
            lSort = `&sort=${sort.field},${sort.direction}`;
        }
        let lTypes = '';
        if (!ArrayUtil.isEmpty(types)) {
            if (types.length === 1) {
                lTypes = `&type=${types[0]}`;
            }
            else if (types.length > 1) {
                lTypes = `&type=${types.join('&type=')}`;
            }
        }
        return this.baseService.httpClient.get(this.url(`find-by-value?page=${page}&size=${size}${lSort}${lTypes}&value=${value}`, version), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    /**
     * Utiliza o endpoint findByValue
     * @value ''
     * @page 0
     * @size 100
     * @sort description,ASK
     * @version v1
     */
    findByTypes(types = []) {
        const page = 0;
        const size = 100;
        const sort = `&sort=description,ASC`;
        const version = 'v1';
        let lTypes = '';
        if (!ArrayUtil.isEmpty(types)) {
            if (types.length === 1) {
                lTypes = `&type=${types[0]}`;
            }
            else if (types.length > 1) {
                lTypes = `&type=${types.join('&type=')}`;
            }
        }
        return this.baseService.httpClient.get(this.url(`find-by-value?page=${page}&size=${size}${sort}${lTypes}`, version), this.options()).pipe(map(json => {
            const page = this.handleMapper(json);
            if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                return page.content;
            }
            return [];
        }), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
FinancialChargeTypeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialChargeTypeService_Factory() { return new FinancialChargeTypeService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: FinancialChargeTypeService, providedIn: "root" });
