import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthUserPublicService } from '@gipi-financial/user/services/user-public.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ObjectUtil, PasswordUtil, StringUtil } from '@gipisistemas/ng-core';
export class ChangePasswordComponent extends AbstractComponent {
    constructor(messageService, router, activatedRoute, _userPublicService, _authenticationService) {
        super(messageService, router, activatedRoute);
        this.messageService = messageService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this._userPublicService = _userPublicService;
        this._authenticationService = _authenticationService;
        this.newPassword = '';
        this.newPasswordConfirm = '';
        this.isViewNewPassword = false;
        this.isViewNewPasswordConfirm = false;
        this.passwordFocused = false;
        localStorage.removeItem('token');
    }
    ngOnInit() {
        super.ngOnInit();
        this.token = this.activatedRoute.snapshot.params.token;
    }
    updatePassword() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this._validate()) {
                    this.loading = true;
                    const reponseUser = yield this._userPublicService.updatePassword(this.token, this.newPassword).toPromise().catch(error => {
                        this.loading = false;
                        this.handleError(error);
                        return null;
                    });
                    if (!ObjectUtil.isNull(reponseUser)) {
                        reponseUser.password = this.newPassword;
                        this._authenticationService.login(reponseUser).toPromise().then(() => {
                            this.addSuccessMessage('Operação realizada com sucesso');
                            location.replace('/dashboard');
                        }).catch(error => {
                            this.loading = false;
                            this.handleError(error);
                        });
                    }
                }
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    _validate() {
        if (StringUtil.isEmpty(this.newPassword)) {
            this.addWarningMessage('Campo "Nova senha" é obrigatório e não foi informado');
            return false;
        }
        else if (StringUtil.isEmpty(this.newPasswordConfirm)) {
            this.addWarningMessage('Campo "Confirmação nova senha" é obrigatório e não foi informado');
            return false;
        }
        else if (!PasswordUtil.isValidMinLength(this.newPassword)) {
            this.addWarningMessage('A nova senha deve conter no mínimo 6 caracteres');
            return false;
        }
        else if (!PasswordUtil.isValidMaxLength(this.newPassword)) {
            this.addWarningMessage('A nova senha deve conter no máximo 15 caracteres');
            return false;
        }
        else if (!PasswordUtil.hasUppercase(this.newPassword)) {
            this.addWarningMessage('A nova senha deve conter no mínimo 1 caractere maiúsculo');
            return false;
        }
        else if (!PasswordUtil.hasLowercase(this.newPassword)) {
            this.addWarningMessage('A nova senha deve conter no mínimo 1 caractere minúsculo');
            return false;
        }
        else if (!PasswordUtil.hasNumber(this.newPassword)) {
            this.addWarningMessage('A nova senha deve conter no mínimo 1 número');
            return false;
        }
        else if (PasswordUtil.hasBackspace(this.newPassword)) {
            this.addWarningMessage('A nova senha não deve conter espaços');
            return false;
        }
        else if (this.newPassword !== this.newPasswordConfirm) {
            this.addWarningMessage('A nova senha e a confirmação da nova senha devem ser iguais');
            return false;
        }
        return true;
    }
    _isValid() {
        return (!StringUtil.isEmpty(this.newPassword) &&
            !StringUtil.isEmpty(this.newPasswordConfirm) &&
            (this.newPassword === this.newPasswordConfirm));
    }
    keydownChangePassword() {
        if (this._isValid()) {
            this.updatePassword();
        }
    }
    returnToLogin() {
        this.router.navigate(['/oauth/login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
    }
}
