import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialCashierShift } from '@gipi-financial/cashier-shift/models/cashier-shift.model';
import { FinancialChargeType } from '@gipi-financial/charge-type/models/charge-type.model';
import { BaseModel } from '@gipi-shared/models/base.model';

export class FinancialPayment extends BaseModel {

    billInstallment: FinancialBillInstallment;

    fine: number;

    interest: number;

    discount: number;

    chargeType: FinancialChargeType;

    amount: number;

    amountPaid: number;

    paymentDate: Date;

    bankAccount: FinancialBankAccount;

    shift: FinancialCashierShift;

}
