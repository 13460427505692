import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService, StringUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class RegistrationManufacturerService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(RegistrationPaths.manufacturer, 'v1', baseService);
        this.baseService = baseService;
    }
    getDescription(entity) {
        const cnpj = !StringUtil.isEmpty(entity.cnpj) ? StringUtil.format(entity.cnpj, '00.000.000/0000-00') + ' - ' : '';
        return `${cnpj}${entity.name}`;
    }
}
RegistrationManufacturerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationManufacturerService_Factory() { return new RegistrationManufacturerService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: RegistrationManufacturerService, providedIn: "root" });
