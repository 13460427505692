<div fxLayout="column"
     fxFlex
     fxLayoutGap="16px">
    <ng-container *ngFor="let phone of phoneList; let i = index">
        <div class="group-panel-register"
             *ngIf="(phoneList?.length > 0) && !loading"
             [attr.id]="'groupPhone' + i"
             fxLayout="column"
             fxLayoutGap="16px"
             fxFlex>

            <gipi-button tooltip="Excluir"
                         gipi-icon
                         class="btn-delete-group"
                         id="buttonDeletePhone"
                         name="buttonDeletePhone"
                         icon="close"
                         *ngIf="!loading && !isViewing"
                         (click)="deletePhone(phone)">
            </gipi-button>

            <div fxLayout="row"
                 fxLayoutAlign="start"
                 fxLayoutGap="16px">
                <itss-select-entity label="Tipo de telefone"
                                    id="phoneType"
                                    [required]="true"
                                    [disabled]="loading || isViewing"
                                    [entities]="phoneTypeList"
                                    [(model)]="phone.type"
                                    [property]="'description'"
                                    (selectionChange)="validPhone(phone)"
                                    fxFlex
                                    fxFlex.lt-sm="100">
                </itss-select-entity>
                <itss-input label="Número"
                            id="numberPhone"
                            #numberPhone
                            [placeholder]="'(00) 0 0000-0000'"
                            [required]="true"
                            [disabled]="loading || isViewing || (!phone?.type?.description)"
                            [phone]="(person.typePerson !== 'FOREIGN_PERSON')"
                            [mask]="(person.typePerson === 'FOREIGN_PERSON') ? '0*' : ''"
                            [max]="12"
                            [(ngModel)]="phone.number"
                            (keyup)="setCaretEndNumber($event, numberPhone)"
                            fxFlex
                            fxFlex.lt-sm="100">
                </itss-input>
                <itss-checkbox label="Telefone padrão"
                               id="useInInvoicePhone"
                               class="checkbox-center"
                               color="primary"
                               [disabled]="loading || isViewing"
                               [ngModel]="phone.useInInvoice"
                               (change)="validateUseInInvoice(phone)"
                               fxFlex="20"
                               fxFlex.lt-sm="30">
                </itss-checkbox>
            </div>
            <div fxLayout="row"
                 fxLayoutAlign="start"
                 fxLayoutGap="16px"
                 fxLayout.lt-sm="column"
                 fxLayout.lt-sm="column">
                <itss-textarea label="Observação"
                               id="commentsPhone"
                               [rows]="4"
                               [disabled]="loading || isViewing"
                               [(ngModel)]="phone.comments"
                               fxFlex
                               fxFlex.lt-sm="100">
                </itss-textarea>
            </div>
        </div>
    </ng-container>
    <div class="group-panel-register new-group-panel-register"
         id="buttonNewPhone"
         *ngIf="!loading && !isViewing"
         (click)="addNewPhone()">
        <label> + Adicionar telefone </label>
    </div>
</div>