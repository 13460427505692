import { catchError, map, takeUntil } from 'rxjs/operators';
import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { SessionStorageService } from '@gipi-shared/services/session-storage.service';
import { GIPIAbstractCrudService, GIPIBaseService, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/services/session-storage.service";
import * as i2 from "@gipisistemas/ng-core";
export class RegistrationProviderService extends GIPIAbstractCrudService {
    constructor(_sessionStorageService, baseService) {
        super(RegistrationPaths.provider, 'v1', baseService);
        this._sessionStorageService = _sessionStorageService;
        this.baseService = baseService;
    }
    getDescription(entity) {
        let name = entity.person.name;
        const configuration = this._sessionStorageService.get('configuration');
        if (!ObjectUtil.isNull(configuration)) {
            name = configuration.showBusinessNameInReceiptQuery ? (entity.person.fantasyName ? entity.person.fantasyName : entity.person.name) : entity.person.name;
        }
        if (ObjectUtil.isNewModel(entity.person.legalPerson) || ObjectUtil.isNull(entity.person.legalPerson)) {
            const cpf = !StringUtil.isEmpty(entity.person.naturalPerson.cpf) ? StringUtil.format(entity.person.naturalPerson.cpf, '000.000.000-00') + ' - ' : '';
            return `${cpf}${name}`;
        }
        else {
            const cnpj = !StringUtil.isEmpty(entity.person.legalPerson.cnpj) ? StringUtil.format(entity.person.legalPerson.cnpj, '00.000.000/0000-00') + ' - ' : '';
            return `${cnpj}${name}`;
        }
    }
    findByCpfOrCnpj(cpfOrCnpj) {
        return this.baseService.httpClient.get(this.url(`find-by-cpf-or-cnpj/${cpfOrCnpj}?page=0&size=1`), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
RegistrationProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationProviderService_Factory() { return new RegistrationProviderService(i0.ɵɵinject(i1.SessionStorageService), i0.ɵɵinject(i2.GIPIBaseService)); }, token: RegistrationProviderService, providedIn: "root" });
