import { catchError, map, takeUntil } from 'rxjs/operators';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class FinancialCompanyService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(FinancialPaths.company, 'v1', baseService);
        this.baseService = baseService;
    }
    findByIdIn(idList) {
        return this.baseService.httpClient.post(this.url('find-by-ids'), idList, this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
FinancialCompanyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialCompanyService_Factory() { return new FinancialCompanyService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: FinancialCompanyService, providedIn: "root" });
