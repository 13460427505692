import { Injectable } from '@angular/core';

import { SalePaths } from '@gipi-paths/sale.paths';
import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import { SaleCountry } from '../models/country.model';

@Injectable({ providedIn: 'root' })
export class SaleCountryService extends GIPIAbstractCrudService<SaleCountry, GIPIBaseAbstractFilterModel> {

    constructor(protected baseService: GIPIBaseService) {
        super(SalePaths.country, 'v1', baseService);
    }

}
