import { Injectable } from '@angular/core';

import { SalePaths } from '@gipi-paths/sale.paths';
import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import { SaleReasonDevolution } from '../models/reason-devolution.model';

@Injectable({ providedIn: 'root' })
export class SaleReasonDevolutionService extends GIPIAbstractCrudService<SaleReasonDevolution, GIPIBaseAbstractFilterModel> {

    constructor(protected baseService: GIPIBaseService) {
        super(SalePaths.reasonDevolution, 'v1', baseService);
    }

}
