export enum FinancialFilterByTypeDateEnum {
    DUE_DATE = 'Vencimento',
    ISSUANCE_DATE = 'Emissão',
    DISCHARGE_DATE_RECEIVEMENT = 'Recebimento', // nome correto é DISCHARGE_DATE
    DISCHARGE_DATE_PAYMENT = 'Pagamento' // nome correto é DISCHARGE_DATE
}

export type FinancialFilterByTypeDate = (
    'DUE_DATE' |
    'ISSUANCE_DATE' |
    'DISCHARGE_DATE_RECEIVEMENT' | // nome correto é DISCHARGE_DATE
    'DISCHARGE_DATE_PAYMENT' | // nome correto é DISCHARGE_DATE
    'DISCHARGE_DATE'
);
