import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { FinancialPaths } from '@gipi-paths/financial.paths';
import { GIPIAbstractCrudService, GIPIBaseService, GIPIPageModel, GIPIUuid } from '@gipisistemas/ng-core';
import { FinancialSaleConciliationFilterDTO } from '../models/dto/sale-conciliation-filter.dto';
import { FinancialSaleConciliationDTO } from '../models/dto/sale-conciliation.dto';
import { FinancialSaleFilterDTO } from '../models/dto/sale-filter.dto';
import { FinancialSaleItem } from '../models/sale-item.model';
import { FinancialSale } from '../models/sale.model';

@Injectable({ providedIn: 'root' })
export class FinancialSaleService extends GIPIAbstractCrudService<FinancialSale, FinancialSaleFilterDTO> {

    constructor(protected baseService: GIPIBaseService,) {
        super(FinancialPaths.sale, 'v1', baseService);
    }

    findByBillInstallment(billInstallmentId: GIPIUuid): Observable<FinancialSale> {
        return this.baseService.httpClient.get(this.url(`find-by-bill-installment/${billInstallmentId}`), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    updateSaleItemList(saleItemList: FinancialSaleItem[]): Observable<FinancialSaleItem[]> {
        return this.baseService.httpClient.post(this.url('update-sale-item-list'), saleItemList, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    findAllSaleConciliation(filter: FinancialSaleConciliationFilterDTO): Observable<GIPIPageModel<FinancialSaleConciliationDTO>> {
        return this.baseService.httpClient.post(this.url('find-all-sale-conciliation'), filter, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        )
    }

}
