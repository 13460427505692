import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { FinancialPaths } from '../../../paths/financial.paths';
import { BaseFilterDTO } from '../../../shared/models/dto/base-filter.dto';
import { FinancialCardAdministrator } from '../models/card-administrador.model';

@Injectable({ providedIn: 'root' })
export class FinancialCardAdministratorService extends BaseCrudService<FinancialCardAdministrator, BaseFilterDTO> {

    constructor(
        http: HttpClient,
        authenticationService: CustomAuthenticationService
    ) {
        super(FinancialPaths.cardAdministrator, http, authenticationService);
    }

}
