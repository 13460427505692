import { RegistrationClient } from '@gipi-registration/client/models/client.model';
import { SaleStatus, SaleStatusEnum } from '@gipi-sale/sale/enums/sale-status.enum';
import { SaleTaxInvoiceStatus, SaleTaxInvoiceStatusEnum } from '@gipi-sale/sale/enums/sale-tax-invoice-status.enum';
import { BaseFilterDTO } from '@gipi-shared/models/dto/base-filter.dto';
import { GIPIUuid } from '@gipisistemas/ng-core';
import { SaleClient } from '../sale-client.model';

export class SaleFilterDTO extends BaseFilterDTO {

    begin: Date;

    end: Date;

    client: SaleClient | RegistrationClient;

    document: string;

    total: number;

    taxCouponNumber: number;

    taxCouponSeries: number;

    saleStatusList: (SaleStatusEnum | SaleStatus)[];

    saleIdsExcluded: GIPIUuid[];

    taxInvoiceStatusList: (SaleTaxInvoiceStatusEnum | SaleTaxInvoiceStatus)[];

}
