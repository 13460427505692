import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { FinancialPaths } from '@gipi-paths/financial.paths';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { ObjectUtil } from '@gipisistemas/ng-core';
import { FinancialBankAccountTransfer } from '../models/bank-account-transfer.model';
import { FinancialBankAccountTransferFilterDTO } from '../models/dto/bank-account-transfer-filter.dto';

@Injectable({ providedIn: 'root' })
export class FinancialBankAccountTransferService extends BaseCrudService<FinancialBankAccountTransfer, FinancialBankAccountTransferFilterDTO> {

    constructor(
        http: HttpClient,
        authenticationService: CustomAuthenticationService
    ) {
        super(FinancialPaths.bankAccountTransfer, http, authenticationService);
    }

    validate(entity: FinancialBankAccountTransfer): void {
        super.validate(entity);
        if (ObjectUtil.isNewModel(entity.origin)
            || ObjectUtil.isNewModel(entity.destiny)
            || ObjectUtil.isNull(entity.amount)
            || ObjectUtil.isNull(entity.date)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
        if (entity.origin.id === entity.destiny.id) {
            throw new Error('A conta de destino deve ser diferente da conta de origem');
        }
        if (entity.amount > entity.origin.currentBalance) {
            throw new Error('Transferência não autorizada. O valor que está tentando transferir excede o saldo da conta');
        }
    }

    transfer(entity: FinancialBankAccountTransfer): Observable<FinancialBankAccountTransfer> {
        return this.http.post(this.url('transfer'), entity, this.options()).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

}
