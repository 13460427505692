import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { FinancialTransactionConciliationService } from '@gipi-financial/bill-conciliation/services/transaction-concilitation.service';
import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialBill } from '@gipi-financial/bill/models/bill.model';
import { FinancialBillInstallmentService } from '@gipi-financial/bill/services/bill-installment.service';
import { FinancialChargeTypeService } from '@gipi-financial/charge-type/services/charge-type.service';
import { FinancialClientService } from '@gipi-financial/client/services/client.service';
import { FinancialPostingCategoryService } from '@gipi-financial/posting-category/services/posting-category.service';
import { FinancialProviderService } from '@gipi-financial/provider/services/provider.service';
import { ArrayUtil, AuthenticationService, DateUtil, GIPIAbstractComponent, GIPIBaseService, GIPISortModel, NumberUtil, ObjectUtil } from '@gipisistemas/ng-core';
export class AddBillInstallmentDialogComponent extends GIPIAbstractComponent {
    constructor(baseService, activatedRoute, _billInstallmentService, _postingCategoryService, _clientService, _providerService, _chargeTypeService, _transactionConciliationService, _authenticationService, data = {
        billInstallmentType: 'NEW',
        isBillReceivable: true,
        billInstallmentUpdate: null,
        transactionConciliation: null,
        bankAccount: null,
        paymentOrReceivementDate: null,
    }, dialogRef) {
        super(baseService, activatedRoute);
        this.baseService = baseService;
        this.activatedRoute = activatedRoute;
        this._billInstallmentService = _billInstallmentService;
        this._postingCategoryService = _postingCategoryService;
        this._clientService = _clientService;
        this._providerService = _providerService;
        this._chargeTypeService = _chargeTypeService;
        this._transactionConciliationService = _transactionConciliationService;
        this._authenticationService = _authenticationService;
        this.data = data;
        this.dialogRef = dialogRef;
        this.billInstallmentType = 'NEW';
        this.isBillReceivable = true;
        this.billInstallment = new FinancialBillInstallment();
        this.chargeTypeList = [];
        this.billInstallmentChargeTypeList = [];
        /** Valor líquido da conta */
        this.billInstallmentAmountNet = 0;
        this.isLoad = false;
        this.postingCategoryFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const typeOperation = !this.isBillReceivable ? 'DEBIT' : 'CREDIT';
            const result = yield this._postingCategoryService.findByValue(value, page, 10, { property: 'fullCode', direction: 'asc' }, typeOperation).toPromise();
            if (!ObjectUtil.isNull(result) && !ArrayUtil.isEmpty(result.content)) {
                result.content = result.content.filter(category => category.enabled && !category.hasChildren);
                return result;
            }
            else {
                result.content = [];
            }
        });
        this.postingCategoryFn = (obj) => `${obj.fullCode} - ${obj.description}`;
        this.clientFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const configuration = this.baseService.sessionStorageService.get('configuration');
            let propertySort = 'person.name';
            if (!ObjectUtil.isNull(configuration) && configuration.showBusinessNameInReceiptQuery) {
                propertySort = 'person.fantasyName';
            }
            const result = yield this._clientService.findByValue(value, page, 10, new GIPISortModel(propertySort, 'ASC')).toPromise();
            return result;
        });
        this.clientFn = (obj) => this._clientService.getDescription(obj);
        this.providerFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const configuration = this.baseService.sessionStorageService.get('configuration');
            let propertySort = 'person.name';
            if (!ObjectUtil.isNull(configuration) && configuration.showBusinessNameInReceiptQuery) {
                propertySort = 'person.fantasyName';
            }
            const result = yield this._providerService.findByValue(value, page, 10, new GIPISortModel(propertySort, 'ASC')).toPromise();
            return result;
        });
        this.providerFn = (obj) => this._providerService.getDescription(obj);
        this.dialogRef.disableClose = true;
    }
    ngOnInit() {
        const _super = Object.create(null, {
            ngOnInit: { get: () => super.ngOnInit }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isLoad = true;
            _super.ngOnInit.call(this);
            this.billInstallmentType = this.data.billInstallmentType;
            this.isBillReceivable = this.data.isBillReceivable;
            this._userLoggedToken = this._authenticationService.tokenValue.user;
            this.receivementDate = this._transactionConciliationService.getDateTransaction(this.data.transactionConciliation.date);
            if (this.data.billInstallmentType !== 'UPDATE') {
                this._newBillInstallment();
            }
            if ((this.data.billInstallmentType === 'UPDATE') || (this.data.billInstallmentType === 'INTEREST_AND_FINE')) {
                this.billInstallment = ObjectUtil.clone(this.data.billInstallmentUpdate);
            }
            yield this._findChargeType();
            this.setAmountNet();
            this.isLoad = false;
        });
    }
    _findChargeType() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.isBillReceivable) {
                this._chargeTypeService.findAllEnabled(0, 50).toPromise().then(page => {
                    this.billInstallmentChargeTypeList = page.content.filter(ct => ct.type === 'BANK_SLIP' || ct.type === 'STORE_CREDIT');
                    let chargeTypeAux = null;
                    if (this._getExistInstallmentCreatedByConciliation()) {
                        chargeTypeAux = this.data.transactionConciliation.installmentListInternal.filter(b => b.createdByConciliation || b.createdByDifferenceConciliation)[0].chargeTypeReceivement;
                    }
                    if (ObjectUtil.isNull(chargeTypeAux)) {
                        this.chargeTypeList = page.content.filter(ct => ct.type === 'MONEY' || ct.type === 'TRANSFER' || ct.type === 'DEPOSIT' || ct.type === 'PIX');
                    }
                    else {
                        this.chargeTypeList = page.content.filter(ct => ct.type === chargeTypeAux.type);
                        this.billInstallment.chargeTypeReceivement = chargeTypeAux;
                    }
                });
            }
            else {
                this.billInstallmentChargeTypeList = yield this._chargeTypeService.findByTypes(['MONEY', 'STORE_CREDIT', 'DEBIT_CARD', 'ACCOUNT_DEBIT', 'TRANSFER', 'OTHER', 'DEPOSIT']).toPromise();
            }
        });
    }
    _getExistInstallmentCreatedByConciliation() {
        if (ArrayUtil.isEmpty(this.data.transactionConciliation.installmentListInternal)) {
            return false;
        }
        return this.data.transactionConciliation.installmentListInternal.filter(b => b.createdByConciliation || b.createdByDifferenceConciliation).length > 0;
    }
    _newBillInstallment() {
        this.billInstallment.bill = new FinancialBill();
        this.billInstallment.bankAccount = ObjectUtil.clone(this.data.bankAccount);
        this.billInstallment.conciled = true;
        this.billInstallment.userId = this._userLoggedToken.id;
        this.billInstallment.bill.type = this.isBillReceivable ? 'RECEIVABLE' : 'PAYABLE';
        this.billInstallment.status = this.isBillReceivable ? 'RECEIVABLE' : 'PAYABLE';
        this.billInstallment.conciled = true;
        this.billInstallment.createdByConciliation = true;
        this.billInstallment.description = this.data.transactionConciliation.description;
        this.billInstallment.issuanceDate = this._transactionConciliationService.getDateTransaction(this.data.transactionConciliation.date);
        this.billInstallment.dueDate = this._transactionConciliationService.getDateTransaction(this.data.transactionConciliation.date);
        this.billInstallment.paymentDate = null;
        this.billInstallment.dischargeDate = null;
        this.billInstallment.dischargeAmount = 0;
        this.billInstallment.interest = 0;
        this.billInstallment.fine = 0;
        this.billInstallment.addition = 0;
        this.billInstallment.discount = 0;
        this.billInstallment.amount = 0;
        if (this.billInstallmentType === 'NEW') {
            const amount = ((this.data.transactionConciliation.value > 0)
                ? this.data.transactionConciliation.value
                : (this.data.transactionConciliation.value * (-1)));
            this.billInstallment.amount = amount;
        }
        else if (this.billInstallmentType === 'UPDATE') {
        }
        else if (this.billInstallmentType === 'DIFFERENCE') {
            this.billInstallment.createdByDifferenceConciliation = true;
            const amount = ((this.data.transactionConciliation.differenceValue > 0)
                ? this.data.transactionConciliation.differenceValue
                : (this.data.transactionConciliation.differenceValue * (-1)));
            this.billInstallment.amount = amount;
        }
    }
    setAmountNet() {
        // Faz isso pois os campos são obrigatórios, mais é permitido passar 0. Então se o campo tiver null já seta 0 no atributo.
        this.billInstallment.interest = (ObjectUtil.isNull(this.billInstallment.interest) ? 0 : this.billInstallment.interest);
        this.billInstallment.fine = (ObjectUtil.isNull(this.billInstallment.fine) ? 0 : this.billInstallment.fine);
        this.billInstallment.addition = (ObjectUtil.isNull(this.billInstallment.addition) ? 0 : this.billInstallment.addition);
        this.billInstallment.discount = (ObjectUtil.isNull(this.billInstallment.discount) ? 0 : this.billInstallment.discount);
        let amountNetAux = this.billInstallment.amount;
        if (NumberUtil.isPositive(this.billInstallment.interest)) {
            amountNetAux += this.billInstallment.interest;
        }
        if (NumberUtil.isPositive(this.billInstallment.fine)) {
            amountNetAux += this.billInstallment.fine;
        }
        if (NumberUtil.isPositive(this.billInstallment.addition)) {
            amountNetAux += this.billInstallment.addition;
        }
        if (NumberUtil.isPositive(this.billInstallment.discount)) {
            amountNetAux -= this.billInstallment.discount;
        }
        if (amountNetAux >= 0) {
            this.billInstallmentAmountNet = Number(amountNetAux.toFixed(2));
        }
        else {
            this.billInstallmentAmountNet = this.billInstallment.amount;
        }
    }
    validateDateIsGreaterThanTransaction() {
        const issuanceDate = new Date(moment(this.billInstallment.issuanceDate).format('yyyy/MM/DD'));
        const dueDate = new Date(moment(this.billInstallment.dueDate).format('yyyy/MM/DD'));
        if (DateUtil.isGreaterThan(new Date(issuanceDate), this._transactionConciliationService.getDateTransaction(this.data.transactionConciliation.date))) {
            this.addWarningMessage('Data da emissão não pode ser superior a data do pagamento');
            return;
        }
        if (DateUtil.isGreaterThan(new Date(issuanceDate), new Date(dueDate))) {
            this.addWarningMessage('Data da emissão não pode ser superior a data do vencimento');
            return;
        }
    }
    saveBillInstallment() {
        try {
            this.isLoad = true;
            this.loading = true;
            // if (!this.isBillReceivable) {
            //     this.billInstallment.chargeType = ObjectUtil.clone(this.billInstallment.chargeTypeReceivement);
            // }
            this._billInstallmentService.validate(this.billInstallment);
            if (this.isBillReceivable && ObjectUtil.isNewModel(this.billInstallment.chargeTypeReceivement)) {
                this.addWarningMessage('Tipo de cobrança do recebimento é obrigatório e não foi informado');
                return;
            }
            if ((this.billInstallmentType === 'NEW') || this.billInstallmentType === 'DIFFERENCE') {
                if (ArrayUtil.isEmpty(this.data.transactionConciliation.installmentListInternal)) {
                    this.data.transactionConciliation.installmentListInternal = [];
                }
                this.billInstallment.orderId = this.data.transactionConciliation.installmentListInternal.length + 1;
                this.data.transactionConciliation.installmentListInternal.push(this.billInstallment);
            }
            else if (this.billInstallmentType === 'UPDATE') {
                const indexInstallment = this.data.transactionConciliation.installmentListInternal
                    .findIndex(i => (i.id === this.billInstallment.id) || (i.orderId === this.billInstallment.orderId) || (i === this.billInstallment));
                if (indexInstallment >= 0) {
                    this.data.transactionConciliation.installmentListInternal.splice(indexInstallment, 1, this.billInstallment);
                }
            }
            this.close('RELOAD_TABLE');
        }
        catch (e) {
            this.isLoad = false;
            this.loading = false;
            this.handleError(e);
        }
    }
    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation) {
        this.dialogRef.close(operation);
    }
}
