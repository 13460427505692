import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { OAuthUser } from '@gipi-financial/user/models/user.model';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, InputComponent, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends AbstractComponent implements OnInit {

    @ViewChild('firstInput', { static: true }) firstInput: InputComponent;

    user: OAuthUser = new OAuthUser();

    public isViewPassword: boolean = false;

    public numberFailedAttempts: number = 0;
    public tokenReCAPTCHA: string | undefined;

    constructor(
        protected messageService: CustomMessageService,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        private _authenticationService: CustomAuthenticationService,
    ) {
        super(messageService, router, activatedRoute);
    }

    ngOnInit(): void {
        super.ngOnInit();
        setTimeout(() => this.firstInput._elementRef.nativeElement.focus());
    }

    login(): void {
        try {
            this.numberFailedAttempts++;
            if (this._validate()) {
                this.loading = true;
                this._authenticationService.login(this.user).toPromise()
                    .then(() => location.reload(), error => this.handleError(error));
            }
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    private _validate(): boolean {
        if ((this.numberFailedAttempts >= 3) && (StringUtil.isEmpty(this.tokenReCAPTCHA) || ObjectUtil.isNull(this.tokenReCAPTCHA))) {
            if (this.numberFailedAttempts >= 4) {
                this.addWarningMessage('Marque o reCAPTCHA para entrar');
            }
            return false;
        }
        if (StringUtil.isEmpty(this.user.username)) {
            this.addWarningMessage('O campo e-mail é obrigatório');
            return false;
        }
        if (StringUtil.isEmpty(this.user.password)) {
            this.addWarningMessage('O campo senha é obrigatório');
            return false;
        }
        return true;
    }


}
