<gipi-abstract-form id="dialogRegisterApplication"
                    name="dialogRegisterApplication"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogRegisterApplication">
        Cadastrar aplicação
    </gipi-toolbar>
    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <itss-input label="Descrição"
                        id="applicationDescription"
                        name="applicationDescription"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="application.description"
                        [min]="5"
                        [max]="60"
                        [maxlength]="60"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <gipi-input-select-enum label="Situação"
                                    id="applicationEnabled"
                                    name="applicationEnabled"
                                    [required]="true"
                                    [disabled]="loading || (typeOperation !== 'EDIT')"
                                    [valuesExclud]="[]"
                                    [enum]="optionActiveSituationEnum"
                                    [(ngModel)]="optionActiveSituationValue"
                                    fxFlex="30"
                                    fxFlex.lt-md="100">
            </gipi-input-select-enum>
        </div>
    </div>
    <div mat-dialog-actions
         fxLayout="row wrap"
         fxLayout.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     name="btnSave"
                     *ngIf="(typeOperation !== 'VIEW')"
                     (click)="confirm()"
                     [disabled]="loading"
                     [loading]="loading"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     name="btnReturn"
                     (click)="close('NONE', null)"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>