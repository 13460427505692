import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AlertComponent, MessageDTO, MessageService } from '@gipisistemas/ng-core';

@Injectable({ providedIn: 'root' })
export class CustomMessageService extends MessageService {

    constructor(snackBar: MatSnackBar) {
        super(snackBar);
    }

    add(message: MessageDTO): void {
        this.snackBar.openFromComponent(AlertComponent, {
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: 5000,
            data: message
        });
    }

}
