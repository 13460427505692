import { formatNumber } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { SaleChargeType } from '@gipi-sale/charge-type/models/charge-type.model';
import { SaleSaleItem } from '@gipi-sale/sale/models/sale-item.model';
import { SaleSale } from '@gipi-sale/sale/models/sale.model';
import { SaleService } from '@gipi-sale/sale/services/sale.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, CurrencyUtil, GIPIUuid, ObjectUtil, StringUtil, TableColumnBuilder, TableColumnDTO } from '@gipisistemas/ng-core';

@Component({
    templateUrl: './sale-data-dialog.component.html',
    styleUrls: ['./sale-data-dialog.component.scss'],
})
export class SaleDataDialogComponent extends AbstractComponent implements OnInit {

    billInstallmentList: FinancialBillInstallment[] = [];

    isOrigemSale: boolean = false;

    chargeTypeList: SaleChargeType[] = [];

    saleData: SaleSale = null;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: GIPIUuid,
        private _saleService: SaleService,
        messageService: CustomMessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
    }

    async ngOnInit(): Promise<void> {
        if (this.UUIDIsValid(this.data)) {
            this.saleData = await this._saleService.getOne(this.data).toPromise();
        }

        super.ngOnInit();
        this._setFields();
    }

    public createTableColumns(): TableColumnDTO[] {
        return [
            TableColumnBuilder.instance()
                .property('product.description')
                .name('Produto')
                .width(200)
                .value((obj: SaleSaleItem) => !ObjectUtil.isNull(obj.product) && !StringUtil.isEmpty(obj.product.description) ? obj.product.description : '')
                .build(),
            TableColumnBuilder.instance()
                .property('unitMeasurement.initials')
                .name('Und. Medida')
                .width(10)
                .align('center center')
                .value((obj: SaleSaleItem) => !ObjectUtil.isNull(obj.unitMeasurement) && !StringUtil.isEmpty(obj.unitMeasurement.initials) ? obj.unitMeasurement.initials : '')
                .build(),
            TableColumnBuilder.instance()
                .property('quantity')
                .name('Quantidade')
                .width(10)
                .align('center center')
                .value((obj: SaleSaleItem) => formatNumber(obj.quantity, 'pt-BR', '1.4-4'))
                .build(),
            TableColumnBuilder.instance()
                .property('product.amount')
                .name('Valor unitário')
                .width(10)
                .align('center center')
                .value((obj: SaleSaleItem) => 'R$ ' + formatNumber(obj.unitaryValue, 'pt-BR', '1.4-4'))
                .build(),
            TableColumnBuilder.instance()
                .property('discount')
                .name('Desconto')
                .width(10)
                .align('center center')
                .value((obj: SaleSaleItem) => CurrencyUtil.transform(obj.discount, '1.2-2'))
                .build(),
            TableColumnBuilder.instance()
                .property('addition')
                .name('Acréscimo')
                .width(10)
                .align('center center')
                .value((obj: SaleSaleItem) => CurrencyUtil.transform(obj.addition, '1.2-2'))
                .build(),
            TableColumnBuilder.instance()
                .property('freight')
                .name('Frete')
                .width(10)
                .align('center center')
                .value((obj: SaleSaleItem) => CurrencyUtil.transform(obj.freight, '1.2-2'))
                .build(),
            TableColumnBuilder.instance()
                .property('total')
                .name('Total líquido')
                .width(10)
                .align('center center')
                .value((obj: SaleSaleItem) => CurrencyUtil.transform(obj.amountNet, '1.2-2'))
                .build(),
        ];
    }

    private _setFields(): void {
        if (ObjectUtil.isNull(this.saleData)) {
            return;
        }
        for (let i = 0; i < this.saleData.salePaymentList.length; i++) {
            let chargeType = this.chargeTypeList.find((ct) => ct.id === this.saleData.salePaymentList[i].chargeType.id);
            if (ObjectUtil.isNewModel(chargeType)) {
                chargeType = this.saleData.salePaymentList[i].chargeType;
                chargeType.totalInstallmentAmount = 0;
                this.chargeTypeList.push(chargeType);
            }
            chargeType.totalInstallmentAmount += this.saleData.salePaymentList[i].amountPaid;
        }
    }

    public getColorByAmount(amount: number): string {
        if (amount > 0) {
            return '#47ca82';
        } else if (amount < 0) {
            return '#ca0045';
        } else if (amount === 0) {
            return '#696969';
        }
    }
}
