import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialBillStatusEnum } from '@gipi-financial/bill/enums/bill-status.enum';
import { FinancialBillTypeEnum } from '@gipi-financial/bill/enums/bill-type.enum';
import { FinancialFilterByTypeDate } from '@gipi-financial/bill/enums/filter-by-type-date.enum';
import { FinancialClient } from '@gipi-financial/client/models/client.model';
import { FinancialPayment } from '@gipi-financial/payment/models/payment.model';
import { FinancialPostingCategory } from '@gipi-financial/posting-category/models/posting-category.model';
import { FinancialProvider } from '@gipi-financial/provider/models/provider.model';
import { BaseFilterDTO } from '@gipi-shared/models/dto/base-filter.dto';
import { GIPIUuid } from '@gipisistemas/ng-core';
import { FinancialBill } from '../bill.model';

export class FinancialBillFilterDTO extends BaseFilterDTO {

    /** @Transient */
    bankAccount: FinancialBankAccount;
    bankAccountId: GIPIUuid;

    /** @Transient */
    client: FinancialClient;
    clientId: GIPIUuid;

    /** @Transient */
    provider: FinancialProvider;
    providerId: GIPIUuid;

    /** @Transient */
    postingCategory: FinancialPostingCategory;
    postingCategoryId: GIPIUuid;

    type: FinancialBillTypeEnum | 'PAYABLE' | 'RECEIVABLE' = 'RECEIVABLE';

    postingCategoryName: string;

    description: string;

    amount: number;

    begin: Date;

    end: Date;

    beginPayment: Date;

    endPayment: Date;

    documentNumber: string;

    billStatusList: (FinancialBillStatusEnum | string)[];

    conciledOnly: boolean | string;

    bill?: FinancialBill;

    payment?: FinancialPayment;

    billetConciliation: boolean = false;

    filterByTypeDate: FinancialFilterByTypeDate;

    migrated: boolean = true;

}
