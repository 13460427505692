import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';

import { ReportClientSummaryDTO } from '@gipi-report/client/models/dto/client-summary.dto';
import { ReportMostPurchasedProductDTO } from '@gipi-report/client/models/dto/most-purchased-product.dto';
import { ArrayUtil, DialogService, GIPIAbstractComponent, GIPIBaseService, ObjectUtil } from '@gipisistemas/ng-core';

@Component({
    templateUrl: './client-summary-dialog.component.html',
    styleUrls: ['./client-summary-dialog.component.scss']
})
export class ClientSummaryDialogComponent extends GIPIAbstractComponent implements OnInit {

    public client: ReportClientSummaryDTO = null;

    public get mostPurchasedProduct(): string {
        if (
            ObjectUtil.isNull(this.client) ||
            !ObjectUtil.isNull(this.client) && ArrayUtil.isEmpty(this.client.topFiveMostPurchasedProductsList)
        ) {
            return '';
        }

        const itemWithMaxQuantity: ReportMostPurchasedProductDTO = this.client.topFiveMostPurchasedProductsList.reduce((prev, current) => {
            return (prev.quantityPurchase > current.quantityPurchase) ? prev : current;
        });

        return itemWithMaxQuantity.description;
    }

    constructor(
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _dialogService: DialogService,
        @Inject(MAT_DIALOG_DATA) public data: ReportClientSummaryDTO,
    ) {
        super(baseService, activatedRoute);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.client = this.data;
    }

    public getColorByAmount(amount: number): string {
        if (amount > 0) {
            return '#47ca82';
        } else if (amount < 0) {
            return '#ca0045';
        } else if (amount === 0) {
            return '#696969';
        }
    }

}
