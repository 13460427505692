import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from '@gipi-environment/environment';
import { AuthenticationService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
export class CustomAuthenticationService extends AuthenticationService {
    constructor(router, http) {
        super(router, http);
    }
    buildHeaders() {
        return new HttpHeaders()
            .append('Authorization', 'Basic ' + btoa(`${environment.client}:${environment.secret}`))
            .append('Content-Type', 'application/x-www-form-urlencoded')
            .append('X-Requested-With', 'XMLHttpRequest');
    }
    changeCompany(companyId) {
        const body = new HttpParams()
            .set('grant_type', 'refresh_token')
            .set('refresh_token', this.tokenValue.refreshToken)
            .set('currentCompany', companyId);
        return this.http.post('api/authenticator/oauth/token', body, this.options(this.buildParams(), this.buildHeaders().append('skip', String(true)))).pipe(map(token => this.setCurrentUser(token)));
    }
    logout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.revokeToken(this.tokenValue).subscribe(() => {
                this.removeToken();
                location.reload();
                sessionStorage.clear();
            });
        });
    }
    login(user) {
        // Tem que setar null, pois fica aparecendo uma telinha de login no google se o tokenSubject tiver carregado.
        this.tokenSubject.next(null);
        const body = new HttpParams()
            .set('grant_type', 'password')
            .set('username', user.username)
            .set('password', user.password);
        return this.http.post('api/authenticator/oauth/token', body, this.options(this.buildParams(), this.buildHeaders().append('skip', String(true)))).pipe(map(accessToken => this.setCurrentUser(accessToken)));
    }
}
CustomAuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomAuthenticationService_Factory() { return new CustomAuthenticationService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient)); }, token: CustomAuthenticationService, providedIn: "root" });
