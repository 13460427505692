import { SaleLineOfBusiness } from '@gipi-sale/line-of-business/models/line-of-business.model';
import { BaseModel } from '@gipi-shared/models/base.model';
import { SaleLegalPersonType, SaleLegalPersonTypeEnum } from '../enums/legal-person-type.enum';

export class SaleLegalPerson extends BaseModel {

    cnpj: string;

    stateRegistration: string;

    lineOfBusiness: SaleLineOfBusiness;

    type: SaleLegalPersonTypeEnum | SaleLegalPersonType;

    municipalRegistration: string;

    cnae: string;

}
