export enum FinancialBillStatusEnum {
    RECEIVABLE = 'A receber',
    RECEIVED = 'Recebido',
    GROUPED = 'Agrupada',
    RENEGOTIATED = 'Renegociada',
    RECEIVABLE_OVERDUE = 'A receber atrasado',
    RECEIVED_PARTIAL = 'Recebida parcial',
    PAYABLE_OVERDUE = 'A pagar atrasado',
    PAYABLE = 'A pagar',
    PAID = 'Pago',
}

export type FinancialBillStatus = 'RECEIVABLE' | 'RECEIVED' | 'GROUPED' | 'RENEGOTIATED' | 'RECEIVABLE_OVERDUE' | 'RECEIVED_PARTIAL' | 'PAYABLE_OVERDUE' | 'PAYABLE' | 'PAID';
