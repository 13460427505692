import { RegistrationClient } from '@gipi-registration/client/models/client.model';
import { RegistrationProvider } from '@gipi-registration/provider/models/provider.model';
import { TypePerson, TypePersonEnum } from '@gipi-shared/enums/type-person.enum';
import { ArrayUtil, GIPIAbstractDTO, ObjectUtil } from '@gipisistemas/ng-core';

export class RegistrationPersonConsultDTO extends GIPIAbstractDTO {

    /** CPF ou CNPJ */
    cpfOrCnpj: string;

    /** Nome fantasia ou Apelido */
    fantasyName: string;

    /** Razão social ou Nome */
    name: string;

    /** Tipo de pessoa */
    type: TypePersonEnum | TypePerson;

    /** Cidade */
    city: string;

    public static toPersonDTO(entity: RegistrationClient | RegistrationProvider): RegistrationPersonConsultDTO {
        const personConsult: RegistrationPersonConsultDTO = new RegistrationPersonConsultDTO();
        personConsult.id = entity.id;
        personConsult.enabled = entity.enabled;
        personConsult.type = !ObjectUtil.isNull(entity.person.naturalPerson) ? 'NATURAL_PERSON' : 'LEGAL_PERSON';
        personConsult.cpfOrCnpj = !ObjectUtil.isNull(entity.person.naturalPerson) ? entity.person.naturalPerson.cpf : entity.person.legalPerson.cnpj;
        personConsult.name = entity.person.name;
        personConsult.fantasyName = entity.person.fantasyName;
        personConsult.city = !ArrayUtil.isEmpty(entity.person.addressList) ? entity.person.addressList.find(a => a.useInInvoice).city.name : '';
        return personConsult;
    }

}
