import { HttpClient } from '@angular/common/http';
import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { AbstractCrudService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class RegistrationLineOfBusinessService extends AbstractCrudService {
    constructor(http) {
        super(RegistrationPaths.lineOfBusiness, http);
    }
}
RegistrationLineOfBusinessService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationLineOfBusinessService_Factory() { return new RegistrationLineOfBusinessService(i0.ɵɵinject(i1.HttpClient)); }, token: RegistrationLineOfBusinessService, providedIn: "root" });
