import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute, Router } from '@angular/router';

import { RegistrationCity } from '@gipi-registration/city/models/city.model';
import { RegistrationCityService } from '@gipi-registration/city/services/city.service';
import { RegistrationCountry } from '@gipi-registration/country/models/country.model';
import { RegistrationCountryService } from '@gipi-registration/country/services/country.service';
import { RegistrationAddressTypeEnum } from '@gipi-registration/person/enums/address-type.enum';
import { RegistrationAddress } from '@gipi-registration/person/models/address.model';
import { RegistrationPerson } from '@gipi-registration/person/models/person.model';
import { RegistrationAddressService } from '@gipi-registration/person/services/address.service';
import { RegistrationState } from '@gipi-registration/state/models/state.model';
import { RegistrationStateService } from '@gipi-registration/state/services/state.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ArrayUtil, GIPISortModel, ObjectUtil, PageDTO, StringUtil, UUIDUtil } from '@gipisistemas/ng-core';

@Component({
    selector: 'itss-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AddressFormComponent),
            multi: true
        }
    ],
})
export class AddressFormComponent extends AbstractComponent implements OnInit {

    typeAddressEnum: typeof RegistrationAddressTypeEnum = RegistrationAddressTypeEnum;

    countryList: RegistrationCountry[] = [];

    stateList: RegistrationState[] = [];

    loadingFindCep: boolean = false;

    streetNumberNotIdentifiedAddress: boolean = false;

    addressListValue: RegistrationAddress[] = [];
    @Input() get addressList(): RegistrationAddress[] {
        return this.addressListValue;
    }
    set addressList(addressList: RegistrationAddress[]) {
        if ((this.person.typePerson !== 'FOREIGN_PERSON') && !ArrayUtil.isEmpty(addressList)) {
            for (let i: number = 0; i < addressList.length; i++) {
                const address: RegistrationAddress = addressList[i];
                if (!ObjectUtil.isNewModel(address.city) && !ObjectUtil.isNull(address.city)) {
                    if (!ObjectUtil.isNewModel(address.state) && !ObjectUtil.isNull(address.state)) {
                        address.city.state = address.state;

                        if (!ObjectUtil.isNewModel(address.country) && !ObjectUtil.isNull(address.country)) {
                            address.city.state.country = address.state.country;
                        }
                    }
                }
            }
        }

        this.addressListValue = addressList;
        this.addressListChange.emit(this.addressListValue);
    }
    @Output() addressListChange: EventEmitter<RegistrationAddress[]> = new EventEmitter<RegistrationAddress[]>();

    @Input() person: RegistrationPerson = new RegistrationPerson();

    @Input() isCreating: boolean = false;

    @Input() isViewing: boolean = false;

    @Input() disabledTypeAddress: boolean = false;

    @Input() isRepresentativeProvider: boolean = false;

    cityFindByValueFn = async (value: string, page: number, address: RegistrationAddress) => {
        if (!ObjectUtil.isNull(address.state)) {
            const result: PageDTO<RegistrationCity> = await this._cityService.findByValue(value, page, 10, new GIPISortModel('name', 'ASC'), 'v1', address.state.ibgeCode).toPromise();
            address.cityList = result.content;
            return result;
        } else {
            return [];
        }
    };

    constructor(
        private _addressService: RegistrationAddressService,
        private _countryService: RegistrationCountryService,
        private _stateService: RegistrationStateService,
        private _cityService: RegistrationCityService,
        private _changeDetectorRef: ChangeDetectorRef,
        messageService: CustomMessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();
        this.loading = true;

        await this.getCountry();
        await this.getState();

        setTimeout(async () => {
            if (!this.isCreating && (this.person.typePerson !== 'FOREIGN_PERSON')) {
                if (!ArrayUtil.isEmpty(this.addressList)) {
                    for (let i: number = 0; i < this.addressList.length; i++) {
                        if (!ObjectUtil.isNull(this.addressList[i].city)) {
                            const address: RegistrationAddress = this.addressList[i];
                            address.state = address.city.state;
                            address.country = address.city.state.country;
                            this.getCity(address);
                        }
                    }
                }
            }

            if (ArrayUtil.isEmpty(this.addressList)) {
                this.addressList = [];
                if (!this.isRepresentativeProvider) {
                    this.addNewAddress();
                }

                if (this.person.typePerson !== 'FOREIGN_PERSON') {
                    await this.setCountryDefault();
                }
            }

            this.loading = false;
        });
    }

    private async getCountry(): Promise<void> {
        try {
            await this._countryService.findAllEnabled(0, 300).toPromise().then(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    this.countryList = page.content;
                }
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    private async setCountryDefault(): Promise<void> {
        if (!ArrayUtil.isEmpty(this.countryList)) {
            const countryDefault: RegistrationCountry = this.countryList.find(country => country.code.trim() === '1058');
            if (!ObjectUtil.isNull(countryDefault)) {
                for (let i: number = 0; i < this.addressList.length; i++) {
                    const address: RegistrationAddress = this.addressList[i];
                    address.country = countryDefault;
                }
            }
        }

        return Promise.resolve();
    }

    private async getState(): Promise<void> {
        try {
            await this._stateService.findAllEnabled(0, 50).toPromise().then(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    this.stateList = page.content;
                }
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    async getCity(address: RegistrationAddress): Promise<void> {
        try {
            if (ObjectUtil.isNewModel(address.state) || ObjectUtil.isNull(address.state)) {
                address.cityList = [];
                address.state = new RegistrationState();
                return;
            }

            // await this.cityService.findByState(address.state).toPromise().then(cityList => {
            //     address.cityList = cityList;
            // }, error => {
            //     throw new Error(error);
            // });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    findCep(address: RegistrationAddress): void {
        try {
            if (this.person.typePerson === 'FOREIGN_PERSON') {
                return;
            }
            if (StringUtil.isEmpty(address.zipCode)) {
                this.addWarningMessage('Preencha o campo para realizar a consulta');
                return;
            }
            if (address.zipCode.length !== 8) {
                this.addWarningMessage('CEP inválido');
                return;
            }

            this.loadingFindCep = true;

            this._addressService.getAddressByCep(address.zipCode).then(resp => {
                this._cityService.findByIbgeCode(resp.ibge).toPromise().then(city => {
                    if (!ObjectUtil.isNull(city)) {
                        address.cityList = [city];
                        address.country = city.state.country;
                        address.state = city.state;
                        address.city = city;

                        this.getCity(address);
                    }
                    this.loadingFindCep = false;
                }, error => {
                    throw new Error(error);
                });
            }).catch(error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loadingFindCep = false;
            this.handleError(e);
        }
    }

    deleteAddress(address: RegistrationAddress): void {
        if (UUIDUtil.isValid(address.id)) {
            this.addressList.splice(this.addressList.findIndex(a => a.id === address.id), 1);
        } else {
            this.addressList.splice(this.addressList.findIndex(a => a.key === address.key), 1);
        }

        if ((address.useInInvoice) && (!ArrayUtil.isEmpty(this.addressList))) {
            this.addressList[0].useInInvoice = true;
        }
    }

    addNewAddress(): void {
        const address = new RegistrationAddress();

        if (this.person.typePerson !== 'FOREIGN_PERSON') {
            address.city = new RegistrationCity();
            address.state = new RegistrationState();
        }

        address.country = new RegistrationCountry();

        address.type = 'RESIDENTIAL';
        address.key = this.addressList.length;
        address.withoutStreetNumber = false;

        if ((ArrayUtil.isEmpty(this.addressList) || this.isCreating) && address.key === 0) {
            address.useInInvoice = true;
        }

        this.addressList.push(address);

        if (this.person.typePerson !== 'FOREIGN_PERSON') {
            this.setCountryDefault();
        }
    }

    validateUseInInvoice(address: RegistrationAddress): void {
        this.addressList.map(a => a.useInInvoice = false);

        setTimeout(() => {
            this.addressList.filter(a => a === address).map(a => a.useInInvoice = true);
        }, 10);
    }

    public setCountryCode(address: RegistrationAddress): void {
        if (
            (this.person.typePerson === 'FOREIGN_PERSON') &&
            !ObjectUtil.isNull(address) &&
            !ObjectUtil.isNull(address.country)
        ) {
            address.countryCode = address.country.code;
        }
    }

    public validateStreetNumber(streetNumber: string, address: RegistrationAddress): void {
        setTimeout(() => {
            address.withoutStreetNumber = false;
            if (!StringUtil.isEmpty(streetNumber) && (streetNumber.toUpperCase() === 'SN')) {
                address.withoutStreetNumber = true;
            }
        }, 1);
    }

    public onChangeStreetNumber(checkBox: MatCheckboxChange, address: RegistrationAddress): void {
        if (
            ObjectUtil.isNull(checkBox) ||
            ObjectUtil.isNull(address.withoutStreetNumber)
        ) {
            return;
        }

        if (checkBox.checked || address.withoutStreetNumber) {
            address.streetNumber = 'SN';
        } else {
            address.streetNumber = '';
        }
    }

}
