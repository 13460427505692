import { catchError, map, takeUntil } from 'rxjs/operators';
import { SalePaths } from '@gipi-paths/sale.paths';
import { GIPIAbstractCrudService, GIPIBaseService, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class SalePaymentMethodService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(SalePaths.paymentMethod, 'v1', baseService);
        this.baseService = baseService;
    }
    findByChargeType(chargeType) {
        return this.baseService.httpClient.get(this.url(`find-by-charge-type/${chargeType}`), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    findAllEnabled(page, size, version, sort) {
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        let lSort = '';
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.field)) {
            lSort = `&sort=${sort.field},${sort.direction}`;
        }
        return this.baseService.httpClient.get(this.url(`find-all-enabled?page=${page}&size=${size}${lSort}`, version), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
SalePaymentMethodService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SalePaymentMethodService_Factory() { return new SalePaymentMethodService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: SalePaymentMethodService, providedIn: "root" });
