import { Injectable } from '@angular/core';

import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import { RegistrationIntermediaryFilterDTO } from '../models/dto/intermediary-filter.dto';
import { RegistrationIntermediary } from '../models/intermediary.model';

@Injectable({ providedIn: 'root' })
export class RegistrationIntermediaryService extends GIPIAbstractCrudService<RegistrationIntermediary, RegistrationIntermediaryFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(RegistrationPaths.intermediary, 'v1', baseService);
    }

}
