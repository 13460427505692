import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { FinancialPaths } from '@gipi-paths/financial.paths';
import { AbstractCrudService, FilterDTO } from '@gipisistemas/ng-core';
import { FinancialConfiguration } from '../models/configuration.model';

@Injectable({ providedIn: 'root' })
export class FinancialConfigurationService extends AbstractCrudService<FinancialConfiguration, FilterDTO> {

    constructor(http: HttpClient) {
        super(FinancialPaths.configuration, http);
    }

    findEnabled(): Observable<FinancialConfiguration> {
        return this.http.get(this.url('enabled'), this.options()).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

}
