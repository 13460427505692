import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SaleClientService } from '@gipi-sale/client/services/client.service';
import { SaleStatusEnum } from '@gipi-sale/sale/enums/sale-status.enum';
import { SaleTaxInvoiceStatusEnum } from '@gipi-sale/sale/enums/sale-tax-invoice-status.enum';
import { SaleFilterDTO } from '@gipi-sale/sale/models/dto/sale-filter.dto';
import { SaleService } from '@gipi-sale/sale/services/sale.service';
import { AppliedFilter } from '@gipi-shared/applied-filter.dto';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { SessionStorageService } from '@gipi-shared/services/session-storage.service';
import { AbstractComponent, ArrayUtil, CurrencyUtil, DateUtil, DialogService, GIPIFormFieldComponent, GIPISortModel, ObjectUtil, PageDTO, StringUtil, TableColumnBuilder } from '@gipisistemas/ng-core';
import { SaleDataDialogComponent } from '../sale-data-dialog/sale-data-dialog.component';
export class SaleListDialogComponent extends AbstractComponent {
    constructor(dialogRef, data = { salesSelected: [], selectOne: false }, _saleService, _clientService, _dialogService, _sessionStorageService, messageService, router, activatedRoute) {
        super(messageService, router, activatedRoute);
        this.dialogRef = dialogRef;
        this.data = data;
        this._saleService = _saleService;
        this._clientService = _clientService;
        this._dialogService = _dialogService;
        this._sessionStorageService = _sessionStorageService;
        this.filter = new SaleFilterDTO();
        this.salePage = new PageDTO();
        this.salesSelected = [];
        this.selectOne = false;
        this.selected = false;
        this.indeterminate = false;
        this.allTypesChecked = true;
        this.indeterminateType = false;
        this.finishedChecked = false;
        this.partialRefoundCheked = false;
        this.clientIdSelect = null;
        this.saleStatusEnum = SaleStatusEnum;
        this.saleTaxInvoiceStatusEnum = SaleTaxInvoiceStatusEnum;
        this.appliedFilters = [];
        this.clientFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const configuration = this._sessionStorageService.get('configuration');
            let propertySort = 'person.name';
            if (!ObjectUtil.isNull(configuration) && configuration.showBusinessNameInReceiptQuery) {
                propertySort = 'person.fantasyName';
            }
            const result = yield this._clientService.findByValue(value, page, 10, new GIPISortModel(propertySort, 'ASC')).toPromise();
            return result;
        });
        this.clientFn = (obj) => this._clientService.getDescription(obj);
    }
    ngOnInit() {
        super.ngOnInit();
        this.createTableColumnsSale();
        this.salesSelected = [...this.data.salesSelected];
        this.selectOne = this.data.selectOne;
        setTimeout(() => this.search.focus());
    }
    clear() {
        this.filter = new SaleFilterDTO();
        this._clearAppliedFilters();
        this.find();
    }
    _clearAppliedFilters() {
        this.appliedFilters = [];
    }
    _setAppliedFilters() {
        if (ObjectUtil.isNull(this.filter)) {
            return;
        }
        this._clearAppliedFilters();
        if (!ObjectUtil.isNull(this.filter.begin) && !ObjectUtil.isNull(this.filter.end)) {
            const description = `${DateUtil.format(this.filter.begin, DateUtil.DATE_FORMAT)} á ${DateUtil.format(this.filter.end, DateUtil.DATE_FORMAT)}`;
            this.appliedFilters.push(new AppliedFilter(description, 'begin_and_end_date'));
        }
        else {
            if (!ObjectUtil.isNull(this.filter.begin)) {
                const description = `Data inicial: ${DateUtil.format(this.filter.begin, DateUtil.DATE_FORMAT)}`;
                this.appliedFilters.push(new AppliedFilter(description, 'begin_date'));
            }
            if (!ObjectUtil.isNull(this.filter.end)) {
                const description = `Data final: ${DateUtil.format(this.filter.end, DateUtil.DATE_FORMAT)}`;
                this.appliedFilters.push(new AppliedFilter(description, 'end_date'));
            }
        }
        if (this.filter.taxCouponNumber) {
            this.appliedFilters.push(new AppliedFilter('Número da nota fiscal', 'tax_coupon_number'));
        }
        if (this.filter.taxCouponSeries) {
            this.appliedFilters.push(new AppliedFilter('Série da nota fiscal', 'tax_coupon_series'));
        }
        if (!StringUtil.isEmpty(this.filter.document)) {
            this.appliedFilters.push(new AppliedFilter('Documento', 'document'));
        }
        if (this.filter.total) {
            this.appliedFilters.push(new AppliedFilter('Valor', 'total'));
        }
        if (!ObjectUtil.isNull(this.filter.client)) {
            this.appliedFilters.push(new AppliedFilter('Cliente', 'client'));
        }
    }
    removeAppliedFilter(filter) {
        switch (filter.chip.key) {
            case 'begin_and_end_date': {
                this.filter.begin = null;
                this.filter.end = null;
                break;
            }
            case 'begin_date': {
                this.filter.begin = null;
                break;
            }
            case 'end_date': {
                this.filter.end = null;
                break;
            }
            case 'tax_coupon_number': {
                this.filter.taxCouponNumber = null;
                break;
            }
            case 'tax_coupon_series': {
                this.filter.taxCouponNumber = null;
                break;
            }
            case 'client': {
                this.filter.client = null;
                break;
            }
            case 'document': {
                this.filter.document = '';
                break;
            }
            case 'total': {
                this.filter.total = null;
                break;
            }
        }
        this.find(null);
    }
    find(pageEvent) {
        try {
            if (DateUtil.isGreaterThan(this.filter.begin, this.filter.end)) {
                throw new Error('Data final não pode ser menor que data inicial');
            }
            this.loading = true;
            this.salePage = new PageDTO();
            if (pageEvent) {
                this.filter.pageNumber = pageEvent.pageIndex;
                this.filter.pageSize = pageEvent.pageSize;
                this.filter.offset = pageEvent.pageIndex * pageEvent.pageSize;
            }
            else {
                this.filter.pageNumber = 0;
                this.filter.pageSize = 5;
                this.filter.offset = 0;
            }
            this.setStatusEnumList(this.filter);
            // this.filter.saleIdsExcluded = this.salesSelected.map(sale => sale.id);
            this.filter.taxInvoiceStatusList = ['NOT_TRANSMITTED', 'AUTHORIZED', 'NOT_GENERATED'];
            this._setAppliedFilters();
            this._saleService.find(this.filter).toPromise().then((page) => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    page.content.map(sale => sale.selected = (this.salesSelected.findIndex(s => s.id === sale.id) >= 0));
                }
                this.salePage = page;
                this.validateAllSelected();
                this.loading = false;
            }, (error) => {
                this.loading = false;
                this.handleError(error);
            });
        }
        catch (e) {
            this.handleError(e);
        }
    }
    setStatusEnumList(filter) {
        filter.saleStatusList = [];
        if (!this.allTypesChecked) {
            if (this.finishedChecked) {
                filter.saleStatusList.push('FINISHED');
            }
            if (this.partialRefoundCheked) {
                filter.saleStatusList.push('PARTIALLY_RETURNED');
            }
            if (filter.saleStatusList.length === 0) {
                filter.saleStatusList.push('FINISHED');
                filter.saleStatusList.push('PARTIALLY_RETURNED');
            }
        }
        else {
            filter.saleStatusList.push('FINISHED');
            filter.saleStatusList.push('PARTIALLY_RETURNED');
        }
    }
    createTableColumnsSale() {
        return [
            TableColumnBuilder.instance()
                .property('checkAll')
                .templateHeader(this.checkAllTemplate)
                .template(this.checkboxTemplate)
                .width(5)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('createdDate')
                .name('Data')
                .width(10)
                .align('center center')
                .sortable(true)
                .value((obj) => DateUtil.format(obj.date, DateUtil.DATE_FORMAT))
                .action((obj) => {
                obj.selected = !obj.selected;
                this.select(obj, true);
                if (this.selectOne) {
                    this.close(true, obj);
                }
            })
                .build(),
            TableColumnBuilder.instance()
                .property('clientName')
                .name('Cliente')
                .sortable(true)
                .value((obj) => obj.client.person.name)
                .action((obj) => {
                obj.selected = !obj.selected;
                this.select(obj, true);
                if (this.selectOne) {
                    this.close(true, obj);
                }
            })
                .build(),
            TableColumnBuilder.instance()
                .property('documentNumber')
                .name('Documento')
                .width(10)
                .align('center center')
                .sortable(true)
                .value((obj) => obj.documentNumber)
                .action((obj) => {
                obj.selected = !obj.selected;
                this.select(obj, true);
                if (this.selectOne) {
                    this.close(true, obj);
                }
            })
                .build(),
            TableColumnBuilder.instance()
                .property('taxCouponNumber')
                .name('N° nota fiscal')
                .width(10)
                .align('center center')
                .sortable(true)
                .value((obj) => obj.taxCouponNumber)
                .action((obj) => {
                obj.selected = !obj.selected;
                this.select(obj, true);
                if (this.selectOne) {
                    this.close(true, obj);
                }
            })
                .build(),
            TableColumnBuilder.instance()
                .property('taxCouponSeries')
                .name('Série nota fiscal')
                .width(10)
                .align('center center')
                .sortable(true)
                .value((obj) => obj.taxCouponSeries)
                .action((obj) => {
                obj.selected = !obj.selected;
                this.select(obj, true);
                if (this.selectOne) {
                    this.close(true, obj);
                }
            })
                .build(),
            TableColumnBuilder.instance()
                .property('amount')
                .name('Total líquido')
                .width(10)
                .align('center center')
                .value((obj) => CurrencyUtil.transform(obj.amountNet, '1.2-2'))
                .action((obj) => {
                obj.selected = !obj.selected;
                this.select(obj, true);
                if (this.selectOne) {
                    this.close(true, obj);
                }
            })
                .build(),
            TableColumnBuilder.instance()
                .property('taxInvoiceStatus')
                .name('Status nota fiscal')
                .align('center center')
                .width(10)
                .marginLeft(5)
                .marginRight(5)
                .value((obj) => this.saleTaxInvoiceStatusEnum[obj.taxInvoiceStatus])
                .action((obj) => {
                obj.selected = !obj.selected;
                this.select(obj, true);
                if (this.selectOne) {
                    this.close(true, obj);
                }
            })
                .build(),
            TableColumnBuilder.instance()
                .property('status')
                .name('Status')
                .align('center center')
                .width(13)
                .marginLeft(5)
                .marginRight(5)
                .template(this.statusTemplate)
                .action((obj) => {
                obj.selected = !obj.selected;
                this.select(obj, true);
                if (this.selectOne) {
                    this.close(true, obj);
                }
            })
                .build(),
            TableColumnBuilder.instance()
                .property('actions')
                .name('Ações')
                .width(10)
                .align('center center')
                .template(this.actions)
                .build(),
        ];
    }
    close(isConfirm, entitySelected) {
        try {
            if (isConfirm) {
                if (this.selectOne) {
                    this.dialogRef.close([entitySelected]);
                }
                else {
                    this.dialogRef.close(this.salesSelected);
                }
            }
            else {
                this.dialogRef.close(null);
            }
        }
        catch (e) {
            this.handleError(e);
        }
    }
    select(sale, validate) {
        try {
            const idClient = sale.client.id;
            if ((this.clientIdSelect !== null) && (this.clientIdSelect !== idClient)) {
                setTimeout(() => sale.selected = false);
                this.addWarningMessage('Não é possível selecionar vendas de clientes distintos.');
                return;
            }
            if (sale.selected) {
                this.clientIdSelect = idClient;
                this.salesSelected.push(sale);
            }
            else {
                this.salesSelected.splice(this.salesSelected.findIndex(s => s.id === sale.id), 1);
                if (this.salesSelected.length <= 0) {
                    this.clientIdSelect = null;
                }
            }
            if (validate) {
                this.validateAllSelected();
            }
        }
        catch (e) {
            this.handleError(e);
        }
    }
    validateAllSelected() {
        // const list: SaleSale[] = this.salePage.content.filter((sale) => sale.selected);
        this.selected = this.salesSelected.length > 0;
        this.indeterminate = this.salesSelected.length > 0 && this.salesSelected.length !== this.salePage.content.length;
    }
    checkAll() {
        for (let i = 0; i < this.salePage.content.length; i++) {
            this.salePage.content[i].selected = this.selected;
            this.select(this.salePage.content[i], false);
        }
        this.validateAllSelected();
    }
    checkAllStatus() {
        this.finishedChecked = false;
        this.partialRefoundCheked = false;
        this.indeterminateType = false;
    }
    validateAllStatusSelected() {
        if (this.finishedChecked &&
            this.partialRefoundCheked) {
            this.allTypesChecked = true;
            this.indeterminateType = false;
        }
        else if (this.finishedChecked ||
            this.partialRefoundCheked) {
            this.indeterminateType = true;
            this.allTypesChecked = false;
        }
        else {
            this.indeterminateType = false;
            this.allTypesChecked = false;
        }
    }
    navigateSaleData(entity) {
        this._dialogService.open({
            componentOrTemplateRef: SaleDataDialogComponent,
            data: entity.id,
            width: '90%',
            height: '90%',
        });
    }
    setColorStatus(entity) {
        switch (entity.status.trim()) {
            case 'IN_PROGRESS':
                return '#ffefe3';
            case 'OPENED':
                return '#d0e6f7';
            case 'FINISHED':
                return '#d2f8dc';
            case 'CANCELED':
                return '#eec2c2';
            case 'RETURNED':
                return '#3B405A';
            case 'PARTIALLY_RETURNED':
                return '#8256d066';
        }
    }
    setTextColor(entity) {
        switch (entity.status.trim()) {
            case 'IN_PROGRESS':
                return '#ffa160';
            case 'OPENED':
                return '#19348c';
            case 'FINISHED':
                return '#197837';
            case 'CANCELED':
                return '#ec5040';
            case 'RETURNED':
                return '#4A54AB';
            case 'PARTIALLY_RETURNED':
                return '#8256D0';
        }
    }
}
