import { Injectable } from '@angular/core';

import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import { RegistrationClientClassification } from '../models/client-classification.model';
import { RegistrationClientClassificationFilterDTO } from '../models/dto/client-classification-filter.dto';

@Injectable({ providedIn: 'root' })
export class RegistrationClientClassificationService extends GIPIAbstractCrudService<RegistrationClientClassification, RegistrationClientClassificationFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(RegistrationPaths.clientClassification, 'v1', baseService);
    }

}
