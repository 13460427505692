import { catchError, map, takeUntil } from 'rxjs/operators';
import { NotificationPaths } from '@gipi-paths/notification.paths';
import { ArrayUtil, DateUtil, GIPIAbstractCrudService, GIPIBaseService, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class NotificationInvoiceIntegrationsService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(NotificationPaths.notification, 'v1', baseService);
        this.baseService = baseService;
    }
    findIfHaveNotificationToReceive(version) {
        return this.baseService.httpClient.get(this.url(`find-if-have-notification-to-receive`, version), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    findNotificationStatement(initialDate, endDate, version) {
        const isValidDate = (date) => !ObjectUtil.isNull(date) && DateUtil.isValid(date);
        const params = new URLSearchParams();
        if (isValidDate(initialDate)) {
            params.set('initialDate', initialDate.toISOString());
        }
        if (isValidDate(endDate)) {
            params.set('endDate', endDate.toISOString());
        }
        const queryString = !StringUtil.isEmpty(params.toString()) ? `?${params.toString()}` : '';
        return this.baseService.httpClient.get(this.url(`find-notification-statement${queryString}`, version), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    findNotificationStatementIgnoreTenancy(page, size = 10, initialDate, endDate, isClosed = null, version) {
        const validatedPage = Math.max(page, 0);
        const validatedSize = Math.max(size, 10);
        const params = new URLSearchParams();
        params.set('page', validatedPage.toString());
        params.set('size', validatedSize.toString());
        const isValidDate = (date) => !ObjectUtil.isNull(date) && DateUtil.isValid(date);
        if (isValidDate(initialDate)) {
            params.set('initialDate', initialDate.toISOString());
        }
        if (isValidDate(endDate)) {
            params.set('endDate', endDate.toISOString());
        }
        if (isClosed !== null) {
            params.set('isClosed', isClosed.toString());
        }
        const queryString = !StringUtil.isEmpty(params.toString()) ? `?${params.toString()}` : '';
        return this.baseService.httpClient.get(this.url(`find-notification-statement-ignore-tenancy${queryString}`, version), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    sendWhatsappNotifications(notificationType, notificationList, version) {
        if (ArrayUtil.isEmpty(notificationList)) {
            throw new Error('Informe ao menos uma mensagem à ser enviada');
        }
        return this.baseService.httpClient.post(this.url(`send-whatsapp-notifications/${notificationType}`, version), notificationList, this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
NotificationInvoiceIntegrationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationInvoiceIntegrationsService_Factory() { return new NotificationInvoiceIntegrationsService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: NotificationInvoiceIntegrationsService, providedIn: "root" });
