import { of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { ArrayUtil, GIPIAbstractCrudService, GIPIBaseService, ObjectUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class RegistrationProductService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(RegistrationPaths.product, 'v1', baseService);
        this.baseService = baseService;
    }
    findByBarCode(barCode, version) {
        return this.baseService.httpClient.get(this.url(`find-by-value?page=0&size=20&value=${barCode}`, version), this.options()).pipe(map(json => {
            const page = this.handleMapper(json);
            if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                return of(page.content[0]);
            }
            return null;
        }), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    findByCodeBalance(code, version) {
        return this.baseService.httpClient.get(this.url(`find-by-value?page=0&size=20&value=${code}`, version), this.options()).pipe(map(json => {
            const page = this.handleMapper(json);
            if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                return of(page.content[0]);
            }
            return null;
        }), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
RegistrationProductService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationProductService_Factory() { return new RegistrationProductService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: RegistrationProductService, providedIn: "root" });
