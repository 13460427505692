<div fxLayout="column"
     fxLayoutAlign="center center">
    <div fxLayout="row"
         fxLayoutAlign="center center"
         fxLayoutGap="150px"
         fxFlex="100vh">
        <itss-card class="change-password">
            <div fxLayout="column"
                 fxLayoutAlign="center center">
                <img width="180"
                     src="../../../assets/logo/logo_and_name.png" />
            </div>

            <div fxLayout="column"
                 fxLayoutGap="30px">
                <p>
                    Para alterar sua senha, basta digitar abaixo a nova senha e a confirmação da nova senha
                </p>

                <div fxLayout="column"
                     fxLayoutGap="20px">
                    <div fxLayout="column"
                         fxLayoutGap="5px">
                        <itss-input label="Nova senha"
                                    class="input-rounded"
                                    [type]="isViewNewPassword ? 'text' : 'password'"
                                    [disabled]="loading"
                                    [(ngModel)]="newPassword"
                                    [min]="6"
                                    [max]="15"
                                    [maxlength]="15"
                                    fxFlex="100"
                                    (focus)="passwordFocused = true"
                                    (blur)="passwordFocused = false">
                            <ng-template #suffix>
                                <itss-button [svgIcon]="isViewNewPassword ? 'eye' : 'eye_off'"
                                             color="primary"
                                             type="icon"
                                             [tooltip]="isViewNewPassword ? 'Ocultar senha' : 'Exibir senha'"
                                             (click)="isViewNewPassword = !isViewNewPassword">
                                </itss-button>
                            </ng-template>
                        </itss-input>

                        <gipi-password-requeriments *ngIf="passwordFocused || (newPassword && (newPassword.length > 0))"
                                                    [(ngModel)]="newPassword">
                        </gipi-password-requeriments>
                    </div>

                    <itss-input label="Confirmação nova senha"
                                class="input-rounded"
                                [type]="isViewNewPasswordConfirm ? 'text' : 'password'"
                                [disabled]="loading"
                                [(ngModel)]="newPasswordConfirm"
                                [min]="6"
                                [max]="15"
                                [maxlength]="15"
                                (keydown.enter)="keydownChangePassword()"
                                fxFlex="100">
                        <ng-template #suffix>
                            <itss-button [svgIcon]="isViewNewPasswordConfirm ? 'eye' : 'eye_off'"
                                         color="primary"
                                         type="icon"
                                         [tooltip]="isViewNewPasswordConfirm ? 'Ocultar senha' : 'Exibir senha'"
                                         (click)="isViewNewPasswordConfirm = !isViewNewPasswordConfirm">
                            </itss-button>
                        </ng-template>
                    </itss-input>
                </div>

                <div fxLayoutAlign="center center"
                     fxLayout="row"
                     fxLayoutGap="20px">
                    <button class="btn-rounded confirm"
                            aria-label="Confirmar"
                            [disabled]="loading"
                            (click)="updatePassword()"
                            fxFlex="25"
                            fxFlex.lt-md="100">
                        Confirmar
                    </button>
                    <button class="btn-rounded cancel"
                            aria-label="Cancelar"
                            (click)="returnToLogin()"
                            fxFlex="25"
                            fxFlex.lt-md="100">
                        Cancelar
                    </button>
                </div>
            </div>
        </itss-card>
    </div>
</div>