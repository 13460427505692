<gipi-abstract-form id="importExtract"
                    name="importExtract"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarImportExtract">
        Importar extrato (OFX)
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <itss-select-entity-paged label="Conta bancária"
                                  *ngIf="isBillConciliation"
                                  [required]="true"
                                  [disabled]="isLoad || loading"
                                  [(model)]="bankAccount"
                                  [nextBatchFn]="bankAccountFindByTypeFn"
                                  [property]="'description'"
                                  fxFlex
                                  fxFlex.lt-md="100">
        </itss-select-entity-paged>
        <itss-input label="Banco"
                    id="importExtractBank"
                    name="importExtractBank"
                    [disabled]="true"
                    [value]="bankAccount?.bank ? bankAccount?.bank?.code + ' - ' + bankAccount?.bank?.name : ''"
                    fxFlex
                    fxFlex.lt-md="100">
        </itss-input>
        <div fxLayout="row"
             fxLayoutAlign="start"
             fxLayoutGap="16px"
             fxLayout.lt-sm="column">
            <itss-input-file label="Selecione o arquivo OFX / Money 2000"
                             #inputFile
                             id="importExtractFile"
                             name="importExtractFile"
                             [required]="true"
                             [disabled]="isLoad || loading"
                             [extensions]="['.ofx', '.OFX']"
                             [(archive)]="archive"
                             (nativeFileChange)="selectionChangeArchive($event)"
                             fxFlex
                             fxFlex.lt-md="100">
            </itss-input-file>
            <itss-input-currency label="Saldo do extrato"
                                 id="importExtractBalance"
                                 name="importExtractBalance"
                                 [required]="true"
                                 [disabled]="true"
                                 [value]="archive && ofxFile ? ofxFile.balance : 0"
                                 fxFlex="30"
                                 fxFlex.lt-md="100">
            </itss-input-currency>
        </div>
    </div>
    <div mat-dialog-actions
         fxLayout="row wrap"
         fxLayout.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnSave"
                     (click)="confirm()"
                     [disabled]="isLoad || disableBtnConfirmation || !hasPermission('BANK_ACCOUNT_MAKE') || !hasPermission('BANK_ACCOUNT_UPDATE')"
                     [loading]="loading"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close('NONE')"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>