export enum SaleStatusEnum {
    IN_PROGRESS = 'Em progresso',
    OPENED = 'Em aberto',
    FINISHED = 'Finalizada',
    CANCELED = 'Cancelada',
    RETURNED = 'Devolvida',
    PARTIALLY_RETURNED = 'Devolvida parcialmente'
}

export type SaleStatus = 'IN_PROGRESS' | 'OPENED' | 'FINISHED' | 'CANCELED' | 'RETURNED' | 'PARTIALLY_RETURNED';
