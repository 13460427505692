import * as tslib_1 from "tslib";
import { AfterViewInit, ChangeDetectorRef, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthUser } from '@gipi-financial/user/models/user.model';
import { OAuthUserPublicService } from '@gipi-financial/user/services/user-public.service';
import { BaseCrudComponent } from '@gipi-shared/base-crud.component';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { ConfirmationService, EmailUtil, MessageService, ObjectUtil, PasswordUtil, StringUtil } from '@gipisistemas/ng-core';
export class ConfirmAccessComponent extends BaseCrudComponent {
    constructor(userPublicService, messageService, confirmationService, router, activatedRoute, _authenticationService, _changeDetectorRef) {
        super(userPublicService, messageService, confirmationService, router, activatedRoute);
        this.userPublicService = userPublicService;
        this.messageService = messageService;
        this.confirmationService = confirmationService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this._authenticationService = _authenticationService;
        this._changeDetectorRef = _changeDetectorRef;
        this.user = new OAuthUser();
        this.hasAccessControlEnabled = false;
        this.isViewPassword = false;
        this.isViewPasswordConfirmation = false;
        this.passwordFocused = false;
        localStorage.removeItem('token');
    }
    ngOnInit() {
        const _super = Object.create(null, {
            ngOnInit: { get: () => super.ngOnInit }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            _super.ngOnInit.call(this);
            this.token = this.activatedRoute.snapshot.params.token;
            this._getUserByToken();
        });
    }
    _getUserByToken() {
        try {
            if (StringUtil.isEmpty(this.token)) {
                return;
            }
            this.loading = true;
            this.userPublicService.getByInvitationToken(this.token).toPromise().then(user => {
                this.hasAccessControlEnabled = user.accessControlList.filter(uac => uac.status === 'ENABLED').length > 0;
                user.accessControlList.filter(uac => uac.status === 'NOT_CONFIRMED').map(uac => uac.status = 'ENABLED');
                this.user = user;
                this.loading = false;
                this._changeDetectorRef.detectChanges();
            }, error => {
                setTimeout(() => this.returnToLogin(), 1000);
                throw new Error(error);
            });
        }
        catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }
    newEntity() {
        return new OAuthUser();
    }
    getPath() {
        return `/oauth/confirm-access/${this.token}`;
    }
    save() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this._validate()) {
                    this.loading = true;
                    if (this.hasAccessControlEnabled) {
                        this.user.password = this.passwordConfirmation.trim();
                        this._authenticationService.login(this.user).toPromise().then(() => {
                            this._confirmAccess();
                        }).catch(() => {
                            this.loading = false;
                            this.handleError('Credenciais inválidas');
                        });
                    }
                    else {
                        this.user.password = this.password;
                        yield this._confirmAccess();
                    }
                }
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    keydownConfirmAccess() {
        if (this._isValid()) {
            this.save();
        }
    }
    _validate() {
        if (StringUtil.isEmpty(this.user.username) || !EmailUtil.isValid(this.user.username.toLowerCase())) {
            this.addWarningMessage('Campo "E-mail" inválido');
            return false;
        }
        if (StringUtil.isEmpty(this.user.name)) {
            this.addWarningMessage('Campo "Nome" inválido');
            return false;
        }
        if (this.hasAccessControlEnabled) {
            if (StringUtil.isEmpty(this.passwordConfirmation)) {
                this.addWarningMessage('Campo "Confirmação senha" é obrigatório e não foi informado');
                return false;
            }
        }
        else {
            if (StringUtil.isEmpty(this.password)) {
                this.addWarningMessage('Campo "Senha" é obrigatório e não foi informado');
                return false;
            }
            else if (StringUtil.isEmpty(this.passwordConfirmation)) {
                this.addWarningMessage('Campo "Confirmação senha" é obrigatório e não foi informado');
                return false;
            }
            else if (!PasswordUtil.isValidMinLength(this.password)) {
                this.addWarningMessage('A senha deve conter no mínimo 6 caracteres');
                return false;
            }
            else if (!PasswordUtil.isValidMaxLength(this.password)) {
                this.addWarningMessage('A senha deve conter no máximo 15 caracteres');
                return false;
            }
            else if (!PasswordUtil.hasUppercase(this.password)) {
                this.addWarningMessage('A senha deve conter no mínimo 1 caractere maiúsculo');
                return false;
            }
            else if (!PasswordUtil.hasLowercase(this.password)) {
                this.addWarningMessage('A senha deve conter no mínimo 1 caractere minúsculo');
                return false;
            }
            else if (!PasswordUtil.hasNumber(this.password)) {
                this.addWarningMessage('A senha deve conter no mínimo 1 número');
                return false;
            }
            else if (PasswordUtil.hasBackspace(this.password)) {
                this.addWarningMessage('A senha não deve conter espaços');
                return false;
            }
            else if (this.password !== this.passwordConfirmation) {
                this.addWarningMessage('A senha e a confirmação da senha devem ser iguais');
                return false;
            }
        }
        return true;
    }
    _isValid() {
        if (this.hasAccessControlEnabled) {
            return !StringUtil.isEmpty(this.passwordConfirmation);
        }
        else {
            return (!StringUtil.isEmpty(this.password) &&
                !StringUtil.isEmpty(this.passwordConfirmation) &&
                (this.password === this.passwordConfirmation));
        }
    }
    _confirmAccess() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const photoUser = !StringUtil.isEmpty(this.user.photo) ? this.user.photo : '';
                const userConfirmed = yield this.userPublicService.confirmAccess(this.token, this.user.password, this.user.name, photoUser).toPromise().catch(error => {
                    this.loading = false;
                    this.handleError(error);
                    return null;
                });
                if (!ObjectUtil.isNull(userConfirmed)) {
                    this.returnToLogin();
                }
                this._authenticationService.revokeToken(this._authenticationService.tokenValue).subscribe(() => this._authenticationService.removeToken());
            }
            catch (e) {
                throw new Error(e);
            }
        });
    }
    returnToLogin() {
        this.router.navigate(['/oauth/login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
    }
}
