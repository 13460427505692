import { BaseModel } from '@gipi-shared/models/base.model';
import { SaleGenderEnum } from '../enums/gender.enum';

export class SaleNaturalPerson extends BaseModel {

    cpf: string;

    gender: SaleGenderEnum;

    documentNumber: string;

}
