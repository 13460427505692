import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { FinancialPaths } from '@gipi-paths/financial.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import { FinancialCheckReceived } from '../models/check-received.model';
import { FinancialCheckReceivedFilterDTO } from '../models/dto/check-received-filter.dto';

@Injectable({ providedIn: 'root' })
export class FinancialCheckReceivedService extends GIPIAbstractCrudService<FinancialCheckReceived, FinancialCheckReceivedFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(FinancialPaths.checkReceived, 'v1', baseService);
    }

    updateCheckReceivedList(checkReceivedList: FinancialCheckReceived[]): Observable<FinancialCheckReceived[]> {
        return this.baseService.httpClient.put(this.url('update-check-receiveds'), checkReceivedList, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    findByIds(idCheckReceivedList: number[]): Observable<FinancialCheckReceived[]> {
        return this.baseService.httpClient.post(this.url('find-by-ids'), idCheckReceivedList, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    moveCheckReceived(checkReceivedList: FinancialCheckReceived[], action: string): Observable<FinancialCheckReceived[]> {
        const url: string = this.url(`move-check/${action}`);
        return this.baseService.httpClient.post(url, checkReceivedList, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

}
