import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { OAuthAccessControl } from '@gipi-financial/access-control/models/access-control.model';
import { OAuthPermission } from '@gipi-financial/access-control/models/permission.model';
import { OAuthAccessControlService } from '@gipi-financial/access-control/services/access-control.service';
import { FinancialCompany } from '@gipi-financial/company/models/company.model';
import { FinancialCompanyService } from '@gipi-financial/company/services/company.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ArrayUtil, ConfirmationService, ObjectUtil } from '@gipisistemas/ng-core';

@Component({
    selector: 'gipi-replicate-confirmation-dialog',
    templateUrl: './replicate-confirmation-dialog.component.html',
    styles: []
})
export class ReplicateConfirmationDialogComponent extends AbstractComponent implements OnInit {

    companyList: FinancialCompany[] = [];

    company: FinancialCompany;

    accessControlList: OAuthAccessControl[];

    count: number = 0;

    findIndex: number = 0;

    constructor(
        public dialogRef: MatDialogRef<ReplicateConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: OAuthAccessControl[],
        private _accessControlService: OAuthAccessControlService,
        private _confirmationService: ConfirmationService,
        private _companyService: FinancialCompanyService,
        messageService: CustomMessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
    }

    ngOnInit(): void {
        this._companyService.findAllEnabled(0, 50).toPromise().then(page => {
            this.companyList = [];

            if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                this.companyList = page.content.filter(company => company.id !== this._accessControlService.getCurrentCompany().id)
            }
        });

        this.accessControlList = this.data;
        this.accessControlList.forEach(accessControl => accessControl.permitSameName = false);
        this.accessControlList.forEach(accessControl => accessControl.persist = true);
    }

    replicate(): void {
        try {
            if (ObjectUtil.isNewModel(this.company)) {
                throw new Error('Campos obrigatórios (*) não informados');
            }
            this.loading = true;
            this.loadingChange.emit(this.loading);

            if (this.findIndex < this.accessControlList.length) {
                this.existByDescriptionAndCompany();
            } else {
                const newAccessControlList: OAuthAccessControl[] = this.accessControlList.filter(accessControl => accessControl.persist);

                newAccessControlList.map(async accessControl => {
                    this._accessControlService.save(await this.mapNewAccessControl(accessControl)).subscribe(
                        () => {
                            this.count++;
                            if (this.count === newAccessControlList.length) {
                                this.dialogRef.close();
                                this.accessControlList = [];
                                this.addSuccessMessage('Operação realizada com sucesso');
                            }
                        },
                        error => this.handleError(error));
                });
            }
        } catch (e) {
            this.loading = false;
            this.loadingChange.emit(this.loading);
            this.handleError(e);
        }
    }

    close(): void {
        this.dialogRef.close(null);
    }

    private async mapNewAccessControl(accessControl: OAuthAccessControl): Promise<OAuthAccessControl> {
        let newPermissionList: OAuthPermission[];
        const obj: OAuthAccessControl = ObjectUtil.clone(accessControl);
        obj.id = null;
        obj.companyId = this.company.id;
        newPermissionList = obj.permissionList.map(permission => {
            permission.id = null;
            permission.companyId = this.company.id;
            return permission;
        });
        obj.permissionList = newPermissionList;
        await this._accessControlService.existsByDescriptionAndCompany(accessControl, this.company.id)
            .toPromise()
            .then(exists => {
                if (accessControl.permitSameName && exists) {
                    obj.description = `${accessControl.description} - cópia`;
                }
            });
        return obj;
    }

    existByDescriptionAndCompany(): void {
        this._accessControlService.existsByDescriptionAndCompany(this.accessControlList[this.findIndex], this.company.id).subscribe(existSameDescription => {
            if (existSameDescription && !this.accessControlList[this.findIndex].permitSameName) {
                this.confirmationCopySameDescription(this.accessControlList[this.findIndex]);
            } else {
                this.findIndex++;
                this.replicate();
            }
        });
    }

    confirmationCopySameDescription(accessControl: OAuthAccessControl): void {
        this._confirmationService.confirm({
            title: 'Confirmação',
            message: `Já existe um perfil com o nome ${accessControl.description}. Deseja fazer uma cópia para a empresa de destino?`,
            accept: () => {
                if (this.findIndex < (this.accessControlList.length - 1)) {
                    this.confirmationAllSameCase(accessControl);
                } else {
                    this.accessControlList[this.findIndex].permitSameName = true;
                    this.findIndex = this.accessControlList.length;
                    this.replicate();
                }
                this.close();
            },
            cancel: () => {
                accessControl.persist = false;
                this.findIndex++;
                this.replicate();
                this.close();
            }
        });
    }

    confirmationAllSameCase(accessControl: OAuthAccessControl): void {
        this._confirmationService.confirm({
            title: 'Confirmação',
            message: 'Fazer o mesmo para todos que encontrar?',
            accept: () => {
                this.accessControlList.forEach(accessControlEl => accessControlEl.permitSameName = true);
                this.findIndex = this.accessControlList.length;
                this.close();
                this.replicate();
            },
            cancel: () => {
                accessControl.permitSameName = true;
                this.findIndex++;
                this.close();
                this.replicate();
            }
        });
    }

}
