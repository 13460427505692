import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { SalePaths } from '@gipi-paths/sale.paths';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/services/custom-authentication.service";
export class SaleService extends BaseCrudService {
    constructor(http, authenticationService) {
        super(SalePaths.sale, http, authenticationService);
    }
    findByBillInstallment(billInstallmentId) {
        return this.http.get(this.url(`find-by-bill-installment/${billInstallmentId}`), this.options()).pipe(map(this.mapper), catchError(this.handleError));
    }
    updateSaleItemList(saleItemList) {
        return this.http.post(this.url('update-sale-item-list'), saleItemList, this.options()).pipe(map(this.mapper), catchError(this.handleError));
    }
}
SaleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SaleService_Factory() { return new SaleService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CustomAuthenticationService)); }, token: SaleService, providedIn: "root" });
