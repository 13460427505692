import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FinancialCheckReceivedService } from '@gipi-financial/check-received/services/check-received.service';
import { ReasonRefundService } from '@gipi-financial/reason-refund/services/reason-refund.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ArrayUtil, DateUtil, ObjectUtil } from '@gipisistemas/ng-core';
;
export class RefundDialogComponent extends AbstractComponent {
    constructor(dialogRef, data, _checkReceivedService, _reasonRefundService, messageService, router, activatedRoute) {
        super(messageService, router, activatedRoute);
        this.dialogRef = dialogRef;
        this.data = data;
        this._checkReceivedService = _checkReceivedService;
        this._reasonRefundService = _reasonRefundService;
        this.checkReceivedList = [];
        this.moveDate = new Date();
        this.reasonRefundFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._reasonRefundService.findByValue(value, page, 10).toPromise();
            if (!ObjectUtil.isNull(result) && !ArrayUtil.isEmpty(result.content)) {
                this.reasonRefund = result.content[0];
            }
            return result;
        });
    }
    ngOnInit() {
        const _super = Object.create(null, {
            ngOnInit: { get: () => super.ngOnInit }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            _super.ngOnInit.call(this);
            this.checkReceivedList = Object.keys(this.data.entityList).map(key => this.data.entityList[key]);
            this.action = this.data.action.toUpperCase();
        });
    }
    validateMovement() {
        if (!DateUtil.isValid(this.moveDate)) {
            throw new Error('Campo data devolução foi informado incorretamente');
        }
        if (ObjectUtil.isNewModel(this.reasonRefund)) {
            throw new Error('Campo motivo da devolução é obrigatório e não informado');
        }
    }
    confirm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.validateMovement();
                this.loading = true;
                this.checkReceivedList.forEach(checkReceived => {
                    checkReceived.check.moveDate = this.moveDate;
                    checkReceived.check.reasonRefund = this.reasonRefund;
                    if (checkReceived.check.status === 'DEPOSITED') {
                        checkReceived.check.status = 'REFUND1';
                    }
                    else {
                        checkReceived.check.status = 'REFUND2';
                    }
                });
                yield this._checkReceivedService.moveCheckReceived(this.checkReceivedList, this.action).toPromise().then(() => {
                    this.close('RELOAD_TABLE');
                }, error => {
                    throw new Error(error);
                });
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation) {
        this.dialogRef.close(operation);
    }
}
