import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { OAuthUserPublicService } from '@gipi-financial/user/services/user-public.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, EmailUtil, StringUtil } from '@gipisistemas/ng-core';

@Component({
    templateUrl: './resend-recovery.component.html',
    styleUrls: ['./resend-recovery.component.scss']
})
export class ResendRecoveryComponent extends AbstractComponent implements OnInit {

    username: string;

    constructor(
        protected messageService: CustomMessageService,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        private _userPublicService: OAuthUserPublicService,
    ) {
        super(messageService, router, activatedRoute);
        localStorage.removeItem('token');
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.username = this.activatedRoute.snapshot.params.username;
    }

    async resend(): Promise<void> {
        try {
            this.validate();
            this.loading = true;
            await this._userPublicService.recovery(this.username).toPromise().then(() => {
                this.addSuccessMessage('Operação realizada com sucesso');
                const currentUrl = 'oauth/resend-recovery';
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([currentUrl, this.username]));
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    validate(): void {
        if (StringUtil.isEmpty(this.username) || !EmailUtil.isValid(this.username.toLowerCase())) {
            throw new Error('E-mail inválido');
        }
    }

    returnToLogin(): void {
        this.router.navigate(['/oauth/login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
    }

}
