import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { RegistrationCountry } from '../../country/models/country.model';

export class RegistrationState extends GIPIBaseAbstractModel {

    ibgeCode: number;

    name: string;

    acronym: string;

    country: RegistrationCountry;

}
