import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialBank } from '@gipi-financial/bank/models/bank.model';
import { FinancialCheckReceived } from '@gipi-financial/check-received/models/check-received.model';
import { FinancialClientSelectDTO } from '@gipi-financial/client/models/dto/client-select.dto';
import { FinancialPostingCategory } from '@gipi-financial/posting-category/models/posting-category.model';
import { GIPIAbstractDTO, GIPIUuid, ObjectUtil } from '@gipisistemas/ng-core';

export class FinancialCheckReceivedConciliationDTO extends GIPIAbstractDTO {

    /** Conta */
    account: string;

    /** Dígito da conta */
    accountDigit: string;

    /** Agência */
    agency: string;

    /** Dígito da agência */
    agencyDigit: string;

    /** Valor */
    amount: number;

    /** @Transient Conta bancária */
    bankAccount: FinancialBankAccount;
    bankAccountId: GIPIUuid;

    /** @Transient Banco */
    bank: FinancialBank;
    bankId: GIPIUuid;

    /** Número do cheque */
    checkNumber: number;

    /** @Transient Cliente */
    client: FinancialClientSelectDTO;
    clientId: GIPIUuid;

    /** Observação */
    comments: string;

    /** CPF ou CNPJ do emitente */
    cpfOrCnpjIssuer: string;

    /** Data do cheque */
    date: Date;

    /** Data de vencimento */
    dueDate: Date;

    /** Data de emissão */
    issuanceDate: Date;

    /** Emitente */
    issuer: string;

    /** Telefone */
    phone: string;

    /** @Transient Categoria */
    postingCategory: FinancialPostingCategory;
    postingCategoryId: GIPIUuid;

    /** @Transient */
    orderId: number;

    public static transformToDTO(checkReceived: FinancialCheckReceived, orderId: number = 0): FinancialCheckReceivedConciliationDTO {
        const checkReceivedConciliationDTO: FinancialCheckReceivedConciliationDTO = new FinancialCheckReceivedConciliationDTO();
        checkReceivedConciliationDTO.id = checkReceived.id;
        checkReceivedConciliationDTO.account = checkReceived.account;
        checkReceivedConciliationDTO.accountDigit = checkReceived.accountDigit;
        checkReceivedConciliationDTO.agency = checkReceived.agency;
        checkReceivedConciliationDTO.agencyDigit = checkReceived.agencyDigit;

        checkReceivedConciliationDTO.amount = !ObjectUtil.isNull(checkReceived.check) ? checkReceived.check.amount : 0;
        checkReceivedConciliationDTO.checkNumber = !ObjectUtil.isNull(checkReceived.check) ? checkReceived.check.checkNumber : null;

        checkReceivedConciliationDTO.bankAccount = null;
        checkReceivedConciliationDTO.bankAccountId = null;
        if (!ObjectUtil.isNull(checkReceived.bankAccount)) {
            checkReceivedConciliationDTO.bankAccount = ObjectUtil.clone(checkReceived.bankAccount);
            checkReceivedConciliationDTO.bankAccountId = checkReceived.bankAccount.id;
        }

        checkReceivedConciliationDTO.bank = null;
        checkReceivedConciliationDTO.bankId = null;
        if (!ObjectUtil.isNull(checkReceived.bank)) {
            checkReceivedConciliationDTO.bank = ObjectUtil.clone(checkReceived.bank);
            checkReceivedConciliationDTO.bankId = checkReceived.bank.id;
        }

        checkReceivedConciliationDTO.client = null;
        checkReceivedConciliationDTO.clientId = null;
        if (!ObjectUtil.isNull(checkReceived.client)) {
            checkReceivedConciliationDTO.client = FinancialClientSelectDTO.transformToDTO(checkReceived.client);
            checkReceivedConciliationDTO.clientId = checkReceived.client.id;
        }

        checkReceivedConciliationDTO.comments = checkReceived.comments;
        checkReceivedConciliationDTO.cpfOrCnpjIssuer = !ObjectUtil.isNull(checkReceived.check) ? checkReceived.check.cpfOrCnpjIssuer : checkReceived.cpfOrCnpjIssuer;
        checkReceivedConciliationDTO.date = !ObjectUtil.isNull(checkReceived.check) ? checkReceived.check.createdDate : checkReceived.createdDate;
        checkReceivedConciliationDTO.dueDate = !ObjectUtil.isNull(checkReceived.check) ? checkReceived.check.dueDate : checkReceived.createdDate;
        checkReceivedConciliationDTO.issuanceDate = !ObjectUtil.isNull(checkReceived.check) ? checkReceived.check.issuanceDate : checkReceived.createdDate;
        checkReceivedConciliationDTO.issuer = !ObjectUtil.isNull(checkReceived.check) ? checkReceived.check.issuerName : checkReceived.issuer;
        checkReceivedConciliationDTO.phone = checkReceived.phone;

        checkReceivedConciliationDTO.postingCategory = null;
        checkReceivedConciliationDTO.postingCategoryId = null;
        if (!ObjectUtil.isNull(checkReceived.check) && !ObjectUtil.isNull(checkReceived.check.category)) {
            checkReceivedConciliationDTO.postingCategory = ObjectUtil.clone(checkReceived.check.category);
            checkReceivedConciliationDTO.postingCategoryId = checkReceived.check.category.id;
        }

        checkReceivedConciliationDTO.orderId = orderId;
        return checkReceivedConciliationDTO;
    }

}
