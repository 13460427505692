import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialOfxFileDTO } from '@gipi-financial/bank-account/models/dto/ofx-file.dto';
import { FinancialBankAccountService } from '@gipi-financial/bank-account/services/bank-account.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, APP_MESSAGES, Archive, INJECTOR, InputFileComponent, ObjectUtil, PageDTO } from '@gipisistemas/ng-core';

@Component({
    templateUrl: './import-extract-dialog.component.html',
})
export class ImportExtractDialogComponent extends AbstractComponent implements OnInit {

    @ViewChild('inputFile', { static: false }) inputFile: TemplateRef<InputFileComponent>;

    public bankAccount: FinancialBankAccount = new FinancialBankAccount();

    public archive: Archive;

    ofxFile: FinancialOfxFileDTO;

    file: File;

    isBillConciliation: boolean = false;

    isLoad: boolean = false;

    bankAccountFindByTypeFn = async (value: string, page: number) => {
        if (this.isBillConciliation) {
            const result: PageDTO<FinancialBankAccount> = await this._bankAccountService.findByValue(value, page, 10, { property: 'description', direction: 'asc' }, 'CHECKING_ACCOUNT').toPromise();
            return result;
        } else {
            return this.data;
        }
    };

    constructor(
        public dialogRef: MatDialogRef<ImportExtractDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FinancialBankAccount = null,
        private _bankAccountService: FinancialBankAccountService,
        messageService: CustomMessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();

        // Se this.data está null é porque veio da billConciliation
        this.isBillConciliation = ObjectUtil.isNull(this.data);
    }

    public get disableBtnConfirmation(): boolean {
        return ObjectUtil.isNull(this.bankAccount) || ObjectUtil.isNewModel(this.bankAccount) || ObjectUtil.isNull(this.ofxFile);
    }

    async selectionChangeArchive(file: File): Promise<void> {
        try {
            this.isLoad = true;
            this.file = file;
            this._bankAccountService.readOfxFile(file).toPromise().then(ofxFile => {
                this.ofxFile = ofxFile;
                this.isLoad = false;
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.isLoad = false;
            this.handleError(e);
        }
    }

    async confirm(): Promise<void> {
        try {
            if (ObjectUtil.isNull(this.bankAccount) || ObjectUtil.isNewModel(this.bankAccount)) {
                throw new Error('Selecione uma conta bancária');
            }
            if (ObjectUtil.isNull(this.ofxFile)) {
                throw new Error('Selecione o arquivo OFX / Money 2000');
            }

            this.isLoad = true;
            this.loading = true;

            await this._bankAccountService.importTransactions(this.bankAccount.id, this.file).toPromise().then(() => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.isBillConciliation ? this.close('RELOAD_TABLE') : this.close('NONE');
            }, error => {
                throw new Error(error);
            });

        } catch (e) {
            this.loading = false;
            this.isLoad = false;
            this.handleError(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE'): void {
        this.dialogRef.close(operation);
    }

}
