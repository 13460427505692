import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialChargeType } from '@gipi-financial/charge-type/models/charge-type.model';
import { FinancialFlagCard } from '@gipi-financial/flag-card/models/flag-card.model';
import { FinancialPostingCategory } from '@gipi-financial/posting-category/models/posting-category.model';
import { BaseModel } from '@gipi-shared/models/base.model';
import { GIPIUuid } from '@gipisistemas/ng-core';
import { FinancialReceivementCardOriginEnum } from '../enums/receivement-card-origin.enum';
import { FinancialReceivementCardTypeEnum } from '../enums/receivement-card-type.enum';
import { FinancialCardAdministrator } from './card-administrador.model';

export class FinancialReceivementCard extends BaseModel {

    chargeType: FinancialChargeType;

    cardAdministrator: FinancialCardAdministrator;

    flagCard: FinancialFlagCard;

    numberInstallment: number;

    amountCard: number;

    amountNet: number;

    issuanceDate: Date;

    dueDate: Date;

    status: FinancialReceivementCardTypeEnum | string;

    bankAccount: FinancialBankAccount;

    documentOrigin: string;

    origin: FinancialReceivementCardOriginEnum | string;

    postingCategory: FinancialPostingCategory;

    saleReceivementCardId: GIPIUuid;

    /** @Transient */
    selected?: boolean;

    /** @Transient */
    idLauchReference: number;

}
