import { BaseModel } from '@gipi-shared/models/base.model';
import { GIPIUuid } from '@gipisistemas/ng-core';

export class FinancialCardAdministrator extends BaseModel {

    description: string;

    cashFee: number;

    dayCashReceipt: number;

    installmentFee: number;

    debitFee: number;

    dayDebitReceipt: number;

    creditFee: number;

    dayCreditReceipt: number;

    cnpj: string;

    creditAnticipationFee: number;

    debitAnticipationFee: number;

    registrationCardAdministratorId: GIPIUuid;

}
