import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { FinancialBillStatusEnum } from '@gipi-financial/bill/enums/bill-status.enum';
import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialBillInstallmentService } from '@gipi-financial/bill/services/bill-installment.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, APP_MESSAGES, ArrayUtil, ConfirmationService, CurrencyUtil, DateUtil, INJECTOR, TableColumnBuilder } from '@gipisistemas/ng-core';
export class DeleteInstallmentDialogComponent extends AbstractComponent {
    constructor(data, dialogRef, _billIntallmentService, _confirmationService, messageService, router, activatedRoute) {
        super(messageService, router, activatedRoute);
        this.data = data;
        this.dialogRef = dialogRef;
        this._billIntallmentService = _billIntallmentService;
        this._confirmationService = _confirmationService;
        this.selected = false;
        this.indeterminate = false;
        this.billInstallmentData = new FinancialBillInstallment();
        this.billInstallmentList = [];
        this.billInstallmentSelectList = [];
        this.deleteIntallmentSelected = '0';
        this.date = new Date();
        this.compareDate = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 0, 0, 0);
        this.options = false;
        this.isLoad = false;
    }
    ngOnInit() {
        super.ngOnInit();
        this.isLoad = true;
        this.billInstallmentData = this.data.billInstallment;
        this.createTableColumnsBillInstallments();
        setTimeout(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const lBillInstallmentList = yield this._billIntallmentService.findByBillId(this.billInstallmentData.bill.id).toPromise();
            if (!ArrayUtil.isEmpty(lBillInstallmentList)) {
                this.billInstallmentList = lBillInstallmentList.filter(i => i.status === 'RECEIVABLE' || i.status === 'RECEIVABLE_OVERDUE' || i.status === 'PAYABLE' || i.status === 'PAYABLE_OVERDUE');
                this.billInstallmentList.forEach(installment => {
                    const duoDate = new Date(moment(installment.dueDate).format('yyyy/MM/DD'));
                    if (DateUtil.isLessThan(duoDate, this.compareDate) && installment.status === 'RECEIVABLE') {
                        installment.status = 'RECEIVABLE_OVERDUE';
                    }
                    else if (DateUtil.isLessThan(duoDate, this.compareDate) && installment.status === 'PAYABLE') {
                        installment.status = 'PAYABLE_OVERDUE';
                    }
                });
                this.sortBillInstallment();
                this.validateOptionRadioButton();
            }
        }));
        this.isLoad = false;
    }
    sortBillInstallment() {
        this.billInstallmentList.sort((a, b) => {
            const lFieldA = a.documentNumber;
            const lFieldB = b.documentNumber;
            return this.compareSort(lFieldA, lFieldB, true);
        });
    }
    compareSort(a, b, isAsk) {
        return (a < b ? -1 : 1) * (isAsk ? 1 : -1);
    }
    getNamePerson() {
        let objectPerson;
        if (this.billInstallmentData.bill.type === 'RECEIVABLE') {
            objectPerson = this.billInstallmentData.bill.client.person;
        }
        else {
            objectPerson = this.billInstallmentData.bill.provider.person;
        }
        return (objectPerson.name.length > 50) ? objectPerson.name.slice(0, 50) + '...' : objectPerson.name;
    }
    createTableColumnsBillInstallments() {
        return [
            TableColumnBuilder.instance()
                .property('checkAll')
                .templateHeader(this.checkAllTemplate)
                .template(this.checkboxTemplate)
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('dueDate')
                .name('Vencimento')
                .value((obj) => DateUtil.format(obj.dueDate, DateUtil.DATE_FORMAT))
                .width(30)
                .align('center center')
                .sortable(true)
                .build(),
            TableColumnBuilder.instance()
                .property('documentNumber')
                .name('Nº Documento')
                .value((obj) => obj.documentNumber)
                .width(30)
                .align('center center')
                .sortable(true)
                .build(),
            TableColumnBuilder.instance()
                .property('amount')
                .name('Valor')
                .value((obj) => CurrencyUtil.transform(obj.amount, '1.2-2'))
                .width(30)
                .align('center center')
                .sortable(true)
                .build(),
            TableColumnBuilder.instance()
                .property('status')
                .name('Status')
                .align('center center')
                .width(20)
                .template(this.statusTemplate)
                .build(),
        ];
    }
    checkAll() {
        this.billInstallmentSelectList = [];
        this.billInstallmentList.forEach((i) => {
            i.selected = this.selected;
            this.select(i, false, true, false);
        });
        const installment = this.billInstallmentSelectList.filter(i => i.id === this.billInstallmentData.id);
        if (installment.length <= 0) {
            setTimeout(() => {
                this.billInstallmentList.filter(i => i.id === this.billInstallmentData.id).map(i => {
                    i.selected = true;
                    this.select(i, true, false, false);
                });
            });
            this.handleError('A parcela principal não pode ser desmarcada');
        }
        this.validateAllSelected();
    }
    select(billInstallment, validateAllSelected, validateRadioButton, validateInstallmentData) {
        if (billInstallment.selected) {
            this.billInstallmentSelectList.push(billInstallment);
        }
        else {
            this.billInstallmentSelectList.splice(this.billInstallmentSelectList.findIndex((i) => i.id === billInstallment.id), 1);
        }
        if (validateAllSelected) {
            this.validateAllSelected();
        }
        if (validateRadioButton && (billInstallment.id !== this.billInstallmentData.id)) {
            this.deleteIntallmentSelected = null;
        }
        if (validateInstallmentData && (billInstallment.id === this.billInstallmentData.id) && !billInstallment.selected) {
            setTimeout(() => {
                billInstallment.selected = true;
                this.select(billInstallment, validateAllSelected, validateRadioButton, validateInstallmentData);
            });
            this.handleError('A parcela principal não pode ser desmarcada');
        }
    }
    validateAllSelected() {
        const list = this.billInstallmentList.filter(i => i.selected);
        this.selected = list.length > 0;
        this.indeterminate = list.length > 0 && list.length !== this.billInstallmentList.length;
    }
    setColorStatus(entity) {
        switch (String(entity.status)) {
            case 'RECEIVABLE':
                return '#b4b4b4';
            case 'RECEIVED':
                return '#56b910';
            case 'RECEIVED_PARTIAL':
                return '#62757f';
            case 'GROUPED':
                return '#8256D0';
            case 'RENEGOTIATED':
                return '#2bd7d9';
            case 'RECEIVABLE_OVERDUE':
                return '#f5db00';
            case 'PAYABLE_OVERDUE':
                return '#f5db00';
            case 'PAYABLE':
                return '#b4b4b4';
            case 'PAID':
                return '#56b910';
            default:
                return '#b4b4b4';
        }
    }
    setHintStatus(entity) {
        switch (String(entity.status)) {
            case 'RECEIVABLE':
                return 'A receber';
            case 'RECEIVED':
                return 'Recebida';
            case 'RECEIVED_PARTIAL':
                return 'Recebida parcialmente';
            case 'GROUPED':
                return 'Agrupada';
            case 'RENEGOTIATED':
                return 'Renegociada';
            case 'RECEIVABLE_OVERDUE':
                return 'A receber atrasada';
            case 'PAYABLE_OVERDUE':
                return 'A pagar atrasada';
            case 'PAYABLE':
                return 'A pagar';
            case 'PAID':
                return 'Paga';
            default:
                return 'Não identificado';
        }
    }
    validateOptionRadioButton() {
        this.billInstallmentList.forEach(i => {
            i.selected = false;
            this.select(i, true, false, false);
        });
        switch (this.deleteIntallmentSelected) {
            case '0': { // Somente esta
                this.billInstallmentList.filter(i => i.id === this.billInstallmentData.id).map(i => {
                    i.selected = true;
                    this.select(i, true, false, false);
                });
                break;
            }
            case '1': { // Esta e as anteriores
                const listBillInstallments = this.billInstallmentList.filter(i => {
                    const dateInitial = new Date(moment(i.dueDate).format('yyyy/MM/DD'));
                    const dateFinal = new Date(moment(this.billInstallmentData.dueDate).format('yyyy/MM/DD'));
                    return DateUtil.isLessThanOrEqual(dateInitial, dateFinal);
                });
                listBillInstallments.forEach(i => {
                    i.selected = true;
                    this.select(i, true, false, false);
                });
                break;
            }
            case '2': { // Esta e as futuras
                const listBillInstallments = this.billInstallmentList.filter(i => {
                    const dateInitial = new Date(moment(i.dueDate).format('yyyy/MM/DD'));
                    const dateFinal = new Date(moment(this.billInstallmentData.dueDate).format('yyyy/MM/DD'));
                    return DateUtil.isGreaterThanOrEqual(dateInitial, dateFinal);
                });
                listBillInstallments.forEach(i => {
                    i.selected = true;
                    this.select(i, true, false, false);
                });
                break;
            }
            case '3': { // Todas as contas não pagas
                this.billInstallmentList.forEach(i => {
                    i.selected = true;
                    this.select(i, true, false, false);
                });
                break;
            }
        }
    }
    validateDeletion() {
        if (this.billInstallmentSelectList.length <= 0) {
            throw new Error('Deve ter ao menos uma parcela selecionada');
        }
    }
    confirm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.validateDeletion();
                if (this.billInstallmentData.billRenegotiated) {
                    this.deleteBillRenegotiated();
                }
                else if (this.billInstallmentData.billGrouped) {
                    this.deleteBillGrouped();
                }
                else {
                    this.deleteInstallment();
                }
            }
            catch (e) {
                this.isLoad = false;
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    deleteInstallment() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const descriptionList = this.billInstallmentSelectList.map(i => i.documentNumber);
            const textMessage = (this.billInstallmentSelectList.length === 1)
                ? `Confirma a exclusão da parcela: ${descriptionList}?`
                : `Confirma a exclusão das parcelas: ${descriptionList.join(', ')}?`;
            this._confirmationService.confirm({
                title: 'Confirmação',
                message: textMessage,
                accept: () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    this.isLoad = true;
                    this.loading = true;
                    yield this._billIntallmentService.deleteAll(this.billInstallmentSelectList.map(i => i.id)).toPromise().then(() => {
                        this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                        this.close('RELOAD_TABLE');
                    }, error => {
                        this.isLoad = false;
                        this.loading = false;
                        this.handleError(error);
                    });
                }),
                cancel: () => {
                    this.isLoad = false;
                    this.loading = false;
                }
            });
        });
    }
    deleteBillRenegotiated() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this._billIntallmentService.findByBillId(this.billInstallmentData.bill.id).toPromise().then((installmentList) => {
                    const listMessages = [];
                    const list = installmentList.filter(b => b.status === 'RENEGOTIATED' || b.status === 'RECEIVED' || b.status === 'RECEIVED_PARTIAL' || b.status === 'GROUPED');
                    if (list.length > 0) {
                        listMessages.push(...list.map(r => `Conta a receber n.° ${r.documentNumber} do cliente ${r.bill.client.person.name} com status ${FinancialBillStatusEnum[r.status]}.`));
                        this._confirmationService.confirm({
                            title: 'Aviso',
                            message: 'Não é possível realizar a exclusão. Para prosseguir cancele a movimentação dos seguintes documentos:',
                            width: '30%',
                            isWarningScreen: true,
                            listMessage: listMessages,
                            accept: () => { }
                        });
                        this.loading = false;
                        return;
                    }
                    listMessages.push(...installmentList.map(r => `Conta a receber n.° ${r.documentNumber} do cliente ${r.bill.client.person.name}`));
                    const textMessage = (installmentList.length > 1
                        ? `A parcela foi gerada através de uma renegociação. Portanto, sua exclusão irá excluir todas as parcelas. Documentos que serão excluídos:`
                        : `A parcela foi gerada através de uma renegociação, deseja continuar?`);
                    this._confirmationService.confirm({
                        title: 'Confirmação',
                        width: '30%',
                        message: textMessage,
                        listMessage: ((installmentList.length > 1) ? listMessages : []),
                        accept: () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            yield this._billIntallmentService.delete(this.billInstallmentData.id).toPromise().then(() => {
                                this.loading = true;
                                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                                this.close('RELOAD_TABLE');
                            }, error => {
                                throw new Error(error);
                            });
                        }),
                        cancel: () => { this.loading = false; }
                    });
                });
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    deleteBillGrouped() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const oldInstallmetsList = yield this._billIntallmentService.getBillInstallmentGroupedList(this.billInstallmentData.id).toPromise().catch(error => { throw new Error(error); });
                const listMessages = [];
                listMessages.push(...oldInstallmetsList.map(r => `Conta a receber nº ${r.documentNumber} do cliente ${r.bill.client.person.name}`));
                this._confirmationService.confirm({
                    title: 'Confirmação',
                    width: '30%',
                    message: 'A parcela foi gerada através de um agrupamento. Portanto, sua exclusão irá estornar os seguintes documentos:',
                    listMessage: listMessages,
                    accept: () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        this.loading = true;
                        oldInstallmetsList.forEach((bi) => {
                            bi.status = 'RECEIVABLE';
                            bi.groupedBillInstallmentId = null;
                        });
                        yield this._billIntallmentService.delete(this.billInstallmentData.id).toPromise().then(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            yield this._billIntallmentService.updateBillInstallmentList(oldInstallmetsList).toPromise().then(() => {
                                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                                this.close('RELOAD_TABLE');
                            }, error => {
                                throw new Error(error);
                            });
                        }), error => {
                            throw new Error(error);
                        });
                    }),
                    cancel: () => { this.loading = false; }
                });
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation) {
        this.dialogRef.close(operation);
    }
}
