import { Injectable } from '@angular/core';

import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import { RegistrationTransporterFilterDTO } from '../models/dto/transporter-filter.dto';
import { RegistrationTransporter } from '../models/transporter.model';

@Injectable({ providedIn: 'root' })
export class RegistrationTransporterService extends GIPIAbstractCrudService<RegistrationTransporter, RegistrationTransporterFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(RegistrationPaths.transporter, 'v1', baseService);
    }

}
