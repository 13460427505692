import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { NotificationPaths } from '@gipi-paths/notification.paths';
import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';
import { GIPIAbstractCrudService, GIPIBaseService, StringUtil } from '@gipisistemas/ng-core';
import { NotificationCustomMessagingSubType } from '../enums/custom-messaging-subtype.enum';
import { NotificationCustomMessagingType } from '../enums/custom-messaging-type.enum';
import { NotificationCustomMessagingConfiguration } from '../models/custom-messaging-configuration.model';

@Injectable({ providedIn: 'root' })
export class NotificationCustomMessagingConfigurationService extends GIPIAbstractCrudService<NotificationCustomMessagingConfiguration, GIPIBaseAbstractFilterModel> {

    constructor(protected baseService: GIPIBaseService) {
        super(NotificationPaths.customMessagingConfiguration, 'v1', baseService);
    }

    findByNotificationType(notificationType: NotificationCustomMessagingType, notificationSubType?: NotificationCustomMessagingSubType): Observable<NotificationCustomMessagingConfiguration> {
        const params: HttpParams = !StringUtil.isEmpty(notificationSubType)
            ? new HttpParams().set('notificationSubType', notificationSubType)
            : new HttpParams();

        return this.baseService.httpClient.get(this.url(`find-by-notification-type/${notificationType}`), this.options(params)).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        )
    }

}
