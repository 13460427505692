import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { FinancialPaths } from '../../../paths/financial.paths';
import { FinancialBank } from '../models/bank.model';
import { FinancialBankFilterDTO } from '../models/dto/bank-filter.dto';

@Injectable({ providedIn: 'root' })
export class FinancialBankService extends BaseCrudService<FinancialBank, FinancialBankFilterDTO> {

    constructor(
        http: HttpClient,
        authenticationService: CustomAuthenticationService
    ) {
        super(FinancialPaths.bank, http, authenticationService);
    }

}
