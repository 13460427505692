import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { PersonAssociationData, PersonAssociationDialogComponent, PersonAssociationReturn } from '@gipi-registration/person/components/person-association-dialog/person-association-dialog.component';
import { SaleCity } from '@gipi-sale/city/models/city.model';
import { SaleCityService } from '@gipi-sale/city/services/city.service';
import { SaleClient } from '@gipi-sale/client/models/client.model';
import { SaleClientService } from '@gipi-sale/client/services/client.service';
import { SaleCountry } from '@gipi-sale/country/models/country.model';
import { SaleCountryService } from '@gipi-sale/country/services/country.service';
import { SaleAddress } from '@gipi-sale/person/models/address.model';
import { SaleEmail } from '@gipi-sale/person/models/email.model';
import { SaleLegalPerson } from '@gipi-sale/person/models/legal-person.model';
import { SaleNaturalPerson } from '@gipi-sale/person/models/natural-person.model';
import { SalePerson } from '@gipi-sale/person/models/person.model';
import { SalePhone } from '@gipi-sale/person/models/phone.model';
import { SaleAddressService } from '@gipi-sale/person/services/address.service';
import { SalePhoneType } from '@gipi-sale/phone-type/models/phone-type.model';
import { SalePhoneTypeService } from '@gipi-sale/phone-type/services/phone-type.service';
import { SaleState } from '@gipi-sale/state/models/state.model';
import { SaleStateService } from '@gipi-sale/state/services/state.service';
import { TypePerson, TypePersonEnum } from '@gipi-shared/enums/type-person.enum';
import { APP_MESSAGES, ArrayUtil, DocumentUtil, EmailUtil, GIPIAbstractComponent, GIPIBaseService, GIPIPageModel, GIPISortModel, INJECTOR, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';

export interface ClientFormData {
    typeOperation: 'NEW' | 'EDIT' | 'VIEW';
    client: SaleClient;
}

@Component({
    templateUrl: './client-form-dialog.component.html',
    styleUrls: ['./client-form-dialog.component.scss']
})
export class ClientFormDialogComponent extends GIPIAbstractComponent implements OnInit, OnDestroy {

    private _phoneTypeList: SalePhoneType[] = [];

    public typeOperation: 'NEW' | 'EDIT' | 'VIEW' = 'NEW';

    public client: SaleClient = this._newSaleClient();

    public typePersonEnum: typeof TypePersonEnum = TypePersonEnum;

    public phoneOne: string = '';
    public phoneTwo: string = '';
    public email: string = '';

    countryFindByValueFn = async (value: string, page: number) => {
        const result: GIPIPageModel<SaleCountry> = await this._countryService.findByValue(value, page, 50, new GIPISortModel('name', 'ASC')).toPromise();
        return result;
    };

    stateFindByValueFn = async (value: string, page: number) => {
        const result: GIPIPageModel<SaleState> = await this._stateService.findByValue(value, page, 50, new GIPISortModel('acronym', 'ASC')).toPromise();
        return result;
    };

    cityFindByValueFn = async (value: string, page: number) => {
        if (
            ObjectUtil.isNull(this.client.person) ||
            ArrayUtil.isEmpty(this.client.person.addressList) ||
            ObjectUtil.isNull(this.client.person.addressList[0].state)
        ) {
            return [];
        }
        const ibgeCode: number = this.client.person.addressList[0].state.ibgeCode;
        const result: GIPIPageModel<SaleCity> = await this._cityService.findByValue(value, page, 10, new GIPISortModel('name', 'ASC'), 'v1', ibgeCode).toPromise();
        return result;
    };

    constructor(
        protected service: SaleClientService,
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        public dialogRef: MatDialogRef<ClientFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ClientFormData = { typeOperation: 'NEW', client: null },
        private _countryService: SaleCountryService,
        private _stateService: SaleStateService,
        private _cityService: SaleCityService,
        private _addressService: SaleAddressService,
        private _phoneTypeService: SalePhoneTypeService,
    ) {
        super(baseService, activatedRoute);
        this.dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this._findPhoneType();

        this.typeOperation = this.data.typeOperation;
        if (this.typeOperation !== 'NEW') {
            this.client = this.data.client;

            if (!ObjectUtil.isNull(this.data.client.person.legalPerson)) {
                this.client.person.typePerson = 'LEGAL_PERSON';
            } else {
                this.client.person.typePerson = 'NATURAL_PERSON';
            }

            if (!ArrayUtil.isEmpty(this.client.person.emailList)) {
                if (this.client.person.emailList.length >= 1) {
                    this.email = this.client.person.emailList[0].email;
                }
            }

            if (!ArrayUtil.isEmpty(this.client.person.phoneList)) {
                if (this.client.person.phoneList.length >= 1) {
                    this.phoneOne = this.client.person.phoneList[0].number;
                }
                if (this.client.person.phoneList.length >= 2) {
                    this.phoneTwo = this.client.person.phoneList[1].number;
                }
            }
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    private _newSaleClient(): SaleClient {
        const saleClient: SaleClient = new SaleClient();
        saleClient.enabled = true;
        saleClient.blocked = false;
        saleClient.controlClientLimit = false;
        saleClient.creditTypeList = [];
        saleClient.bankSlipLimit = 0;
        saleClient.checkLimit = 0;
        saleClient.storeCreditLimit = 0;
        saleClient.maximumDelay = 0;
        saleClient.ruralProducer = false;
        saleClient.person = new SalePerson();
        saleClient.person.naturalPerson = new SaleNaturalPerson();
        saleClient.person.legalPerson = new SaleLegalPerson();
        saleClient.person.typePerson = 'NATURAL_PERSON';

        saleClient.person.addressList = [new SaleAddress()];
        saleClient.person.addressList[0].type = 'RESIDENTIAL';
        saleClient.person.addressList[0].useInInvoice = true;

        // Cell
        // saleClient.person.phoneList.push(new SalePhone());
        // saleClient.person.phoneList[0].type = this._phoneTypeList[0];

        // Phone
        // saleClient.person.phoneList.push(new SalePhone());
        // saleClient.person.phoneList[0].type = this._phoneTypeList[1];

        // saleClient.person.emailList = [new SaleEmail()];
        return saleClient;
    }

    public confirm(): void {
        try {
            if (!this._isValid()) {
                return;
            }

            const clientAux: SaleClient = ObjectUtil.clone(this.client);

            if (clientAux.person.typePerson === 'LEGAL_PERSON') {
                clientAux.person.naturalPerson = null;
            } else {
                clientAux.person.legalPerson = null;
            }

            clientAux.person.emailList = [];
            if (!StringUtil.isEmpty(this.email)) {
                const emailAux: SaleEmail = new SaleEmail();
                emailAux.email = this.email;
                clientAux.person.emailList.push(emailAux);
            }

            clientAux.person.phoneList = [];
            if (!StringUtil.isEmpty(this.phoneOne)) {
                const cell: SalePhone = new SalePhone();
                cell.number = this.phoneOne;
                cell.type = this._phoneTypeList[0];
                clientAux.person.phoneList.push(cell);
            }
            if (!StringUtil.isEmpty(this.phoneTwo)) {
                const phone: SalePhone = new SalePhone();
                phone.number = this.phoneTwo;
                phone.type = this._phoneTypeList[1];
                clientAux.person.phoneList.push(phone);
            }

            if (!ArrayUtil.isEmpty(clientAux.person.phoneList)) {
                clientAux.person.phoneList[0].useInInvoice = true;
            }

            this.service.save(clientAux).toPromise().then(entity => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.close(entity);
            }, error => {
                throw new Error(error);
            });

        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public close(client?: SaleClient): void {
        this.dialogRef.close(client);
    }

    private _isValid(): boolean {
        if (StringUtil.isEmpty(this.client.person.name)) {
            this.addWarningMessage('Campo nome é obrigatório e não foi informado');
            return false;
        }
        if (StringUtil.isEmpty(this.client.person.addressList[0].zipCode)) {
            this.addWarningMessage('Campo CEP é obrigatório e não foi informado');
            return false;
        }
        if (StringUtil.isEmpty(this.client.person.addressList[0].street)) {
            this.addWarningMessage('Campo endereço é obrigatório e não foi informado');
            return false;
        }

        if (this.client.person.typePerson !== 'FOREIGN_PERSON') {
            if (StringUtil.isEmpty(this.client.person.addressList[0].streetNumber)) {
                this.addWarningMessage('Número do endereço é obrigatório e não foi informado');
                return false;
            }
            if (ObjectUtil.isNull(this.client.person.addressList[0].state)) {
                this.addWarningMessage('Estado é obrigatório e não foi informado');
                return false;
            }
            if (ObjectUtil.isNull(this.client.person.addressList[0].city)) {
                this.addWarningMessage('Cidade é obrigatório e não foi informado');
                return false;
            }
            if (ObjectUtil.isNull(this.client.person.addressList[0].neighborhood)) {
                this.addWarningMessage('Bairro é obrigatório e não foi informado');
                return false;
            }
        }

        if (this.client.person.typePerson === 'LEGAL_PERSON') {
            if (StringUtil.isEmpty(this.client.person.legalPerson.cnpj)) {
                this.addWarningMessage('Campo CNPJ é obrigatório e não foi informado');
                return false;
            }
            if (!DocumentUtil.isValidCnpj(this.client.person.legalPerson.cnpj)) {
                this.addWarningMessage('CNPJ informado é inválido');
                return false;
            }
        } else if (this.client.person.typePerson === 'NATURAL_PERSON') {
            if (StringUtil.isEmpty(this.client.person.naturalPerson.cpf)) {
                this.addWarningMessage('Campo CPF é obrigatório e não foi informado');
                return false;
            }
            if (!DocumentUtil.isValidCpf(this.client.person.naturalPerson.cpf)) {
                this.addWarningMessage('CPF informado é inválido');
                return false;
            }
        } else if (this.client.person.typePerson === 'FOREIGN_PERSON') {
            if (StringUtil.isEmpty(this.client.person.naturalPerson.documentNumber)) {
                this.addWarningMessage('Campo documento é obrigatório e não foi informado');
                return false;
            }
            if (ObjectUtil.isNull(this.client.person.addressList[0].country)) {
                this.addWarningMessage('País é obrigatório e não foi informado');
                return false;
            }
        }

        return true;
    }

    private _findPhoneType(): void {
        try {
            this._phoneTypeService.findAllEnabled(0, 50).toPromise().then(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    this._phoneTypeList = page.content;
                }
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.handleError(e);
        }
    }

    public validateTypePerson(typePerson: TypePersonEnum | TypePerson): void {
        if (StringUtil.isEmpty(typePerson)) {
            return;
        }

        if (typePerson === 'FOREIGN_PERSON') {
            this.client.person.legalPerson = new SaleLegalPerson();
            this.client.person.naturalPerson = new SaleNaturalPerson();

            for (let i = 0; i < this.client.person.addressList.length; i++) {
                this.client.person.addressList[i].country = null;
                this.client.person.addressList[i].city = null;
                this.client.person.addressList[i].state = null;
            }
        }
    }

    public removeSpecialCharactersStateRegistration(): void {
        if ((this.client.person.typePerson === 'LEGAL_PERSON') && !StringUtil.isEmpty(this.client.person.legalPerson.stateRegistration)) {
            this.client.person.legalPerson.stateRegistration = StringUtil.removeAccents(this.client.person.legalPerson.stateRegistration);
        } else if ((this.client.person.typePerson === 'NATURAL_PERSON') && !StringUtil.isEmpty(this.client.person.naturalPerson.documentNumber)) {
            this.client.person.naturalPerson.documentNumber = StringUtil.removeAccents(this.client.person.naturalPerson.documentNumber);
        }
    }

    public setNameInFantasyName(): void {
        if (!StringUtil.isEmpty(this.client.person.name) && StringUtil.isEmpty(this.client.person.fantasyName)) {
            this.client.person.fantasyName = this.client.person.name;
        }
    }

    public hasPermissionForOperation(): boolean {
        return this.hasPermission('CLIENT_MAKE') && this.hasPermission('CLIENT_UPDATE');
    }

    public async consultCpfOrCnpj(isBlur: boolean = false): Promise<void> {
        try {
            if (this.client.person.typePerson === 'FOREIGN_PERSON') {
                return;
            }

            const isNaturalPerson: boolean = (this.client.person.typePerson === 'NATURAL_PERSON');
            const cpfOrCnpj: string = isNaturalPerson ? this.client.person.naturalPerson.cpf : this.client.person.legalPerson.cnpj;

            if (isBlur && StringUtil.isEmpty(cpfOrCnpj)) {
                return;
            }

            if (
                this.loading ||
                this.typeOperation !== 'NEW' ||
                StringUtil.isEmpty(cpfOrCnpj) ||
                !this.hasPermissionForOperation()
            ) {
                this.addErrorMessage(`Ocorreu um erro ao consulta o ${isNaturalPerson ? 'CPF' : 'CNPJ'}`);
                return;
            }
            const isValidDocument: boolean = isNaturalPerson
                ? DocumentUtil.isValidCpf(this.client.person.naturalPerson.cpf)
                : DocumentUtil.isValidCnpj(this.client.person.legalPerson.cnpj);

            if (!isValidDocument) {
                this.addWarningMessage(`${isNaturalPerson ? 'CPF' : 'CNPJ'} informado é inválido`);
                return;
            }

            this.loading = true;

            const entityList: SaleClient[] = await this.service.findByCpfOrCnpj(cpfOrCnpj).toPromise();
            if (!ArrayUtil.isEmpty(entityList)) {
                const typeOperation: PersonAssociationReturn = await this._showDialogPersonAssociation();
                if (typeOperation !== 'NEW_RECORD') {
                    this.close(null);
                }
            }

            this.loading = false;
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    private async _showDialogPersonAssociation(): Promise<PersonAssociationReturn> {
        let typeDocument: string = 'documento';
        if (!ObjectUtil.isNull(this.client.person)) {
            if (this.client.person.typePerson === 'NATURAL_PERSON') {
                typeDocument = 'CPF';
            } else if (this.client.person.typePerson === 'LEGAL_PERSON') {
                typeDocument = 'CNPJ';
            }
        }

        const personAssociationData: PersonAssociationData = {
            title: 'Clientes associados',
            message: `Identificamos que existem registros associados a este ${typeDocument}. Deseja criar um novo?`,
        };

        const typeOperation: PersonAssociationReturn = await this.baseService.dialogService.open({
            componentOrTemplateRef: PersonAssociationDialogComponent,
            data: personAssociationData,
            width: '20%',
        }).afterClosed().toPromise();

        return Promise.resolve(typeOperation);
    }

    public async findZipCode(address: SaleAddress): Promise<void> {
        try {
            if (this.client.person.typePerson === 'FOREIGN_PERSON') {
                return;
            }
            if (StringUtil.isEmpty(address.zipCode)) {
                this.addWarningMessage('Preencha o campo para realizar a consulta');
                return;
            }
            if (address.zipCode.length !== 8) {
                this.addWarningMessage('CEP inválido');
                return;
            }

            const ibgeCode: string = await this._addressService.getAddressByCep(address.zipCode);
            if (StringUtil.isEmpty(ibgeCode)) {
                this.addWarningMessage('Endereço não encontrado para o CEP informado');
                return;
            }

            this._cityService.findByIbgeCode(ibgeCode).toPromise().then(city => {
                if (!ObjectUtil.isNull(city) && !ObjectUtil.isNull(city.state)) {
                    address.city = city;
                    address.state = city.state;
                }
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.handleError(e);
        }
    }

    public validateEmail(email: string): void {
        if (!StringUtil.isEmpty(email) && !EmailUtil.isValid(email)) {
            this.addWarningMessage('O e-mail informado é inválido');
        }
    }

    public setCountryCode(address: SaleAddress): void {
        if (
            (this.client.person.typePerson === 'FOREIGN_PERSON') &&
            !ObjectUtil.isNull(address) &&
            !ObjectUtil.isNull(address.country)
        ) {
            this.client.person.addressList[0].countryCode = address.country.code;
        }
    }

    public validateStreetNumber(streetNumber: string, address: SaleAddress): void {
        setTimeout(() => {
            address.withoutStreetNumber = false;
            if (!StringUtil.isEmpty(streetNumber) && (streetNumber.toUpperCase() === 'SN')) {
                address.withoutStreetNumber = true;
            }
        }, 1);
    }

    public onChangeStreetNumber(checkBox: MatCheckboxChange, address: SaleAddress): void {
        if (
            ObjectUtil.isNull(checkBox) ||
            ObjectUtil.isNull(address.withoutStreetNumber)
        ) {
            return;
        }

        if (checkBox.checked || address.withoutStreetNumber) {
            address.streetNumber = 'SN';
        } else {
            address.streetNumber = '';
        }
    }

}
