import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { RegistrationLegalPerson } from '@gipi-registration/person/models/legal-person.model';
import { RegistrationNaturalPerson } from '@gipi-registration/person/models/natural-person.model';
import { RegistrationPersonService } from '@gipi-registration/person/services/person.service';
import { RegistrationProviderRepresentative } from '@gipi-registration/provider/models/provider-representative.model';
import { RegistrationProvider } from '@gipi-registration/provider/models/provider.model';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ObjectUtil } from '@gipisistemas/ng-core';

export interface RepresentativeProviderData {
    typeOperation: 'NEW' | 'EDIT' | 'VIEW';
    provider: RegistrationProvider;
    representative: RegistrationProviderRepresentative;
}

@Component({
    templateUrl: './representative-provider-dialog.component.html',
    styleUrls: ['./representative-provider-dialog.component.scss']
})
export class RepresentativeProviderDialogComponent extends AbstractComponent implements OnInit {

    representative: RegistrationProviderRepresentative;

    isLoad: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: RepresentativeProviderData = { typeOperation: 'NEW', provider: null, representative: null },
        public dialogRef: MatDialogRef<RepresentativeProviderDialogComponent>,
        private _personService: RegistrationPersonService,
        messageService: CustomMessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
        this.dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.isLoad = true;

        if ((this.data.typeOperation === 'EDIT') || (this.data.typeOperation === 'VIEW')) {
            this.representative = this.data.representative;

            if (!ObjectUtil.isNull(this.representative.person.legalPerson)) {
                this.representative.person.typePerson = 'LEGAL_PERSON';
            } else {
                this.representative.person.typePerson = 'NATURAL_PERSON';
            }
        } else if (this.data.typeOperation === 'NEW') {
            this.representative = this.newEntity();
        }

        this.isLoad = false;
    }

    newEntity(): RegistrationProviderRepresentative {
        const representative: RegistrationProviderRepresentative = new RegistrationProviderRepresentative();
        representative.person.typePerson = 'LEGAL_PERSON';
        representative.person.legalPerson = new RegistrationLegalPerson();
        representative.person.naturalPerson = new RegistrationNaturalPerson();
        representative.person.personStatus = 'ENABLED';

        return representative;
    }

    isViewing(): boolean {
        return this.data.typeOperation === 'VIEW';
    }

    isCreating(): boolean {
        return this.data.typeOperation === 'NEW';
    }

    isEditing(): boolean {
        return this.data.typeOperation === 'EDIT';
    }

    validate(): void {
        this._personService.validatePerson(this.representative.person, true);
        this._personService.validateAddress(this.representative.person, true);
        this._personService.validatePhone(this.representative.person);
        this._personService.validateEmail(this.representative.person);
        this._personService.validateSocialNetwork(this.representative.person);

        if ((this.representative.person.typePerson === 'LEGAL_PERSON') && (this.representative.person.legalPerson.stateRegistration)) {
            this._personService.validateStateRegistration(this.representative.person.addressList, this.representative.person.legalPerson.stateRegistration);
        }
    }

    confirm(): void {
        try {
            this.isLoad = true;
            this.loading = true;

            this.validate();

            if (this.representative.person.typePerson === 'LEGAL_PERSON') {
                this.representative.person.naturalPerson = null;
            } else {
                this.representative.person.legalPerson = null;
            }

            if (this.data.typeOperation === 'NEW') {
                this.representative.key = this.data.provider.representativeList.length;
            }

            const index: number = this.data.provider.representativeList.findIndex(r => this.representative.id ? (r.id === this.representative.id) : (r.key === this.representative.key));
            if (index >= 0) {
                this.data.provider.representativeList.splice(index, 1);
            }

            this.data.provider.representativeList.push(this.representative);

            this.close();
        } catch (e) {
            this.loading = false;
            this.isLoad = false;
            this.handleError(e);
        }
    }

    close(): void {
        this.dialogRef.close();
    }

}
