import { AfterViewInit, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { RegistrationCity } from '@gipi-registration/city/models/city.model';
import { RegistrationCityService } from '@gipi-registration/city/services/city.service';
import { RegistrationClientStatusEnum } from '@gipi-registration/client/enums/client-status.enum';
import { RegistrationClient } from '@gipi-registration/client/models/client.model';
import { RegistrationClientConsultDTO } from '@gipi-registration/client/models/dto/client-consult.dto';
import { RegistrationClientFilterDTO } from '@gipi-registration/client/models/dto/client-filter.dto';
import { RegistrationClientService } from '@gipi-registration/client/services/client.service';
import { RegistrationCountry } from '@gipi-registration/country/models/country.model';
import { RegistrationCountryService } from '@gipi-registration/country/services/country.service';
import { RegistrationState } from '@gipi-registration/state/models/state.model';
import { RegistrationStateService } from '@gipi-registration/state/services/state.service';
import { ArrayUtil, GIPIAbstractFindComponent, GIPIAppliedFilter, GIPIBaseService, GIPISortModel, ObjectUtil, PageDTO, PhoneUtil, SortDirectionEnum, SortDTO, StringUtil, TableColumnBuilder, TableColumnDTO } from '@gipisistemas/ng-core';

export type FilterTypes = 'country' | 'state' | 'city';

@Component({
    templateUrl: './find-client-dialog.component.html',
    styleUrls: ['./find-client-dialog.component.scss']
})
export class FindClientDialogComponent extends GIPIAbstractFindComponent<RegistrationClient, RegistrationClientFilterDTO> implements OnInit, AfterViewInit {

    @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;

    clientStatusEnum: typeof RegistrationClientStatusEnum = RegistrationClientStatusEnum;

    enabledAndDisabledStatus: RegistrationClientStatusEnum | string = 'ENABLED';

    blockedAndUnblockedStatus: RegistrationClientStatusEnum | string = 'ALL';

    public _countryList: RegistrationCountry[] = [];

    public _stateList: RegistrationState[] = [];

    cityFindByValueFn = async (value: string, page: number) => {
        if (!ObjectUtil.isNull(this.filter) && !ObjectUtil.isNull(this.filter.state)) {
            const result: PageDTO<RegistrationCity> = await this._cityService.findByValue(value, page, 10, new GIPISortModel('person.name', 'ASC'), 'v1', this.filter.state.ibgeCode).toPromise();
            return result;
        } else {
            return [];
        }
    };

    constructor(
        public dialogRef: MatDialogRef<FindClientDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { byConfiguration: boolean } = { byConfiguration: false },
        protected service: RegistrationClientService,
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _countryService: RegistrationCountryService,
        private _stateService: RegistrationStateService,
        private _cityService: RegistrationCityService,
    ) {
        super(service, baseService, activatedRoute);
        this.basePermissionList = {
            MAKE: 'CLIENT_MAKE',
            UPDATE: 'CLIENT_UPDATE',
            READ: 'CLIENT_READ',
            DELETE: 'CLIENT_DELETE',
            ENABLE_DISABLE: 'CLIENT_ENABLE_DISABLE',
        };

        this._findCountryList();
        this._findStateList();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public createTableSorts(): SortDTO[] {
        return [new SortDTO('createdDate', SortDirectionEnum.DESC)];
    }

    public getPath(): string {
        return '/financial/registers/clients/clients';
    }

    protected setFilterSessionStorage(): void { }

    protected handleFilterSessionStorage(): void { }

    removeAppliedFilters(filter: { chip: GIPIAppliedFilter<any>; index: number; }): void {
        switch (filter.chip.key) {
            case 'country': {
                this.filter.country = null;
                this.filter.countryCode = null;
                break;
            }
            case 'state': {
                this.filter.state = null;
                this.filter.stateIbgeCode = null;
                break;
            }
            case 'city': {
                this.filter.city = null;
                this.filter.cityIbgeCode = null;
                break;
            }
        }

        this.find(null);
    }

    protected setAppliedFilters(): void {
        if (!StringUtil.isEmpty(this.filter.countryCode)) {
            this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>('País', 'country'));
        }
        if (this.filter.stateIbgeCode && (this.filter.stateIbgeCode > 0)) {
            this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>('Estado', 'state'));
        }
        if (this.filter.cityIbgeCode && (this.filter.cityIbgeCode > 0)) {
            this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>('Cidade', 'city'));
        }
    }

    protected clearAppliedFilters(): void {
        this.appliedFilters = [];
    }

    protected newFilter(): RegistrationClientFilterDTO {
        const filter: RegistrationClientFilterDTO = new RegistrationClientFilterDTO();
        filter.blocked = false;
        filter.enabled = true;

        filter.city = null;
        filter.cityIbgeCode = null;

        filter.country = null;
        filter.countryCode = null;

        filter.state = null;
        filter.stateIbgeCode = null;

        filter.researchField = '';
        filter.sorts = this.createTableSorts();
        return filter;
    }

    async clear(): Promise<void> {
        this.page = this.newPage();
        this.filter = this.newFilter();
        super.clear();
    }

    protected createTableColumns(): TableColumnDTO[] {
        return [
            TableColumnBuilder.instance()
                .property('person.cpf_cnpj')
                .name('CPF / CNPJ')
                .value((obj: RegistrationClientConsultDTO) =>
                    !StringUtil.isEmpty(obj.cpfOrCnpj)
                        ? ((obj.type === 'LEGAL_PERSON') ? StringUtil.format(obj.cpfOrCnpj, '00.000.000/0000-00') : StringUtil.format(obj.cpfOrCnpj, '000.000.000-00'))
                        : ''
                )
                .action((obj: RegistrationClientConsultDTO) => this.close(obj))
                .width(15)
                .align('center center')
                .marginRight(15)
                .build(),
            TableColumnBuilder.instance()
                .property('person.name')
                .name('Nome')
                .value((obj: RegistrationClientConsultDTO) => !StringUtil.isEmpty(obj.name) ? obj.name : '')
                .action((obj: RegistrationClientConsultDTO) => this.close(obj))
                .sortable(true)
                .marginRight(15)
                .build(),
            TableColumnBuilder.instance()
                .property('person.fantasyName')
                .name('Nome fantasia')
                .value((obj: RegistrationClientConsultDTO) => !StringUtil.isEmpty(obj.fantasyName) ? obj.fantasyName : '')
                .action((obj: RegistrationClientConsultDTO) => this.close(obj))
                .sortable(true)
                .marginRight(15)
                .build(),
            TableColumnBuilder.instance()
                .property('person.city')
                .name('Cidade')
                .value((obj: RegistrationClientConsultDTO) => !StringUtil.isEmpty(obj.city) ? obj.city : '')
                .action((obj: RegistrationClientConsultDTO) => this.close(obj))
                .sortable(true)
                .marginLeft(15)
                .build(),
            TableColumnBuilder.instance()
                .property('person.phone')
                .name('Telefone')
                .value((obj: RegistrationClientConsultDTO) => !StringUtil.isEmpty(obj.phoneNumber) ? PhoneUtil.format(obj.phoneNumber) : '')
                .action((obj: RegistrationClientConsultDTO) => this.close(obj))
                .sortable(true)
                .width(10)
                .align('center center')
                .marginLeft(15)
                .build(),
            TableColumnBuilder.instance()
                .property('enabled')
                .name('Ativo')
                .value((obj: RegistrationClientConsultDTO) => obj.enabled ? 'Sim' : 'Não')
                .action((obj: RegistrationClientConsultDTO) => this.close(obj))
                .width(5)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('blocked')
                .name('Status')
                .template(this.statusTemplate)
                .action((obj: RegistrationClientConsultDTO) => this.close(obj))
                .width(7)
                .align('center center')
                .build(),
        ];
    }

    async find(pageEvent?: any): Promise<void> {
        try {
            this.loading = true;
            this.page.content = [];

            if (ObjectUtil.isNull(this.page)) {
                this.page = this.newPage();
            }

            if (ObjectUtil.isNull(this.filter)) {
                this.filter = this.newFilter();
            }

            if (pageEvent) {
                this.tablePageEventDTO = pageEvent;

                if (pageEvent.sort) {
                    this.filter.sorts = [
                        new SortDTO(
                            pageEvent.sort.active,
                            StringUtil.isEmpty(pageEvent.sort.direction) ? SortDirectionEnum.DESC : pageEvent.sort.direction.toUpperCase()
                        ),
                    ];
                }

                this.filter.pageNumber = pageEvent.pageIndex;
                this.filter.pageSize = 5;
                this.filter.offset = pageEvent.pageIndex * 5;
            } else {
                this.filter.pageNumber = 0;
                this.filter.offset = 0;
                this.filter.pageSize = 5;
            }

            if (this.enabledAndDisabledStatus === 'ALL') {
                this.filter.enabled = null;
            } else if (this.enabledAndDisabledStatus === 'ENABLED') {
                this.filter.enabled = true;
            } else if (this.enabledAndDisabledStatus === 'DISABLED') {
                this.filter.enabled = false;
            } else {
                this.filter.enabled = null;
            }

            if (this.blockedAndUnblockedStatus === 'ALL') {
                this.filter.blocked = null;
            } else if (this.blockedAndUnblockedStatus === 'BLOCKED') {
                this.filter.blocked = true;
            } else if (this.blockedAndUnblockedStatus === 'UNBLOCKED') {
                this.filter.blocked = false;
            } else {
                this.filter.blocked = null;
            }

            if (!ObjectUtil.isNull(this.filter.country)) {
                this.filter.countryCode = this.filter.country.code;
            } else {
                this.filter.countryCode = null;
            }

            if (!ObjectUtil.isNull(this.filter.state)) {
                this.filter.stateIbgeCode = this.filter.state.ibgeCode;
            } else {
                this.filter.stateIbgeCode = null;
            }

            if (!ObjectUtil.isNull(this.filter.city)) {
                this.filter.cityIbgeCode = this.filter.city.ibgeCode;
            } else {
                this.filter.cityIbgeCode = null;
            }

            await this.service.findAll(this.filter).toPromise().then((page) => {
                this.page = page;
                this.setAppliedFilters();
                this.loading = false;
            }, (error) => this.handleError(error));
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    private _findCountryList(): void {
        try {
            this._countryService.findAllEnabled(0, 300).toPromise().then(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    this._countryList = page.content;
                }
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    private _findStateList(): void {
        try {
            this._stateService.findAllEnabled(0, 50).toPromise().then(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    this._stateList = page.content;
                }
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    close(client?: RegistrationClientConsultDTO): void {
        if (!ObjectUtil.isNull(this.data) && this.data.byConfiguration) {
            this.dialogRef.close(!ObjectUtil.isNull(client) ? client : null);
        } else {
            this.dialogRef.close(!ObjectUtil.isNull(client) ? client.id : null);
        }
    }

}
