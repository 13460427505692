import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationCity } from '@gipi-registration/city/models/city.model';
import { RegistrationCityService } from '@gipi-registration/city/services/city.service';
import { RegistrationCountry } from '@gipi-registration/country/models/country.model';
import { RegistrationCountryService } from '@gipi-registration/country/services/country.service';
import { RegistrationAddressTypeEnum } from '@gipi-registration/person/enums/address-type.enum';
import { RegistrationAddress } from '@gipi-registration/person/models/address.model';
import { RegistrationPerson } from '@gipi-registration/person/models/person.model';
import { RegistrationAddressService } from '@gipi-registration/person/services/address.service';
import { RegistrationState } from '@gipi-registration/state/models/state.model';
import { RegistrationStateService } from '@gipi-registration/state/services/state.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ArrayUtil, GIPISortModel, ObjectUtil, StringUtil, UUIDUtil } from '@gipisistemas/ng-core';
export class AddressFormComponent extends AbstractComponent {
    constructor(_addressService, _countryService, _stateService, _cityService, _changeDetectorRef, messageService, router, activatedRoute) {
        super(messageService, router, activatedRoute);
        this._addressService = _addressService;
        this._countryService = _countryService;
        this._stateService = _stateService;
        this._cityService = _cityService;
        this._changeDetectorRef = _changeDetectorRef;
        this.typeAddressEnum = RegistrationAddressTypeEnum;
        this.countryList = [];
        this.stateList = [];
        this.loadingFindCep = false;
        this.streetNumberNotIdentifiedAddress = false;
        this.addressListValue = [];
        this.addressListChange = new EventEmitter();
        this.person = new RegistrationPerson();
        this.isCreating = false;
        this.isViewing = false;
        this.disabledTypeAddress = false;
        this.isRepresentativeProvider = false;
        this.cityFindByValueFn = (value, page, address) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!ObjectUtil.isNull(address.state)) {
                const result = yield this._cityService.findByValue(value, page, 10, new GIPISortModel('name', 'ASC'), 'v1', address.state.ibgeCode).toPromise();
                address.cityList = result.content;
                return result;
            }
            else {
                return [];
            }
        });
    }
    get addressList() {
        return this.addressListValue;
    }
    set addressList(addressList) {
        if ((this.person.typePerson !== 'FOREIGN_PERSON') && !ArrayUtil.isEmpty(addressList)) {
            for (let i = 0; i < addressList.length; i++) {
                const address = addressList[i];
                if (!ObjectUtil.isNewModel(address.city) && !ObjectUtil.isNull(address.city)) {
                    if (!ObjectUtil.isNewModel(address.state) && !ObjectUtil.isNull(address.state)) {
                        address.city.state = address.state;
                        if (!ObjectUtil.isNewModel(address.country) && !ObjectUtil.isNull(address.country)) {
                            address.city.state.country = address.state.country;
                        }
                    }
                }
            }
        }
        this.addressListValue = addressList;
        this.addressListChange.emit(this.addressListValue);
    }
    ngOnInit() {
        const _super = Object.create(null, {
            ngOnInit: { get: () => super.ngOnInit }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            _super.ngOnInit.call(this);
            this.loading = true;
            yield this.getCountry();
            yield this.getState();
            setTimeout(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (!this.isCreating && (this.person.typePerson !== 'FOREIGN_PERSON')) {
                    if (!ArrayUtil.isEmpty(this.addressList)) {
                        for (let i = 0; i < this.addressList.length; i++) {
                            if (!ObjectUtil.isNull(this.addressList[i].city)) {
                                const address = this.addressList[i];
                                address.state = address.city.state;
                                address.country = address.city.state.country;
                                this.getCity(address);
                            }
                        }
                    }
                }
                if (ArrayUtil.isEmpty(this.addressList)) {
                    this.addressList = [];
                    if (!this.isRepresentativeProvider) {
                        this.addNewAddress();
                    }
                    if (this.person.typePerson !== 'FOREIGN_PERSON') {
                        yield this.setCountryDefault();
                    }
                }
                this.loading = false;
            }));
        });
    }
    getCountry() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this._countryService.findAllEnabled(0, 300).toPromise().then(page => {
                    if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                        this.countryList = page.content;
                    }
                });
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    setCountryDefault() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!ArrayUtil.isEmpty(this.countryList)) {
                const countryDefault = this.countryList.find(country => country.code.trim() === '1058');
                if (!ObjectUtil.isNull(countryDefault)) {
                    for (let i = 0; i < this.addressList.length; i++) {
                        const address = this.addressList[i];
                        address.country = countryDefault;
                    }
                }
            }
            return Promise.resolve();
        });
    }
    getState() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this._stateService.findAllEnabled(0, 50).toPromise().then(page => {
                    if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                        this.stateList = page.content;
                    }
                }, error => {
                    throw new Error(error);
                });
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    getCity(address) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (ObjectUtil.isNewModel(address.state) || ObjectUtil.isNull(address.state)) {
                    address.cityList = [];
                    address.state = new RegistrationState();
                    return;
                }
                // await this.cityService.findByState(address.state).toPromise().then(cityList => {
                //     address.cityList = cityList;
                // }, error => {
                //     throw new Error(error);
                // });
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    findCep(address) {
        try {
            if (this.person.typePerson === 'FOREIGN_PERSON') {
                return;
            }
            if (StringUtil.isEmpty(address.zipCode)) {
                this.addWarningMessage('Preencha o campo para realizar a consulta');
                return;
            }
            if (address.zipCode.length !== 8) {
                this.addWarningMessage('CEP inválido');
                return;
            }
            this.loadingFindCep = true;
            this._addressService.getAddressByCep(address.zipCode).then(resp => {
                this._cityService.findByIbgeCode(resp.ibge).toPromise().then(city => {
                    if (!ObjectUtil.isNull(city)) {
                        address.cityList = [city];
                        address.country = city.state.country;
                        address.state = city.state;
                        address.city = city;
                        this.getCity(address);
                    }
                    this.loadingFindCep = false;
                }, error => {
                    throw new Error(error);
                });
            }).catch(error => {
                throw new Error(error);
            });
        }
        catch (e) {
            this.loadingFindCep = false;
            this.handleError(e);
        }
    }
    deleteAddress(address) {
        if (UUIDUtil.isValid(address.id)) {
            this.addressList.splice(this.addressList.findIndex(a => a.id === address.id), 1);
        }
        else {
            this.addressList.splice(this.addressList.findIndex(a => a.key === address.key), 1);
        }
        if ((address.useInInvoice) && (!ArrayUtil.isEmpty(this.addressList))) {
            this.addressList[0].useInInvoice = true;
        }
    }
    addNewAddress() {
        const address = new RegistrationAddress();
        if (this.person.typePerson !== 'FOREIGN_PERSON') {
            address.city = new RegistrationCity();
            address.state = new RegistrationState();
        }
        address.country = new RegistrationCountry();
        address.type = 'RESIDENTIAL';
        address.key = this.addressList.length;
        address.withoutStreetNumber = false;
        if ((ArrayUtil.isEmpty(this.addressList) || this.isCreating) && address.key === 0) {
            address.useInInvoice = true;
        }
        this.addressList.push(address);
        if (this.person.typePerson !== 'FOREIGN_PERSON') {
            this.setCountryDefault();
        }
    }
    validateUseInInvoice(address) {
        this.addressList.map(a => a.useInInvoice = false);
        setTimeout(() => {
            this.addressList.filter(a => a === address).map(a => a.useInInvoice = true);
        }, 10);
    }
    setCountryCode(address) {
        if ((this.person.typePerson === 'FOREIGN_PERSON') &&
            !ObjectUtil.isNull(address) &&
            !ObjectUtil.isNull(address.country)) {
            address.countryCode = address.country.code;
        }
    }
    validateStreetNumber(streetNumber, address) {
        setTimeout(() => {
            address.withoutStreetNumber = false;
            if (!StringUtil.isEmpty(streetNumber) && (streetNumber.toUpperCase() === 'SN')) {
                address.withoutStreetNumber = true;
            }
        }, 1);
    }
    onChangeStreetNumber(checkBox, address) {
        if (ObjectUtil.isNull(checkBox) ||
            ObjectUtil.isNull(address.withoutStreetNumber)) {
            return;
        }
        if (checkBox.checked || address.withoutStreetNumber) {
            address.streetNumber = 'SN';
        }
        else {
            address.streetNumber = '';
        }
    }
}
