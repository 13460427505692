import { RegistrationPhoneType } from '@gipi-registration/phone-type/models/phone-type.model';
import { BaseModel } from '@gipi-shared/models/base.model';

export class RegistrationPhone extends BaseModel {

    type: RegistrationPhoneType;

    number: string;

    comments: string;

    useInInvoice: boolean;

    /** @Transient */
    key: number;

    /** @Transient */
    disabled: boolean;

}
