import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { ArrayUtil, ObjectUtil } from '@gipisistemas/ng-core';
import { FinancialPaths } from '../../../paths/financial.paths';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/services/custom-authentication.service";
export class FinancialPaymentService extends BaseCrudService {
    constructor(http, authenticationService) {
        super(FinancialPaths.payment, http, authenticationService);
    }
    findByBillInstallment(billInstallmentId) {
        return this.http.get(this.url(`find-by-bill-installment/${billInstallmentId}?page=${0}&size=300`), this.options()).pipe(map((json) => {
            const page = this.mapper(json);
            if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                return page.content;
            }
            return [];
        }), catchError(this.handleError));
    }
    pay(paymentDto) {
        return this.http.post(this.url('pay'), paymentDto).pipe(map(this.mapper), catchError(this.handleError));
    }
}
FinancialPaymentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialPaymentService_Factory() { return new FinancialPaymentService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CustomAuthenticationService)); }, token: FinancialPaymentService, providedIn: "root" });
