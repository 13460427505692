import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { GIPIUuid } from '@gipisistemas/ng-core';
import { FinancialPaths } from '../../../paths/financial.paths';
import { FinancialBankSlipConfiguration } from '../models/bank-slip-configuration.model';
import { FinancialBankSlipConfigurationFilterDTO } from '../models/dto/bank-slip-configuation-filter.dto';

@Injectable({ providedIn: 'root' })
export class FinancialBankSlipConfigurationService extends BaseCrudService<FinancialBankSlipConfiguration, FinancialBankSlipConfigurationFilterDTO> {

    constructor(
        http: HttpClient,
        authenticationService: CustomAuthenticationService) {
        super(FinancialPaths.bankSlipConfiguration, http, authenticationService);
    }

    findByBankAccountId(bankAccountId: GIPIUuid): Observable<FinancialBankSlipConfiguration> {
        return this.http.get(this.url(`find-by-bank-account-id/${bankAccountId}`)).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

    getNextShipmentNumber(bankAccountId: GIPIUuid): Observable<FinancialBankSlipConfiguration> {
        return this.http.get(this.url(`next-shipment-number/find-by-bank-account-id/${bankAccountId}`)).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

}
