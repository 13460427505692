import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { OAuthPaths } from '@gipi-paths/oauth.paths';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/services/custom-authentication.service";
export class OAuthUserPublicService extends BaseCrudService {
    constructor(http, authenticationService) {
        super(OAuthPaths.userPublic, http, authenticationService);
    }
    recovery(username) {
        return this.http.get(this.url(`recovery/${username}`), this.options()).pipe(map(() => null), catchError(this.handleError));
    }
    updatePassword(token, password) {
        const httpParams = new HttpParams()
            .append('token', token)
            .append('password', password);
        return this.http.get(this.url('update-password'), this.options(httpParams)).pipe(map(this.mapper), catchError(this.handleError));
    }
    confirmAccess(token, password, name, photo) {
        const httpParams = new HttpParams()
            .append('token', token)
            .append('password', password)
            .append('name', name)
            .append('photo', photo);
        return this.http.get(this.url('confirm-access'), this.options(httpParams)).pipe(map(this.mapper), catchError(this.handleError));
    }
    getByInvitationToken(token) {
        const httpParams = new HttpParams().append('token', token);
        return this.http.get(this.url('get-by-invitation-token'), this.options(httpParams)).pipe(map(this.mapper), catchError(this.handleError));
    }
}
OAuthUserPublicService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OAuthUserPublicService_Factory() { return new OAuthUserPublicService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CustomAuthenticationService)); }, token: OAuthUserPublicService, providedIn: "root" });
