import { NotificationNotification } from '@gipi-notification/invoice-integrations/models/notification.model';
import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';

export class NotificationCustomerNotificationConfig extends GIPIBaseAbstractModel {

    /** @Required Indica se a notificação de conta a pagar está habilitada */
    accountPayableNotificationEnabled: boolean;

    /** @Required Indica se a notificação de conta a receber está habilitada */
    accountReceivableNotificationEnabled: boolean;

    /** @Required Indica se a notificação de aniversário está habilitada */
    birthdayNotificationEnabled: boolean;

    /** @Required Cnpj do cliente */
    cnpj: string;

    /** @Required E-mail do cliente */
    emailCustomer: string;

    /** @Required Indica se a notificação de cliente inativo está habilitada */
    inactiveCustomerNotificationEnabled: boolean;

    /** Lista de notificações enviadas */
    notificationList: NotificationNotification[];

    /** @Required Indica se a notificação da chave de pagamento está ativada */
    paymentKeyNotificationEnabled: boolean;

    /** @Required Indica se a notificação de lembrete de pagamento está habilitada */
    paymentReminderNotificationEnabled: boolean;

    /** Data do fechamento */
    paymentTerm: number;

    /** @Required Número de telefone */
    phoneNumber: string;

    /** @Required Indica se a notificação de promoções e sugestões está habilitada */
    promotionsAndSuggestionsNotificationEnabled: boolean;

}
