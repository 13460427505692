import { RegistrationState } from "@gipi-registration/state/models/state.model";
import { GIPIBaseAbstractModel } from "@gipi-shared/models/base-abstract.model";

export class RegistrationTransporterVehicle extends GIPIBaseAbstractModel {

    /**
     * ANTT - Agência Nacional de Transportes Terrestres
     * @Min 0 characters
     * @Max 20 characters
     */
    antt: string;

    /**
     * Placa do veículo
     * @Min 6 characters
     * @Max 8 characters
     */
    plate: string;

    /** Estado da placa */
    plateState: RegistrationState;

    /** @Transient */
    key: number;

}
