import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { RegistrationUnitMeasurement } from '@gipi-registration/unit-measurement/models/unit-measurement.model';
import { RegistrationUnitMeasurementService } from '@gipi-registration/unit-measurement/services/unit-measurement.service';
import { OptionActiveSituation, OptionActiveSituationEnum } from '@gipi-shared/enums/option-active-situation.enum';
import { APP_MESSAGES, GIPIAbstractComponent, GIPIBaseService, INJECTOR, NumberUtil, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';

export interface UnitMeasurementData {
    typeOperation: 'NEW' | 'EDIT' | 'VIEW';
    unitMeasurement: RegistrationUnitMeasurement;
}

@Component({
    templateUrl: './unit-measurement-form-dialog.component.html',
})
export class UnitMeasurementFormDialogComponent extends GIPIAbstractComponent implements OnInit, OnDestroy {

    public typeOperation: 'NEW' | 'EDIT' | 'VIEW' = 'NEW';

    public unitMeasurement: RegistrationUnitMeasurement = new RegistrationUnitMeasurement();

    public optionActiveSituationEnum: typeof OptionActiveSituationEnum = OptionActiveSituationEnum;
    public optionActiveSituationValue: OptionActiveSituation = 'ENABLED';

    constructor(
        protected service: RegistrationUnitMeasurementService,
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        public dialogRef: MatDialogRef<UnitMeasurementFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: UnitMeasurementData = { typeOperation: 'NEW', unitMeasurement: null },
    ) {
        super(baseService, activatedRoute);
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {
        super.ngOnInit();
        this.typeOperation = this.data.typeOperation;
        if (this.typeOperation !== 'NEW') {
            this.unitMeasurement = this.data.unitMeasurement;

            if (!this.unitMeasurement.enabled) {
                this.optionActiveSituationValue = 'DISABLED';
            }
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public confirm(): void {
        try {
            if (ObjectUtil.isNull(this.unitMeasurement)) {
                return;
            }
            if (StringUtil.isEmpty(this.unitMeasurement.description)) {
                this.addWarningMessage('Campo descrição é obrigatório e não foi informado');
                return;
            }
            if (this.unitMeasurement.description.length < 2) {
                this.addWarningMessage('Campo descrição deve conter no mínimo 3 caracteres');
                return;
            }
            if (!this.unitMeasurement.decimals || ObjectUtil.isNull(this.unitMeasurement.decimals) || NumberUtil.isNegative(this.unitMeasurement.decimals)) {
                this.addWarningMessage('Campo decimais é obrigatório e não foi informado');
                return;
            }
            if (StringUtil.isEmpty(this.unitMeasurement.initials)) {
                this.addWarningMessage('Campo iniciais é obrigatório e não foi informado');
                return;
            }
            this.loading = true;

            this.unitMeasurement.enabled = (this.optionActiveSituationValue === 'ENABLED');

            this.service.save(this.unitMeasurement).toPromise().then(() => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.loading = false;
                this.close('RELOAD_TABLE');
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    public close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE'): void {
        this.dialogRef.close(operation);
    }

}
