<gipi-abstract-form id="dialogRegisterCardAdministrator"
                    name="dialogRegisterCardAdministrator"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogRegisterCardAdministrator">
        Cadastrar administradora de cartão
    </gipi-toolbar>
    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <itss-input label="Descrição"
                        id="cardAdministratorDescription"
                        name="cardAdministratorDescription"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="cardAdministrator.description"
                        [min]="3"
                        [max]="80"
                        [maxlength]="80"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <gipi-input-select-enum label="Situação"
                                    id="cardAdministratorEnabled"
                                    name="cardAdministratorEnabled"
                                    [disabled]="loading || (typeOperation !== 'EDIT')"
                                    [required]="true"
                                    [valuesExclud]="[]"
                                    [enum]="optionActiveSituationEnum"
                                    [(ngModel)]="optionActiveSituationValue"
                                    fxFlex="32"
                                    fxFlex.lt-md="100">
            </gipi-input-select-enum>
        </div>
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <gipi-input-select-paged label="Conta bancária"
                                     id="cardAdministratorBankAccount"
                                     name="cardAdministratorBankAccount"
                                     [required]="true"
                                     [disabled]="loading || (typeOperation === 'VIEW')"
                                     [(model)]="cardAdministrator.bankAccount"
                                     [nextBatchFn]="bankAccountFindByValueFn"
                                     [property]="'description'"
                                     fxFlex
                                     fxFlex.lt-md="100">
            </gipi-input-select-paged>
            <itss-input label="CNPJ"
                        id="cardAdministratorCnpj"
                        name="cardAdministratorCnpj"
                        mask="00.000.000/0000-00"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="cardAdministrator.cnpj"
                        fxFlex="32"
                        fxFlex.lt-md="100">
            </itss-input>
        </div>
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <itss-input-currency label="Taxa à vista (%)"
                                 id="cardAdministratorCashFee"
                                 name="cardAdministratorCashFee"
                                 [required]="true"
                                 [disabled]="loading || (typeOperation === 'VIEW')"
                                 [(ngModel)]="cardAdministrator.cashFee"
                                 [options]="{ min: 0, max: 100, nullable: false, prefix: '', suffix: ' %' }"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-input-currency label="Taxa parcelado (%)"
                                 id="cardAdministratorInstallmentFee"
                                 name="cardAdministratorInstallmentFee"
                                 [required]="true"
                                 [disabled]="loading || (typeOperation === 'VIEW')"
                                 [(ngModel)]="cardAdministrator.installmentFee"
                                 [options]="{ min: 0, max: 100, nullable: false, prefix: '', suffix: ' %' }"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-input-currency label="Taxa do débito (%)"
                                 id="cardAdministratorDebitFee"
                                 name="cardAdministratorDebitFee"
                                 [required]="true"
                                 [disabled]="loading || (typeOperation === 'VIEW')"
                                 [(ngModel)]="cardAdministrator.debitFee"
                                 [options]="{ min: 0, max: 100, nullable: false, prefix: '', suffix: ' %' }"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>
        </div>
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <itss-input-currency label="Taxa do crédito (%)"
                                 id="cardAdministratorCreditFee"
                                 name="cardAdministratorCreditFee"
                                 [required]="true"
                                 [disabled]="loading || (typeOperation === 'VIEW')"
                                 [(ngModel)]="cardAdministrator.creditFee"
                                 [options]="{ min: 0, max: 100, nullable: false, prefix: '', suffix: ' %' }"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-input-currency label="Taxa de antecipação do débito (%)"
                                 id="cardAdministratorDebitAnticipationFee"
                                 name="cardAdministratorDebitAnticipationFee"
                                 [required]="true"
                                 [disabled]="loading || (typeOperation === 'VIEW')"
                                 [(ngModel)]="cardAdministrator.debitAnticipationFee"
                                 [options]="{ min: 0, max: 100, nullable: false, prefix: '', suffix: ' %' }"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-input-currency label="Taxa de antecipação do crédito (%)"
                                 id="cardAdministratorCreditAnticipationFee"
                                 name="cardAdministratorCreditAnticipationFee"
                                 [required]="true"
                                 [disabled]="loading || (typeOperation === 'VIEW')"
                                 [(ngModel)]="cardAdministrator.creditAnticipationFee"
                                 [options]="{ min: 0, max: 100, nullable: false, prefix: '', suffix: ' %' }"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>
        </div>
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <itss-input label="Dias para recebimento à vista"
                        id="cardAdministratorDayCashReceipt"
                        name="cardAdministratorDayCashReceipt"
                        mask="0*"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="cardAdministrator.dayCashReceipt"
                        [maxlength]="3"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Dias para recebimento débito"
                        id="cardAdministratorDayDebitReceipt"
                        name="cardAdministratorDayDebitReceipt"
                        mask="0*"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="cardAdministrator.dayDebitReceipt"
                        [maxlength]="3"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Dias para recebimento crédito"
                        id="cardAdministratorDayCreditReceipt"
                        name="cardAdministratorDayCreditReceipt"
                        mask="0*"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="cardAdministrator.dayCreditReceipt"
                        [maxlength]="3"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
        </div>
    </div>
    <div mat-dialog-actions
         fxLayout="row wrap"
         fxLayout.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     name="btnSave"
                     *ngIf="(typeOperation !== 'VIEW')"
                     (click)="confirm()"
                     [disabled]="loading"
                     [loading]="loading"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     name="btnReturn"
                     (click)="close('NONE')"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>