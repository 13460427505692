import { AfterViewInit, Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '@gipi-environment/environment';
import { AbstractComponent, MessageService, StringUtil } from '@gipisistemas/ng-core';

@Component({
    selector: 'gipi-novelties-dialog',
    templateUrl: './novelties-dialog.component.html',
    styleUrls: ['./novelties-dialog.component.scss']
})
export class GIPINoveltiesDialogComponent extends AbstractComponent implements OnInit, AfterViewInit {

    @ViewChild('iframeRef', { static: false }) iframe: ElementRef<HTMLIFrameElement>;

    constructor(
        public dialogRef: MatDialogRef<GIPINoveltiesDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        messageService: MessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
    }

    ngOnInit(): void {
        this.dialogRef.disableClose = true;
    }

    ngAfterViewInit(): void {
        const noveltiesUrl: string = environment.noveltiesUrl;
        if (!StringUtil.isEmpty(noveltiesUrl)) {
            (this.iframe.nativeElement as HTMLIFrameElement).src = noveltiesUrl;
        }
    }

    async close(): Promise<void> {
        this.dialogRef.close(null);
    }

}
