import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OAuthFileService {

    constructor(private http: HttpClient) { }

    upload(key: string, file: File, cannedAcl: 'Private' | 'PublicRead' = 'Private'): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('key', key);
        formData.append('cannedAcl', cannedAcl);
        return this.http.post(`api/authenticator/file/upload`, formData, { responseType: 'text' });
    }

    download(key: string): Observable<any> {
        const formData = new FormData();
        formData.append('key', key);
        return this.http.post(`api/authenticator/file/download`, formData, {
            observe: 'response',
            responseType: 'blob' as 'json'
        });
    }

    delete(key: string): Observable<any> {
        const formData = new FormData();
        formData.append('key', key);
        return this.http.post(`api/authenticator/file/delete`, formData);
    }

}
