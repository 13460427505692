import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { GIPIUuid } from '@gipisistemas/ng-core';

export class RegistrationUnitMeasurement extends GIPIBaseAbstractModel {

    /** @Required Quantidade de casas decimais */
    decimals: number;

    /**
     * @Required Descrição
     * @Min 2 characters
     * @Max 30 characters
     */
    description: string;

    /**
     * @Required Iniciais
     * @Min 1 characters
     * @Max 6 characters
     */
    initials: string;

    registrationUnitMeasurementId: GIPIUuid;

}
