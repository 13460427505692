import { AbstractModel, GIPIUuid } from '@gipisistemas/ng-core';

export class BaseModel extends AbstractModel {

    companyId: GIPIUuid;

    enabled: boolean;

    createdDate: Date;

    modifiedDate: Date;

    migrated: boolean;

}
