import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FinancialFileService {
    constructor(http) {
        this.http = http;
    }
    upload(key, file, cannedAcl = 'Private') {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('key', key);
        formData.append('cannedAcl', cannedAcl);
        return this.http.post(`api/financial/file/upload`, formData, { responseType: 'text' });
    }
    download(key) {
        const formData = new FormData();
        formData.append('key', key);
        return this.http.post(`api/financial/file/download`, formData, {
            observe: 'response',
            responseType: 'blob'
        });
    }
    delete(key) {
        const formData = new FormData();
        formData.append('key', key);
        return this.http.post(`api/financial/file/delete`, formData);
    }
}
FinancialFileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialFileService_Factory() { return new FinancialFileService(i0.ɵɵinject(i1.HttpClient)); }, token: FinancialFileService, providedIn: "root" });
