import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as moment_ from 'moment';
const moment = moment_;
import { FinancialClientService } from '@gipi-financial/client/services/client.service';
import { FinancialSaleConciliationFilterDTO } from '@gipi-financial/sale/models/dto/sale-conciliation-filter.dto';
import { FinancialSale } from '@gipi-financial/sale/models/sale.model';
import { FinancialSaleService } from '@gipi-financial/sale/services/sale.service';
import { CurrencyUtil, DateUtil, GIPIAbstractComponent, GIPIAppliedFilter, GIPIBaseService, GIPIPageModel, GIPISortModel, ObjectUtil, StringUtil, TableColumnBuilder } from '@gipisistemas/ng-core';
export class FindSaleDialogComponent extends GIPIAbstractComponent {
    constructor(service, baseService, activatedRoute, _changeDetectorRef, _clientService, dialogRef, data) {
        super(baseService, activatedRoute);
        this.service = service;
        this.baseService = baseService;
        this.activatedRoute = activatedRoute;
        this._changeDetectorRef = _changeDetectorRef;
        this._clientService = _clientService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.filter = this._newFilter();
        this.page = new GIPIPageModel();
        this.columns = [];
        this.salePaymentListSelected = [];
        this.appliedFilters = [];
        this.selected = false;
        this.indeterminate = false;
        this.clientFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const configuration = this.baseService.sessionStorageService.get('configuration');
            let propertySort = 'person.name';
            if (!ObjectUtil.isNull(configuration) && configuration.showBusinessNameInReceiptQuery) {
                propertySort = 'person.fantasyName';
            }
            const result = yield this._clientService.findByValue(value, page, 10, new GIPISortModel(propertySort, 'ASC')).toPromise();
            return result;
        });
        this.clientFn = (obj) => this._clientService.getDescription(obj);
    }
    ngOnInit() {
        const _super = Object.create(null, {
            ngOnInit: { get: () => super.ngOnInit }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.columns = this._createTableBillInstallmentColumns();
            _super.ngOnInit.call(this);
        });
    }
    _newFilter() {
        const filter = new FinancialSaleConciliationFilterDTO();
        filter.begin = moment().startOf('month').startOf('day').toDate();
        filter.end = moment().toDate();
        filter.researchField = '';
        filter.document = '';
        filter.clientId = null;
        filter.total = null;
        filter.salePaymentIdsExcluded = [];
        return filter;
    }
    _createTableBillInstallmentColumns() {
        return [
            TableColumnBuilder.instance()
                .property('date')
                .name('Data')
                .sortable(true)
                .value((obj) => DateUtil.format(obj.date, DateUtil.DATE_FORMAT))
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('documentNumber')
                .name('Nº Documento')
                .sortable(true)
                .value((obj) => !StringUtil.isEmpty(obj.documentNumber) ? obj.documentNumber : '')
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('amountNet')
                .name('Valor')
                .sortable(true)
                .value((obj) => CurrencyUtil.transform(obj.amount, '1.2-2'))
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('client.person.name')
                .name('Cliente')
                .sortable(true)
                .value((obj) => !StringUtil.isEmpty(obj.clientName) ? obj.clientName : '')
                .build(),
        ];
    }
    clearAppliedFilters() {
        this.appliedFilters = [];
    }
    setAppliedFilters() {
        if (ObjectUtil.isNull(this.filter)) {
            return;
        }
        this.clearAppliedFilters();
        if (!ObjectUtil.isNull(this.filter.begin) && !ObjectUtil.isNull(this.filter.end)) {
            const description = `${DateUtil.format(this.filter.begin, DateUtil.DATE_FORMAT)} á ${DateUtil.format(this.filter.end, DateUtil.DATE_FORMAT)}`;
            this.appliedFilters.push(new GIPIAppliedFilter(description, 'begin_and_end_date'));
        }
        else {
            if (!ObjectUtil.isNull(this.filter.begin)) {
                const description = `Data inicial: ${DateUtil.format(this.filter.begin, DateUtil.DATE_FORMAT)}`;
                this.appliedFilters.push(new GIPIAppliedFilter(description, 'begin_date'));
            }
            if (!ObjectUtil.isNull(this.filter.end)) {
                const description = `Data final: ${DateUtil.format(this.filter.end, DateUtil.DATE_FORMAT)}`;
                this.appliedFilters.push(new GIPIAppliedFilter(description, 'end_date'));
            }
        }
        if (!ObjectUtil.isNull(this.filter.clientId)) {
            this.appliedFilters.push(new GIPIAppliedFilter('Cliente', 'client'));
        }
        if (!StringUtil.isEmpty(this.filter.document)) {
            this.appliedFilters.push(new GIPIAppliedFilter('N° Documento', 'document'));
        }
        if (this.filter.total) {
            this.appliedFilters.push(new GIPIAppliedFilter('Valor', 'total'));
        }
    }
    removeAppliedFilter(filter) {
        switch (filter.chip.key) {
            case 'begin_and_end_date': {
                this.filter.begin = null;
                this.filter.end = null;
                break;
            }
            case 'begin_date': {
                this.filter.begin = null;
                break;
            }
            case 'end_date': {
                this.filter.end = null;
                break;
            }
            case 'client': {
                this.filter.client = null;
                this.filter.clientId = null;
                break;
            }
            case 'document': {
                this.filter.document = '';
                break;
            }
            case 'total': {
                this.filter.total = null;
                break;
            }
        }
        this.find(null);
    }
    find(pageEvent) {
        try {
            this.loading = true;
            if (ObjectUtil.isNull(this.filter)) {
                this.filter = this._newFilter();
            }
            if (this.filter.begin && !DateUtil.isValid(new Date(this.filter.begin))) {
                this.loading = false;
                this.addWarningMessage('Data inicial não é uma data válida');
                return;
            }
            if (this.filter.end && !DateUtil.isValid(new Date(this.filter.end))) {
                this.loading = false;
                this.addWarningMessage('Data final não é uma data válida');
                return;
            }
            if (this.filter.begin && !this.filter.end) {
                this.loading = false;
                this.addWarningMessage('Data final não informada');
                return;
            }
            if (!this.filter.begin && this.filter.end) {
                this.loading = false;
                this.addWarningMessage('Data inicial não informada');
                return;
            }
            if (DateUtil.isLessThan(new Date(this.filter.end), new Date(this.filter.begin))) {
                this.loading = false;
                this.addWarningMessage('Data final não pode ser menor que data inicial');
                return;
            }
            if (!ObjectUtil.isNull(this.filter.client) && !ObjectUtil.isNewModel(this.filter.client)) {
                this.filter.clientId = Number(this.filter.client.id);
            }
            // this.filter.bankAccountId = this.data.bankAccountId;
            this.filter.salePaymentIdsExcluded = this.data.salePaymentListUsed.map(id => new FinancialSale(id));
            // const existNumber: number = Number(this.filter.researchField ? this.filter.researchField.replace(',', '.') : 0);
            // if (!StringUtil.isEmpty(this.filter.researchField)) {
            //     this.filter.researchField = NumberUtil.isPositive(existNumber) ? null : this.filter.researchField;
            // }
            if (pageEvent) {
                this.filter.pageNumber = pageEvent.pageIndex;
                this.filter.pageSize = 5;
                this.filter.offset = pageEvent.pageIndex * 5;
            }
            else {
                this.filter.pageNumber = 0;
                this.filter.pageSize = 5;
                this.filter.offset = 0;
            }
            this.setAppliedFilters();
            this.service.findAllSaleConciliation(this.filter).toPromise().then(page => {
                this.page = page;
                this.loading = false;
            }, error => {
                this.loading = false;
                throw new Error(error);
            });
        }
        catch (e) {
            this.handleError(e);
        }
    }
    clear() {
        this.filter = this._newFilter();
        this.clearAppliedFilters();
        this.find();
    }
    _paymentHasUsed(salePayment) {
        return this.data.salePaymentListUsed.filter(installmentId => installmentId === salePayment.id).length > 0;
    }
    checkAll(sale) {
        sale.salePaymentList.forEach(salePayment => {
            salePayment.selected = this.selected;
            this.select(sale, salePayment, false);
        });
        this.validateAllSelected(sale);
    }
    select(sale, salePayment, validate) {
        salePayment.selected = !salePayment.selected;
        if (salePayment.selected && !this._paymentHasUsed(salePayment)) {
            const alreadySelected = this.salePaymentListSelected.find(e => e.id === salePayment.id);
            if (ObjectUtil.isNull(alreadySelected)) {
                salePayment.saleAmount = sale.amount;
                salePayment.saleClientName = sale.clientName;
                salePayment.saleDate = sale.date;
                salePayment.saleDocumentNumber = sale.documentNumber;
                this.salePaymentListSelected.push(salePayment);
            }
        }
        else {
            this.salePaymentListSelected.splice(this.salePaymentListSelected.findIndex(e => e.id === salePayment.id), 1);
        }
        if (validate) {
            this.validateAllSelected(sale);
        }
        this._changeDetectorRef.detectChanges();
    }
    validateAllSelected(sale) {
        const list = sale.salePaymentList.filter(billInstallment => billInstallment.selected);
        this.selected = list.length > 0;
        this.indeterminate = (list.length > 0 && list.length !== sale.salePaymentList.length) || (this.salePaymentListSelected.length > 0);
    }
    confirm() {
        this.dialogRef.close(this.salePaymentListSelected);
    }
    close() {
        this.dialogRef.close([]);
    }
}
