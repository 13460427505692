import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AbstractService, AcronymState, ArrayUtil, DateUtil, DocumentUtil, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import { RegistrationAddress } from '../models/address.model';
import { RegistrationEmail } from '../models/email.model';
import { RegistrationPerson } from '../models/person.model';
import { RegistrationPhone } from '../models/phone.model';
import { RegistrationSocialNetwork } from '../models/social-network.model';

@Injectable({ providedIn: 'root' })
export class RegistrationPersonService extends AbstractService {

    constructor(protected http: HttpClient) {
        super();
    }

    validatePerson(person: RegistrationPerson, isRepresentativeProvider?: boolean): string {
        const now: Date = new Date();

        if (person.typePerson === 'LEGAL_PERSON') {
            if ((person.legalPerson.cnpj) && (!StringUtil.isEmpty(person.legalPerson.cnpj))) {
                if (!DocumentUtil.isValidCnpj(person.legalPerson.cnpj)) {
                    return ('CNPJ informado é inválido');
                }
            }

            if (!isRepresentativeProvider) {
                if (StringUtil.isEmpty(person.legalPerson.cnpj)) {
                    return ('Campo CNPJ é obrigatório e não foi informado');
                }
                if (!DateUtil.isValid(person.birthDate)
                    || DateUtil.isGreaterThan(new Date(person.birthDate), now)) {
                    return ('Campo data de abertura é inválida');
                }
            }

            if (StringUtil.isEmpty(person.name)) {
                return ('Campo razão social é obrigatório e não foi informado');
            }
        } else if (person.typePerson === 'NATURAL_PERSON') {
            if ((person.naturalPerson.cpf) && (!StringUtil.isEmpty(person.naturalPerson.cpf))) {
                if (!DocumentUtil.isValidCpf(person.naturalPerson.cpf)) {
                    return ('CPF informado é inválido');
                }
            }

            if (!isRepresentativeProvider) {
                if (StringUtil.isEmpty(person.naturalPerson.cpf)) {
                    return ('Campo CPF é obrigatório e não foi informado');
                }
                if (!DateUtil.isValid(person.birthDate)
                    || DateUtil.isGreaterThan(new Date(person.birthDate), now)) {
                    return ('Campo data de nascimento é inválida');
                }
            }

            if (StringUtil.isEmpty(person.name)) {
                return ('Campo nome é obrigatório e não foi informado');
            }
        } else if (person.typePerson === 'FOREIGN_PERSON') {
            if (StringUtil.isEmpty(person.naturalPerson.documentNumber)) {
                return ('Campo documento é obrigatório e não foi informado');
            }
            if (StringUtil.isEmpty(person.name)) {
                return ('Campo nome é obrigatório e não foi informado');
            }
        }

        return '';
    }

    validateAddress(person: RegistrationPerson, isRepresentativeProvider?: boolean): string {
        if (ArrayUtil.isEmpty(person.addressList) && (!isRepresentativeProvider)) {
            return ('Endereço é obrigatório e não foi informado');
        } else {
            for (let i: number = 0; i < person.addressList.length; i++) {
                const address: RegistrationAddress = person.addressList[i];

                if (person.typePerson === 'FOREIGN_PERSON') {
                    if (ObjectUtil.isNewModel(address.country)
                        || ObjectUtil.isNull(address.country)) {
                        throw new Error('País na aba endereços é obrigatório e não foi informado');
                    }
                    if (StringUtil.isEmpty(address.zipCode)) {
                        throw new Error('CEP na aba endereços é obrigatório e não foi informado');
                    }
                    if (StringUtil.isEmpty(address.street)) {
                        throw new Error('Endereço na aba endereços é obrigatório e não foi informado');
                    }
                    if (StringUtil.isEmpty(address.neighborhood)) {
                        throw new Error('Bairro na aba endereços é obrigatório e não foi informado');
                    }
                } else {
                    if (StringUtil.isEmpty(address.zipCode)) {
                        return ('CEP na aba endereços é obrigatório e não foi informado');
                    }
                    if (StringUtil.isEmpty(address.street)) {
                        return ('Endereço na aba endereços é obrigatório e não foi informado');
                    }
                    if (ObjectUtil.isNewModel(address.state)
                        || ObjectUtil.isNull(address.state)) {
                        return ('Estado na aba endereços é obrigatório e não foi informado');
                    }
                    if (ObjectUtil.isNewModel(address.city)
                        || ObjectUtil.isNull(address.city)) {
                        return ('Cidade na aba endereços é obrigatório e não foi informado');
                    }
                    if (StringUtil.isEmpty(address.neighborhood)) {
                        return ('Bairro na aba endereços é obrigatório e não foi informado');
                    }
                    if (StringUtil.isEmpty(address.streetNumber)) {
                        return ('Número na aba endereços é obrigatório e não foi informado');
                    }
                }
            }
        }

        return '';
    }

    validatePhone(person: RegistrationPerson): string {
        if (!ArrayUtil.isEmpty(person.phoneList)) {
            for (let i: number = 0; i < person.phoneList.length; i++) {
                const phone: RegistrationPhone = person.phoneList[i];
                if (ObjectUtil.isNewModel(phone.type)
                    || ObjectUtil.isNull(phone.type)) {
                    return ('Tipo de telefone na aba telefones é obrigatório e não foi informado');
                }
                if (StringUtil.isEmpty(phone.number)) {
                    return ('Número na aba telefone é obrigatório e não foi informado');
                }
            }
        }

        return '';
    }

    validateEmail(person: RegistrationPerson): string {
        if (!ArrayUtil.isEmpty(person.emailList)) {
            for (let i: number = 0; i < person.emailList.length; i++) {
                const email: RegistrationEmail = person.emailList[i];
                if (StringUtil.isEmpty(email.email)) {
                    return ('E-mail na aba e-mails é obrigatório e não foi informado');
                }
            }
        }

        return '';
    }

    validateSocialNetwork(person: RegistrationPerson): string {
        if (!ArrayUtil.isEmpty(person.socialNetworkList)) {
            for (let i: number = 0; i < person.socialNetworkList.length; i++) {
                const socialNetwork: RegistrationSocialNetwork = person.socialNetworkList[i];
                if (ObjectUtil.isNewModel(socialNetwork.type)
                    || ObjectUtil.isNull(socialNetwork.type)) {
                    return ('Tipo de rede social na aba redes sociais é obrigatório e não foi informado');
                }
                if (StringUtil.isEmpty(socialNetwork.description)) {
                    return ('Descrição na aba redes sociais é obrigatório e não foi informado');
                }
            }
        }

        return '';
    }

    validateStateRegistration(addressList: RegistrationAddress[], stateRegistration: string): string {
        if (stateRegistration === 'ISENTO') {
            return '';
        }
        if (!StringUtil.isEmpty(stateRegistration)) {
            if (ArrayUtil.isEmpty(addressList)) {
                return ('Endereço não informado, portanto não é possível validar a inscrição estadual');
            }

            const addressUseInInvoice: RegistrationAddress = addressList.find(a => a.useInInvoice);
            if (ObjectUtil.isNull(addressUseInInvoice)) {
                return ('Endereço padrão não informado, portanto não é possível validar a inscrição estadual');
            }
            if (StringUtil.isEmpty(addressUseInInvoice.state.acronym)) {
                return ('Estado não informado, portanto não é possível validar a inscrição estadual');
            }
            if (!DocumentUtil.isValidStateRegistration(addressUseInInvoice.state.acronym.toUpperCase() as AcronymState, stateRegistration)) {
                return ('Inscrição estadual informada é inválida');
            }
        }

        return '';
    }

}
