import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { OAuthPaths } from '@gipi-paths/oauth.paths';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { ArrayUtil, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/services/custom-authentication.service";
export class OAuthUserService extends BaseCrudService {
    constructor(http, authenticationService) {
        super(OAuthPaths.user, http, authenticationService);
    }
    validate(entity) {
        super.validate(entity);
        if (StringUtil.isEmpty(entity.username)
            || ArrayUtil.isEmpty(entity.accessControlList)
            || ObjectUtil.isNull(entity.enabled)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
    }
    authorize(username, password) {
        this.validateAuthorize(username, password);
        const params = new HttpParams()
            .append('username', username)
            .append('password', password);
        return this.http.get(this.url(`authorize`), this.options(params)).pipe(map(this.mapper), catchError(this.handleError));
    }
    validateAuthorize(username, password) {
        if (StringUtil.isEmpty(username) || StringUtil.isEmpty(password)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
    }
    // recovery(username: string): Observable<OAuthUser> {
    //     return this.http.get(`api/authenticator/public/user/recovery/${username}`, this.options()).pipe(
    //         map(() => null),
    //         catchError(this.handleError)
    //     );
    // }
    // updatePassword(token: string, password: string): Observable<OAuthUser> {
    //     const httpParams = new HttpParams()
    //         .append('token', token)
    //         .append('password', password);
    //     return this.http.get('api/authenticator/public/user/update-password', this.options(httpParams)).pipe(
    //         map(this.mapper),
    //         catchError(this.handleError)
    //     );
    // }
    confirmPassword(id, password) {
        return this.http.post(this.url(`confirm-password/${id}`), password, this.options()).pipe(map(this.mapper), catchError(this.handleError));
    }
    generateAccessControl() {
        return this.http.get(this.url('generate-access-control'), this.options()).pipe(map(this.mapper), catchError(this.handleError));
    }
    getByUserName(username) {
        return this.http.post(this.url(`find-by-username/${username}`), null, this.options()).pipe(map(this.mapper), catchError(this.handleError));
    }
    sendInvitation(user) {
        return this.http.post(this.url('send-invitation'), user, this.options()).pipe(map(this.mapper), catchError(this.handleError));
    }
    // confirmAccess(token: string, password: string, name: string, photo?: string): Observable<OAuthUser> {
    //     const httpParams = new HttpParams()
    //         .append('token', token)
    //         .append('password', password)
    //         .append('name', name)
    //         .append('photo', photo);
    //     return this.http.get('api/authenticator/public/user/confirm-access', this.options(httpParams)).pipe(
    //         map(this.mapper),
    //         catchError(this.handleError)
    //     );
    // }
    // getByInvitationToken(token: string): Observable<OAuthUser> {
    //     const httpParams = new HttpParams().append('token', token);
    //     return this.http.get('api/authenticator/public/user/get-by-invitation-token', this.options(httpParams)).pipe(
    //         map(this.mapper),
    //         catchError(this.handleError)
    //     );
    // }
    deleteUserAccessControl(userAccessControl) {
        const httpParams = new HttpParams()
            .append('id', String(userAccessControl));
        return this.http.delete(this.url('delete-user-access-constrol'), this.options(httpParams)).pipe(map(this.mapper), catchError(this.handleError));
    }
    deleteAllEntity(idList) {
        return this.http.post(this.url('delete-all-user-access-constrol'), idList, this.options()).pipe(map(this.mapper), catchError(this.handleError));
    }
    updatedAllEntity(idList) {
        return this.http.post(this.url('update-all-user-access-constrol'), idList, this.options()).pipe(map(this.mapper), catchError(this.handleError));
    }
    updateInformationOfUser(userInformationDto) {
        return this.http.post(this.url('update-information-user'), userInformationDto, this.options()).pipe(map(this.mapper), catchError(this.handleError));
    }
}
OAuthUserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OAuthUserService_Factory() { return new OAuthUserService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CustomAuthenticationService)); }, token: OAuthUserService, providedIn: "root" });
