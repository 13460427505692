import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialCashierShift } from '@gipi-financial/cashier-shift/models/cashier-shift.model';
import { FinancialChargeType } from '@gipi-financial/charge-type/models/charge-type.model';
import { GIPIAbstractDTO } from '@gipisistemas/ng-core';

export class FinancialPaymentDTO extends GIPIAbstractDTO {

    /** @Required Valor */
    amount: number;

    /** @Required Valor pago */
    amountPaid: number;

    /** @Required Conta bancária */
    bankAccount: FinancialBankAccount;

    /** Lista de contas */
    billInstallmentList: FinancialBillInstallment[] = [];

    /** Tipo de cobrança */
    chargeType: FinancialChargeType;

    /** @Required Desconto */
    discount: number;

    /** @Required Multa */
    fine: number;

    /** @Required Juros */
    interest: number;

    /** @Required Data do pagamento */
    paymentDate: Date;

    /** Turno do caixa */
    shift: FinancialCashierShift;

}
