import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { RegistrationProductGrouping } from '@gipi-registration/product-grouping/models/product-grouping.model';
import { RegistrationProductGroupingService } from '@gipi-registration/product-grouping/services/product-groupings.service';
import { OptionActiveSituation, OptionActiveSituationEnum } from '@gipi-shared/enums/option-active-situation.enum';
import { APP_MESSAGES, ArrayUtil, GIPIAbstractComponent, GIPIBaseService, INJECTOR, ObjectUtil, StringUtil, TableColumnBuilder, TableColumnDTO } from '@gipisistemas/ng-core';

export interface ProductGroupingsData {
    typeOperation: 'NEW' | 'EDIT' | 'VIEW';
    productGrouping: RegistrationProductGrouping;
}

@Component({
    templateUrl: './product-groupings-form-dialog.component.html',
    styleUrls: ['./product-groupings-form-dialog.component.scss']
})
export class ProductGroupingsFormDialogComponent extends GIPIAbstractComponent implements OnInit, OnDestroy {

    @ViewChild('statusGroupTemplate', { static: true }) statusGroupTemplate: TemplateRef<any>;
    @ViewChild('actionsGroupTemplate', { static: true }) actionsGroupTemplate: TemplateRef<any>;

    @ViewChild('statusSubgroupTemplate', { static: true }) statusSubgroupTemplate: TemplateRef<any>;
    @ViewChild('actionsSubgroupTemplate', { static: true }) actionsSubgroupTemplate: TemplateRef<any>;

    public _columnsTableGroup: TableColumnDTO[] = [];
    public _columnsTableSubgroup: TableColumnDTO[] = [];

    public typeOperation: 'NEW' | 'EDIT' | 'VIEW' = 'NEW';

    public productSection: RegistrationProductGrouping = new RegistrationProductGrouping();
    public productGroup: RegistrationProductGrouping = new RegistrationProductGrouping();
    public productSubgroup: RegistrationProductGrouping = new RegistrationProductGrouping();

    public optionActiveSituationEnum: typeof OptionActiveSituationEnum = OptionActiveSituationEnum;
    public optionActiveSituationSectionValue: OptionActiveSituation = 'ENABLED';
    public optionActiveSituationGroupValue: OptionActiveSituation = 'ENABLED';
    public optionActiveSituationSubgroupValue: OptionActiveSituation = 'ENABLED';

    constructor(
        protected service: RegistrationProductGroupingService,
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        public dialogRef: MatDialogRef<ProductGroupingsFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ProductGroupingsData = { typeOperation: 'NEW', productGrouping: null },
    ) {
        super(baseService, activatedRoute);

        this.basePermissionList = {
            MAKE: 'PRODUCT_GROUPING_MAKE',
            UPDATE: 'PRODUCT_GROUPING_UPDATE',
            READ: 'PRODUCT_GROUPING_READ',
            DELETE: 'PRODUCT_GROUPING_DELETE',
            ENABLE_DISABLE: 'PRODUCT_GROUPING_ENABLE_DISABLE'
        };

        this.dialogRef.disableClose = true;

    }

    ngOnInit() {
        super.ngOnInit();
        this._columnsTableGroup = this._createTableColumnsGroup();
        this._columnsTableSubgroup = this._createTableColumnsSubgroup();

        this.typeOperation = this.data.typeOperation;
        if (this.typeOperation !== 'NEW') {
            this.productSection = this.data.productGrouping;

            if (!this.productSection.enabled) {
                this.optionActiveSituationSectionValue = 'DISABLED';
            }
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    private _createTableColumnsGroup(): TableColumnDTO[] {
        return [
            TableColumnBuilder.instance()
                .property('description')
                .name('Descrição')
                .marginLeft(15)
                .sortable(true)
                .value((obj: RegistrationProductGrouping) => !StringUtil.isEmpty(obj.description) ? obj.description : '')
                .build(),
            TableColumnBuilder.instance()
                .property('status')
                .name('Status')
                .align('center center')
                .width(15)
                .template(this.statusGroupTemplate)
                .build(),
            TableColumnBuilder.instance()
                .property('actions')
                .name('Ações')
                .width(15)
                .align('center center')
                .template(this.actionsGroupTemplate)
                .build(),
        ];
    }

    private _createTableColumnsSubgroup(): TableColumnDTO[] {
        return [
            TableColumnBuilder.instance()
                .property('description')
                .name('Descrição')
                .marginLeft(15)
                .sortable(true)
                .value((obj: RegistrationProductGrouping) => !StringUtil.isEmpty(obj.description) ? obj.description : '')
                .build(),
            TableColumnBuilder.instance()
                .property('status')
                .name('Status')
                .align('center center')
                .width(15)
                .template(this.statusSubgroupTemplate)
                .build(),
            TableColumnBuilder.instance()
                .property('actions')
                .name('Ações')
                .width(15)
                .align('center center')
                .template(this.actionsSubgroupTemplate)
                .build(),
        ];
    }

    private _isValidSection(): boolean {
        if (StringUtil.isEmpty(this.productSection.description)) {
            this.addWarningMessage('Campo descrição da seção é obrigatório e não foi informado');
            return false;
        }
        if (this.productSection.description.length < 3) {
            this.addWarningMessage('Campo descrição da seção deve conter no mínimo 3 caracteres');
            return false;
        }
        if (ArrayUtil.isEmpty(this.productSection.children)) {
            this.addWarningMessage('A seção deve conter no mínimo 1 grupo');
            return false;
        }
        return true;
    }

    public confirm(): void {
        try {
            if (ObjectUtil.isNull(this.productSection) || !this._isValidSection()) {
                return;
            }

            this.loading = true;

            this.productSection.type = 'SECTION';
            this.productSection.enabled = (this.optionActiveSituationSectionValue === 'ENABLED');

            this.service.save(this.productSection).toPromise().then(() => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.loading = false;
                this.close('RELOAD_TABLE');
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public setTextStatus(entity: RegistrationProductGrouping): string {
        return entity.enabled ? 'Ativo' : 'Inativo';
    }

    public setColorStatus(entity: RegistrationProductGrouping): string {
        return entity.enabled ? '#02840f' : '#c24245';
    }

    public addGroupFromSection(): void {
        try {
            if (ObjectUtil.isNull(this.productSection) ||
                (!ObjectUtil.isNull(this.productSection) && StringUtil.isEmpty(this.productSection.description)) ||
                ObjectUtil.isNull(this.productGroup) ||
                (!ObjectUtil.isNull(this.productGroup) && StringUtil.isEmpty(this.productGroup.description)) ||
                this.loading ||
                this.isViewing()
            ) {
                return;
            }

            if (StringUtil.isEmpty(this.productGroup.description)) {
                this.addWarningMessage('Campo descrição do grupo é obrigatório e não foi informado');
                return;
            }
            if (this.productGroup.description.length < 3) {
                this.addWarningMessage('Campo descrição do grupo deve conter no mínimo 3 caracteres');
                return;
            }

            const existSameDescritpion: RegistrationProductGrouping = this.productSection.children.find(pg => pg.description === this.productGroup.description);
            if (!ObjectUtil.isNull(existSameDescritpion)) {
                this.addWarningMessage(`Já existe um grupo com a esta descrição: ${existSameDescritpion.description}`);
                return;
            }

            this.loading = true;

            const newProductGroupingChildren = new RegistrationProductGrouping();
            newProductGroupingChildren.description = this.productGroup.description;
            newProductGroupingChildren.type = 'GROUP';
            newProductGroupingChildren.enabled = (this.optionActiveSituationGroupValue === 'ENABLED');
            newProductGroupingChildren.children = [];
            newProductGroupingChildren.key = this.productSection.children.length;

            if (!ObjectUtil.isNull(this.productGroup) && (this.productGroup.key >= 0)) {
                const indexProductGrouping: number = this.productSection.children.findIndex(pg => pg.key === this.productGroup.key);
                const childrenAux = ArrayUtil.clone(this.productSection.children);
                childrenAux.splice(indexProductGrouping, 1, newProductGroupingChildren);
                this.productSection.children = [...childrenAux];
            } else {
                this.productSection.children = [...this.productSection.children, newProductGroupingChildren];
            }

            this.productGroup = new RegistrationProductGrouping();
            this.optionActiveSituationGroupValue = 'ENABLED';

            this.loading = false;
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public editGroupFromSection(children: RegistrationProductGrouping): void {
        try {
            this.baseService.confirmationService.confirm({
                title: 'Confirmação',
                message: `Deseja editar este grupo?`,
                accept: () => {
                    if (!ArrayUtil.isEmpty(this.productSection.children)) {
                        this.productGroup = ObjectUtil.clone(children);
                        this.optionActiveSituationGroupValue = children.enabled ? 'ENABLED' : 'DISABLED';
                    }
                }
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public removeGroupFromSection(children: RegistrationProductGrouping): void {
        try {
            this.baseService.confirmationService.confirm({
                title: 'Confirmação',
                message: `Deseja realmente remover este grupo?`,
                accept: () => {
                    if (!ArrayUtil.isEmpty(this.productSection.children)) {
                        const indexProductGrouping: number = this.productSection.children.findIndex(pg => pg.key === children.key);
                        if (indexProductGrouping >= 0) {
                            const childrenAux = ArrayUtil.clone(this.productSection.children);
                            childrenAux.splice(indexProductGrouping, 1);
                            this.productSection.children = [...childrenAux];
                        }
                    }
                }
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public addSubgroupFromGroupOfSection(group: RegistrationProductGrouping): void {
        try {
            if (StringUtil.isEmpty(this.productSubgroup.description)) {
                this.addWarningMessage('Campo descrição do subgrupo é obrigatório e não foi informado');
                return;
            }
            if (this.productSubgroup.description.length < 3) {
                this.addWarningMessage('Campo descrição do subgrupo deve conter no mínimo 3 caracteres');
                return;
            }

            const existSameDescritpion: RegistrationProductGrouping = group.children.find(pg => pg.description === this.productSubgroup.description);
            if (!ObjectUtil.isNull(existSameDescritpion)) {
                this.addWarningMessage(`Já existe um subgrupo com a esta descrição: ${existSameDescritpion.description}`);
                return;
            }

            this.loading = true;

            const newProductGroupingChildren = new RegistrationProductGrouping();
            newProductGroupingChildren.description = this.productSubgroup.description;
            newProductGroupingChildren.type = 'SUBGROUP';
            newProductGroupingChildren.enabled = (this.optionActiveSituationSubgroupValue === 'ENABLED');
            newProductGroupingChildren.children = [];
            newProductGroupingChildren.key = group.children.length;

            if (!ObjectUtil.isNull(this.productSubgroup) && (this.productSubgroup.key >= 0)) {
                const indexProductGrouping: number = group.children.findIndex(pg => pg.key === this.productSubgroup.key);
                const childrenAux = ArrayUtil.clone(group.children);
                childrenAux.splice(indexProductGrouping, 1, newProductGroupingChildren);
                group.children = [...childrenAux];
            } else {
                group.children = [...group.children, newProductGroupingChildren];
            }

            this.productSubgroup = new RegistrationProductGrouping();
            this.optionActiveSituationSubgroupValue = 'ENABLED';

            this.loading = false;
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public editSubgroupFromGroupOfSection(children: RegistrationProductGrouping): void {
        try {
            this.baseService.confirmationService.confirm({
                title: 'Confirmação',
                message: `Deseja editar este subgrupo?`,
                accept: () => {
                    this.productSubgroup = ObjectUtil.clone(children);
                    this.optionActiveSituationSubgroupValue = children.enabled ? 'ENABLED' : 'DISABLED';
                }
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public removeSubgroupFromGroupOfSection(children: RegistrationProductGrouping): void {
        try {
            this.baseService.confirmationService.confirm({
                title: 'Confirmação',
                message: `Deseja realmente remover este subgrupo?`,
                accept: () => {
                    const indexProductGrouping: number = this.productSection.children.findIndex(pg => pg.key === children.key);
                    if (indexProductGrouping >= 0) {
                        const childrenAux = ArrayUtil.clone(this.productSection.children);
                        childrenAux.splice(indexProductGrouping, 1);
                        this.productSection.children = [...childrenAux];
                    }
                }
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    public close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE'): void {
        this.dialogRef.close(operation);
    }

}
