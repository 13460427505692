<gipi-abstract-form id="dialogRegisterTransporter"
                    name="dialogRegisterTransporter"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogRegisterTransporter">
        Cadastrar transportadora
    </gipi-toolbar>
    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <gipi-input-select-enum label="Tipo de pessoa"
                                    id="transporterTypePerson"
                                    name="transporterTypePerson"
                                    [required]="true"
                                    [valuesExclud]="['FOREIGN_PERSON']"
                                    [enum]="typePersonEnum"
                                    [disabled]="loading || (typeOperation === 'VIEW')"
                                    [(ngModel)]="transporter.person.typePerson"
                                    fxFlex
                                    fxFlex.lt-md="100">
            </gipi-input-select-enum>
            <itss-input label="CPF"
                        id="transporterCpf"
                        name="transporterCpf"
                        *ngIf="transporter.person.typePerson === 'NATURAL_PERSON'"
                        mask="000.000.000-00"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="transporter.person.naturalPerson.cpf"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="CNPJ"
                        id="transporterCnpj"
                        name="transporterCnpj"
                        *ngIf="transporter.person.typePerson === 'LEGAL_PERSON'"
                        mask="00.000.000/0000-00"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="transporter.person.legalPerson.cnpj"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Inscrição Estadual"
                        id="transporterStateRegistration"
                        name="transporterStateRegistration"
                        *ngIf="transporter.person.typePerson === 'LEGAL_PERSON'"
                        mask="0*"
                        [dropSpecialCharacters]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="transporter.person.legalPerson.stateRegistration"
                        [max]="14"
                        [maxlength]="14"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Documento"
                        id="transporterDocumentNumber"
                        name="transporterDocumentNumber"
                        *ngIf="transporter.person.typePerson === 'NATURAL_PERSON'"
                        [dropSpecialCharacters]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="transporter.person.naturalPerson.documentNumber"
                        [max]="20"
                        [maxlength]="20"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <gipi-input-select-enum label="Situação"
                                    id="transporterEnabled"
                                    name="transporterEnabled"
                                    [disabled]="loading || (typeOperation !== 'EDIT')"
                                    [required]="true"
                                    [valuesExclud]="[]"
                                    [enum]="optionActiveSituationEnum"
                                    [(ngModel)]="optionActiveSituationValue"
                                    fxFlex
                                    fxFlex.lt-md="100">
            </gipi-input-select-enum>
        </div>

        <div fxLayout="row"
             fxLayoutAlign="start"
             fxLayoutGap="16px"
             fxLayout.lt-sm="column"
             fxLayout.lt-md="column">
            <itss-input [label]="(transporter.person.typePerson === 'LEGAL_PERSON') ? 'Razão social' : 'Nome'"
                        id="namePerson"
                        id="transporterNamePerson"
                        name="transporterNamePerson"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="transporter.person.name"
                        (focusout)="setNameInFantasyName()"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input [label]="(transporter.person.typePerson === 'LEGAL_PERSON') ? 'Nome fantasia' : 'Como você quer ser chamado(a)?'"
                        id="transporterFantasyNamePerson"
                        name="transporterFantasyNamePerson"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="transporter.person.fantasyName"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
        </div>

        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <itss-input label="CEP"
                        id="transporterZipCodeAddress"
                        name="transporterZipCodeAddress"
                        [required]="true"
                        [placeholder]="'00.000-000'"
                        [mask]="'00.000-000'"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="transporter.person.addressList[0].zipCode"
                        (keydown.enter)="findZipCode(transporter.person.addressList[0])"
                        fxFlex="24"
                        fxFlex.lt-md="100">
                <ng-template #suffix>
                    <div fxLayout="row"
                         fxLayoutGap="5px">
                        <gipi-button tooltip="Buscar CEP"
                                     gipi-icon
                                     id="btnSearchCepAddress"
                                     svgIcon="search"
                                     [forInput]="true"
                                     [disabled]="loading || (typeOperation === 'VIEW')"
                                     (click)="findZipCode(transporter.person.addressList[0])">
                        </gipi-button>
                    </div>
                </ng-template>
            </itss-input>
            <itss-input label="Endereço"
                        id="transporterStreetAddress"
                        name="transporterStreetAddress"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="transporter.person.addressList[0].street"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Número"
                        id="transporterNumberAddress"
                        name="transporterNumberAddress"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="transporter.person.addressList[0].streetNumber"
                        fxFlex="24"
                        fxFlex.lt-md="100">
            </itss-input>
        </div>

        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <itss-input label="Bairro"
                        id="transporterNeighborhoodAddress"
                        name="transporterNeighborhoodAddress"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="transporter.person.addressList[0].neighborhood"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <gipi-input-select-paged label="Estado"
                                     id="transporterStateAddress"
                                     name="transporterStateAddress"
                                     [showClear]="true"
                                     [required]="true"
                                     [disabled]="loading || (typeOperation === 'VIEW')"
                                     [property]="'acronym'"
                                     [nextBatchFn]="stateFindByValueFn"
                                     [(model)]="transporter.person.addressList[0].state"
                                     fxFlex="24">
            </gipi-input-select-paged>
            <gipi-input-select-paged label="Cidade"
                                     id="transporterCityAddress"
                                     name="transporterCityAddress"
                                     [showClear]="true"
                                     [required]="true"
                                     [disabled]="loading || (typeOperation === 'VIEW') || (!transporter.person.addressList[0].state)"
                                     [property]="'name'"
                                     [paramNextBatch]="transporter"
                                     [nextBatchFn]="cityFindByValueFn"
                                     [(model)]="transporter.person.addressList[0].city"
                                     fxFlex="24">
            </gipi-input-select-paged>
        </div>

        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <itss-input label="E-mail"
                        id="transporterEmail"
                        name="transporterEmail"
                        [type]="'email'"
                        [placeholder]="'exemplo@email.com'"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [lowerCase]="true"
                        [(ngModel)]="email"
                        (focusout)="validateEmail(email)"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Telefone"
                        id="transporterPhoneOne"
                        name="transporterPhoneOne"
                        [placeholder]="'(00) 0 0000-0000'"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [mask]="'(00) 0000-0000||(00) 0 0000-0000'"
                        [(ngModel)]="phoneOne"
                        fxFlex="24"
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Telefone"
                        id="transporterPhoneTwo"
                        name="transporterPhoneTwo"
                        [placeholder]="'(00) 0 0000-0000'"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [mask]="'(00) 0000-0000||(00) 0 0000-0000'"
                        [(ngModel)]="phoneTwo"
                        fxFlex="24"
                        fxFlex.lt-md="100">
            </itss-input>
        </div>

        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <gipi-expansion-panel panelTitle="Veículos"
                                  id="panelTransporterVehicle"
                                  svgIcon="transporter_vehicle"
                                  [iconSize]="24"
                                  [disabled]="loading || (typeOperation === 'VIEW')"
                                  fxFlexFill>
                <div fxLayout="column"
                     fxFlex
                     fxLayoutGap="16px">
                    <ng-container *ngFor="let vehicle of transporter.vehicleList; let i = index;">
                        <div fxFlex
                             fxLayout="column"
                             fxLayoutGap="10px"
                             class="group-panel-register"
                             *ngIf="transporter.vehicleList && (transporter.vehicleList.length > 0)"
                             [attr.id]="'groupVehicle' + i">
                            <gipi-button tooltip="Excluir"
                                         gipi-icon
                                         class="btn-delete-group"
                                         id="btnDeleteVehicle"
                                         icon="close"
                                         *ngIf="!loading && (typeOperation !== 'VIEW')"
                                         (click)="deleteVehicle(vehicle)">
                            </gipi-button>

                            <div fxFlex
                                 fxLayout="column"
                                 fxLayoutGap="16px">
                                <div fxLayout="row wrap"
                                     fxLayoutGap="16px"
                                     fxLayoutGap.lt-md="0"
                                     fxFlexFill>
                                    <itss-input label="ANTT"
                                                id="transporterAnttVehicle"
                                                name="transporterAnttVehicle"
                                                [required]="true"
                                                [disabled]="loading || (typeOperation === 'VIEW')"
                                                [(ngModel)]="vehicle.antt"
                                                [min]="0"
                                                [max]="20"
                                                [maxlength]="20"
                                                fxFlex
                                                fxFlex.lt-md="100">
                                    </itss-input>
                                    <itss-input label="Placa"
                                                #inputPlateVehicle
                                                id="transporterPlateVehicle"
                                                name="transporterPlateVehicle"
                                                [mask]="getMaskPlateVehicle(vehicle.plate, inputPlateVehicle)"
                                                [required]="true"
                                                [disabled]="loading || (typeOperation === 'VIEW')"
                                                [(ngModel)]="vehicle.plate"
                                                fxFlex
                                                fxFlex.lt-md="100">
                                    </itss-input>
                                    <gipi-input-select-paged label="UF da placa"
                                                             id="transporterStateAddress"
                                                             name="transporterStateAddress"
                                                             [showClear]="true"
                                                             [required]="true"
                                                             [disabled]="loading || (typeOperation === 'VIEW')"
                                                             [property]="'acronym'"
                                                             [nextBatchFn]="stateFindByValueFn"
                                                             [(model)]="vehicle.plateState"
                                                             fxFlex>
                                    </gipi-input-select-paged>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="group-panel-register new-group-panel-register"
                         id="btnNewVehicle"
                         *ngIf="!loading && (typeOperation !== 'VIEW')"
                         (click)="addNewVehicle()">
                        + Adicionar veículo
                    </div>
                </div>
            </gipi-expansion-panel>
        </div>
    </div>
    <div mat-dialog-actions
         fxLayout="row wrap"
         fxLayout.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     name="btnSave"
                     *ngIf="(typeOperation !== 'VIEW')"
                     (click)="confirm()"
                     [disabled]="loading"
                     [loading]="loading"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     name="btnReturn"
                     (click)="close('NONE')"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>