<gipi-abstract-form id="dialogPersonAssociations"
                    name="dialogPersonAssociations"
                    [dialog]="true"
                    class="form-content-person-associations">
    <div mat-dialog-title
         class="person-associations-header">
        <span role="text">
            {{ data.title }}
        </span>
        <span role="textbox"
              class="person-associations-header-actions">
            <gipi-button gipi-icon
                         id="btnClose"
                         icon="close"
                         tooltip="Fechar"
                         (click)="close()">
            </gipi-button>
        </span>
    </div>

    <div mat-dialog-content
         class="person-associations-content">
        <p>{{ data.message }}</p>
    </div>

    <div mat-dialog-actions
         class="person-associations-actions"
         fxLayout="row"
         fxLayout.lt-sm="column"
         fxLayoutGap="16px"
         fxFlex>
        <gipi-button label="Atualizar"
                     gipi-primary
                     *ngIf="data.showBtnUpdateRecord"
                     fxFlex
                     fxFlex.lt-sm="100"
                     [mat-dialog-close]="'UPDATE_RECORD'">
        </gipi-button>

        <gipi-button label="Novo"
                     gipi-secondary
                     fxFlex
                     [mat-dialog-close]="'NEW_RECORD'"
                     fxFlex.lt-sm="100">
        </gipi-button>
    </div>
</gipi-abstract-form>