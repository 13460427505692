<gipi-abstract-form id="dialogFindSale"
                    name="dialogFindSale"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogFindSale">
        Selecionar vendas
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">

        <div fxLayout="column"
             fxLayoutGap="16px">
            <div fxLayout="row wrap"
                 fxLayout.lt-sm="column"
                 fxLayoutAlign="space-between center"
                 fxLayoutGap="16px">
                <div fxFlex="40"
                     style="width: 100%;"
                     fxLayout="row"
                     fxLayoutGap="16px">
                    <gipi-form-field id="inputSearch"
                                     #search
                                     fxFlex
                                     type="search"
                                     placeholder="Pesquisar"
                                     showSearch="true"
                                     [disabled]="loading"
                                     [(ngModel)]="filter.researchField"
                                     (onKeydownEnter)="find(null)"
                                     (onSearch)="find(null)">
                    </gipi-form-field>

                    <gipi-button tooltip="Filtrar"
                                 gipi-primary-icon
                                 #searchButton
                                 id="btnOptionsSearch"
                                 svgIcon="filter"
                                 [notification]="appliedFilters && (appliedFilters.length > 0)"
                                 [popover]="true"
                                 [disabled]="loading">
                        <div fxLayout="column"
                             fxLayoutGap="12px"
                             style="max-width: 36rem; padding: 8px;">
                            <div fxLayout="row"
                                 fxLayoutGap="12px"
                                 fxFlexAlign="space-between center">
                                <gipi-datepicker label="Data inicial"
                                                 [(ngModel)]="filter.begin"
                                                 [disabled]="loading"
                                                 fxFlex="48">
                                </gipi-datepicker>
                                <gipi-datepicker label="Data final"
                                                 [(ngModel)]="filter.end"
                                                 [disabled]="loading"
                                                 fxFlex="48">
                                </gipi-datepicker>
                            </div>

                            <gipi-input-select-paged label="Cliente"
                                                     [(model)]="filter.client"
                                                     [nextBatchFn]="clientFindByValueFn"
                                                     [propertyFn]="clientFn"
                                                     [disabled]="loading"
                                                     fxFlex>
                            </gipi-input-select-paged>

                            <div fxFlex
                                 fxLayout="row"
                                 fxFlexAlign="space-between center"
                                 fxLayoutGap="12px">
                                <gipi-form-field label="Número do documento"
                                                 id="inputFilterDocumentNumber"
                                                 type="text"
                                                 placeholder="Ex: 0001"
                                                 [(ngModel)]="filter.document"
                                                 [disabled]="loading"
                                                 fxFlex="48">
                                </gipi-form-field>
                                <gipi-input-currency label="Valor"
                                                     id="inputFilterAmount"
                                                     name="currencyAmount"
                                                     type="currency"
                                                     placeholder="R$ 0,00"
                                                     [(ngModel)]="filter.total"
                                                     [disabled]="loading"
                                                     fxFlex="48">
                                </gipi-input-currency>
                            </div>

                            <div style="margin-top: 12px;"
                                 fxLayout="row"
                                 fxLayoutGap="12px"
                                 fxFlex>
                                <gipi-button label="Pesquisar"
                                             gipi-primary
                                             id="btnFind"
                                             icon="search"
                                             [disabled]="loading"
                                             (click)="find(null); searchButton.popoverTrigger.togglePopover()"
                                             fxFlex>
                                </gipi-button>
                                <gipi-button label="Limpar"
                                             gipi-secondary
                                             id="btnClear"
                                             icon="clear"
                                             [disabled]="loading"
                                             (click)="clear(); searchButton.popoverTrigger.togglePopover()"
                                             fxFlex>
                                </gipi-button>
                            </div>
                        </div>
                    </gipi-button>
                </div>
            </div>
            <gipi-chips label="Filtros aplicados:"
                        style="max-width: 70%;"
                        [(ngModel)]="appliedFilters"
                        [showRemovable]="true"
                        [showRemovableAll]="true"
                        [disabled]="loading"
                        (removeChange)="removeAppliedFilter($event)"
                        (removeAllChange)="clear()">
            </gipi-chips>
        </div>

        <itss-table [expandable]="true"
                    [columnExpandableFixed]="true"
                    [ignoreChildrenExpandRow]="true"
                    [columns]="columns"
                    [loading]="loading"
                    [paginator]="true"
                    [paginatorLength]="page.totalElements"
                    [paginatorPageIndex]="page.number"
                    [paginatorPageSize]="5"
                    [data]="page.content"
                    (page)="find($event)">
            <ng-template #expanded
                         let-entity>
                <div class="detail-table">
                    <div *ngFor="let payment of entity?.salePaymentList; let i = index;"
                         class="detail-row"
                         (click)="select(entity, payment, true)">
                        <div class="detail-column column-select">
                            <gipi-input-checkbox [id]="'selectPayment' + i"
                                                 [name]="'selectPayment' + i"
                                                 [value]="payment.selected"
                                                 (change)="select(entity, payment, true)"
                                                 (click)="$event.stopPropagation()">
                            </gipi-input-checkbox>
                        </div>
                        <div class="detail-column column-amount">
                            <span class="title"> Valor </span>
                            <span class="text"> {{ payment?.amountPaid | currency:'BRL':'symbol':'1.2-2' }} </span>
                        </div>
                        <div class="detail-column column-charge-type">
                            <span class="title"> Tipo de cobrança </span>
                            <span class="text"> {{ payment?.chargeType?.description }} </span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </itss-table>
    </div>

    <div mat-dialog-actions
         fxLayout="row wrap"
         fxLayout.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Usar selecionados"
                     gipi-primary
                     id="btnConfirm"
                     [disabled]="loading || (salePaymentListSelected?.length === 0)"
                     (click)="confirm()"
                     [loading]="loading"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     fxFlex="20"
                     (click)="close()"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>
