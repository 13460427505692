import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SaleCardAdministrator } from '@gipi-sale/card-administrator/models/card-administrador.model';
import { SaleCardAdministratorService } from '@gipi-sale/card-administrator/services/card-administrator.service';
import { SaleChargeTypeService } from '@gipi-sale/charge-type/services/charge-type.service';
import { SalePaymentMethod } from '@gipi-sale/payment-method/models/payment-method.model';
import { SalePaymentMethodService } from '@gipi-sale/payment-method/services/payment-method.service';
import { OptionActiveSituationEnum } from '@gipi-shared/enums/option-active-situation.enum';
import { APP_MESSAGES, GIPIAbstractComponent, GIPIBaseService, GIPISortModel, INJECTOR, NumberUtil, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
export class PaymentMethodsFormDialogComponent extends GIPIAbstractComponent {
    constructor(service, baseService, activatedRoute, dialogRef, data = { typeOperation: 'NEW', paymentMethod: null }, _chargeTypeService, _cardAdministratorService) {
        super(baseService, activatedRoute);
        this.service = service;
        this.baseService = baseService;
        this.activatedRoute = activatedRoute;
        this.dialogRef = dialogRef;
        this.data = data;
        this._chargeTypeService = _chargeTypeService;
        this._cardAdministratorService = _cardAdministratorService;
        this.typeOperation = 'NEW';
        this.paymentMethod = new SalePaymentMethod();
        this.optionActiveSituationEnum = OptionActiveSituationEnum;
        this.optionActiveSituationValue = 'ENABLED';
        this.cardAdministratorSelected = null;
        this.chargeTypeFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const ignoreType = ['ADDITION', 'DISCOUNT', 'CASH_CHANGE', 'CREDIT_CLIENT'];
            const result = yield this._chargeTypeService.findByValue(value, page, 50, new GIPISortModel('description', 'ASC'), '', ignoreType).toPromise();
            return result;
        });
        this.cardAdministratorFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._cardAdministratorService.findByValue(value, page, 50, new GIPISortModel('description', 'ASC')).toPromise();
            return result;
        });
        this.dialogRef.disableClose = true;
    }
    ngOnInit() {
        super.ngOnInit();
        this.typeOperation = this.data.typeOperation;
        if (this.typeOperation !== 'NEW') {
            this.paymentMethod = this.data.paymentMethod;
            if (!this.paymentMethod.enabled) {
                this.optionActiveSituationValue = 'DISABLED';
            }
        }
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
    confirm() {
        try {
            if (ObjectUtil.isNull(this.paymentMethod)) {
                return;
            }
            if (StringUtil.isEmpty(this.paymentMethod.description)) {
                this.addWarningMessage('Campo descrição é obrigatório e não foi informado');
                return;
            }
            if (this.paymentMethod.description.length < 3) {
                this.addWarningMessage('Campo descrição deve conter no mínimo 3 caracteres');
                return;
            }
            if (ObjectUtil.isNull(this.paymentMethod.chargeType)) {
                this.addWarningMessage('Campo tipo de cobrança é obrigatório e não foi informado');
                return;
            }
            if (this.paymentMethod.hasEntry &&
                (ObjectUtil.isNull(this.paymentMethod.entryPercentage) || NumberUtil.isNegative(this.paymentMethod.entryPercentage) || this.paymentMethod.entryPercentage === 0)) {
                this.addWarningMessage('Campo entrada é obrigatório e não foi informado');
                return;
            }
            else if (ObjectUtil.isNull(this.paymentMethod.entryPercentage) || NumberUtil.isNegative(this.paymentMethod.entryPercentage)) {
                this.paymentMethod.entryPercentage = 0;
            }
            if (ObjectUtil.isNull(this.paymentMethod.addition) || NumberUtil.isNegative(this.paymentMethod.addition)) {
                this.paymentMethod.addition = 0;
            }
            if (ObjectUtil.isNull(this.paymentMethod.discount) || NumberUtil.isNegative(this.paymentMethod.discount)) {
                this.paymentMethod.discount = 0;
            }
            if (ObjectUtil.isNull(this.paymentMethod.installmentsNumber) || NumberUtil.isNegative(this.paymentMethod.installmentsNumber)) {
                this.paymentMethod.installmentsNumber = 0;
            }
            if (ObjectUtil.isNull(this.paymentMethod.numberDaysWinsFirstInstallment) || NumberUtil.isNegative(this.paymentMethod.numberDaysWinsFirstInstallment)) {
                this.paymentMethod.numberDaysWinsFirstInstallment = 0;
            }
            if (ObjectUtil.isNull(this.paymentMethod.daysBetweenInstallments) || NumberUtil.isNegative(this.paymentMethod.daysBetweenInstallments)) {
                this.paymentMethod.daysBetweenInstallments = 0;
            }
            this.paymentMethod.cardAdministrator = null;
            if (this.paymentMethod.integratedTEF && ObjectUtil.isNull(this.cardAdministratorSelected)) {
                this.addWarningMessage('Campo administradora de cartão é obrigatório e não foi informado');
                return;
            }
            else if (this.paymentMethod.integratedTEF && !ObjectUtil.isNull(this.cardAdministratorSelected)) {
                this.paymentMethod.cardAdministrator = new SaleCardAdministrator(this.cardAdministratorSelected.id);
            }
            this.loading = true;
            this.paymentMethod.enabled = (this.optionActiveSituationValue === 'ENABLED');
            this.service.save(this.paymentMethod).toPromise().then(() => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.loading = false;
                this.close('RELOAD_TABLE');
            }, error => {
                throw new Error(error);
            });
        }
        catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }
    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation) {
        this.dialogRef.close(operation);
    }
}
