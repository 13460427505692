import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { OAuthPaths } from '@gipi-paths/oauth.paths';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { OAuthUserFilterDTO } from '../models/dto/user-filter.dto';
import { OAuthUser } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class OAuthUserPublicService extends BaseCrudService<OAuthUser, OAuthUserFilterDTO> {

    constructor(
        http: HttpClient,
        authenticationService: CustomAuthenticationService
    ) {
        super(OAuthPaths.userPublic, http, authenticationService);
    }

    recovery(username: string): Observable<OAuthUser> {
        return this.http.get(this.url(`recovery/${username}`), this.options()).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    updatePassword(token: string, password: string): Observable<OAuthUser> {
        const httpParams = new HttpParams()
            .append('token', token)
            .append('password', password);
        return this.http.get(this.url('update-password'), this.options(httpParams)).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

    confirmAccess(token: string, password: string, name: string, photo?: string): Observable<OAuthUser> {
        const httpParams = new HttpParams()
            .append('token', token)
            .append('password', password)
            .append('name', name)
            .append('photo', photo);
        return this.http.get(this.url('confirm-access'), this.options(httpParams)).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

    getByInvitationToken(token: string): Observable<OAuthUser> {
        const httpParams = new HttpParams().append('token', token);
        return this.http.get(this.url('get-by-invitation-token'), this.options(httpParams)).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

}
