import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';

import { environment } from '../../environments/environment';

import { CoreModule, SharedModule } from '@gipisistemas/ng-core';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConfirmAccessComponent } from './confirm-access/confirm-access.component';
import { LoginComponent } from './login/login.component';
import { OauthRoutingModule } from './oauth-routing.module';
import { RequestPasswordComponent } from './request-password/request-password.component';
import { ResendRecoveryComponent } from './resend-recovery/resend-recovery.component';

@NgModule({
    declarations: [
        LoginComponent,
        RequestPasswordComponent,
        ChangePasswordComponent,
        ResendRecoveryComponent,
        ConfirmAccessComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        OauthRoutingModule,
        CoreModule,

        RecaptchaModule,
        RecaptchaFormsModule,
    ],
    providers: [
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: { siteKey: environment.recaptcha.siteKey } as RecaptchaSettings,
        },
        // {
        //     provide: RECAPTCHA_V3_SITE_KEY,
        //     useValue: environment.recaptcha.siteKey,
        // },
    ]
})
export class OauthModule { }
