import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { FinancialClientService } from '@gipi-financial/client/services/client.service';

import { FinancialSaleFilterDTO } from '@gipi-financial/sale/models/dto/sale-filter.dto';
import { FinancialSale } from '@gipi-financial/sale/models/sale.model';
import { FinancialSaleService } from '@gipi-financial/sale/services/sale.service';
import { RegistrationClient } from '@gipi-registration/client/models/client.model';
import { RegistrationClientCardDTO } from '@gipi-registration/client/models/dto/client-card.dto';
import { RegistrationPhone } from '@gipi-registration/person/models/phone.model';
import { SaleDataDialogComponent } from '@gipi-sale/sale/components/sale-data-dialog/sale-data-dialog.component';
import { WhatsappContactDialogComponent } from '@gipi-shared/components/whatsapp-contact-dialog/whatsapp-contact-dialog.component';
import { WhatsappContact } from '@gipi-shared/models/whatsapp-contact.model';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ArrayUtil, CurrencyUtil, DateUtil, DialogService, ObjectUtil, PageDTO, StringUtil, TableColumnBuilder, TableColumnDTO } from '@gipisistemas/ng-core';

@Component({
    templateUrl: './client-dialog.component.html',
    styleUrls: ['./client-dialog.component.scss'],
})
export class ClientDialogComponent extends AbstractComponent implements OnInit {

    private _filter: FinancialSaleFilterDTO;

    public client: RegistrationClientCardDTO;

    public page: PageDTO<FinancialSale>;

    public columns: TableColumnDTO[];

    @ViewChild('actions', { static: true }) actions: TemplateRef<any>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: RegistrationClientCardDTO,
        private _clientService: FinancialClientService,
        private _saleService: FinancialSaleService,
        private _dialogService: DialogService,
        messageService: CustomMessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.createTableColumns();
        this.page = new PageDTO<FinancialSale>();
        this.client = this.data;
    }

    protected createTableColumns(): void {
        this.columns = [
            TableColumnBuilder.instance()
                .property('date')
                .name('Data')
                .value((obj: FinancialSale) => DateUtil.format(obj.date, DateUtil.DATE_FORMAT))
                .build(),
            TableColumnBuilder.instance()
                .property('documentNumber')
                .name('Documento')
                .value((obj: FinancialSale) => obj.documentNumber)
                .build(),
            TableColumnBuilder.instance()
                .property('salePayment')
                .name('Total Líquido')
                .value((obj: FinancialSale) => CurrencyUtil.transform(obj.salePaymentList.reduce((sum, payment) => sum += payment.amountPaid, 0), '1.2-2'))
                .build(),
            TableColumnBuilder.instance()
                .property('chargeType')
                .name('Forma de pagamento')
                .value((obj: FinancialSale) => obj.paymentMethod.chargeType.description)
                .build(),
            TableColumnBuilder.instance()
                .property('seller')
                .name('Vendedor')
                .value((obj: FinancialSale) => obj.seller ? obj.seller.person.name : '')
                .build(),
            TableColumnBuilder.instance()
                .property('actions')
                .name('Ações')
                .width(10)
                .align('center center')
                .template(this.actions)
                .build(),
        ];
    }

    private _newFilterDTO(): void {
        this._filter = new FinancialSaleFilterDTO();
        this._filter.client = new RegistrationClient();
        this._filter.client.id = this.client.id;
    }

    public find(pageEvent?: PageEvent): void {
        this.loading = true;
        this.loadingChange.emit(this.loading);
        this.page = new PageDTO<FinancialSale>();
        if (ObjectUtil.isNull(this._filter)) {
            this._newFilterDTO();
        }

        this._filter.pageSize = 10;
        if (pageEvent) {
            this._filter.pageNumber = pageEvent.pageIndex;
            this._filter.offset = pageEvent.pageIndex * pageEvent.pageSize;
        } else {
            this._filter.pageNumber = 0;
            this._filter.offset = 0;
        }

        this._saleService.findAll(this._filter).toPromise().then(page => {
            this.page = page;
            this.loading = false;
            this.loadingChange.emit(this.loading);
        }, error => this.handleError(error));
    }

    public getColorByAmount(amount: number): string {
        if (amount > 0) {
            return '#47ca82';
        } else if (amount < 0) {
            return '#ca0045';
        } else if (amount === 0) {
            return '#696969';
        }
    }

    public navigateSaleData(entity: FinancialSale): void {
        this._dialogService.open({
            componentOrTemplateRef: SaleDataDialogComponent,
            data: entity.id,
            width: '90%',
            height: '90%',
        });
    }

    public async whatsappContact(): Promise<void> {
        try {
            if (!ObjectUtil.isNull(this.client)) {
                const getRecipientPhone = (phoneList: RegistrationPhone[]) => {
                    if (ArrayUtil.isEmpty(phoneList)) {
                        return '';
                    }

                    const phone: RegistrationPhone = phoneList.find(e => e.type.type === 'WHATSAPP');
                    return !ObjectUtil.isNull(phone) ? phone.number : '';
                };

                const recipient: RegistrationClient = await this._clientService.getOne(this.client.id).toPromise();
                const recipientPhoneAux: string = getRecipientPhone(recipient.person.phoneList);

                const keyLocalStorage: string = '@GIPIClientCardMessage';
                const savedMessage: string = WhatsappContactDialogComponent.getMessage(keyLocalStorage);

                const whatsappContact: WhatsappContact = new WhatsappContact();
                whatsappContact.recipientName = this.client.name;
                whatsappContact.recipientPhone = recipientPhoneAux;
                whatsappContact.message = !StringUtil.isEmpty(savedMessage) ? savedMessage : '';

                this._dialogService.open({
                    componentOrTemplateRef: WhatsappContactDialogComponent,
                    data: whatsappContact,
                    width: '55rem',
                    maxHeight: '55rem',
                }).afterClosed().toPromise().then((message: string) => {
                    if (!StringUtil.isEmpty(message)) {
                        WhatsappContactDialogComponent.saveMessage(keyLocalStorage, message);
                    }
                });
            }
        } catch (e) {
            this.handleError(e);
        }
    }

}
