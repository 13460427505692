import { FinancialCancelCheckDTO } from "./cancel-check.dto";

export class FinancialPaymentByCheckDTO {

    checkIssuedList: FinancialCancelCheckDTO[];

    checkReceivedList: FinancialCancelCheckDTO[];

    checkWithMovementList: FinancialCancelCheckDTO[];

    numberDocumentPaymentCheckIssued: string[];

    numberDocumentPaymentCheckReceived: string[];

}
