import { catchError, map, takeUntil } from 'rxjs/operators';
import { SalePaths } from '@gipi-paths/sale.paths';
import { GIPIAbstractCrudService, GIPIBaseService, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class SaleCityService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(SalePaths.city, 'v1', baseService);
        this.baseService = baseService;
    }
    findByState(state) {
        return this.baseService.httpClient.get(this.url(`find-by-state/${state.id}`), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    findByIbgeCode(ibgeCode) {
        return this.baseService.httpClient.get(this.url(`find-by-ibge-code/${ibgeCode}`), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    findByValue(value, page, size, sort, version, ibgeCodeState) {
        let lValue = value;
        if (value) {
            lValue = StringUtil.removeAccents(value).trim();
        }
        else {
            lValue = '';
        }
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        let lSort = '';
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.field)) {
            lSort = `&sort=${sort.field},${sort.direction}`;
        }
        if ((!ibgeCodeState) || (ibgeCodeState && (ibgeCodeState <= 0))) {
            ibgeCodeState = 0;
        }
        return this.baseService.httpClient.get(this.url(`find-by-value?ibgeCodeState=${ibgeCodeState}&page=${page}&size=${size}${lSort}&value=${lValue}`, version), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
SaleCityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SaleCityService_Factory() { return new SaleCityService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: SaleCityService, providedIn: "root" });
