import { AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';

import { environment } from '../../environments/environment';

import { AbstractCrudComponent, APP_MESSAGES, ArrayUtil, ConfirmationService, INJECTOR, InputComponent, InputCurrencyComponent, ObjectUtil, SelectEntityComponent } from '@gipisistemas/ng-core';
import { BaseModel } from './models/base.model';
import { BaseFilterDTO } from './models/dto/base-filter.dto';
import { BaseCrudService } from './services/base-crud.service';
import { CustomMessageService } from './services/custom-message.service';

export abstract class BaseCrudComponent<T extends BaseModel, F extends BaseFilterDTO> extends AbstractCrudComponent<T, F> implements OnInit, AfterViewInit {

    @ViewChild('firstInput', { static: false }) firstInput: InputComponent | SelectEntityComponent<any> | InputCurrencyComponent;

    isView: boolean = false;

    protected constructor(
        protected service: BaseCrudService<T, F>,
        messageService: CustomMessageService,
        confirmationService: ConfirmationService,
        router: Router,
        activatedRoute: ActivatedRoute,
    ) {
        super(service, messageService, confirmationService, router, activatedRoute);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.activatedRoute.url.subscribe(url => {
            const urlView: UrlSegment[] = url.filter(urlSeg => urlSeg.path === 'view');
            if (!ArrayUtil.isEmpty(urlView)) {
                this.isView = true;
            }
        });
    }

    ngAfterViewInit(): void {
        this.setFocus();
    }

    setFocus(): void {
        if (this.firstInput) {
            setTimeout(() => this.firstInput._elementRef.nativeElement.focus());
        }
    }

    protected newEntity(): T {
        return null;
    }

    preSave(isNewUser?: boolean): void {
        super.preSave();
        if (ObjectUtil.isNewModel(this.service.getCurrentCompany()) && ObjectUtil.isNull(isNewUser)) {
            throw new Error('O campo Empresa é obrigatório');
        } else if (ObjectUtil.isNull(isNewUser)) {
            this.entity.companyId = Number(this.service.getCurrentCompany().id);
        }
    }

    return(): void {
        if (!this.isView) {
            this.confirmationService.confirm({
                title: 'Confirmação',
                message: INJECTOR.get(APP_MESSAGES).RETURN_CONFIRMATION,
                accept: () => {
                    this.router.navigateByUrl(this.service.returnPath, { skipLocationChange: true })
                        .then(() => this.router.navigate([this.service.returnPath]));
                }
            });
        } else {
            this.router.navigateByUrl(this.service.returnPath, { skipLocationChange: true })
                .then(() => this.router.navigate([this.service.returnPath]));
        }
    }

    public getFullPath(key: string): string {
        return environment.bucketBasePath + key;
    }

    getExistInRouterPath(value: string, validateParams: boolean = false): boolean {
        const url: string = this.router.url;
        const pathList: string[] = url.slice(1, url.indexOf('?') + 1).split('/');
        const parameterList: string[] = url.slice(url.indexOf('?') + 1, url.length).split('=');

        if (validateParams) {
            return parameterList.filter(path => path.toUpperCase() === value.toUpperCase()).length > 0;
        } else {
            return pathList.filter(path => path.toUpperCase() === value.toUpperCase()).length > 0;
        }
    }

}
