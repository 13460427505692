<gipi-abstract-form id="dialogDeleteInstallment"
                    name="dialogDeleteInstallment"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogDeleteInstallment">
        Deletar conta
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">

        <div fxLayout="column"
             fxLayoutGap="5px">
            <label class="sub-title"> {{ billInstallmentData.bill.type === 'RECEIVABLE' ? 'Cliente' : 'Fornecedor' }}
            </label>
            <label class="card-text"> {{ getNamePerson() }} </label>
        </div>

        <div fxLayoutAlign="space-between"
             fxLayout.xl="row"
             fxLayout.gt-xs="column"
             fxLayoutGap.xl="5px"
             fxLayoutGap.gt-xs="16px">
            <div fxLayout="column"
                 fxLayoutGap="5px">
                <label class="sub-title"> Descrição </label>
                <label class="card-text">
                    {{ billInstallmentData.description.length > 60
                    ? (billInstallmentData.description | slice:0:60) + '...'
                    : billInstallmentData.description }}
                </label>
            </div>

            <div fxLayout="row"
                 fxLayoutGap="5px"
                 fxLayoutAlign="space-between"
                 fxFlexFill
                 fxFlex="30">
                <div fxLayout="column"
                     fxLayoutGap="5px">
                    <label class="sub-title"> N° Documento </label>
                    <label class="card-text"> {{ billInstallmentData.documentNumber }} </label>
                </div>

                <div fxLayout="column"
                     fxLayoutGap="5px">
                    <label class="sub-title"> Valor </label>
                    <label class="card-text"> {{ billInstallmentData.amount | currency:'BRL':'symbol':'1.2-2' }}
                    </label>
                </div>
            </div>
        </div>

        <div class="message"
             fxLayout="column"
             fxLayoutAlign="center center"
             fxFlexFill
             *ngIf="billInstallmentList.length > 1">
            <div> Atenção! Esta conta possui vínculo com outras parcelas </div>
        </div>

        <div fxLayout="column"
             fxLayoutAlign="center start"
             fxFlex="10px"
             *ngIf="billInstallmentList.length > 1"
             style="margin-bottom: 0px !important;">
            <label fxFlexFill
                   class="sub-title">
                Como deseja deletar?
            </label>
        </div>

        <div fxLayout="column"
             fxLayoutAlign="center"
             *ngIf="billInstallmentList.length > 1"
             style="min-height: 150px !important; margin-bottom: 0px !important;">
            <mat-radio-group fxLayout="column"
                             fxLayoutAlign="center"
                             fxLayoutGap="1px"
                             fxFlexFill
                             [(ngModel)]="deleteIntallmentSelected"
                             (change)="validateOptionRadioButton()"
                             [disabled]="isLoad">
                <mat-radio-button value="0"> Somente esta</mat-radio-button>
                <mat-radio-button value="1"> Esta, e as anteriores</mat-radio-button>
                <mat-radio-button value="2"> Esta, e as futuras</mat-radio-button>
                <mat-radio-button value="3"> Todas as contas não pagas</mat-radio-button>
            </mat-radio-group>
        </div>

        <div fxLayout="row"
             fxLayoutAlign="end center"
             fxLayoutAlign.lt-md="start"
             *ngIf="deleteIntallmentSelected !== '0' && billInstallmentList.length > 1">
            <label class="options-text"
                   [ngStyle]="isLoad ? {'cursor': 'not-allowed'} : {'cursor': 'pointer'}"
                   (click)="options = (isLoad ? false : !options)">
                {{ !options ? 'Exibir parcelas' : 'Ocultar parcelas' }}
            </label>
            <itss-button [icon]="options ? 'expand_less' : 'expand_more'"
                         color="primary"
                         [disabled]="isLoad"
                         type="icon"
                         (click)="options = (isLoad ? false : !options)">
            </itss-button>
        </div>

        <div *ngIf="options && deleteIntallmentSelected !== '0' && billInstallmentList.length > 1"
             fxFlexFill>
            <itss-table [loading]="loading"
                        [paginator]="true"
                        [backendPagination]="false"
                        [paginatorLength]="billInstallmentList.length"
                        [paginatorPageSize]="4"
                        [columns]="createTableColumnsBillInstallments()"
                        [data]="billInstallmentList">
            </itss-table>
        </div>

    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutGap="16px"
         fxLayoutAlign="end end">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnSave"
                     [disabled]="isLoad || loading"
                     [loading]="loading"
                     fxFlex="30"
                     (click)="confirm()">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     fxFlex="30"
                     (click)="close('REMARK_SELECTED')">
        </gipi-button>
    </div>
</gipi-abstract-form>

<ng-template #checkboxTemplate
             let-entity>
    <itss-checkbox color="primary"
                   [(ngModel)]="entity.selected"
                   (change)="select(entity, true, true, true)"
                   [disabled]="isLoad"
                   fxFlex="4"
                   fxFlexFill></itss-checkbox>
</ng-template>
<ng-template #checkAllTemplate>
    <itss-checkbox color="primary"
                   [(ngModel)]="selected"
                   [(indeterminate)]="indeterminate"
                   (change)="checkAll()"
                   [disabled]="isLoad"
                   fxFlex="4"
                   fxFlexFill></itss-checkbox>
</ng-template>
<ng-template #statusTemplate
             let-entity>
    <itss-icon class="icon-table"
               icon="fiber_manual_record"
               [color]="setColorStatus(entity)"
               [tooltip]="setHintStatus(entity)"></itss-icon>
</ng-template>
