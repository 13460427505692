import { SalePhoneType } from '@gipi-sale/phone-type/models/phone-type.model';
import { BaseModel } from '@gipi-shared/models/base.model';

export class SalePhone extends BaseModel {

    type: SalePhoneType;

    number: string;

    comments: string;

    useInInvoice: boolean;

    /** @Transient */
    key: number;

    /** @Transient */
    disabled: boolean;

}
