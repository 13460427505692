import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/services/custom-authentication.service";
export class RegistrationConsultCnpjService extends BaseCrudService {
    constructor(http, authenticationService) {
        super(RegistrationPaths.consultCnpj, http, authenticationService);
    }
    consultCnpj(cnpj) {
        return this.http.get(this.url(cnpj)).pipe(map(this.mapper), catchError(this.handleError));
    }
    consultCompanyData(cnpj) {
        return this.http.get(this.url(`consult-company-data/${cnpj}`)).pipe(map(this.mapper), catchError(this.handleError));
    }
}
RegistrationConsultCnpjService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationConsultCnpjService_Factory() { return new RegistrationConsultCnpjService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CustomAuthenticationService)); }, token: RegistrationConsultCnpjService, providedIn: "root" });
