import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';
import { GIPIUuid } from '@gipisistemas/ng-core';

export class RegistrationApplicationFilterDTO extends GIPIBaseAbstractFilterModel {

    enabled: boolean;

    applicationsIdsExcluded: GIPIUuid[];

}
