import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FinancialPaths } from '@gipi-paths/financial.paths';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { FinancialReasonRefundFilterDTO } from '../models/dto/reason-refund-filter.dto';
import { FinancialReasonRefund } from '../models/reason-refund.model';

@Injectable({ providedIn: 'root' })
export class ReasonRefundService extends BaseCrudService<FinancialReasonRefund, FinancialReasonRefundFilterDTO> {

    constructor(
        http: HttpClient,
        authenticationService: CustomAuthenticationService
    ) {
        super(FinancialPaths.reasonRefund, http, authenticationService);
    }

}
