import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { OAuthUserFilterDTO } from '@gipi-financial/user/models/dto/user-filter.dto';
import { OAuthUser } from '@gipi-financial/user/models/user.model';
import { OAuthUserPublicService } from '@gipi-financial/user/services/user-public.service';
import { BaseCrudComponent } from '@gipi-shared/base-crud.component';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { ConfirmationService, EmailUtil, MessageService, ObjectUtil, PasswordUtil, StringUtil } from '@gipisistemas/ng-core';

@Component({
    templateUrl: './confirm-access.component.html',
    styleUrls: ['./confirm-access.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmAccessComponent extends BaseCrudComponent<any, OAuthUserFilterDTO> implements OnInit, AfterViewInit {

    @ViewChild('passwordRef', { static: false, read: ElementRef }) passwordRef: ElementRef;

    @ViewChild('passwordConfirmationRef', { static: false, read: ElementRef }) passwordConfirmationRef: ElementRef;

    token: string;

    password: string;

    passwordConfirmation: string;

    user: OAuthUser = new OAuthUser();

    hasAccessControlEnabled: boolean = false;

    isViewPassword: boolean = false;

    isViewPasswordConfirmation: boolean = false;

    passwordFocused: boolean = false;

    constructor(
        protected userPublicService: OAuthUserPublicService,
        protected messageService: MessageService,
        protected confirmationService: ConfirmationService,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        private _authenticationService: CustomAuthenticationService,
        private _changeDetectorRef: ChangeDetectorRef,
    ) {
        super(userPublicService, messageService, confirmationService, router, activatedRoute);
        localStorage.removeItem('token');
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();
        this.token = this.activatedRoute.snapshot.params.token;
        this._getUserByToken();
    }

    private _getUserByToken(): void {
        try {
            if (StringUtil.isEmpty(this.token)) {
                return;
            }

            this.loading = true;

            this.userPublicService.getByInvitationToken(this.token).toPromise().then(user => {
                this.hasAccessControlEnabled = user.accessControlList.filter(uac => uac.status === 'ENABLED').length > 0;
                user.accessControlList.filter(uac => uac.status === 'NOT_CONFIRMED').map(uac => uac.status = 'ENABLED');
                this.user = user;
                this.loading = false;

                this._changeDetectorRef.detectChanges();
            }, error => {
                setTimeout(() => this.returnToLogin(), 1000);
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    protected newEntity(): any {
        return new OAuthUser();
    }

    protected getPath(): string {
        return `/oauth/confirm-access/${this.token}`;
    }

    public async save(): Promise<void> {
        try {
            if (this._validate()) {
                this.loading = true;

                if (this.hasAccessControlEnabled) {
                    this.user.password = this.passwordConfirmation.trim();

                    this._authenticationService.login(this.user).toPromise().then(() => {
                        this._confirmAccess();
                    }).catch(() => {
                        this.loading = false;
                        this.handleError('Credenciais inválidas');
                    });
                } else {
                    this.user.password = this.password;
                    await this._confirmAccess();
                }
            }
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public keydownConfirmAccess(): void {
        if (this._isValid()) {
            this.save();
        }
    }

    private _validate(): boolean {
        if (StringUtil.isEmpty(this.user.username) || !EmailUtil.isValid(this.user.username.toLowerCase())) {
            this.addWarningMessage('Campo "E-mail" inválido');
            return false;
        }
        if (StringUtil.isEmpty(this.user.name)) {
            this.addWarningMessage('Campo "Nome" inválido');
            return false;
        }

        if (this.hasAccessControlEnabled) {
            if (StringUtil.isEmpty(this.passwordConfirmation)) {
                this.addWarningMessage('Campo "Confirmação senha" é obrigatório e não foi informado');
                return false;
            }
        } else {
            if (StringUtil.isEmpty(this.password)) {
                this.addWarningMessage('Campo "Senha" é obrigatório e não foi informado');
                return false;
            } else if (StringUtil.isEmpty(this.passwordConfirmation)) {
                this.addWarningMessage('Campo "Confirmação senha" é obrigatório e não foi informado');
                return false;
            } else if (!PasswordUtil.isValidMinLength(this.password)) {
                this.addWarningMessage('A senha deve conter no mínimo 6 caracteres');
                return false;
            } else if (!PasswordUtil.isValidMaxLength(this.password)) {
                this.addWarningMessage('A senha deve conter no máximo 15 caracteres');
                return false;
            } else if (!PasswordUtil.hasUppercase(this.password)) {
                this.addWarningMessage('A senha deve conter no mínimo 1 caractere maiúsculo');
                return false;
            } else if (!PasswordUtil.hasLowercase(this.password)) {
                this.addWarningMessage('A senha deve conter no mínimo 1 caractere minúsculo');
                return false;
            } else if (!PasswordUtil.hasNumber(this.password)) {
                this.addWarningMessage('A senha deve conter no mínimo 1 número');
                return false;
            } else if (PasswordUtil.hasBackspace(this.password)) {
                this.addWarningMessage('A senha não deve conter espaços');
                return false;
            } else if (this.password !== this.passwordConfirmation) {
                this.addWarningMessage('A senha e a confirmação da senha devem ser iguais');
                return false;
            }
        }

        return true;
    }

    private _isValid(): boolean {
        if (this.hasAccessControlEnabled) {
            return !StringUtil.isEmpty(this.passwordConfirmation);
        } else {
            return (
                !StringUtil.isEmpty(this.password) &&
                !StringUtil.isEmpty(this.passwordConfirmation) &&
                (this.password === this.passwordConfirmation)
            );
        }
    }

    private async _confirmAccess(): Promise<void> {
        try {
            const photoUser: string = !StringUtil.isEmpty(this.user.photo) ? this.user.photo : '';
            const userConfirmed: OAuthUser = await this.userPublicService.confirmAccess(this.token, this.user.password, this.user.name, photoUser).toPromise().catch(error => {
                this.loading = false;
                this.handleError(error);
                return null;
            });
            if (!ObjectUtil.isNull(userConfirmed)) {
                this.returnToLogin();
            }

            this._authenticationService.revokeToken(this._authenticationService.tokenValue).subscribe(() => this._authenticationService.removeToken());
        } catch (e) {
            throw new Error(e);
        }
    }

    public returnToLogin(): void {
        this.router.navigate(['/oauth/login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
    }

}
