import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { RegistrationPerson } from '@gipi-registration/person/models/person.model';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { RegistrationIntegrationConsultCnpjDTO } from '../models/dto/integration-consult-cnpj.dto';

@Injectable({ providedIn: 'root' })
export class RegistrationConsultCnpjService extends BaseCrudService<RegistrationPerson, any> {

    constructor(
        http: HttpClient,
        authenticationService: CustomAuthenticationService
    ) {
        super(RegistrationPaths.consultCnpj, http, authenticationService);
    }

    consultCnpj(cnpj: string): Observable<RegistrationPerson> {
        return this.http.get(this.url(cnpj)).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

    consultCompanyData(cnpj: string): Observable<RegistrationIntegrationConsultCnpjDTO> {
        return this.http.get(this.url(`consult-company-data/${cnpj}`)).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

}
