import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SharedModule } from '@gipisistemas/ng-core';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { EmailFormComponent } from './components/email-form/email-form.component';
import { PersonFormComponent } from './components/person-form/person-form.component';
import { PhoneFormComponent } from './components/phone-form/phone-form.component';
import { SocialNetworkFormComponent } from './components/social-network-form/social-network-form.component';

@NgModule({
    declarations: [
        AddressFormComponent,
        EmailFormComponent,
        PhoneFormComponent,
        SocialNetworkFormComponent,
        PersonFormComponent,
    ],
    imports: [
        SharedModule,
        MatDividerModule,
        MatTooltipModule,
    ],
    exports: [
        AddressFormComponent,
        EmailFormComponent,
        PhoneFormComponent,
        SocialNetworkFormComponent,
        PersonFormComponent,
    ],
})
export class PersonModule { }
