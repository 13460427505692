<div mat-dialog-title>
    Autorização
</div>

<div mat-dialog-content
     fxLayout="column">
    <div fxLayout="row"
         fxLayoutAlign="start"
         fxLayoutGap="25px"
         fxLayout.lt-sm="column">
        <itss-input label="Usuário"
                    placeholder="Usuário"
                    [(ngModel)]="username"
                    [disabled]="loading"
                    fxFlex>
        </itss-input>
    </div>
    <div fxLayout="row"
         fxLayoutAlign="start"
         fxLayoutGap="25px"
         fxLayout.lt-sm="column">
        <itss-input label="Senha"
                    placeholder="Senha"
                    [type]="isViewPassword ? 'text' : 'password'"
                    [(ngModel)]="password"
                    [min]="6"
                    [max]="15"
                    [maxlength]="15"
                    [disabled]="loading"
                    fxFlex>
            <ng-template #suffix>
                <itss-button [svgIcon]="isViewPassword ? 'eye' : 'eye_off'"
                             color="primary"
                             type="icon"
                             [tooltip]="isViewPassword ? 'Ocultar senha' : 'Exibir senha'"
                             (click)="isViewPassword = !isViewPassword">
                </itss-button>
            </ng-template>
        </itss-input>
    </div>
</div>

<div mat-dialog-actions
     fxLayout="row"
     fxLayoutAlign="start start"
     fxLayoutGap="16px">
    <itss-button label="Autorizar"
                 color="primary"
                 type="spinner"
                 [loading]="loading"
                 [disabled]="loading"
                 (click)="validateAuthorization()"
                 fxFlex.lt-md="100">
    </itss-button>
    <itss-button label="Cancelar"
                 color="primary"
                 type="stroked"
                 (click)="close()"
                 fxFlex.lt-md="100">
    </itss-button>
</div>