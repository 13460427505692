import { BaseModel } from '@gipi-shared/models/base.model';
import { RegistrationGender, RegistrationGenderEnum } from '../enums/gender.enum';

export class RegistrationNaturalPerson extends BaseModel {

    /**
     * @Required CPF
     * @Min 11 characters
     * @Max 11 characters
     */
    cpf: string;

    /**
     * Número de documento
     * @Min 0 characters
     * @Max 20 characters
     */
    documentNumber: string;

    /** Gênero --- [ FEMALE, MALE ] */
    gender: RegistrationGenderEnum | RegistrationGender;


}
