import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { BaseFilterDTO } from '@gipi-shared/models/dto/base-filter.dto';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { GIPIUuid } from '@gipisistemas/ng-core';
import { FinancialBillet } from '../models/billet.model';

@Injectable({ providedIn: 'root' })
export class FinancialBilletFinancialService extends BaseCrudService<FinancialBillet, BaseFilterDTO> {

    constructor(
        http: HttpClient,
        authenticationService: CustomAuthenticationService
    ) {
        super(FinancialPaths.billet, http, authenticationService);
    }

    download(installmentIds: GIPIUuid[], generatePix: boolean): Observable<any> {
        const params = new HttpParams().set('generatePix', String(generatePix));
        return this.http.post(this.url('download'), installmentIds, {
            ...this.options(params),
            observe: 'response',
            responseType: 'blob' as 'json'
        }).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

    generateBillet(billInstallment: FinancialBillInstallment, generatePdf: boolean): Observable<any> {
        const httpParams: HttpParams = new HttpParams().append('generatePdf', String(generatePdf));
        return this.http.post(this.url('generate-billet'), billInstallment, { ...this.options(httpParams), observe: 'response', responseType: 'blob' as 'json' }).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

    generateBilletAll(billInstallmentList: FinancialBillInstallment[], generatePdf: boolean): Observable<any> {
        const httpParams: HttpParams = new HttpParams().append('generatePdf', String(generatePdf));
        return this.http.post(this.url('generate-billet-all'), billInstallmentList, { ...this.options(httpParams), observe: 'response', responseType: 'blob' as 'json' }).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

    generateBilletForBillInstallmentWithoutBillet(): Observable<any> {
        return this.http.post(this.url('generate-billet-for-bill-installment-without-billet'), null, this.options()).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

    downloadBillet(billInstallmentList: GIPIUuid[]): Observable<any> {
        return this.http.post(this.url('download'), billInstallmentList, { observe: 'response', responseType: 'blob' as 'json' }).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

}
