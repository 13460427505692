import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class GlobalFileService {
    constructor(http) {
        this.http = http;
    }
    upload(target, key, file, cannedAcl = 'Private') {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('key', key);
        formData.append('cannedAcl', cannedAcl);
        return this.http.post(`api/${target}/file/upload`, formData, { responseType: 'text' });
    }
    download(target, key) {
        const formData = new FormData();
        formData.append('key', key);
        return this.http.post(`api/${target}/file/download`, formData, {
            observe: 'response',
            responseType: 'blob'
        });
    }
    delete(target, key) {
        const formData = new FormData();
        formData.append('key', key);
        return this.http.post(`api/${target}/file/delete`, formData);
    }
}
GlobalFileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GlobalFileService_Factory() { return new GlobalFileService(i0.ɵɵinject(i1.HttpClient)); }, token: GlobalFileService, providedIn: "root" });
