import { SalePaths } from '@gipi-paths/sale.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class SaleCountryService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(SalePaths.country, 'v1', baseService);
        this.baseService = baseService;
    }
}
SaleCountryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SaleCountryService_Factory() { return new SaleCountryService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: SaleCountryService, providedIn: "root" });
