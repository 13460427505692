import { TypePerson, TypePersonEnum } from '@gipi-shared/enums/type-person.enum';
import { BaseModel } from '@gipi-shared/models/base.model';
import { RegistrationPersonStatus, RegistrationPersonStatusEnum } from '../enums/person-status.enum';
import { RegistrationAddress } from './address.model';
import { RegistrationEmail } from './email.model';
import { RegistrationLegalPerson } from './legal-person.model';
import { RegistrationNaturalPerson } from './natural-person.model';
import { RegistrationPhone } from './phone.model';
import { RegistrationSocialNetwork } from './social-network.model';

export class RegistrationPerson extends BaseModel {

    /** Lista de endereços */
    addressList: RegistrationAddress[];

    /** Data de abertura ou nascimento */
    birthDate: Date;

    /** Observação */
    comments: string;

    /** Lista de e-mails */
    emailList: RegistrationEmail[];

    /**
     * Nome fantasia
     * @Min 0 characters
     * @Max 60 characters
     */
    fantasyName: string;

    /** Pessoa jurídica */
    legalPerson: RegistrationLegalPerson;

    /**
     * @Required Nome
     * @Min 5 characters
     * @Max 60 characters
     */
    name: string;

    /** Pessoa física */
    naturalPerson: RegistrationNaturalPerson;

    /** Lista de telefones */
    phoneList: RegistrationPhone[];

    /**
     * Endereço do site
     * @Min 0 characters
     * @Max 70 characters
     */
    site: string;

    /** Lista de redes sociais */
    socialNetworkList: RegistrationSocialNetwork[];

    /** @Transient */
    typePerson: TypePersonEnum | TypePerson;

    /** @Transient */
    personStatus: RegistrationPersonStatusEnum | RegistrationPersonStatus;

    /** @Transient */
    key: number;

    constructor() {
        super();
        this.phoneList = [];
        this.addressList = [];
        this.emailList = [];
        this.socialNetworkList = [];
    }

}
