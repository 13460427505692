import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { FinancialClient } from '@gipi-financial/client/models/client.model';
import { FinancialUnlockBlockedClientEnum } from '@gipi-financial/configuration/enums/unlock-blocked-client.enum';
import { FinancialConfiguration } from '@gipi-financial/configuration/models/configuration.model';
import { FinancialConfigurationService } from '@gipi-financial/configuration/services/configuration.service';
import { WppFormatTypes, WppOptionMessage, wppOptionMessageList, WppParamTypes } from '@gipi-financial/configuration/shared/wpp-option-messages';
import { FinancialPerson } from '@gipi-financial/person/models/person.model';
import { FinancialPostingCategory } from '@gipi-financial/posting-category/models/posting-category.model';
import { FinancialPostingCategoryService } from '@gipi-financial/posting-category/services/posting-category.service';
import { NotificationCustomMessagingSubType } from '@gipi-notification/custom-messaging-configuration/enums/custom-messaging-subtype.enum';
import { NotificationCustomMessagingType } from '@gipi-notification/custom-messaging-configuration/enums/custom-messaging-type.enum';
import { NotificationCustomMessagingConfiguration } from '@gipi-notification/custom-messaging-configuration/models/custom-messaging-configuration.model';
import { NotificationCustomMessagingConfigurationService } from '@gipi-notification/custom-messaging-configuration/services/custom-messaging-configuration.service';
import { NotificationNotificationSubTypeEnum } from '@gipi-notification/invoice-integrations/enums/notification-subtype.enum';
import { FindClientDialogComponent } from '@gipi-registration/client/components/find-client-dialog/find-client-dialog.component';
import { RegistrationClientConsultDTO } from '@gipi-registration/client/models/dto/client-consult.dto';
import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { SessionStorageService } from '@gipi-shared/services/session-storage.service';
import { AbstractComponent, ArrayUtil, ConfirmationService, DialogService, GIPIPageModel, ObjectUtil, PageDTO, StringUtil } from '@gipisistemas/ng-core';

@Component({
    templateUrl: './configuration-dialog.component.html',
    styleUrls: ['./configuration-dialog.component.scss']
})
export class ConfigurationDialogComponent extends AbstractComponent implements OnInit {

    // For tab WhatsApp
    @ViewChild('wppTextEditor', { static: false }) wppTextEditorRef: ElementRef<HTMLTextAreaElement>;

    public wppOptionMessageList: WppOptionMessage[] = [];

    // For configuration
    public configuration: FinancialConfiguration = new FinancialConfiguration();

    public unlockBlockedClientEnum: typeof FinancialUnlockBlockedClientEnum = FinancialUnlockBlockedClientEnum;

    public isLoad: boolean = false;

    postingCategoryForAdditionToCreditFindByValueFn = (value: string, page: number) => {
        return this._findPostingCategory(value, page, 'CREDIT');
    };
    postingCategoryForAdditionToDebitFindByValueFn = (value: string, page: number) => {
        return this._findPostingCategory(value, page, 'DEBIT');
    };

    defaultClientCreditCategoryToCreditFindByValueFn = (value: string, page: number) => {
        return this._findPostingCategory(value, page, 'CREDIT');
    };
    defaultClientCreditCategoryToDebitFindByValueFn = (value: string, page: number) => {
        return this._findPostingCategory(value, page, 'DEBIT');
    };

    postingCategoryForReceivementCardAdministratorFindByValueFn = (value: string, page: number) => {
        return this._findPostingCategory(value, page, 'CREDIT');
    };

    postingCategoryFn = (obj: FinancialPostingCategory) => `${obj.fullCode} - ${obj.description}`;

    constructor(
        protected messageService: CustomMessageService,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        private _dialogService: DialogService,
        private _confirmationService: ConfirmationService,
        private _sessionStorageService: SessionStorageService<FinancialConfiguration>,
        private _postingCategoryService: FinancialPostingCategoryService,
        private _customMessagingConfigurationService: NotificationCustomMessagingConfigurationService,
        private _configurationService: FinancialConfigurationService,
        public dialogRef: MatDialogRef<ConfigurationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        super(messageService, router, activatedRoute);
        this.dialogRef.disableClose = true;
        this._findConfiguration();
        this._getWppOptionMessageList();
    }

    async ngOnInit(): Promise<void> {
        this.isLoad = true;
        super.ngOnInit();

        if (StringUtil.isEmpty(this.configuration.unlockBlockedClient)) {
            this.configuration.unlockBlockedClient = 'NO_SITUATION';
        }

        this.isLoad = false;
    }

    private async _findConfiguration(): Promise<void> {
        try {
            this.isLoad = true;
            this.configuration = await this._configurationService.findEnabled().toPromise().catch(error => {
                this.isLoad = false;
                this.addErrorMessage(error);
                return null;
            });
        } catch (e) {
            this.loading = false;
            this.isLoad = false;
            throw new Error(e);
        }
    }

    private async _findPostingCategory(value: string, page: number, typeOperation: 'DEBIT' | 'CREDIT'): Promise<PageDTO<FinancialPostingCategory>> {
        const result: PageDTO<FinancialPostingCategory> = await this._postingCategoryService.findByValue(value, page, 10, { property: 'fullCode', direction: 'asc' }, typeOperation).toPromise();
        if (!ObjectUtil.isNull(result) && !ArrayUtil.isEmpty(result.content)) {
            result.content = result.content.filter(category => category.enabled && !category.hasChildren);
            return result;
        } else {
            result.content = [];
        }

        return Promise.resolve(result);
    }

    public selectClient(): void {
        try {
            this._dialogService.open({
                componentOrTemplateRef: FindClientDialogComponent,
                data: { byConfiguration: true },
                width: '85%',
                height: '65rem'
            }).afterClosed().toPromise().then((client: RegistrationClientConsultDTO) => {
                if (!ObjectUtil.isNull(client) && this.UUIDIsValid(client.id)) {
                    this.configuration.defaultClientForSales = new FinancialClient(client.id);
                    this.configuration.defaultClientForSales.person = new FinancialPerson();
                    this.configuration.defaultClientForSales.person.name = client.name;

                }
            });
        } catch (e) {
            this.loading = false;
            this.isLoad = false;
            this.handleError(e);
        }
    }

    public confirm(): void {
        try {
            this.isLoad = true;
            this.loading = true;

            if (ObjectUtil.isNull(this.configuration)) {
                this.addWarningMessage('A configuração não foi criada, verifique e tente novamente');
                return;
            }

            const customMessagginList: NotificationCustomMessagingConfiguration[] = this._prepareForSaveCustomMessagingConfig();
            this._customMessagingConfigurationService.saveAll(customMessagginList).toPromise()
                .then(_ => this._saveConfiguration())
                .catch(error => {
                    this.isLoad = false;
                    this.loading = false;
                    this.handleError(error);
                });
        } catch (e) {
            this.isLoad = false;
            this.loading = false;
            this.handleError(e);
        }
    }

    private _saveConfiguration(): void {
        if (ObjectUtil.isNull(this.configuration.graceDays)) {
            this.configuration.graceDays = 0;
        }
        if (ObjectUtil.isNull(this.configuration.daysToNegative)) {
            this.configuration.daysToNegative = 0;
        }
        if (ObjectUtil.isNull(this.configuration.interest)) {
            this.configuration.interest = 0;
        }
        if (ObjectUtil.isNull(this.configuration.fine)) {
            this.configuration.fine = 0;
        }

        this._configurationService.save(this.configuration).toPromise()
            .then((configuration) => {
                if (!ObjectUtil.isNull(configuration) && !ObjectUtil.isNewModel(configuration)) {
                    this._sessionStorageService.set('configuration', configuration)
                    this.close();
                } else {
                    this.loading = false;
                    this.isLoad = false;
                }
            }).catch(error => {
                this.isLoad = false;
                this.loading = false;
                this.handleError(error);
            });
    }

    public close(): void {
        this.dialogRef.close();
    }

    // For tab WhatsApp
    public get hasWppOptionMessageSelected(): boolean {
        const selectedList: WppOptionMessage[] = this.wppOptionMessageList.filter(e => e.selected);
        return !ArrayUtil.isEmpty(selectedList);
    }

    public get wppOptionMessageSelected(): WppOptionMessage {
        if (ArrayUtil.isEmpty(this.wppOptionMessageList)) {
            return null;
        }

        return this.wppOptionMessageList.find(e => e.selected);
    }

    private get _wppTextEditorRef(): HTMLTextAreaElement {
        if (ObjectUtil.isNull(this.wppTextEditorRef)) {
            return null;
        }

        return (this.wppTextEditorRef.nativeElement as HTMLTextAreaElement);
    }

    public selectWppOptionMessage(option: WppOptionMessage): void {
        if (ArrayUtil.isEmpty(this.wppOptionMessageList) || this.isLoad || this.loading) {
            return;
        }

        this.wppOptionMessageList.forEach(e => {
            e.selected = false;
            e.oldMessage = '';
        });

        const selectedOption = this.wppOptionMessageList.find(e => e.key === option.key);
        if (!ObjectUtil.isNull(selectedOption)) {
            selectedOption.selected = true;
            selectedOption.oldMessage = selectedOption.message;
        }
    }

    private _getWppOptionMessageList(): void {
        const baseList: WppOptionMessage[] = ArrayUtil.clone(wppOptionMessageList);

        const filter: GIPIBaseAbstractFilterModel = new GIPIBaseAbstractFilterModel();
        filter.pageNumber = 0;
        filter.pageSize = 300;
        filter.researchField = '';
        this._customMessagingConfigurationService.findAll(filter).toPromise().then((page: GIPIPageModel<NotificationCustomMessagingConfiguration>) => {
            if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                for (const customMessage of page.content) {
                    baseList
                        .filter(e => (customMessage.notificationType === 'ACCOUNT_RECEIVABLE') ? (e.key === customMessage.notificationSubType) : (e.key === customMessage.notificationType))
                        .map(e => {
                            e.message = customMessage.message;
                            e.enabled = customMessage.enabled;
                            e.objectApi = ObjectUtil.clone(customMessage);
                            return e;
                        });
                }
            } else {
                baseList.map(e => e.message = e.defaultMessage);
            }

            this.wppOptionMessageList = ArrayUtil.clone(baseList);
        }).catch(error => {
            this.addErrorMessage(error);
            this.isLoad = false;
            this.loading = false;
        });
    }

    public applyFormat(format: WppFormatTypes): void {
        if (ObjectUtil.isNull(this.wppOptionMessageSelected) || ObjectUtil.isNull(this._wppTextEditorRef)) {
            return;
        }

        const selectionStart: number = this._wppTextEditorRef.selectionStart;
        const selectionEnd: number = this._wppTextEditorRef.selectionEnd;
        const selectedText: string = this.wppOptionMessageSelected.message.substring(selectionStart, selectionEnd);

        if (!StringUtil.isEmpty(selectedText)) {
            const formattedText: string = format + selectedText + format;
            this.wppOptionMessageSelected.message = this.wppOptionMessageSelected.message.substring(0, selectionStart) + formattedText + this.wppOptionMessageSelected.message.substring(selectionEnd);
        } else {
            const formattedText: string = format + format;
            const cursorPointer: number = selectionStart + format.length;
            this.wppOptionMessageSelected.message = this.wppOptionMessageSelected.message.substring(0, selectionStart) + formattedText + this.wppOptionMessageSelected.message.substring(selectionEnd);

            setTimeout(() => this._wppTextEditorRef.setSelectionRange(cursorPointer, cursorPointer));
        }

        this._wppTextEditorRef.focus();
    }

    public insertParam(param: WppParamTypes): void {
        if (ObjectUtil.isNull(this.wppOptionMessageSelected) || ObjectUtil.isNull(this._wppTextEditorRef)) {
            return;
        }

        const selectionStart: number = this._wppTextEditorRef.selectionStart;
        const selectionEnd: number = this._wppTextEditorRef.selectionEnd;
        const cursorPointer: number = selectionStart + param.length;
        this.wppOptionMessageSelected.message = this.wppOptionMessageSelected.message.substring(0, selectionStart) + param + this.wppOptionMessageSelected.message.substring(selectionEnd);

        setTimeout(() => this._wppTextEditorRef.setSelectionRange(cursorPointer, cursorPointer));

        this._wppTextEditorRef.focus();
    }

    public clearFormatting(): void {
        if (ObjectUtil.isNull(this.wppOptionMessageSelected) || ObjectUtil.isNull(this._wppTextEditorRef)) {
            return;
        }

        const selectionStart: number = this._wppTextEditorRef.selectionStart;
        const selectionEnd: number = this._wppTextEditorRef.selectionEnd;
        const selectedText: string = this.wppOptionMessageSelected.message.substring(selectionStart, selectionEnd);

        if (!StringUtil.isEmpty(selectedText)) {
            const cleanedText: string = selectedText.replace(/[*_~`]/g, '');
            this.wppOptionMessageSelected.message = this.wppOptionMessageSelected.message.substring(0, selectionStart) + cleanedText + this.wppOptionMessageSelected.message.substring(selectionEnd);
            setTimeout(() => this._wppTextEditorRef.setSelectionRange(selectionStart, selectionStart + cleanedText.length));
        }

        this._wppTextEditorRef.focus();
    }

    public isValidParamToShow(param: WppParamTypes): boolean {
        if (ObjectUtil.isNull(this.wppOptionMessageSelected)) {
            return false;
        }

        const hasParam: boolean = this.wppOptionMessageSelected.params.includes(param);
        return hasParam;
    }

    public restoreDefaultMessage(restoreAll: boolean = false): void {
        if (
            (!restoreAll && ObjectUtil.isNull(this.wppOptionMessageSelected)) ||
            (restoreAll && ArrayUtil.isEmpty(this.wppOptionMessageList)) ||
            this.isLoad || this.loading
        ) {
            return;
        }

        this._confirmationService.confirm({
            title: 'Confirmação',
            width: '25%',
            message: `Deseja retornar ${restoreAll ? `todas as mensagens` : `a mensagem`} para o padrão?`,
            accept: () => {
                if (restoreAll) {
                    this.wppOptionMessageList.forEach(e => e.message = e.defaultMessage);
                } else {
                    this.wppOptionMessageSelected.message = this.wppOptionMessageSelected.defaultMessage;
                }

                this.wppOptionMessageList.forEach(e => e.selected = false);
            }
        });
    }

    public cancelCustomMessage(): void {
        if (ObjectUtil.isNull(this.wppOptionMessageSelected) || this.isLoad || this.loading) {
            return;
        }

        this.wppOptionMessageSelected.message = this.wppOptionMessageSelected.oldMessage;
        this.wppOptionMessageList.forEach(e => e.selected = false);
    }

    private _prepareForSaveCustomMessagingConfig(): NotificationCustomMessagingConfiguration[] {
        try {
            this.isLoad = true;
            this.loading = true;

            const isNotificationSubType = (key: string): boolean => Object.keys(NotificationNotificationSubTypeEnum).includes(key as NotificationNotificationSubTypeEnum);

            const customMessagingConfigList: NotificationCustomMessagingConfiguration[] = this.wppOptionMessageList.map(wpp => {
                const isSubType: boolean = isNotificationSubType(wpp.key);
                const customMessaging = !ObjectUtil.isNull(wpp.objectApi) ? ObjectUtil.clone(wpp.objectApi) : new NotificationCustomMessagingConfiguration();
                customMessaging.message = !StringUtil.isEmpty(wpp.message) ? wpp.message : wpp.defaultMessage;
                customMessaging.enabled = wpp.enabled;
                customMessaging.notificationType = isSubType ? 'ACCOUNT_RECEIVABLE' : (wpp.key as NotificationCustomMessagingType);
                customMessaging.notificationSubType = isSubType ? (wpp.key as NotificationCustomMessagingSubType) : null;
                return customMessaging;
            });

            return customMessagingConfigList;
        } catch (error) {
            this.loading = false;
            this.isLoad = false;
            this.handleError(error);
        }
    }

}
