import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { ArrayUtil, GIPIUuid, ObjectUtil, PageDTO } from '@gipisistemas/ng-core';
import { FinancialPaths } from '../../../paths/financial.paths';
import { FinancialPaymentFilterDTO } from '../models/dto/payment-filter.dto';
import { FinancialPaymentDTO } from '../models/dto/payment.dto';
import { FinancialPayment } from '../models/payment.model';

@Injectable({ providedIn: 'root' })
export class FinancialPaymentService extends BaseCrudService<FinancialPayment, FinancialPaymentFilterDTO> {

    constructor(
        http: HttpClient,
        authenticationService: CustomAuthenticationService
    ) {
        super(FinancialPaths.payment, http, authenticationService);
    }

    findByBillInstallment(billInstallmentId: GIPIUuid): Observable<FinancialPayment[]> {
        return this.http.get(this.url(`find-by-bill-installment/${billInstallmentId}?page=${0}&size=300`), this.options()).pipe(
            map((json) => {
                const page: PageDTO<FinancialPayment> = this.mapper(json);
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    return page.content;
                }

                return [];
            }),
            catchError(this.handleError)
        );
    }

    pay(paymentDto: FinancialPaymentDTO): Observable<FinancialPayment[]> {
        return this.http.post(this.url('pay'), paymentDto).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

}
