import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthAccessControlService } from '@gipi-financial/access-control/services/access-control.service';
import { FinancialCompanyService } from '@gipi-financial/company/services/company.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ArrayUtil, ConfirmationService, ObjectUtil } from '@gipisistemas/ng-core';
export class ReplicateConfirmationDialogComponent extends AbstractComponent {
    constructor(dialogRef, data, _accessControlService, _confirmationService, _companyService, messageService, router, activatedRoute) {
        super(messageService, router, activatedRoute);
        this.dialogRef = dialogRef;
        this.data = data;
        this._accessControlService = _accessControlService;
        this._confirmationService = _confirmationService;
        this._companyService = _companyService;
        this.companyList = [];
        this.count = 0;
        this.findIndex = 0;
    }
    ngOnInit() {
        this._companyService.findAllEnabled(0, 50).toPromise().then(page => {
            this.companyList = [];
            if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                this.companyList = page.content.filter(company => company.id !== this._accessControlService.getCurrentCompany().id);
            }
        });
        this.accessControlList = this.data;
        this.accessControlList.forEach(accessControl => accessControl.permitSameName = false);
        this.accessControlList.forEach(accessControl => accessControl.persist = true);
    }
    replicate() {
        try {
            if (ObjectUtil.isNewModel(this.company)) {
                throw new Error('Campos obrigatórios (*) não informados');
            }
            this.loading = true;
            this.loadingChange.emit(this.loading);
            if (this.findIndex < this.accessControlList.length) {
                this.existByDescriptionAndCompany();
            }
            else {
                const newAccessControlList = this.accessControlList.filter(accessControl => accessControl.persist);
                newAccessControlList.map((accessControl) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    this._accessControlService.save(yield this.mapNewAccessControl(accessControl)).subscribe(() => {
                        this.count++;
                        if (this.count === newAccessControlList.length) {
                            this.dialogRef.close();
                            this.accessControlList = [];
                            this.addSuccessMessage('Operação realizada com sucesso');
                        }
                    }, error => this.handleError(error));
                }));
            }
        }
        catch (e) {
            this.loading = false;
            this.loadingChange.emit(this.loading);
            this.handleError(e);
        }
    }
    close() {
        this.dialogRef.close(null);
    }
    mapNewAccessControl(accessControl) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let newPermissionList;
            const obj = ObjectUtil.clone(accessControl);
            obj.id = null;
            obj.companyId = this.company.id;
            newPermissionList = obj.permissionList.map(permission => {
                permission.id = null;
                permission.companyId = this.company.id;
                return permission;
            });
            obj.permissionList = newPermissionList;
            yield this._accessControlService.existsByDescriptionAndCompany(accessControl, this.company.id)
                .toPromise()
                .then(exists => {
                if (accessControl.permitSameName && exists) {
                    obj.description = `${accessControl.description} - cópia`;
                }
            });
            return obj;
        });
    }
    existByDescriptionAndCompany() {
        this._accessControlService.existsByDescriptionAndCompany(this.accessControlList[this.findIndex], this.company.id).subscribe(existSameDescription => {
            if (existSameDescription && !this.accessControlList[this.findIndex].permitSameName) {
                this.confirmationCopySameDescription(this.accessControlList[this.findIndex]);
            }
            else {
                this.findIndex++;
                this.replicate();
            }
        });
    }
    confirmationCopySameDescription(accessControl) {
        this._confirmationService.confirm({
            title: 'Confirmação',
            message: `Já existe um perfil com o nome ${accessControl.description}. Deseja fazer uma cópia para a empresa de destino?`,
            accept: () => {
                if (this.findIndex < (this.accessControlList.length - 1)) {
                    this.confirmationAllSameCase(accessControl);
                }
                else {
                    this.accessControlList[this.findIndex].permitSameName = true;
                    this.findIndex = this.accessControlList.length;
                    this.replicate();
                }
                this.close();
            },
            cancel: () => {
                accessControl.persist = false;
                this.findIndex++;
                this.replicate();
                this.close();
            }
        });
    }
    confirmationAllSameCase(accessControl) {
        this._confirmationService.confirm({
            title: 'Confirmação',
            message: 'Fazer o mesmo para todos que encontrar?',
            accept: () => {
                this.accessControlList.forEach(accessControlEl => accessControlEl.permitSameName = true);
                this.findIndex = this.accessControlList.length;
                this.close();
                this.replicate();
            },
            cancel: () => {
                accessControl.permitSameName = true;
                this.findIndex++;
                this.close();
                this.replicate();
            }
        });
    }
}
