import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class RegistrationUnitMeasurementService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(RegistrationPaths.unitMeasurement, 'v1', baseService);
        this.baseService = baseService;
    }
    getDescription(entity) {
        return `(${entity.initials}) - ${(entity.description.length > 50) ? (entity.description.slice(0, 47) + '...') : entity.description}`;
    }
}
RegistrationUnitMeasurementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationUnitMeasurementService_Factory() { return new RegistrationUnitMeasurementService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: RegistrationUnitMeasurementService, providedIn: "root" });
