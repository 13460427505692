import { BaseUser } from '@gipisistemas/ng-core';
export class OAuthUser extends BaseUser {
    constructor() {
        super(...arguments);
        this.enabled = true;
        this.accessControlList = [];
        this.cashier = false;
        /** @Transient */
        this.companyNameList = [];
    }
}
