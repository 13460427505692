import { FinancialBank } from '@gipi-financial/bank/models/bank.model';
import { FinancialFlagCard } from '@gipi-financial/flag-card/models/flag-card.model';
import { BaseModel } from '@gipi-shared/models/base.model';
import { GIPIUuid, UUIDUtil } from '@gipisistemas/ng-core';
import { FinancialBankAccountType, FinancialBankAccountTypeEnum } from '../enums/bank-account-type.enum';
import { FinancialModality } from '../enums/modality.enum';

export class FinancialBankAccount extends BaseModel {

    type: FinancialBankAccountTypeEnum | FinancialBankAccountType;

    description: string;

    // OTHER, CHECKING_ACCOUNT_WITHOUT_INTEGRATION
    openingBalance: number;

    currentBalance: number;

    // OTHER, CHECKING_ACCOUNT_WITHOUT_INTEGRATION
    balanceDate: Date;

    // CREDIT_CARD
    lastFourNumbers: string;

    // CREDIT_CARD
    closingDay: number;

    // CREDIT_CARD
    expirationDay: number;

    bankAccount: FinancialBankAccount;

    // SAVINGS, CHECKING_ACCOUNT_WITHOUT_INTEGRATION
    bank: FinancialBank;

    financialBankAccountId: GIPIUuid;

    // CREDIT_CARD
    flagCard: FinancialFlagCard;

    // CREDIT_CARD, SAVINGS, INVESTMENT, AUTOMATIC_APPLICATION

    // SAVINGS, AUTOMATIC_APPLICATION, CHECKING_ACCOUNT_WITHOUT_INTEGRATION
    modality: FinancialModality;

    /** @Transient */
    selected: boolean = false;

    deleted: boolean = false;

    account: string;

    accountDigit: string;

    agency: string;

    agencyDigit: string;

    modifiedBalance: boolean = false;

    /** @Transient */
    showAmount: boolean = false;

    constructor(id?: GIPIUuid) {
        super();

        if (UUIDUtil.isValid(id)) {
            this.id = id;
        }
    }

}
