import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { AbstractCrudService, FilterDTO } from '@gipisistemas/ng-core';
import { RegistrationLineOfBusiness } from '../models/line-of-business.model';

@Injectable({ providedIn: 'root' })
export class RegistrationLineOfBusinessService extends AbstractCrudService<RegistrationLineOfBusiness, FilterDTO> {

    constructor(http: HttpClient) {
        super(RegistrationPaths.lineOfBusiness, http);
    }

}
