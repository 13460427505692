<gipi-abstract-form id="dialogClient"
                    name="dialogClient"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogClient">
        Ficha do Cliente
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row wrap"
             fxLayoutAlign="end end"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0">
            <itss-input label="CPF"
                        [(ngModel)]="client.cpf"
                        *ngIf="client.cpf"
                        mask="000.000.000-00"
                        [disabled]="true"
                        fxFlex="15"
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="CNPJ"
                        [(ngModel)]="client.cnpj"
                        *ngIf="client.cnpj"
                        mask="00.000.000/0000-00"
                        [disabled]="true"
                        fxFlex="15"
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Cliente"
                        [(ngModel)]="client.name"
                        [disabled]="true"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <gipi-datepicker label="Cliente desde"
                             [(ngModel)]="client.createDate"
                             [disabled]="true"
                             fxFlex="15"
                             fxFlex.lt-md="100">
            </gipi-datepicker>
            <gipi-button label="Entrar em contato"
                         gipi-secondary
                         id="btnWhatsapp"
                         svgIcon="whatsapp"
                         (click)="whatsappContact()"
                         fxFlex="15"
                         fxFlex.lt-md="100">
            </gipi-button>
        </div>

        <div class="card-information card"
             fxLayoutGap="40px">
            <div fxFlex
                 fxLayout="column"
                 fxFlex.lt-md="100"
                 fxFlex.lt-sm="100">
                <div class="information">
                    <span> Bloqueado: </span>
                    <strong> {{ client.blocked ? 'Sim' : 'Não' }} </strong>
                </div>
                <div class="information">
                    <span> Compras: </span>
                    <strong> {{ client.shopping }} </strong>
                </div>
                <div class="information">
                    <span> Atraso médio: </span>
                    <strong> {{ client.averageDelay }} </strong>
                </div>
                <div class="information">
                    <span> Saldo crédito/haver: </span>
                    <strong [style.color]="getColorByAmount(client.creditBalance)">
                        {{ client.creditBalance | currency:'BRL':'symbol':'1.2-2' }}
                    </strong>
                </div>
            </div>

            <div fxFlex
                 fxLayout="column"
                 fxFlex.lt-md="100"
                 fxFlex.lt-sm="100">
                <div class="information">
                    <span> Limite de promissória: </span>
                    <strong [style.color]="getColorByAmount(client.storeCreditLimit)">
                        {{ client.storeCreditLimit | currency:'BRL':'symbol':'1.2-2' }}
                    </strong>
                </div>
                <div class="information">
                    <span> Pendências promissória: </span>
                    <strong [style.color]="getColorByAmount(client.pendingStoreCredit)">
                        {{ client.pendingStoreCredit | currency:'BRL':'symbol':'1.2-2' }}
                    </strong>
                </div>
                <div class="information">
                    <span> Limite de crédito boleto: </span>
                    <strong [style.color]="getColorByAmount(client.billetCreditLimit)">
                        {{ client.billetCreditLimit | currency:'BRL':'symbol':'1.2-2' }}
                    </strong>
                </div>
                <div class="information">
                    <span> Pendências boleto: </span>
                    <strong [style.color]="getColorByAmount(client.pendingBillet)">
                        {{ client.pendingBillet | currency:'BRL':'symbol':'1.2-2' }}
                    </strong>
                </div>
            </div>

            <div fxFlex
                 fxLayout="column"
                 fxFlex.lt-md="100"
                 fxFlex.lt-sm="100">
                <div class="information">
                    <span> Nº de cheques: </span>
                    <strong> {{ client.numberChecks }} </strong>
                </div>
                <div class="information">
                    <span> Limite de crédito cheque: </span>
                    <strong [style.color]="getColorByAmount(client.creditCheckLimit)">
                        {{ client.creditCheckLimit | currency:'BRL':'symbol':'1.2-2' }}
                    </strong>
                </div>
                <div class="information">
                    <span> Cheques a depositar: </span>
                    <strong [style.color]="getColorByAmount(client.checksToDeposit)">
                        {{ client.checksToDeposit | currency:'BRL':'symbol':'1.2-2' }}
                    </strong>
                </div>
                <div class="information">
                    <span> Cheques devolvidos: </span>
                    <strong [style.color]="getColorByAmount(client.bouncedChecks)">
                        {{ client.bouncedChecks | currency:'BRL':'symbol':'1.2-2' }}
                    </strong>
                </div>
            </div>
        </div>

        <div class="card-information"
             fxLayoutGap="16px"
             fxLayoutAlign="space-between stretch">
            <div class="card information bill-information"
                 fxFlex>
                <span> A receber atrasado: </span>
                <strong [style.color]="getColorByAmount(client.amountReceivableInOverdue)">
                    {{ client.amountReceivableInOverdue | currency:'BRL':'symbol':'1.2-2' }}
                </strong>
            </div>
            <div class="card information bill-information"
                 fxFlex>
                <span> A receber hoje: </span>
                <strong [style.color]="getColorByAmount(client.amountReceivable)">
                    {{ client.amountReceivableToday | currency:'BRL':'symbol':'1.2-2' }}
                </strong>
            </div>
            <div class="card information bill-information"
                 fxFlex>
                <span> Recebimentos futuros: </span>
                <strong [style.color]="getColorByAmount(client.amountReceivableToday)">
                    {{ client.amountReceivable | currency:'BRL':'symbol':'1.2-2' }}
                </strong>
            </div>
            <div class="card information bill-information"
                 fxFlex>
                <span> Total a receber: </span>
                <strong [style.color]="getColorByAmount(client.totalAmountReceivable)">
                    {{ client.totalAmountReceivable | currency:'BRL':'symbol':'1.2-2' }}
                </strong>
            </div>
        </div>

        <itss-table #table
                    [paginator]="true"
                    [showPageSizeOptions]="false"
                    [loading]="loading"
                    [columns]="columns"
                    (page)="find($event)"
                    [paginatorLength]="page.totalElements"
                    [paginatorPageIndex]="page.number"
                    [data]="page.content">
        </itss-table>
    </div>

    <div mat-dialog-actions
         fxLayout="row wrap"
         fxFlex.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     mat-dialog-close
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>

<ng-template #actions
             let-data>
    <itss-button tooltip="Dados da venda"
                 type="icon"
                 color="warn"
                 svgIcon="details"
                 (click)="navigateSaleData(data)">
    </itss-button>
</ng-template>
