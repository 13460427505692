import { RegistrationLegalPerson } from '@gipi-registration/person/models/legal-person.model';
import { RegistrationPerson } from '@gipi-registration/person/models/person.model';
import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';

export class RegistrationManufacturer extends GIPIBaseAbstractModel {

    /** @Required Pessoa */
    person: RegistrationPerson;

    constructor() {
        super();
        this.person = new RegistrationPerson();
        this.person.legalPerson = new RegistrationLegalPerson();
    }

}
