export class SalePaths {
    public static audit: string = 'sale/audit/v1';
    public static bankAccount: string = 'sale/bank-account/v1';
    public static bank: string = 'sale/bank/v1';
    public static benefitClub: string = 'sale/benefit-club';
    public static billet: string = 'sale/billet/v1';
    public static cardAdministrator: string = 'sale/card-administrator';
    public static chargeType: string = 'sale/charge-type';
    public static checkReceived: string = 'sale/check-received/v1';
    public static city: string = 'sale/city';
    public static client: string = 'sale/client';
    public static configuration: string = 'sale/configuration';
    public static country: string = 'sale/country';
    public static devolution: string = 'sale/devolution';
    public static employee: string = 'sale/employee/v1';
    public static file: string = 'sale/file';
    public static flagCard: string = 'sale/flag-card/v1';
    public static lineOfBusiness: string = 'sale/line-of-business/v1';
    public static order: string = 'sale/order';
    public static paymentMethod: string = 'sale/payment-method';
    public static postingCategory: string = 'sale/posting-category/v1';
    public static product: string = 'sale/product';
    public static promotion: string = 'sale/promotion';
    public static reasonDevolution: string = 'sale/reason-devolution';
    public static sale: string = 'sale/sale/v1';
    public static state: string = 'sale/state';
    public static unitMeasurement: string = 'sale/unit-measurement/v1';
    public static phoneType: string = 'sale/phone-type';
}

