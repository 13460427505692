<fieldset>
    <h2 class="title-voucher">Recibo de Pagamento</h2>
    <div class="header-voucher">
        <div class="document-copy">
           <strong> 1° Via </strong>
        </div>
        <div class="document-info">
            <span *ngIf="documentNumber"> N°: <strong> {{ documentNumber }} </strong> </span>
            <span> VALOR: <strong> {{ amountPaid | currency:'BRL':'symbol':'1.2-2' }} </strong> </span>
        </div>
    </div>
    <div class="content">
        <p>
            Eu,
            <strong> {{ provider && provider.person ? provider.person.name : '' }} </strong>
            declaro que recebi da empresa
            <strong> {{ (company && company.person) ? company.person.name : '' }} </strong>
            portador do CPF/CNPJ n°
            <strong> {{ getCpfOrCnpjCompany() }} </strong>
            , a importância de
            <strong> {{ getAmountPaidInFull() }} </strong>
            referente à <strong> {{ (description) ? description : comments }} </strong>.
        </p>
        <span *ngIf="(description && comments)">
            <strong>OBS: </strong> {{ comments }}
        </span>
        <p>
            Para maior clareza firmo(amos) o presente recibo para que produza os seus efeitos, dando plena, rasa e
            irrevogável quitação, pelo valor recebido.
        </p>
    </div>
    <p class='text-right date-voucher'>
        {{ formatDateInFull() }}
    </p>
    <div class="footer-voucher center">
        <div class="signature">
            {{ provider && provider.person ? provider.person.name : '' }}
        </div>
        <div class="document">
            CPF/CNPJ: {{ getCpfOrCnpjProvider() }}
        </div>
    </div>
</fieldset>
