import { RegistrationSocialNetworkType } from '@gipi-registration/social-network-type/models/social-network-type.model';
import { BaseModel } from '@gipi-shared/models/base.model';

export class RegistrationSocialNetwork extends BaseModel {

    description: string;

    type: RegistrationSocialNetworkType;

    /** @Transient */
    key: number;

}
