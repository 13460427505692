import { HttpClient } from '@angular/common/http';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { FinancialPaths } from '../../../paths/financial.paths';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/services/custom-authentication.service";
export class FinancialFlagCardService extends BaseCrudService {
    constructor(http, authenticationService) {
        super(FinancialPaths.flagCard, http, authenticationService);
    }
}
FinancialFlagCardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialFlagCardService_Factory() { return new FinancialFlagCardService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CustomAuthenticationService)); }, token: FinancialFlagCardService, providedIn: "root" });
