import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConfirmAccessComponent } from './confirm-access/confirm-access.component';
import { LoginComponent } from './login/login.component';
import { RequestPasswordComponent } from './request-password/request-password.component';
import { ResendRecoveryComponent } from './resend-recovery/resend-recovery.component';

const routes: Routes = [
    {
        path: 'oauth',
        children: [
            {
                path: 'login',
                component: LoginComponent,
            },
            {
                path: 'request-password',
                component: RequestPasswordComponent,
            },
            {
                path: 'resend-recovery/:username',
                component: ResendRecoveryComponent,
            },
            {
                path: 'change-password/:token',
                component: ChangePasswordComponent,
            },
            {
                path: 'confirm-access/:token',
                component: ConfirmAccessComponent,
            },
            {
                path: '',
                redirectTo: 'login',
                pathMatch: 'full'
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class OauthRoutingModule { }
