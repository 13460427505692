import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { FinancialConfiguration } from '@gipi-financial/configuration/models/configuration.model';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { GIPIAbstractCrudService, GIPIBaseService, GIPIPageModel, GIPISortModel, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import { FinancialProviderFilterDTO } from '../models/dto/provider-filter.dto';
import { FinancialProviderSelectDTO } from '../models/dto/provider-select.dto';
import { FinancialProvider } from '../models/provider.model';

@Injectable({ providedIn: 'root' })
export class FinancialProviderService extends GIPIAbstractCrudService<FinancialProvider, FinancialProviderFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(FinancialPaths.provider, 'v1', baseService);
    }

    getDescription(entity: FinancialProviderSelectDTO): string {
        let name: string = entity.name;

        const configuration: FinancialConfiguration = this.baseService.sessionStorageService.get('configuration');
        if (!ObjectUtil.isNull(configuration)) {
            name = configuration.showBusinessNameInReceiptQuery ? (entity.fantasyName ? entity.fantasyName : entity.name) : entity.name;
        }

        if (entity.type === 'NATURAL_PERSON') {
            const cpf: string = !StringUtil.isEmpty(entity.cpfOrCnpj) ? StringUtil.format(entity.cpfOrCnpj, '000.000.000-00') + ' - ' : '';
            return `${cpf}${name}`;
        } else {
            const cnpj: string = !StringUtil.isEmpty(entity.cpfOrCnpj) ? StringUtil.format(entity.cpfOrCnpj, '00.000.000/0000-00') + ' - ' : '';
            return `${cnpj}${name}`;
        }
    }

    findByValue<T = any>(value: string, page: number, size?: number, sort?: GIPISortModel, version?: string): Observable<GIPIPageModel<T>> {
        let lValue: string = value;
        if (value) {
            lValue = StringUtil.removeAccents(value).trim();
        } else {
            lValue = '';
        }
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        let lSort: string = '';
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.field)) {
            lSort = `&sort=${sort.field},${sort.direction}`;
        }

        return this.baseService.httpClient.get(this.url(`find-by-value?migrated=true&page=${page}&size=${size}${lSort}&value=${lValue}`, version), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

}
