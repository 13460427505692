import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PersonAssociationDialogComponent } from '@gipi-registration/person/components/person-association-dialog/person-association-dialog.component';
import { SaleCityService } from '@gipi-sale/city/services/city.service';
import { SaleClient } from '@gipi-sale/client/models/client.model';
import { SaleClientService } from '@gipi-sale/client/services/client.service';
import { SaleCountryService } from '@gipi-sale/country/services/country.service';
import { SaleAddress } from '@gipi-sale/person/models/address.model';
import { SaleEmail } from '@gipi-sale/person/models/email.model';
import { SaleLegalPerson } from '@gipi-sale/person/models/legal-person.model';
import { SaleNaturalPerson } from '@gipi-sale/person/models/natural-person.model';
import { SalePerson } from '@gipi-sale/person/models/person.model';
import { SalePhone } from '@gipi-sale/person/models/phone.model';
import { SaleAddressService } from '@gipi-sale/person/services/address.service';
import { SalePhoneTypeService } from '@gipi-sale/phone-type/services/phone-type.service';
import { SaleStateService } from '@gipi-sale/state/services/state.service';
import { TypePersonEnum } from '@gipi-shared/enums/type-person.enum';
import { APP_MESSAGES, ArrayUtil, DocumentUtil, EmailUtil, GIPIAbstractComponent, GIPIBaseService, GIPISortModel, INJECTOR, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
export class ClientFormDialogComponent extends GIPIAbstractComponent {
    constructor(service, baseService, activatedRoute, dialogRef, data = { typeOperation: 'NEW', client: null }, _countryService, _stateService, _cityService, _addressService, _phoneTypeService) {
        super(baseService, activatedRoute);
        this.service = service;
        this.baseService = baseService;
        this.activatedRoute = activatedRoute;
        this.dialogRef = dialogRef;
        this.data = data;
        this._countryService = _countryService;
        this._stateService = _stateService;
        this._cityService = _cityService;
        this._addressService = _addressService;
        this._phoneTypeService = _phoneTypeService;
        this._phoneTypeList = [];
        this.typeOperation = 'NEW';
        this.client = this._newSaleClient();
        this.typePersonEnum = TypePersonEnum;
        this.phoneOne = '';
        this.phoneTwo = '';
        this.email = '';
        this.countryFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._countryService.findByValue(value, page, 50, new GIPISortModel('name', 'ASC')).toPromise();
            return result;
        });
        this.stateFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._stateService.findByValue(value, page, 50, new GIPISortModel('acronym', 'ASC')).toPromise();
            return result;
        });
        this.cityFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (ObjectUtil.isNull(this.client.person) ||
                ArrayUtil.isEmpty(this.client.person.addressList) ||
                ObjectUtil.isNull(this.client.person.addressList[0].state)) {
                return [];
            }
            const ibgeCode = this.client.person.addressList[0].state.ibgeCode;
            const result = yield this._cityService.findByValue(value, page, 10, new GIPISortModel('name', 'ASC'), 'v1', ibgeCode).toPromise();
            return result;
        });
        this.dialogRef.disableClose = true;
    }
    ngOnInit() {
        super.ngOnInit();
        this._findPhoneType();
        this.typeOperation = this.data.typeOperation;
        if (this.typeOperation !== 'NEW') {
            this.client = this.data.client;
            if (!ObjectUtil.isNull(this.data.client.person.legalPerson)) {
                this.client.person.typePerson = 'LEGAL_PERSON';
            }
            else {
                this.client.person.typePerson = 'NATURAL_PERSON';
            }
            if (!ArrayUtil.isEmpty(this.client.person.emailList)) {
                if (this.client.person.emailList.length >= 1) {
                    this.email = this.client.person.emailList[0].email;
                }
            }
            if (!ArrayUtil.isEmpty(this.client.person.phoneList)) {
                if (this.client.person.phoneList.length >= 1) {
                    this.phoneOne = this.client.person.phoneList[0].number;
                }
                if (this.client.person.phoneList.length >= 2) {
                    this.phoneTwo = this.client.person.phoneList[1].number;
                }
            }
        }
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
    _newSaleClient() {
        const saleClient = new SaleClient();
        saleClient.enabled = true;
        saleClient.blocked = false;
        saleClient.controlClientLimit = false;
        saleClient.creditTypeList = [];
        saleClient.bankSlipLimit = 0;
        saleClient.checkLimit = 0;
        saleClient.storeCreditLimit = 0;
        saleClient.maximumDelay = 0;
        saleClient.ruralProducer = false;
        saleClient.person = new SalePerson();
        saleClient.person.naturalPerson = new SaleNaturalPerson();
        saleClient.person.legalPerson = new SaleLegalPerson();
        saleClient.person.typePerson = 'NATURAL_PERSON';
        saleClient.person.addressList = [new SaleAddress()];
        saleClient.person.addressList[0].type = 'RESIDENTIAL';
        saleClient.person.addressList[0].useInInvoice = true;
        // Cell
        // saleClient.person.phoneList.push(new SalePhone());
        // saleClient.person.phoneList[0].type = this._phoneTypeList[0];
        // Phone
        // saleClient.person.phoneList.push(new SalePhone());
        // saleClient.person.phoneList[0].type = this._phoneTypeList[1];
        // saleClient.person.emailList = [new SaleEmail()];
        return saleClient;
    }
    confirm() {
        try {
            if (!this._isValid()) {
                return;
            }
            const clientAux = ObjectUtil.clone(this.client);
            if (clientAux.person.typePerson === 'LEGAL_PERSON') {
                clientAux.person.naturalPerson = null;
            }
            else {
                clientAux.person.legalPerson = null;
            }
            clientAux.person.emailList = [];
            if (!StringUtil.isEmpty(this.email)) {
                const emailAux = new SaleEmail();
                emailAux.email = this.email;
                clientAux.person.emailList.push(emailAux);
            }
            clientAux.person.phoneList = [];
            if (!StringUtil.isEmpty(this.phoneOne)) {
                const cell = new SalePhone();
                cell.number = this.phoneOne;
                cell.type = this._phoneTypeList[0];
                clientAux.person.phoneList.push(cell);
            }
            if (!StringUtil.isEmpty(this.phoneTwo)) {
                const phone = new SalePhone();
                phone.number = this.phoneTwo;
                phone.type = this._phoneTypeList[1];
                clientAux.person.phoneList.push(phone);
            }
            if (!ArrayUtil.isEmpty(clientAux.person.phoneList)) {
                clientAux.person.phoneList[0].useInInvoice = true;
            }
            this.service.save(clientAux).toPromise().then(entity => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.close(entity);
            }, error => {
                throw new Error(error);
            });
        }
        catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }
    close(client) {
        this.dialogRef.close(client);
    }
    _isValid() {
        if (StringUtil.isEmpty(this.client.person.name)) {
            this.addWarningMessage('Campo nome é obrigatório e não foi informado');
            return false;
        }
        if (StringUtil.isEmpty(this.client.person.addressList[0].zipCode)) {
            this.addWarningMessage('Campo CEP é obrigatório e não foi informado');
            return false;
        }
        if (StringUtil.isEmpty(this.client.person.addressList[0].street)) {
            this.addWarningMessage('Campo endereço é obrigatório e não foi informado');
            return false;
        }
        if (this.client.person.typePerson !== 'FOREIGN_PERSON') {
            if (StringUtil.isEmpty(this.client.person.addressList[0].streetNumber)) {
                this.addWarningMessage('Número do endereço é obrigatório e não foi informado');
                return false;
            }
            if (ObjectUtil.isNull(this.client.person.addressList[0].state)) {
                this.addWarningMessage('Estado é obrigatório e não foi informado');
                return false;
            }
            if (ObjectUtil.isNull(this.client.person.addressList[0].city)) {
                this.addWarningMessage('Cidade é obrigatório e não foi informado');
                return false;
            }
            if (ObjectUtil.isNull(this.client.person.addressList[0].neighborhood)) {
                this.addWarningMessage('Bairro é obrigatório e não foi informado');
                return false;
            }
        }
        if (this.client.person.typePerson === 'LEGAL_PERSON') {
            if (StringUtil.isEmpty(this.client.person.legalPerson.cnpj)) {
                this.addWarningMessage('Campo CNPJ é obrigatório e não foi informado');
                return false;
            }
            if (!DocumentUtil.isValidCnpj(this.client.person.legalPerson.cnpj)) {
                this.addWarningMessage('CNPJ informado é inválido');
                return false;
            }
        }
        else if (this.client.person.typePerson === 'NATURAL_PERSON') {
            if (StringUtil.isEmpty(this.client.person.naturalPerson.cpf)) {
                this.addWarningMessage('Campo CPF é obrigatório e não foi informado');
                return false;
            }
            if (!DocumentUtil.isValidCpf(this.client.person.naturalPerson.cpf)) {
                this.addWarningMessage('CPF informado é inválido');
                return false;
            }
        }
        else if (this.client.person.typePerson === 'FOREIGN_PERSON') {
            if (StringUtil.isEmpty(this.client.person.naturalPerson.documentNumber)) {
                this.addWarningMessage('Campo documento é obrigatório e não foi informado');
                return false;
            }
            if (ObjectUtil.isNull(this.client.person.addressList[0].country)) {
                this.addWarningMessage('País é obrigatório e não foi informado');
                return false;
            }
        }
        return true;
    }
    _findPhoneType() {
        try {
            this._phoneTypeService.findAllEnabled(0, 50).toPromise().then(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    this._phoneTypeList = page.content;
                }
            }, error => {
                throw new Error(error);
            });
        }
        catch (e) {
            this.handleError(e);
        }
    }
    validateTypePerson(typePerson) {
        if (StringUtil.isEmpty(typePerson)) {
            return;
        }
        if (typePerson === 'FOREIGN_PERSON') {
            this.client.person.legalPerson = new SaleLegalPerson();
            this.client.person.naturalPerson = new SaleNaturalPerson();
            for (let i = 0; i < this.client.person.addressList.length; i++) {
                this.client.person.addressList[i].country = null;
                this.client.person.addressList[i].city = null;
                this.client.person.addressList[i].state = null;
            }
        }
    }
    removeSpecialCharactersStateRegistration() {
        if ((this.client.person.typePerson === 'LEGAL_PERSON') && !StringUtil.isEmpty(this.client.person.legalPerson.stateRegistration)) {
            this.client.person.legalPerson.stateRegistration = StringUtil.removeAccents(this.client.person.legalPerson.stateRegistration);
        }
        else if ((this.client.person.typePerson === 'NATURAL_PERSON') && !StringUtil.isEmpty(this.client.person.naturalPerson.documentNumber)) {
            this.client.person.naturalPerson.documentNumber = StringUtil.removeAccents(this.client.person.naturalPerson.documentNumber);
        }
    }
    setNameInFantasyName() {
        if (!StringUtil.isEmpty(this.client.person.name) && StringUtil.isEmpty(this.client.person.fantasyName)) {
            this.client.person.fantasyName = this.client.person.name;
        }
    }
    hasPermissionForOperation() {
        return this.hasPermission('CLIENT_MAKE') && this.hasPermission('CLIENT_UPDATE');
    }
    consultCpfOrCnpj(isBlur = false) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.client.person.typePerson === 'FOREIGN_PERSON') {
                    return;
                }
                const isNaturalPerson = (this.client.person.typePerson === 'NATURAL_PERSON');
                const cpfOrCnpj = isNaturalPerson ? this.client.person.naturalPerson.cpf : this.client.person.legalPerson.cnpj;
                if (isBlur && StringUtil.isEmpty(cpfOrCnpj)) {
                    return;
                }
                if (this.loading ||
                    this.typeOperation !== 'NEW' ||
                    StringUtil.isEmpty(cpfOrCnpj) ||
                    !this.hasPermissionForOperation()) {
                    this.addErrorMessage(`Ocorreu um erro ao consulta o ${isNaturalPerson ? 'CPF' : 'CNPJ'}`);
                    return;
                }
                const isValidDocument = isNaturalPerson
                    ? DocumentUtil.isValidCpf(this.client.person.naturalPerson.cpf)
                    : DocumentUtil.isValidCnpj(this.client.person.legalPerson.cnpj);
                if (!isValidDocument) {
                    this.addWarningMessage(`${isNaturalPerson ? 'CPF' : 'CNPJ'} informado é inválido`);
                    return;
                }
                this.loading = true;
                const entityList = yield this.service.findByCpfOrCnpj(cpfOrCnpj).toPromise();
                if (!ArrayUtil.isEmpty(entityList)) {
                    const typeOperation = yield this._showDialogPersonAssociation();
                    if (typeOperation !== 'NEW_RECORD') {
                        this.close(null);
                    }
                }
                this.loading = false;
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    _showDialogPersonAssociation() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let typeDocument = 'documento';
            if (!ObjectUtil.isNull(this.client.person)) {
                if (this.client.person.typePerson === 'NATURAL_PERSON') {
                    typeDocument = 'CPF';
                }
                else if (this.client.person.typePerson === 'LEGAL_PERSON') {
                    typeDocument = 'CNPJ';
                }
            }
            const personAssociationData = {
                title: 'Clientes associados',
                message: `Identificamos que existem registros associados a este ${typeDocument}. Deseja criar um novo?`,
            };
            const typeOperation = yield this.baseService.dialogService.open({
                componentOrTemplateRef: PersonAssociationDialogComponent,
                data: personAssociationData,
                width: '20%',
            }).afterClosed().toPromise();
            return Promise.resolve(typeOperation);
        });
    }
    findZipCode(address) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.client.person.typePerson === 'FOREIGN_PERSON') {
                    return;
                }
                if (StringUtil.isEmpty(address.zipCode)) {
                    this.addWarningMessage('Preencha o campo para realizar a consulta');
                    return;
                }
                if (address.zipCode.length !== 8) {
                    this.addWarningMessage('CEP inválido');
                    return;
                }
                const ibgeCode = yield this._addressService.getAddressByCep(address.zipCode);
                if (StringUtil.isEmpty(ibgeCode)) {
                    this.addWarningMessage('Endereço não encontrado para o CEP informado');
                    return;
                }
                this._cityService.findByIbgeCode(ibgeCode).toPromise().then(city => {
                    if (!ObjectUtil.isNull(city) && !ObjectUtil.isNull(city.state)) {
                        address.city = city;
                        address.state = city.state;
                    }
                }, error => {
                    throw new Error(error);
                });
            }
            catch (e) {
                this.handleError(e);
            }
        });
    }
    validateEmail(email) {
        if (!StringUtil.isEmpty(email) && !EmailUtil.isValid(email)) {
            this.addWarningMessage('O e-mail informado é inválido');
        }
    }
    setCountryCode(address) {
        if ((this.client.person.typePerson === 'FOREIGN_PERSON') &&
            !ObjectUtil.isNull(address) &&
            !ObjectUtil.isNull(address.country)) {
            this.client.person.addressList[0].countryCode = address.country.code;
        }
    }
    validateStreetNumber(streetNumber, address) {
        setTimeout(() => {
            address.withoutStreetNumber = false;
            if (!StringUtil.isEmpty(streetNumber) && (streetNumber.toUpperCase() === 'SN')) {
                address.withoutStreetNumber = true;
            }
        }, 1);
    }
    onChangeStreetNumber(checkBox, address) {
        if (ObjectUtil.isNull(checkBox) ||
            ObjectUtil.isNull(address.withoutStreetNumber)) {
            return;
        }
        if (checkBox.checked || address.withoutStreetNumber) {
            address.streetNumber = 'SN';
        }
        else {
            address.streetNumber = '';
        }
    }
}
