import { RegistrationPerson } from "@gipi-registration/person/models/person.model";
import { GIPIBaseAbstractModel } from "@gipi-shared/models/base-abstract.model";
import { RegistrationTransporterVehicle } from "./transporter-vehicle.model";

export class RegistrationTransporter extends GIPIBaseAbstractModel {

    /** @Required Dados da transportadora */
    person: RegistrationPerson;

    /** Lista de veículos */
    vehicleList: RegistrationTransporterVehicle[];

}
