import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { AbstractCrudService, FilterDTO } from '@gipisistemas/ng-core';
import { RegistrationPhoneType } from '../models/phone-type.model';

@Injectable({ providedIn: 'root' })
export class RegistrationPhoneTypeService extends AbstractCrudService<RegistrationPhoneType, FilterDTO> {

    constructor(http: HttpClient) {
        super(RegistrationPaths.phoneType, http);
    }

}
