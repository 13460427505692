<gipi-abstract-form id="dialogIntegrations"
                    name="dialogIntegrations"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogIntegrations">
        Integrações
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px"
         style="height: 100%;">
        <gipi-tab-group class="tab-group-integration">
            <gipi-tab label="WhatsApp"
                      svgIcon="whatsapp"
                      class="tab-integration"
                      [disabled]="loading">
                <ng-container *ngTemplateOutlet="twilioTemplate"> </ng-container>
            </gipi-tab>
            <gipi-tab label="Connect"
                      svgIcon="panel_integration_connect"
                      class="tab-integration"
                      [disabled]="loading">
                <ng-container *ngTemplateOutlet="connectTemplate"> </ng-container>
            </gipi-tab>
        </gipi-tab-group>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnConfirm"
                     [disabled]="loading"
                     [loading]="loading"
                     (click)="confirm()"
                     fxFlex="20">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close()"
                     fxFlex="20">
        </gipi-button>
    </div>
</gipi-abstract-form>

<ng-template #twilioTemplate>
    <div fxLayout="column"
         fxLayoutGap="16px"
         style="padding-block: 1.6rem;">
        <div class="content-information">
            <label for="contentInformation">
                Informações da fatura
            </label>
            <div id="contentInformation"
                 fxLayout="row"
                 fxLayout.lt-md="column"
                 fxLayoutGap="16px">
                <div fxLayout="row"
                     fxLayout.lt-md="column"
                     fxLayoutGap="16px"
                     fxFlex
                     fxFlex.lt-md="100">
                    <gipi-month-year-picker label="Período"
                                            id="panelTwilioPeriodicity"
                                            name="panelTwilioPeriodicity"
                                            [required]="true"
                                            [disabled]="loading"
                                            [calendarView]="'both'"
                                            [(ngModel)]="panelTwilioPeriodicity"
                                            (ngModelChange)="findNotificationStatement()"
                                            fxFlex
                                            fxFlex.lt-md="100">
                    </gipi-month-year-picker>
                    <itss-input label="Quantidade"
                                id="panelTwilioQuantity"
                                name="panelTwilioQuantity"
                                [disabled]="true"
                                [ngModel]="panelTwilioNotificationStatement?.quantity"
                                fxFlex
                                fxFlex.lt-md="100">
                    </itss-input>
                </div>
                <div fxLayout="row"
                     fxLayout.lt-md="column"
                     fxLayoutGap="16px"
                     fxFlex
                     fxFlex.lt-md="100">
                    <itss-input-currency label="Valor"
                                         id="panelTwilioAmount"
                                         name="panelTwilioAmount"
                                         [placeholder]="'R$ 0,00'"
                                         [disabled]="true"
                                         [ngModel]="panelTwilioNotificationStatement?.price"
                                         fxFlex
                                         fxFlex.lt-md="100">
                    </itss-input-currency>
                    <itss-input label="Status"
                                id="panelTwilioStatus"
                                name="panelTwilioStatus"
                                [disabled]="true"
                                [ngModel]="(panelTwilioNotificationStatement?.quantity > 0) ? (panelTwilioNotificationStatement ? (panelTwilioNotificationStatement?.isClosed ? 'Faturado' : 'Em aberto') : '') : ''"
                                fxFlex
                                fxFlex.lt-md="100">
                    </itss-input>
                </div>
            </div>
        </div>

        <div class="content-information">
            <label for="contentInformation">
                Informações do remetente
            </label>
            <div id="contentInformation"
                 fxLayout="row"
                 fxLayout.lt-md="column"
                 fxLayoutGap="16px">
                <itss-input label="E-mail"
                            id="panelTwilioEmail"
                            name="panelTwilioEmail"
                            [type]="'email'"
                            [placeholder]="'exemplo@email.com'"
                            [required]="true"
                            [disabled]="loading"
                            [lowerCase]="true"
                            [(ngModel)]="entity.emailCustomer"
                            (focusout)="validateEmail(entity.emailCustomer)"
                            fxFlex
                            fxFlex.lt-md="100">
                </itss-input>
                <div fxLayout="row"
                     fxLayout.lt-md="column"
                     fxLayoutGap="16px"
                     fxFlex
                     fxFlex.lt-md="100">
                    <itss-input label="Telefone"
                                id="panelTwilioPhoneNumber"
                                name="panelTwilioPhoneNumber"
                                #phoneNumber
                                [placeholder]="'(00) 0 0000-0000'"
                                [required]="true"
                                [disabled]="loading"
                                [max]="12"
                                [phone]="true"
                                [mask]="''"
                                [(ngModel)]="entity.phoneNumber"
                                (keyup)="setCaretEndNumber($event, phoneNumber)"
                                fxFlex
                                fxFlex.lt-md="100">
                    </itss-input>
                    <itss-input label="Dia do fechamento"
                                tooltip="Marca a ativação da integração e o dia em que a fatura será gerada"
                                id="panelTwilioPaymentTerm"
                                name="panelTwilioPaymentTerm"
                                [disabled]="true"
                                [(ngModel)]="entity.paymentTerm"
                                fxFlex
                                fxFlex.lt-md="100">
                    </itss-input>
                </div>
            </div>
        </div>

        <div class="panel-notification"
             [class.selected]="option.selected"
             *ngFor="let option of panelTwilioOptions; let i = index;">
            <div class="informations">
                <strong> {{ option.title }} </strong>
                <small> {{ option.description }} </small>
            </div>

            <gipi-slide-toggle [id]="'twilioOption' + i"
                               [name]="'twilioOption' + i"
                               [(ngModel)]="option.selected">
            </gipi-slide-toggle>
        </div>
    </div>
</ng-template>

<ng-template #connectTemplate>
    <div fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxFlex>
            <gipi-select-button label="Versão"
                                gipi-primary
                                id="panelConnectTypeVersion"
                                name="panelConnectTypeVersion"
                                optionLabel="label"
                                optionValue="type"
                                [disabled]="loading"
                                [required]="true"
                                [options]="panelConnectTypeVersionList"
                                [(ngModel)]="panelConnectTypeVersion"
                                fxFlex
                                fxFlex.lt-md="100">
            </gipi-select-button>
            <itss-input label="Client ID"
                        id="panelConnectClientId"
                        name="panelConnectClientId"
                        [required]="true"
                        [disabled]="loading"
                        [lowerCase]="true"
                        [(ngModel)]="panelConnectClientId"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
        </div>
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxFlex>
            <itss-input label="Login"
                        id="panelConnectLogin"
                        name="panelConnectLogin"
                        [required]="true"
                        [disabled]="loading"
                        [lowerCase]="true"
                        [(ngModel)]="panelConnectLogin"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Senha"
                        id="panelConnectPassword"
                        name="panelConnectPassword"
                        [required]="true"
                        [disabled]="loading"
                        [lowerCase]="true"
                        [(ngModel)]="panelConnectPassword"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
        </div>
    </div>
</ng-template>