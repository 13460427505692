import { BaseModel } from '@gipi-shared/models/base.model';

export class RegistrationCountry extends BaseModel {

    code: string;

    name: string;

    acronym: string;

}
