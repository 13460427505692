import { SaleCity } from "@gipi-sale/city/models/city.model";
import { SaleCountry } from "@gipi-sale/country/models/country.model";
import { SaleState } from "@gipi-sale/state/models/state.model";
import { GIPIBaseAbstractModel } from "@gipi-shared/models/base-abstract.model";
import { SaleAddressType, SaleAddressTypeEnum } from "../enums/address-type.enum";

export class SaleAddress extends GIPIBaseAbstractModel {

    /** Cidade */
    city: SaleCity;

    /**
     * Complemento
     * @Min 0 characters
     * @Max 80 characters
     */
    complement: string;

    /**
     * Código do país (Atenção: Informar somente para clientes estrangeiros)
     * @Min 0 characters
     * @Max 4 characters
     */
    countryCode: string;

    /**
     * Bairro
     * @Min 3 characters
     * @Max 50 characters
     */
    neighborhood: string;

    /**
     * Rua
     * @Min 3 characters
     * @Max 60 characters
     */
    street: string;

    /**
     * Número da rua
     * @Min 2 characters
     * @Max 6 characters
     */
    streetNumber: string;

    /** Tipo de endereço --- [ COLLECTION, COMMERCIAL, DELIVERY, RESIDENTIAL ] */
    type: SaleAddressTypeEnum | SaleAddressType;

    /** Determina se é o endereço padrão */
    useInInvoice: boolean;

    /**
     * CEP
     * @Min 1 characters
     * @Max 9 characters
     */
    zipCode: string;

    /** @Transient */
    key: number;

    /** @Transient */
    state: SaleState;

    /** @Transient */
    country: SaleCountry;

    /** @Transient */
    cityList: SaleCity[];

    /** @Transient */
    withoutStreetNumber: boolean;

}

