<div fxLayout="column"
     fxFlex
     fxLayoutGap="16px">
    <ng-container *ngFor="let socialNetwork of socialNetworkList; let i = index">
        <div fxLayout="column"
             fxLayoutGap="16px"
             fxFlex
             fxLayoutGap="16px"
             class="group-panel-register"
             *ngIf="socialNetworkList && (socialNetworkList.length > 0) && !loading"
             [attr.id]="'groupSocialNetwork' + i">
            <gipi-button tooltip="Excluir"
                         gipi-icon
                         class="btn-delete-group"
                         id="buttonDeleteSocialNetwork"
                         name="buttonDeleteSocialNetwork"
                         icon="close"
                         *ngIf="!loading && !isViewing"
                         (click)="deleteSocialNetwork(socialNetwork)">
            </gipi-button>

            <div fxLayout="row"
                 fxLayoutAlign="start"
                 fxLayoutGap="16px">
                <itss-select-entity label="Tipo de rede social"
                                    id="socialNetworkType"
                                    [entities]="socialNetworkTypeList"
                                    [(model)]="socialNetwork.type"
                                    [disabled]="loading || isViewing"
                                    required="true"
                                    property="description"
                                    fxFlex
                                    fxFlex.lt-sm="100">
                </itss-select-entity>
                <itss-input label="Descrição"
                            id="descriptionSocialNetwork"
                            [(ngModel)]="socialNetwork.description"
                            [disabled]="loading || isViewing"
                            required="true"
                            fxFlex
                            fxFlex.lt-sm="100">
                </itss-input>
            </div>
        </div>
    </ng-container>
    <div class="group-panel-register new-group-panel-register"
         id="buttonNewSocialNetwork"
         name="buttonNewSocialNetwork"
         *ngIf="!loading && !isViewing"
         (click)="addNewSocialNetwork()">
        <label> + Adicionar rede social </label>
    </div>
</div>