import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { OAuthPaths } from '@gipi-paths/oauth.paths';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/services/custom-authentication.service";
export class OAuthAccessControlService extends BaseCrudService {
    constructor(http, authenticationService) {
        super(OAuthPaths.accessControl, http, authenticationService);
    }
    validate(entity) {
        super.validate(entity);
        if (StringUtil.isEmpty(entity.description)
            || ObjectUtil.isNull(entity.enabled)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
    }
    existsByDescriptionAndCompany(accessControl, companyId) {
        return this.http.post(this.url(`exist-by-description-and-company/${companyId}`), accessControl, this.options()).pipe(map(this.mapper), catchError(this.handleError));
    }
    existByAccessControl(accessControlId) {
        const httpParams = new HttpParams()
            .append('accessControlId', String(accessControlId));
        return this.http.get(this.url('get-user-by-accessControl'), this.options(httpParams)).pipe(map(this.mapper), catchError(this.handleError));
    }
    existByAccessControlList(accessControlIdList) {
        return this.http.post(this.url('get-user-by-accessControl-list'), accessControlIdList, this.options()).pipe(map(this.mapper), catchError(this.handleError));
    }
}
OAuthAccessControlService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OAuthAccessControlService_Factory() { return new OAuthAccessControlService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CustomAuthenticationService)); }, token: OAuthAccessControlService, providedIn: "root" });
