import { SaleBankAccount } from '@gipi-sale/bank-account/models/bank-account.model';
import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { GIPIUuid, UUIDUtil } from '@gipisistemas/ng-core';

export class SaleCardAdministrator extends GIPIBaseAbstractModel {

    /** Conta bancária */
    bankAccount: SaleBankAccount;

    /** @Required Porcentagem da taxa de venda à vista */
    cashFee: number;

    /** @Required CNPJ da administradora */
    cnpj: string;

    /** Percentual de antecipação de crédito */
    creditAnticipationFee: number;

    /** @Required Porcentagem para crédito */
    creditFee: number;

    /**
     * @Required Dias para recebimento de dinheiro
     * @Min 0 characters
     */
    dayCashReceipt: number;

    /**
     * @Required Dias para recebimento de crédito
     * @Min 0 characters
     * @Max 99 characters
     */
    dayCreditReceipt: number;

    /**
     * @Required Dias para recebimento de débito
     * @Min 0 characters
     * @Max 99 characters
     */
    dayDebitReceipt: number;

    /** Percentual de antecipação de débito */
    debitAnticipationFee: number;

    /** @Required Porcentagem para valor de débito */
    debitFee: number;

    /**
     * @Required Descrição
     * @Min 5 characters
     * @Max 150 characters
     */
    description: string;

    /** @Required Porcentagem para venda parcelada */
    installmentFee: number;

    registrationCardAdministratorId: GIPIUuid;

    constructor(id?: GIPIUuid) {
        super();
        if (UUIDUtil.isValid(id)) {
            this.id = id;
        }
    }

}
