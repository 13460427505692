import { BaseUser } from '@gipisistemas/ng-core';
import { OAuthUserStatusEnum } from '../enums/user-status.enum';
import { OAuthAccessHistory } from './access-history.model';
import { OAuthUserAccessControl } from './user-access-control.model';

export class OAuthUser extends BaseUser {

    enabled: boolean = true;

    createdDate: Date;

    modifiedDate: Date;

    accessControlList: OAuthUserAccessControl[] = [];

    accessHistoryList: OAuthAccessHistory[];

    cashier: boolean = false;

    photo: string;

    /** @Transient */
    companyList: number[];

    /** @Transient */
    currentCompany: number;

    /** @Transient */
    companyNameList?: string[] = [];

    /** @Transient */
    selected?: boolean;

    /** @Transient */
    userAccessControlId?: number;

    /** @Transient */
    status?: OAuthUserStatusEnum | string;

    /** @Transient */
    filePhoto: File;

    /** @Transient */
    deletedPhoto: boolean;

}
