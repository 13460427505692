import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SaleAddressService {
    constructor(http) {
        this.http = http;
    }
    getAddressByCep(cep) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = yield fetch(`https://viacep.com.br/ws/${cep}/json`, { method: 'GET' });
            const address = yield response.json();
            if (address) {
                return Promise.resolve(address.ibge);
            }
            else {
                return Promise.resolve('');
            }
        });
    }
}
SaleAddressService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SaleAddressService_Factory() { return new SaleAddressService(i0.ɵɵinject(i1.HttpClient)); }, token: SaleAddressService, providedIn: "root" });
