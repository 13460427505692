import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class RegistrationTransporterService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(RegistrationPaths.transporter, 'v1', baseService);
        this.baseService = baseService;
    }
}
RegistrationTransporterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationTransporterService_Factory() { return new RegistrationTransporterService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: RegistrationTransporterService, providedIn: "root" });
