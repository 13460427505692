export class WhatsappContact {

    /** Nome do destinatário */
    recipientName: string;

    /** Telefone whatsapp do destinatário */
    recipientPhone: string;

    /** Mensagem para o destinatário */
    message: string;

    /** Mensagem padrão */
    defaultMessage: string;

}
