import * as tslib_1 from "tslib";
import { formatNumber } from '@angular/common';
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SaleService } from '@gipi-sale/sale/services/sale.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, CurrencyUtil, ObjectUtil, StringUtil, TableColumnBuilder } from '@gipisistemas/ng-core';
export class SaleDataDialogComponent extends AbstractComponent {
    constructor(data, _saleService, messageService, router, activatedRoute) {
        super(messageService, router, activatedRoute);
        this.data = data;
        this._saleService = _saleService;
        this.billInstallmentList = [];
        this.isOrigemSale = false;
        this.chargeTypeList = [];
        this.saleData = null;
    }
    ngOnInit() {
        const _super = Object.create(null, {
            ngOnInit: { get: () => super.ngOnInit }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.UUIDIsValid(this.data)) {
                this.saleData = yield this._saleService.getOne(this.data).toPromise();
            }
            _super.ngOnInit.call(this);
            this._setFields();
        });
    }
    createTableColumns() {
        return [
            TableColumnBuilder.instance()
                .property('product.description')
                .name('Produto')
                .width(200)
                .value((obj) => !ObjectUtil.isNull(obj.product) && !StringUtil.isEmpty(obj.product.description) ? obj.product.description : '')
                .build(),
            TableColumnBuilder.instance()
                .property('unitMeasurement.initials')
                .name('Und. Medida')
                .width(10)
                .align('center center')
                .value((obj) => !ObjectUtil.isNull(obj.unitMeasurement) && !StringUtil.isEmpty(obj.unitMeasurement.initials) ? obj.unitMeasurement.initials : '')
                .build(),
            TableColumnBuilder.instance()
                .property('quantity')
                .name('Quantidade')
                .width(10)
                .align('center center')
                .value((obj) => formatNumber(obj.quantity, 'pt-BR', '1.4-4'))
                .build(),
            TableColumnBuilder.instance()
                .property('product.amount')
                .name('Valor unitário')
                .width(10)
                .align('center center')
                .value((obj) => 'R$ ' + formatNumber(obj.unitaryValue, 'pt-BR', '1.4-4'))
                .build(),
            TableColumnBuilder.instance()
                .property('discount')
                .name('Desconto')
                .width(10)
                .align('center center')
                .value((obj) => CurrencyUtil.transform(obj.discount, '1.2-2'))
                .build(),
            TableColumnBuilder.instance()
                .property('addition')
                .name('Acréscimo')
                .width(10)
                .align('center center')
                .value((obj) => CurrencyUtil.transform(obj.addition, '1.2-2'))
                .build(),
            TableColumnBuilder.instance()
                .property('freight')
                .name('Frete')
                .width(10)
                .align('center center')
                .value((obj) => CurrencyUtil.transform(obj.freight, '1.2-2'))
                .build(),
            TableColumnBuilder.instance()
                .property('total')
                .name('Total líquido')
                .width(10)
                .align('center center')
                .value((obj) => CurrencyUtil.transform(obj.amountNet, '1.2-2'))
                .build(),
        ];
    }
    _setFields() {
        if (ObjectUtil.isNull(this.saleData)) {
            return;
        }
        for (let i = 0; i < this.saleData.salePaymentList.length; i++) {
            let chargeType = this.chargeTypeList.find((ct) => ct.id === this.saleData.salePaymentList[i].chargeType.id);
            if (ObjectUtil.isNewModel(chargeType)) {
                chargeType = this.saleData.salePaymentList[i].chargeType;
                chargeType.totalInstallmentAmount = 0;
                this.chargeTypeList.push(chargeType);
            }
            chargeType.totalInstallmentAmount += this.saleData.salePaymentList[i].amountPaid;
        }
    }
    getColorByAmount(amount) {
        if (amount > 0) {
            return '#47ca82';
        }
        else if (amount < 0) {
            return '#ca0045';
        }
        else if (amount === 0) {
            return '#696969';
        }
    }
}
