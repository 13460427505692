import { AbstractModel } from '@gipisistemas/ng-core';

export class OAuthUserInformationDTO extends AbstractModel {

    name: string;

    password: string;

    photo: string;

}
