import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthUserInformationDTO } from '@gipi-financial/user/models/dto/user-information.dto';
import { OAuthUser } from '@gipi-financial/user/models/user.model';
import { OAuthUserService } from '@gipi-financial/user/services/user.service';
import { OAuthFileService } from '@gipi-oauth/file/services/file.service';
import { AbstractComponent, AuthenticationService, EmailUtil, MessageService, ObjectUtil, PasswordUtil, StringUtil } from '@gipisistemas/ng-core';
export class UserInformationDialogComponent extends AbstractComponent {
    constructor(dialogRef, data, _userService, _authenticationService, _fileService, messageService, router, activatedRoute) {
        super(messageService, router, activatedRoute);
        this.dialogRef = dialogRef;
        this.data = data;
        this._userService = _userService;
        this._authenticationService = _authenticationService;
        this._fileService = _fileService;
        this.extensions = ['.png', '.jpg', '.jpeg'];
        this.maximumSize = 3;
        this.isViewNewPassword = false;
        this.isViewNewPasswordConfirm = false;
        this.isViewValidPassword = false;
        this.editUsername = false;
        this.editPassword = false;
        this.existEmail = false;
        this.isLoadFile = false;
        this.userPhotoSrc = '';
        this.passwordFocused = false;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.dialogRef.disableClose = true;
            this.user = this.data;
            this.user.name = this.user.name.toUpperCase();
            this.user.deletedPhoto = false;
            yield this.getUserPhoto();
        });
    }
    save() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this._validate()) {
                    this.loading = true;
                    yield this._userService.confirmPassword(Number(this.user.id), this.validPassword).toPromise().then((isMatch) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        if (isMatch) {
                            if (this.user.deletedPhoto) {
                                yield this.deletePhoto();
                            }
                            if (!ObjectUtil.isNull(this.user.filePhoto)) {
                                yield this.uploadPhoto();
                            }
                            const userInformationDto = this.userToUserInformationDTO(this.user);
                            userInformationDto.password = this.editPassword ? this.newPassword : this.user.password;
                            yield this._userService.updateInformationOfUser(userInformationDto).toPromise().then(user => {
                                this._authenticationService.logout();
                                this.router.navigate(['/oauth/login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
                                this.dialogRef.close(null);
                            }, error => {
                                this.loading = false;
                                this.handleError(error);
                            });
                        }
                        else {
                            throw new Error('Senha inválida');
                        }
                    }), error => {
                        throw new Error(error);
                    });
                }
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    userToUserInformationDTO(user) {
        const userInformationDto = new OAuthUserInformationDTO();
        userInformationDto.id = user.id;
        userInformationDto.name = user.name;
        userInformationDto.password = user.password;
        userInformationDto.photo = user.photo;
        return userInformationDto;
    }
    close() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.dialogRef.close(null);
        });
    }
    _validate() {
        if (this.editUsername) {
            if ((StringUtil.isEmpty(this.newUsername) || StringUtil.isEmpty(this.confirmNewUsername))) {
                this.addWarningMessage('Campos obrigatórios (*) não informados');
                return false;
            }
            if (!EmailUtil.isValid(this.newUsername.toLowerCase())) {
                this.addWarningMessage('E-mail inválido');
                return false;
            }
            if (this.newUsername.toUpperCase() !== this.confirmNewUsername.toUpperCase()) {
                this.addWarningMessage('O novo email e a confirmação do novo email devem ser iguais');
                return false;
            }
        }
        if (this.editPassword && !this._validatePassword()) {
            return false;
        }
        if (StringUtil.isEmpty(this.validPassword)) {
            this.addWarningMessage('Para alterar suas informações informe a senha atual para realizar a confirmação');
            return false;
        }
        if (this.existEmail) {
            this.addWarningMessage('Este e-mail já está sendo usado por outro usuário');
            return false;
        }
        return true;
    }
    _validatePassword() {
        if (StringUtil.isEmpty(this.newPassword)) {
            this.addWarningMessage('Campo "Senha" é obrigatório e não foi informado');
            return false;
        }
        else if (StringUtil.isEmpty(this.newPasswordConfirm)) {
            this.addWarningMessage('Campo "Confirmação senha" é obrigatório e não foi informado');
            return false;
        }
        else if (!PasswordUtil.isValidMinLength(this.newPassword)) {
            this.addWarningMessage('A senha deve conter no mínimo 6 caracteres');
            return false;
        }
        else if (!PasswordUtil.isValidMaxLength(this.newPassword)) {
            this.addWarningMessage('A senha deve conter no máximo 15 caracteres');
            return false;
        }
        else if (!PasswordUtil.hasUppercase(this.newPassword)) {
            this.addWarningMessage('A senha deve conter no mínimo 1 caractere maiúsculo');
            return false;
        }
        else if (!PasswordUtil.hasLowercase(this.newPassword)) {
            this.addWarningMessage('A senha deve conter no mínimo 1 caractere minúsculo');
            return false;
        }
        else if (!PasswordUtil.hasNumber(this.newPassword)) {
            this.addWarningMessage('A senha deve conter no mínimo 1 número');
            return false;
        }
        else if (PasswordUtil.hasBackspace(this.newPassword)) {
            this.addWarningMessage('A senha não deve conter espaços');
            return false;
        }
        else if (this.newPassword !== this.newPasswordConfirm) {
            this.addWarningMessage('A senha e a confirmação da senha devem ser iguais');
            return false;
        }
        return true;
    }
    changeEditUsername() {
        this.editUsername = !this.editUsername;
        if (!this.editUsername) {
            this.newUsername = '';
            this.confirmNewUsername = '';
        }
    }
    changeEditPassword() {
        this.editPassword = !this.editPassword;
        if (!this.editPassword) {
            this.newPassword = '';
            this.newPasswordConfirm = '';
        }
    }
    validEmail(email) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (!StringUtil.isEmpty(email)) {
                    yield this._userService.getByUserName(email.toLowerCase()).toPromise().then(saved => {
                        this.existEmail = !ObjectUtil.isNull(saved.id);
                    }, error => {
                        throw new Error(error);
                    });
                }
            }
            catch (e) {
                this.handleError(e);
            }
        });
    }
    loadFile() {
        const htmlInputElement = this.fileInput.nativeElement;
        htmlInputElement.onchange = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const file = htmlInputElement.files[0];
            if (!ObjectUtil.isNull(file) && this.validateExtensions(file) && this.validateMaximumSize(file)) {
                this.user.filePhoto = file;
                this.user.deletedPhoto = false;
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file);
                fileReader.onloadend = () => {
                    (this.userPhotoSrc = fileReader.result);
                };
            }
        });
        htmlInputElement.click();
    }
    deleteFile() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                setTimeout(() => {
                    this.user.deletedPhoto = true;
                    this.fileInput.nativeElement.value = '';
                    this.userPhotoSrc = '';
                });
            }
            catch (e) {
                this.fileInput.nativeElement.value = '';
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    validateExtensions(file) {
        if (this.extensions.filter(extension => file.name.endsWith(extension)).length === 0) {
            this.addErrorMessage(`São permitidos somente arquivos do tipo: ${this.extensions.reduce((previous, current) => previous + ', ' + current)}`);
            return false;
        }
        return true;
    }
    validateMaximumSize(file) {
        if (file.size > (this.maximumSize * 1000000)) {
            this.addErrorMessage(`O arquivo deve ter no máxmio ${this.maximumSize} MB.`);
            return false;
        }
        return true;
    }
    deletePhoto() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this._fileService.delete(this.user.photo).toPromise().then(() => {
                    this.user.deletedPhoto = true;
                    this.user.photo = null;
                    this.fileInput.nativeElement.value = '';
                    this.userPhotoSrc = '';
                }, () => {
                    throw new Error('Não foi possível excluir a foto');
                });
            }
            catch (e) {
                this.fileInput.nativeElement.value = '';
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    uploadPhoto() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this._fileService.upload('users', this.user.filePhoto, 'Private').toPromise().then((url) => {
                    this.user.photo = url;
                }, () => {
                    throw new Error('Não foi possível alterar a foto');
                });
            }
            catch (e) {
                this.fileInput.nativeElement.value = '';
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    getUserPhoto() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (!ObjectUtil.isNewModel(this.user) && !StringUtil.isEmpty(this.user.photo)) {
                    this.isLoadFile = true;
                    yield this._fileService.download(this.user.photo).toPromise().then(resp => {
                        const fileReader = new FileReader();
                        fileReader.readAsDataURL(resp.body);
                        fileReader.onloadend = () => this.userPhotoSrc = fileReader.result;
                        this.isLoadFile = false;
                    }, error => {
                        throw new Error(error);
                    });
                }
            }
            catch (e) {
                this.isLoadFile = false;
                this.handleError(e);
            }
        });
    }
}
