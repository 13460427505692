import { FinancialClient } from '@gipi-financial/client/models/client.model';
import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';
import { GIPIUuid } from '@gipisistemas/ng-core';
import { FinancialSale } from '../sale.model';

export class FinancialSaleConciliationFilterDTO extends GIPIBaseAbstractFilterModel {

    bankAccountId: GIPIUuid;

    begin: Date;

    /** @Transient */
    client: FinancialClient;
    clientId: GIPIUuid;

    document: string;

    end: Date;

    salePaymentIdsExcluded: FinancialSale[];

    total: number;

}
