<div fxLayout="column"
     fxLayoutAlign="center center">
    <div fxLayout="row"
         fxLayoutAlign="center center"
         fxLayoutGap="150px"
         fxFlex="100vh">
        <itss-card class="resend-recovery">
            <div fxLayout="column"
                 fxLayoutAlign="center center">
                <img width="180"
                     src="../../../assets/logo/logo_and_name.png" />
            </div>

            <div fxLayout="column"
                 fxLayoutGap="50px">
                <p>
                    Enviamos um link para você recuperar a sua senha no:
                    "<span style="font-weight: bold;">{{ username }}</span>"
                </p>

                <div fxLayout="column">
                    <p style="font-weight: bold;">Não recebeu o e-mail?</p>
                    <p>
                        Confira sua caixa de spam. Se também não estiver lá, solicite o link novamente, ou fale com seu
                        superior.
                    </p>
                </div>

                <div fxLayoutAlign="center center"
                     fxLayout="row"
                     fxLayoutGap="20px">
                    <button class="btn-rounded confirm"
                            aria-label="Reenviar Link"
                            [disabled]="loading"
                            (click)="resend()"
                            fxFlex="25"
                            fxFlex.lt-md="100">
                        Reenviar Link
                    </button>
                    <button class="btn-rounded cancel"
                            aria-label="Cancelar"
                            (click)="returnToLogin()"
                            fxFlex="25"
                            fxFlex.lt-md="100">
                        Cancelar
                    </button>
                </div>
            </div>
        </itss-card>
    </div>
</div>