import { BaseModel } from '@gipi-shared/models/base.model';
import { GIPIUuid } from '@gipisistemas/ng-core';

export class FinancialBillInstallmentRenegotiation extends BaseModel {

    userId: GIPIUuid;

    renegotiatedBillInstallment: GIPIUuid[] = [];

}
