export class OAuthPaths {
    public static accessControl: string = 'authenticator/access-control/v1';
    public static audit: string = 'authenticator/audit';
    public static file: string = 'authenticator/file';
    public static health: string = 'authenticator/access-control/v1';
    public static permission: string = 'authenticator/permission/v1';
    public static token: string = 'authenticator/oauth';
    public static userAccessControl: string = 'authenticator/user-access-control/v1';
    public static user: string = 'authenticator/user/v1';
    public static userPublic: string = 'authenticator/public/user/v1';
}
