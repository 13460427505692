<gipi-abstract-form id="dialogClientSheetCnpj"
                    name="dialogClientSheetCnpj"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogClientSheetCnpj"
                  class="toolbar-client-sheet">
        Dados da empresa

        <span role="textbox"
              class="novelties-actions">
            <gipi-button gipi-icon
                         id="btnClose"
                         icon="close"
                         tooltip="Fechar"
                         (click)="close()">
            </gipi-button>
        </span>
    </gipi-toolbar>

    <div class="last-update">
        Última atualização: <span> {{ formatDate(integrationConsultCnpjDTO?.dateOfLastGIPIUpdate) }} </span>
    </div>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">

        <div gipi-row
             GIPIGap="8">
            <div class="box"
                 gipi-col
                 GIPISpan="6">
                <span class="box-title"> Nome empresarial </span>
                <span class="box-value"> {{ integrationConsultCnpjDTO?.name }} </span>
            </div>
            <div class="box"
                 gipi-col
                 GIPISpan="6">
                <span class="box-title"> Nome fantasia </span>
                <span class="box-value"> {{ integrationConsultCnpjDTO?.fantasy }} </span>
            </div>
        </div>

        <div gipi-row
             GIPIGap="8">
            <div class="box"
                 gipi-col
                 GIPISpan="4">
                <span class="box-title"> Última atualização </span>
                <span class="box-value">
                    {{ formatDate(integrationConsultCnpjDTO?.lastUpdate) }}
                </span>
            </div>
            <div class="box"
                 gipi-col
                 GIPISpan="4">
                <span class="box-title"> Número de inscrição </span>
                <span class="box-value">
                    {{ formatCnpj(integrationConsultCnpjDTO?.cnpj) }}
                </span>
            </div>
            <div class="box"
                 gipi-col
                 GIPISpan="4">
                <span class="box-title"> Data de abertura </span>
                <span class="box-value">
                    {{ formatDate(integrationConsultCnpjDTO?.openDate) }}
                </span>
            </div>
        </div>

        <div gipi-row
             GIPIGap="8">
            <div class="box"
                 gipi-col
                 GIPISpan="6">
                <span class="box-title"> Logradouro </span>
                <span class="box-value"> {{ integrationConsultCnpjDTO?.street }} </span>
            </div>
            <div class="box"
                 gipi-col
                 GIPISpan="2">
                <span class="box-title"> Número </span>
                <span class="box-value"> {{ integrationConsultCnpjDTO?.streetNumber }} </span>
            </div>
            <div class="box"
                 gipi-col
                 GIPISpan="4">
                <span class="box-title"> Complemento </span>
                <span class="box-value"> {{ integrationConsultCnpjDTO?.complement }} </span>
            </div>
        </div>

        <div gipi-row
             GIPIGap="8">
            <div class="box"
                 gipi-col
                 GIPISpan="2">
                <span class="box-title"> CEP </span>
                <span class="box-value"> {{ formatZipCode(integrationConsultCnpjDTO?.zipCode) }} </span>
            </div>
            <div class="box"
                 gipi-col
                 GIPISpan="5">
                <span class="box-title"> Bairro </span>
                <span class="box-value"> {{ integrationConsultCnpjDTO?.neighborhood }} </span>
            </div>
            <div class="box"
                 gipi-col
                 GIPISpan="4">
                <span class="box-title"> Município </span>
                <span class="box-value"> {{ integrationConsultCnpjDTO?.city?.name }} </span>
            </div>
            <div class="box"
                 gipi-col
                 GIPISpan="1">
                <span class="box-title text-center"> UF </span>
                <span class="box-value text-center"> {{ integrationConsultCnpjDTO?.city?.state?.acronym }} </span>
            </div>
        </div>

        <div gipi-row
             GIPIGap="8">
            <div class="box"
                 gipi-col
                 GIPISpan="6">
                <span class="box-title"> Endereço eletrônico </span>
                <span class="box-value"> {{ integrationConsultCnpjDTO?.email }} </span>
            </div>
            <div class="box"
                 gipi-col
                 GIPISpan="6">
                <span class="box-title"> Telefone </span>
                <span class="box-value"> {{ integrationConsultCnpjDTO?.phoneNumber }} </span>
            </div>
        </div>

        <div gipi-row
             GIPIGap="8">
            <div class="box"
                 gipi-col
                 GIPISpan="6">
                <span class="box-title"> Atividades econômicas primárias </span>
                <div class="box-table">
                    <div class="box-table-row">
                        <span class="box-title code-cnae"> Código </span>
                        <span class="box-title"> Descrição </span>
                    </div>
                    <div class="box-table-row border-bottom"
                         *ngFor="let row of corporateMainActivityList">
                        <span class="box-value code-cnae"> {{ formatCnae(row?.cnae) }} </span>
                        <span class="box-value"> {{ row?.description }} </span>
                    </div>
                </div>
            </div>

            <div class="box"
                 gipi-col
                 GIPISpan="6">
                <span class="box-title"> Atividades econômicas secundárias </span>
                <div class="box-table">
                    <div class="box-table-row">
                        <span class="box-title code-cnae"> Código </span>
                        <span class="box-title"> Descrição </span>
                    </div>
                    <div class="box-table-row border-bottom"
                         *ngFor="let row of corporateSecondaryActivityList">
                        <span class="box-value code-cnae"> {{ formatCnae(row?.cnae) }} </span>
                        <span class="box-value">{{ row?.description }} </span>
                    </div>
                </div>
            </div>
        </div>

        <div gipi-row
             GIPIGap="8">
            <div class="box"
                 gipi-col
                 GIPISpan="12">
                <span class="box-title"> Código e descrição da natureza jurídica </span>
                <span class="box-value"> {{ integrationConsultCnpjDTO?.legalNature }} </span>
            </div>
        </div>

        <div gipi-row
             GIPIGap="8">
            <div class="box"
                 gipi-col
                 GIPISpan="12">
                <span class="box-title"> Quadro de sócios e administradores </span>
                <div class="box-table">
                    <div class="box-table-row">
                        <span class="box-title border-right"> Nome </span>
                        <span class="box-title"> Qualificação </span>
                    </div>
                    <div class="box-table-row border-bottom"
                         *ngFor="let row of corporateStructureList">
                        <span class="box-value border-right"> {{ row?.partnerName }} </span>
                        <span class="box-value"> {{ row?.partnerQualification }} </span>
                    </div>
                </div>
            </div>
        </div>

        <div gipi-row
             GIPIGap="8">
            <div class="box"
                 gipi-col
                 GIPISpan="4">
                <span class="box-title"> Capital social </span>
                <span class="box-value"> {{ formatCurrency(integrationConsultCnpjDTO?.shareCapital) }} </span>
            </div>
            <div class="box"
                 gipi-col
                 GIPISpan="4">
                <span class="box-title"> Situação cadastral </span>
                <span class="box-value"> {{ integrationConsultCnpjDTO?.situation }} </span>
            </div>
            <div class="box"
                 gipi-col
                 GIPISpan="4">
                <span class="box-title"> Data da situação cadastral </span>
                <span class="box-value"> {{ formatDate(integrationConsultCnpjDTO?.dateStatus) }} </span>
            </div>
        </div>

    </div>
</gipi-abstract-form>