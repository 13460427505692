import { BaseModel } from '@gipi-shared/models/base.model';
import { SalePersonStatus, SalePersonStatusEnum } from '../enums/person-status.enum';
import { SaleTypePerson, SaleTypePersonEnum } from '../enums/type-person.enum';
import { SaleAddress } from './address.model';
import { SaleEmail } from './email.model';
import { SaleLegalPerson } from './legal-person.model';
import { SaleNaturalPerson } from './natural-person.model';
import { SalePhone } from './phone.model';
import { SaleSocialNetwork } from './social-network.model';

export class SalePerson extends BaseModel {

    name: string;

    fantasyName: string;

    birthDate: Date;

    naturalPerson: SaleNaturalPerson;

    legalPerson: SaleLegalPerson;

    phoneList: SalePhone[];

    addressList: SaleAddress[];

    emailList: SaleEmail[];

    site: string;

    socialNetworkList: SaleSocialNetwork[];

    comments: string;

    /** @Transient */
    typePerson: SaleTypePersonEnum | SaleTypePerson;

    /** @Transient */
    personStatus: SalePersonStatusEnum | SalePersonStatus;

    /** @Transient */
    key: number;

    constructor() {
        super();
        this.phoneList = [];
        this.addressList = [];
        this.emailList = [];
        this.socialNetworkList = [];
    }

}
