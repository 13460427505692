import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { FinancialPaths } from '@gipi-paths/financial.paths';
import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import { FinancialCompany } from '../models/company.model';

@Injectable({ providedIn: 'root' })
export class FinancialCompanyService extends GIPIAbstractCrudService<FinancialCompany, GIPIBaseAbstractFilterModel> {

    constructor(protected baseService: GIPIBaseService) {
        super(FinancialPaths.company, 'v1', baseService);
    }

    findByIdIn(idList: number[]): Observable<FinancialCompany[]> {
        return this.baseService.httpClient.post(this.url('find-by-ids'), idList, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }
}
