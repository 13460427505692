import { Injectable } from '@angular/core';

import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIAbstractFilterModel, GIPIBaseService } from '@gipisistemas/ng-core';
import { RegistrationState } from '../models/state.model';

@Injectable({ providedIn: 'root' })
export class RegistrationStateService extends GIPIAbstractCrudService<RegistrationState, GIPIAbstractFilterModel> {

    constructor(protected baseService: GIPIBaseService) {
        super(RegistrationPaths.state, 'v1', baseService);
    }

}
