<gipi-abstract-form id="dialogListProduct"
                    name="dialogListProduct"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogListProduct">
        Consulta de produtos
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px"
         style="height: 100%;">

        <div fxLayout="column"
             fxLayoutGap="16px">
            <div fxLayout="row wrap"
                 fxLayout.lt-sm="column"
                 fxLayoutAlign="space-between center"
                 fxLayoutGap="16px">
                <div fxFlex="40"
                     style="width: 100%;"
                     fxLayout="row"
                     fxLayoutGap="16px">
                    <gipi-form-field id="inputSearch"
                                     #search
                                     fxFlex
                                     type="search"
                                     placeholder="Pesquisar"
                                     showSearch="true"
                                     [disabled]="loading"
                                     [(ngModel)]="filter.researchField"
                                     (onKeydownEnter)="findAll(null)"
                                     (onSearch)="findAll(null)">
                    </gipi-form-field>
                </div>
                <div fxFlex="15"
                     style="width: 100%;"
                     fxLayout="row"
                     fxLayoutGap="16px">
                    <gipi-button label="Exibir"
                                 gipi-tertiary
                                 id="btnDisplay"
                                 #displayButton
                                 icon="expand_more"
                                 [disabled]="loading"
                                 [popover]="true"
                                 fxFlex
                                 fxFlex.lt-md="100">
                        <div class="btn-display-content"
                             style="flex-direction: column !important; min-width: 140px !important; gap: 8px;">
                            <itss-checkbox label="Todos"
                                           color="primary"
                                           [(indeterminate)]="indeterminateAllTypes"
                                           [(ngModel)]="allTypesChecked"
                                           (change)="checkAllStatus()"
                                           fxFlex="4">
                            </itss-checkbox>
                            <itss-checkbox label="Ativos"
                                           color="primary"
                                           [(ngModel)]="enabledCheckbox"
                                           (change)="validateAllStatusSelected()"
                                           fxFlex="4">
                            </itss-checkbox>
                            <itss-checkbox label="Inativos"
                                           color="primary"
                                           [(ngModel)]="disabledCheckbox"
                                           (change)="validateAllStatusSelected()"
                                           fxFlex="4">
                            </itss-checkbox>
                            <div>
                                <gipi-button label="Aplicar"
                                             gipi-primary
                                             id="btnApplyFilter"
                                             [disabled]="loading"
                                             (click)="findAll(null); displayButton.closePopover()"
                                             fxFlex>
                                </gipi-button>
                            </div>
                        </div>
                    </gipi-button>
                </div>
            </div>
        </div>

        <itss-table [columns]="columns"
                    [paginator]="true"
                    [loading]="loading"
                    [paginatorLength]="productPage.totalElements"
                    [paginatorPageSize]="5"
                    [paginatorPageIndex]="productPage.number"
                    [data]="productPage.content"
                    (page)="findAll($event)">
        </itss-table>
    </div>

    <div mat-dialog-actions
         fxLayout="row wrap"
         fxLayout.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnSave"
                     *ngIf="!selectOne"
                     [disabled]="productsSelected.length === 0"
                     (click)="close(true)"
                     fxFlex="15"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close(false)"
                     fxFlex="15"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>

<ng-template #checkboxAllTemplate>
    <itss-checkbox color="primary"
                   [(ngModel)]="selectedAll"
                   [(indeterminate)]="indeterminateAll"
                   (change)="checkAll()"
                   fxFlex="4">
    </itss-checkbox>
</ng-template>

<ng-template #checkboxTemplate
             let-entity>
    <itss-checkbox color="primary"
                   [(ngModel)]="entity.selected"
                   (change)="select(entity, true)"
                   fxFlex="4">
    </itss-checkbox>
</ng-template>

<ng-template #statusTemplate
             let-entity>
    <gipi-badge [label]="setTextStatus(entity)"
                [color]="setColorStatus(entity)"
                style="width: 80%;">
    </gipi-badge>
</ng-template>
