import { HttpClient } from '@angular/common/http';
import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { AbstractCrudService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class RegistrationPhoneTypeService extends AbstractCrudService {
    constructor(http) {
        super(RegistrationPaths.phoneType, http);
    }
}
RegistrationPhoneTypeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationPhoneTypeService_Factory() { return new RegistrationPhoneTypeService(i0.ɵɵinject(i1.HttpClient)); }, token: RegistrationPhoneTypeService, providedIn: "root" });
