import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { RegistrationCity } from '@gipi-registration/city/models/city.model';
import { RegistrationCityService } from '@gipi-registration/city/services/city.service';

import { RegistrationAddress } from '@gipi-registration/person/models/address.model';
import { RegistrationEmail } from '@gipi-registration/person/models/email.model';
import { RegistrationLegalPerson } from '@gipi-registration/person/models/legal-person.model';
import { RegistrationNaturalPerson } from '@gipi-registration/person/models/natural-person.model';
import { RegistrationPerson } from '@gipi-registration/person/models/person.model';
import { RegistrationPhone } from '@gipi-registration/person/models/phone.model';
import { RegistrationAddressService } from '@gipi-registration/person/services/address.service';
import { RegistrationPhoneType } from '@gipi-registration/phone-type/models/phone-type.model';
import { RegistrationPhoneTypeService } from '@gipi-registration/phone-type/services/phone-type.service';
import { RegistrationState } from '@gipi-registration/state/models/state.model';
import { RegistrationStateService } from '@gipi-registration/state/services/state.service';
import { RegistrationTransporterVehicle } from '@gipi-registration/transporter/models/transporter-vehicle.model';
import { RegistrationTransporter } from '@gipi-registration/transporter/models/transporter.model';
import { RegistrationTransporterService } from '@gipi-registration/transporter/services/transporter.service';
import { OptionActiveSituation, OptionActiveSituationEnum } from '@gipi-shared/enums/option-active-situation.enum';
import { TypePersonEnum } from '@gipi-shared/enums/type-person.enum';
import { APP_MESSAGES, ArrayUtil, DocumentUtil, EmailUtil, GIPIAbstractComponent, GIPIBaseService, GIPISortModel, INJECTOR, InputComponent, ObjectUtil, PageDTO, StringUtil, UUIDUtil } from '@gipisistemas/ng-core';

export interface TransporterFormData {
    typeOperation: 'NEW' | 'EDIT' | 'VIEW';
    transporter: RegistrationTransporter;
}

@Component({
    templateUrl: './transporter-form-dialog.component.html',
})
export class TransporterFormDialogComponent extends GIPIAbstractComponent implements OnInit, OnDestroy {

    private _phoneTypeList: RegistrationPhoneType[] = [];

    public typeOperation: 'NEW' | 'EDIT' | 'VIEW' = 'NEW';

    public transporter: RegistrationTransporter = this._newTransporter();

    public typePersonEnum: typeof TypePersonEnum = TypePersonEnum;

    public optionActiveSituationEnum: typeof OptionActiveSituationEnum = OptionActiveSituationEnum;
    public optionActiveSituationValue: OptionActiveSituation = 'ENABLED';

    public phoneOne: string = '';
    public phoneTwo: string = '';
    public email: string = '';

    stateFindByValueFn = async (value: string, page: number) => {
        const result: PageDTO<RegistrationState> = await this._stateService.findByValue(value, page, 50, new GIPISortModel('acronym', 'ASC')).toPromise();
        return result;
    };

    cityFindByValueFn = async (value: string, page: number, paramNextBatch: RegistrationTransporter) => {
        if (
            ObjectUtil.isNull(paramNextBatch.person) ||
            ArrayUtil.isEmpty(paramNextBatch.person.addressList) ||
            ObjectUtil.isNull(paramNextBatch.person.addressList[0].state)
        ) {
            return [];
        }

        const result: PageDTO<RegistrationCity> = await this._cityService.findByValue(value, page, 10, new GIPISortModel('name', 'ASC'), 'v1', paramNextBatch.person.addressList[0].state.ibgeCode).toPromise();
        return result;
    };

    constructor(
        protected service: RegistrationTransporterService,
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        public dialogRef: MatDialogRef<TransporterFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: TransporterFormData = { typeOperation: 'NEW', transporter: null },
        private _phoneTypeService: RegistrationPhoneTypeService,
        private _cityService: RegistrationCityService,
        private _stateService: RegistrationStateService,
        private _addressService: RegistrationAddressService,
    ) {
        super(baseService, activatedRoute);
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {
        super.ngOnInit();
        this._findPhoneType();

        this.typeOperation = this.data.typeOperation;

        if (this.typeOperation !== 'NEW') {
            this.transporter = ObjectUtil.clone(this.data.transporter);

            if (!ObjectUtil.isNull(this.transporter.person.legalPerson)) {
                this.transporter.person.typePerson = 'LEGAL_PERSON';
            } else {
                this.transporter.person.typePerson = 'NATURAL_PERSON';
            }

            if (!ArrayUtil.isEmpty(this.transporter.person.addressList)) {
                if (!ObjectUtil.isNull(this.transporter.person.addressList[0].city)) {
                    this.transporter.person.addressList[0].state = ObjectUtil.clone(this.transporter.person.addressList[0].city.state);
                    this.transporter.person.addressList[0].country = ObjectUtil.clone(this.transporter.person.addressList[0].city.state.country);
                }
            }

            if (!ArrayUtil.isEmpty(this.transporter.person.emailList)) {
                if (this.transporter.person.emailList.length >= 1) {
                    this.email = this.transporter.person.emailList[0].email;
                }
            }

            if (!ArrayUtil.isEmpty(this.transporter.person.phoneList)) {
                if (this.transporter.person.phoneList.length >= 1) {
                    this.phoneOne = this.transporter.person.phoneList[0].number;
                }
                if (this.transporter.person.phoneList.length >= 2) {
                    this.phoneTwo = this.transporter.person.phoneList[1].number;
                }
            }

            if (!this.transporter.enabled) {
                this.optionActiveSituationValue = 'DISABLED';
            }
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    private _newTransporter(): RegistrationTransporter {
        const transporter = new RegistrationTransporter();
        transporter.person = new RegistrationPerson();
        transporter.person.typePerson = 'LEGAL_PERSON';
        transporter.person.naturalPerson = new RegistrationNaturalPerson();
        transporter.person.legalPerson = new RegistrationLegalPerson();
        transporter.person.birthDate = new Date();

        // Address
        transporter.person.addressList.push(new RegistrationAddress());
        transporter.person.addressList[0].useInInvoice = true;
        transporter.person.addressList[0].type = 'COMMERCIAL';

        // Vehicles
        transporter.vehicleList = [];

        return transporter;
    }

    private _findPhoneType(): void {
        try {
            this._phoneTypeService.findAllEnabled(0, 50).toPromise().then(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    this._phoneTypeList = page.content;
                }
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.handleError(e);
        }
    }

    public setNameInFantasyName(): void {
        if (!StringUtil.isEmpty(this.transporter.person.name) && StringUtil.isEmpty(this.transporter.person.fantasyName)) {
            this.transporter.person.fantasyName = this.transporter.person.name;
        }
    }

    public findZipCode(address: RegistrationAddress): void {
        try {
            if (StringUtil.isEmpty(address.zipCode)) {
                this.addWarningMessage('Preencha o campo para realizar a consulta');
                return;
            }
            if (address.zipCode.length !== 8) {
                this.addWarningMessage('CEP inválido');
                return;
            }

            this._addressService.getAddressByCep(address.zipCode).then(resp => {
                this._cityService.findByIbgeCode(resp.ibge).toPromise().then(city => {
                    if (!ObjectUtil.isNull(city) && !ObjectUtil.isNull(city.state)) {
                        address.city = city;
                        address.state = city.state;
                    }
                }, error => {
                    throw new Error(error);
                });
            }).catch(error => {
                throw new Error(error);
            });
        } catch (e) {
            this.handleError(e);
        }
    }

    public validateEmail(email: string): void {
        if (!StringUtil.isEmpty(email) && !EmailUtil.isValid(email)) {
            this.addWarningMessage('O e-mail informado é inválido');
        }
    }

    public getMaskPlateVehicle(plate: string, inputPlateVehicleRef: InputComponent): string {
        if (StringUtil.isEmpty(plate)) {
            return '';
        }

        if (plate.length < 7) {
            inputPlateVehicleRef._elementRef.nativeElement.focus();
            return '';
        }

        const isNumeric = (value: any) => {
            return /^[0-9]+$/.test(value);
        };

        if (plate.length >= 7) {
            return isNumeric(plate.substring(4, plate.length)) ? 'SSS-0000' : 'SSS 0S00';
        }

        return '';
    }

    public deleteVehicle(vehicle: RegistrationTransporterVehicle): void {
        if (UUIDUtil.isValid(vehicle.id)) {
            this.transporter.vehicleList.splice(this.transporter.vehicleList.findIndex(e => e.id === vehicle.id), 1);
        } else {
            this.transporter.vehicleList.splice(this.transporter.vehicleList.findIndex(e => e.key === vehicle.key), 1);
        }
    }

    public addNewVehicle(): void {
        const vehicle = new RegistrationTransporterVehicle();
        vehicle.key = this.transporter.vehicleList.length;

        this.transporter.vehicleList.push(vehicle);
    }

    public confirm(): void {
        try {
            if (ObjectUtil.isNull(this.transporter)) {
                return;
            }

            const isLegalPerson: boolean = this.transporter.person.typePerson === 'LEGAL_PERSON';

            if (isLegalPerson) {
                if (StringUtil.isEmpty(this.transporter.person.legalPerson.cnpj)) {
                    this.addWarningMessage('Campo CNPJ é obrigatório e não foi informado');
                    return;
                }
                if (!DocumentUtil.isValidCnpj(this.transporter.person.legalPerson.cnpj)) {
                    this.addWarningMessage('CNPJ informado é inválido');
                    return;
                }
            } else {
                if (StringUtil.isEmpty(this.transporter.person.naturalPerson.cpf)) {
                    this.addWarningMessage('Campo CPF é obrigatório e não foi informado');
                    return;
                }
                if (!DocumentUtil.isValidCpf(this.transporter.person.naturalPerson.cpf)) {
                    this.addWarningMessage('CPF informado é inválido');
                    return;
                }
            }

            if (StringUtil.isEmpty(this.transporter.person.name)) {
                this.addWarningMessage('Campo nome é obrigatório e não foi informado');
                return;
            }
            if (this.transporter.person.name.length < 3) {
                this.addWarningMessage('Campo nome deve conter no mínimo 3 caracteres');
                return;
            }

            if (StringUtil.isEmpty(this.transporter.person.addressList[0].zipCode)) {
                this.addWarningMessage('Campo CEP é obrigatório e não foi informado');
                return;
            }
            if (this.transporter.person.addressList[0].zipCode.length !== 8) {
                this.addWarningMessage('Campo CEP foi informado incorretamente');
                return;
            }
            if (StringUtil.isEmpty(this.transporter.person.addressList[0].street)) {
                this.addWarningMessage('Campo endereço é obrigatório e não foi informado');
                return;
            }
            if (StringUtil.isEmpty(this.transporter.person.addressList[0].neighborhood)) {
                this.addWarningMessage('Campo bairro é obrigatório e não foi informado');
                return;
            }
            if (ObjectUtil.isNull(this.transporter.person.addressList[0].state)) {
                this.addWarningMessage('Campo estado é obrigatório e não foi informado');
                return;
            }
            if (ObjectUtil.isNull(this.transporter.person.addressList[0].city)) {
                this.addWarningMessage('Campo cidade é obrigatório e não foi informado');
                return;
            }
            if (!ArrayUtil.isEmpty(this.transporter.person.emailList) &&
                !StringUtil.isEmpty(this.transporter.person.emailList[0].email) &&
                !EmailUtil.isValid(this.transporter.person.emailList[0].email)
            ) {
                this.addWarningMessage('O e-mail informado é inválido');
                return;
            }

            for (let i = 0; i < this.transporter.vehicleList.length; i++) {
                const vehicle: RegistrationTransporterVehicle = this.transporter.vehicleList[i];

                if (StringUtil.isEmpty(vehicle.antt)) {
                    this.addWarningMessage('Campo antt na aba veículos é obrigatório e não foi informado');
                    return;
                }
                if (StringUtil.isEmpty(vehicle.plate)) {
                    this.addWarningMessage('Campo placa na aba veículos é obrigatório e não foi informado');
                    return;
                }
                if (ObjectUtil.isNull(vehicle.plateState)) {
                    this.addWarningMessage('Campo uf do estado na aba veículos é obrigatório e não foi informado');
                    return;
                }
            }

            this.loading = true;

            this.transporter.enabled = (this.optionActiveSituationValue === 'ENABLED');

            if (this.transporter.person.typePerson === 'LEGAL_PERSON') {
                this.transporter.person.naturalPerson = null;
            } else {
                this.transporter.person.legalPerson = null;
            }

            if (!ArrayUtil.isEmpty(this.transporter.person.addressList)) {
                if (!ObjectUtil.isNull(this.transporter.person.addressList[0].city)) {
                    this.transporter.person.addressList[0].country = ObjectUtil.clone(this.transporter.person.addressList[0].city.state.country);
                }

                this.transporter.person.addressList[0].useInInvoice = true;
            }

            this.transporter.person.emailList = [];
            if (!StringUtil.isEmpty(this.email)) {
                const emailAux: RegistrationEmail = new RegistrationEmail();
                emailAux.email = this.email;
                this.transporter.person.emailList.push(emailAux);
            }

            this.transporter.person.phoneList = [];
            if (!StringUtil.isEmpty(this.phoneOne)) {
                const cell: RegistrationPhone = new RegistrationPhone();
                cell.number = this.phoneOne;
                cell.type = this._phoneTypeList[0];
                this.transporter.person.phoneList.push(cell);
            }
            if (!StringUtil.isEmpty(this.phoneTwo)) {
                const phone: RegistrationPhone = new RegistrationPhone();
                phone.number = this.phoneTwo;
                phone.type = this._phoneTypeList[1];
                this.transporter.person.phoneList.push(phone);
            }

            if (!ArrayUtil.isEmpty(this.transporter.person.phoneList)) {
                this.transporter.person.phoneList[0].useInInvoice = true;
            }

            this.service.save(this.transporter).toPromise().then(() => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.loading = false;
                this.close('RELOAD_TABLE');
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    public close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE'): void {
        this.dialogRef.close(operation);
    }

}
