<gipi-abstract-form id="dialogFindBillInstallment"
                    name="dialogFindBillInstallment"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogFindBillInstallment">
        Selecionar contas a {{ data.billType === 'RECEIVABLE' ? 'receber' : 'pagar' }}
    </gipi-toolbar>
    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">

        <div fxLayout="column"
             fxLayoutGap="16px">
            <div fxLayout="row wrap"
                 fxLayout.lt-sm="column"
                 fxLayoutAlign="space-between center"
                 fxLayoutGap="16px">
                <div fxFlex="40"
                     style="width: 100%;"
                     fxLayout="row"
                     fxLayoutGap="16px">
                    <gipi-form-field id="inputSearch"
                                     #search
                                     fxFlex
                                     type="search"
                                     placeholder="Pesquisar"
                                     showSearch="true"
                                     [disabled]="loading"
                                     [(ngModel)]="filter.researchField"
                                     (onKeydownEnter)="find(null)"
                                     (onSearch)="find(null)">
                    </gipi-form-field>

                    <gipi-button tooltip="Filtrar"
                                 gipi-primary-icon
                                 #searchButton
                                 id="btnOptionsSearch"
                                 svgIcon="filter"
                                 [notification]="appliedFilters && (appliedFilters.length > 0)"
                                 [popover]="true"
                                 [disabled]="loading">
                        <div fxLayout="column"
                             fxLayoutGap="12px"
                             style="max-width: 36rem; padding: 8px;">
                            <div fxLayout="row"
                                 fxLayoutGap="12px"
                                 fxFlexAlign="space-between center">
                                <gipi-datepicker label="Data inicial"
                                                 [(ngModel)]="filter.begin"
                                                 [disabled]="loading"
                                                 fxFlex="48">
                                </gipi-datepicker>
                                <gipi-datepicker label="Data final"
                                                 [(ngModel)]="filter.end"
                                                 [disabled]="loading"
                                                 fxFlex="48">
                                </gipi-datepicker>
                            </div>

                            <gipi-input-select-paged label="Cliente"
                                                     *ngIf="(data.billType === 'RECEIVABLE')"
                                                     [(model)]="filter.client"
                                                     [nextBatchFn]="clientFindByValueFn"
                                                     [propertyFn]="clientFn"
                                                     [disabled]="loading"
                                                     fxFlex>
                            </gipi-input-select-paged>

                            <gipi-input-select-paged label="Fornecedor"
                                                     *ngIf="(data.billType === 'PAYABLE')"
                                                     [(model)]="filter.provider"
                                                     [nextBatchFn]="providerFindByValueFn"
                                                     [propertyFn]="providerFn"
                                                     [disabled]="loading"
                                                     fxFlex>
                            </gipi-input-select-paged>

                            <gipi-input-select-paged [label]="getLabelFilterBankAccount()"
                                                     property="description"
                                                     [(model)]="filter.bankAccount"
                                                     [nextBatchFn]="bankAccountFindByValueFn"
                                                     [disabled]="loading"
                                                     fxFlex>
                            </gipi-input-select-paged>

                            <div fxFlex
                                 fxLayout="row"
                                 fxFlexAlign="space-between center"
                                 fxLayoutGap="12px">
                                <gipi-form-field label="Número do documento"
                                                 id="inputFilterDocumentNumber"
                                                 type="text"
                                                 placeholder="Ex: 0001"
                                                 [(ngModel)]="filter.documentNumber"
                                                 [disabled]="loading"
                                                 fxFlex="48">
                                </gipi-form-field>
                                <gipi-input-currency label="Valor"
                                                     id="inputFilterAmount"
                                                     name="currencyAmount"
                                                     type="currency"
                                                     placeholder="R$ 0,00"
                                                     [(ngModel)]="filter.amount"
                                                     [disabled]="loading"
                                                     fxFlex="48">
                                </gipi-input-currency>
                            </div>

                            <gipi-input-select-paged label="Categoria"
                                                     [(model)]="filter.postingCategory"
                                                     [nextBatchFn]="postingCategoryFindByValueFn"
                                                     [propertyFn]="postingCategoryFn"
                                                     [disabled]="loading"
                                                     fxFlex>
                            </gipi-input-select-paged>

                            <div style="margin-top: 12px;"
                                 fxLayout="row"
                                 fxLayoutGap="12px"
                                 fxFlex>
                                <gipi-button label="Pesquisar"
                                             gipi-primary
                                             id="btnFind"
                                             icon="search"
                                             [disabled]="loading"
                                             (click)="find(null); searchButton.popoverTrigger.togglePopover()"
                                             fxFlex>
                                </gipi-button>
                                <gipi-button label="Limpar"
                                             gipi-secondary
                                             id="btnClear"
                                             icon="clear"
                                             [disabled]="loading"
                                             (click)="clear(); searchButton.popoverTrigger.togglePopover()"
                                             fxFlex>
                                </gipi-button>
                            </div>
                        </div>
                    </gipi-button>
                </div>
                <div fxFlex="20"
                     style="width: 100%;"
                     fxLayout="row"
                     fxLayoutGap="16px">
                    <gipi-button label="Exibir"
                                 gipi-tertiary
                                 id="btnDisplayBillReceivable"
                                 #displayButton
                                 icon="expand_more"
                                 [disabled]="loading"
                                 [popover]="true"
                                 *ngIf="data.billType === 'RECEIVABLE'"
                                 fxFlex
                                 fxFlex.lt-md="100">
                        <div class="btn-display-content"
                             style="flex-direction: column !important; min-width: 140px !important; gap: 8px;">
                            <itss-checkbox label="Todos"
                                           color="primary"
                                           [(ngModel)]="allTypesChecked"
                                           [(indeterminate)]="indeterminateType"
                                           (change)="checkAllStatus('RECEIVABLE')">
                            </itss-checkbox>
                            <itss-checkbox label="A receber"
                                           color="primary"
                                           [(ngModel)]="receivableChecked"
                                           (change)="validateAllReceivableStatusSelected()">
                            </itss-checkbox>
                            <itss-checkbox label="Em atraso"
                                           color="primary"
                                           [(ngModel)]="receivableOverdueChecked"
                                           (change)="validateAllReceivableStatusSelected()">
                            </itss-checkbox>
                            <itss-checkbox label="Recebidas"
                                           color="primary"
                                           [(ngModel)]="receivedChecked"
                                           (change)="validateAllReceivableStatusSelected()">
                            </itss-checkbox>
                            <itss-checkbox label="Recebidas parciais"
                                           color="primary"
                                           [(ngModel)]="receivedPartialChecked"
                                           (change)="validateAllReceivableStatusSelected()">
                            </itss-checkbox>
                            <div>
                                <gipi-button label="Aplicar"
                                             id="btnApplyFilterReceivable"
                                             gipi-primary
                                             (click)="find(null); displayButton.closePopover()">
                                </gipi-button>
                            </div>
                        </div>
                    </gipi-button>

                    <gipi-button label="Exibir"
                                 gipi-tertiary
                                 id="btnDisplayBillPayable"
                                 #displayPayableButton
                                 icon="expand_more"
                                 [disabled]="loading"
                                 [popover]="true"
                                 *ngIf="data.billType === 'PAYABLE'"
                                 fxFlex
                                 fxFlex.lt-md="100">
                        <div class="btn-display-content"
                             style="flex-direction: column !important; min-width: 160px !important; gap: 8px;">
                            <itss-checkbox label="Todos"
                                           color="primary"
                                           [(ngModel)]="allTypesChecked"
                                           [(indeterminate)]="indeterminateType"
                                           (change)="checkAllStatus('PAYABLE')">
                            </itss-checkbox>
                            <itss-checkbox label="A pagar"
                                           color="primary"
                                           [(ngModel)]="payableChecked"
                                           (change)="validateAllPayableStatusSelected()">
                            </itss-checkbox>
                            <itss-checkbox label="Em atraso"
                                           color="primary"
                                           [(ngModel)]="payableOverdueChecked"
                                           (change)="validateAllPayableStatusSelected()">
                            </itss-checkbox>
                            <itss-checkbox label="Pagas"
                                           color="primary"
                                           [(ngModel)]="paidChecked"
                                           (change)="validateAllPayableStatusSelected()">
                            </itss-checkbox>
                            <div>
                                <gipi-button label="Aplicar"
                                             gipi-primary
                                             id="btnApplyFilterPayable"
                                             (click)="find(null); displayPayableButton.closePopover()">
                                </gipi-button>
                            </div>
                        </div>
                    </gipi-button>
                </div>
            </div>
            <gipi-chips label="Filtros aplicados:"
                        style="max-width: 70%;"
                        [(ngModel)]="appliedFilters"
                        [showRemovable]="true"
                        [showRemovableAll]="true"
                        [disabled]="loading"
                        (removeChange)="removeAppliedFilter($event)"
                        (removeAllChange)="clear()">
            </gipi-chips>
        </div>

        <itss-table [paginator]="true"
                    [loading]="loading"
                    [columns]="columns"
                    (page)="find($event)"
                    [paginatorLength]="page.totalElements"
                    [paginatorPageIndex]="page.number"
                    [paginatorPageSize]="5"
                    [data]="page.content">
        </itss-table>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Usar selecionados"
                     gipi-primary
                     id="btnConfirm"
                     [disabled]="loading || billInstallmentListSelected.length === 0 || (data.typeConciliation === 'BILLET_CONCILIATION' && billInstallmentListSelected.length > 1)"
                     (click)="confirm()"
                     [loading]="loading"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     fxFlex="20"
                     (click)="close()"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>

<ng-template #checkboxTemplate
             let-entity>
    <itss-checkbox color="primary"
                   [(ngModel)]="entity.selected"
                   (change)="select(entity, true)"
                   fxFlex="4">
    </itss-checkbox>
</ng-template>

<ng-template #checkAllTemplate>
    <itss-checkbox color="primary"
                   [(ngModel)]="selected"
                   [(indeterminate)]="indeterminate"
                   (change)="checkAll()"
                   fxFlex="4">
    </itss-checkbox>
</ng-template>

<ng-template #statusTemplate
             let-entity>
    <itss-icon class="icon-table"
               icon="fiber_manual_record"
               [color]="setColorStatus(entity)"
               [tooltip]="setHintStatus(entity)">
    </itss-icon>
</ng-template>