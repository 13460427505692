<gipi-abstract-form id="dialogRegisterBankAccountTransfer"
                    name="dialogRegisterBankAccountTransfer"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogRegisterBankAccountTransfer">
        Cadastrar transferência bancária
    </gipi-toolbar>
    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <itss-select-entity-paged label="Conta de origem"
                                      id="bankAccountTransferOrigin"
                                      name="bankAccountTransferOrigin"
                                      placeholder="Selecione"
                                      [required]="true"
                                      [disabled]="isLoad || (typeOperation === 'DELETE_BANK_ACCOUNT') || (!isBillReceivable && (isTransactionConciliation)) || (typeOperation === 'VIEW')"
                                      [(model)]="bankAccountTransfer.origin"
                                      [nextBatchFn]="bankAccountOriginFindByValueFn"
                                      [property]="'description'"
                                      [valuesExclud]="bankAccountTransfer?.destiny ? [bankAccountTransfer.destiny] : []"
                                      (modelChange)="refreshDestinyList()"
                                      fxFlex
                                      fxFlex.lt-md="100">
            </itss-select-entity-paged>
            <itss-input-currency label="Saldo"
                                 id="bankAccountTransferAmountOrigin"
                                 name="bankAccountTransferAmountOrigin"
                                 placeholder="R$ 0,00"
                                 [disabled]="true"
                                 [value]="(amountOrigin$ | async)"
                                 fxFlex="30"
                                 fxFlex.lt-md="100">
            </itss-input-currency>
        </div>
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <itss-select-entity-paged label="Conta de destino"
                                      id="bankAccountTransferDestiny"
                                      name="bankAccountTransferDestiny"
                                      placeholder="Selecione"
                                      [required]="true"
                                      [disabled]="isLoad || (typeOperation === 'VIEW') || (isBillReceivable && (isTransactionConciliation))"
                                      [valuesExclud]="bankAccountTransfer?.origin ? [bankAccountTransfer.origin] : []"
                                      [(model)]="bankAccountTransfer.destiny"
                                      [nextBatchFn]="bankAccountDestinyFindByValueFn"
                                      [property]="'description'"
                                      (modelChange)="refreshOriginList()"
                                      fxFlex
                                      fxFlex.lt-md="100">
            </itss-select-entity-paged>
            <itss-input-currency label="Saldo"
                                 id="amountDestiny"
                                 name="amountDestiny"
                                 placeholder="R$ 0,00"
                                 [disabled]="true"
                                 [value]="(amountDestiny$ | async)"
                                 fxFlex="30"
                                 fxFlex.lt-md="100">
            </itss-input-currency>
        </div>
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <itss-input-currency label="Valor da transferência"
                                 id="bankAccountTransferAmount"
                                 name="bankAccountTransferAmount"
                                 placeholder="R$ 0,00"
                                 [required]="true"
                                 [disabled]="isLoad || (typeOperation === 'DELETE_BANK_ACCOUNT') || (isTransactionConciliation) || (typeOperation === 'VIEW')"
                                 [(ngModel)]="bankAccountTransfer.amount"
                                 [options]="{min: 0}"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <gipi-datepicker label="Data da transferência"
                             id="bankAccountTransferDate"
                             name="bankAccountTransferDate"
                             [required]="true"
                             [disabled]="isLoad || (typeOperation === 'DELETE_BANK_ACCOUNT') || (isTransactionConciliation) || (typeOperation === 'VIEW')"
                             [(ngModel)]="bankAccountTransfer.date"
                             fxFlex
                             fxFlex.lt-md="100">
            </gipi-datepicker>
        </div>
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <itss-input label="Descrição"
                        id="bankAccountTransferDescription"
                        name="bankAccountTransferDescription"
                        [disabled]="isLoad || (typeOperation === 'VIEW')"
                        [(ngModel)]="bankAccountTransfer.description"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
        </div>
    </div>
    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     *ngIf="(this.typeOperation !== 'VIEW') && (hasPermission('BANK_ACCOUNT_TRANSFER_MAKE') || hasPermission('BANK_ACCOUNT_TRANSFER_UPDATE'))"
                     [disabled]="isLoad || loading"
                     [loading]="loading"
                     (click)="confirm()"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close('NONE')"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>