export enum FinancialPeriodicityEnum {
    WEEKLY = 'Semanal',
    MONTHLY = 'Mensal',
    BIMONTHLY = 'Bimestral',
    QUARTERLY = 'Trimestral',
    SEMESTER = 'Semestral',
    YEARLY = 'Anual',
    FREE = 'Livre'
}

export type FinancialPeriodicity = 'WEEKLY' | 'MONTHLY' | 'BIMONTHLY' | 'QUARTERLY' | 'SEMESTER' | 'YEARLY' | 'FREE';
