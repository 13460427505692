import { Injectable } from '@angular/core';

import { SalePaths } from '@gipi-paths/sale.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import { SaleCardAdministrator } from '../models/card-administrador.model';
import { SaleCardAdministratorFilterDTO } from '../models/dto/card-administrator-filter.dto';

@Injectable({ providedIn: 'root' })
export class SaleCardAdministratorService extends GIPIAbstractCrudService<SaleCardAdministrator, SaleCardAdministratorFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(SalePaths.cardAdministrator, 'v1', baseService);
    }

}
