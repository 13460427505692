import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { RegistrationState } from '../../state/models/state.model';

export class RegistrationCity extends GIPIBaseAbstractModel {

    name: string;

    ibgeCode: number;

    state: RegistrationState;

}
