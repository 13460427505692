import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FinancialCardAdministrator } from '@gipi-financial/card-administrator/models/card-administrador.model';
import { FinancialReceivementCard } from '@gipi-financial/card-administrator/models/receivement-card.model';
import { FinancialCardAdministratorService } from '@gipi-financial/card-administrator/services/card-administrator.service';
import { FinancialFlagCard } from '@gipi-financial/flag-card/models/flag-card.model';
import { FinancialFlagCardService } from '@gipi-financial/flag-card/services/flag-card.service';
import { FinancialReceivementService } from '@gipi-financial/receivement/services/receivement.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, APP_MESSAGES, ArrayUtil, CurrencyUtil, DateUtil, INJECTOR, NumberUtil, ObjectUtil, TableColumnBuilder } from '@gipisistemas/ng-core';
export class ReceivementCardDialogComponent extends AbstractComponent {
    constructor(dialogRef, data = { receivement: null, installmentList: [], isCashier: false, useClientCredit: false }, _receivementService, _cardAdministratorService, _flagCardService, messageService, router, activatedRoute) {
        super(messageService, router, activatedRoute);
        this.dialogRef = dialogRef;
        this.data = data;
        this._receivementService = _receivementService;
        this._cardAdministratorService = _cardAdministratorService;
        this._flagCardService = _flagCardService;
        this.billInstallmentList = [];
        this.receivementCardList = [];
        this.receivementCard = new FinancialReceivementCard();
        this.receivementAmount = 0;
        this.amountAdded = 0;
        this.amountRemaining = 0;
        this.quantityInstallment = 1;
        this.optionAmountCard = {};
        this.cardAdministratorFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._cardAdministratorService.findByValue(value, page, 10).toPromise();
            return result;
        });
        this.flagCardFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._flagCardService.findByValue(value, page, 10).toPromise();
            return result;
        });
    }
    ngOnInit() {
        const _super = Object.create(null, {
            ngOnInit: { get: () => super.ngOnInit }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            _super.ngOnInit.call(this);
            this.receivementCard.chargeType = this.data.receivement.chargeType;
            this.receivementCard.bankAccount = this.data.receivement.bankAccount;
            this.receivement = this.data.receivement;
            this.receivement.amountNet = this.receivement.amountReceivable;
            this.receivementAmount = (this.receivement.amountReceived ? this.receivement.amountReceived : this.receivement.amountReceivable);
            this.billInstallmentList = this.data.installmentList;
            this.newReceivementCard();
        });
    }
    addReceivementCard() {
        try {
            this.validateAddNewReceivementCard();
            const idLaunchReference = this.getIdLaunchReference();
            const amountPortion = (this.receivementCard.amountCard / this.quantityInstallment);
            const lNow = new Date();
            const lReceivementCardList = [];
            let daysAdd = 0;
            if (this.receivementCard.chargeType.type === 'CREDIT_CARD') {
                daysAdd = this.receivementCard.cardAdministrator.dayCreditReceipt;
            }
            else {
                daysAdd = this.receivementCard.cardAdministrator.dayDebitReceipt;
            }
            for (let i = 0; i < this.quantityInstallment; i++) {
                const lReceivementCard = ObjectUtil.clone(this.receivementCard);
                lReceivementCard.amountCard = Number(amountPortion.toFixed(2));
                lReceivementCard.numberInstallment = i + 1;
                lReceivementCard.dueDate = new Date(lNow.getFullYear(), lNow.getMonth() + i, lNow.getDate() + daysAdd);
                lReceivementCard.idLauchReference = idLaunchReference;
                lReceivementCardList.push(lReceivementCard);
            }
            const amountAdded = Number(lReceivementCardList.reduce((sum, rc) => sum += rc.amountCard, 0));
            const amountDifference = Number(amountAdded.toFixed(2)) - this.receivementCard.amountCard;
            if (amountDifference > 0) {
                lReceivementCardList[0].amountCard -= amountDifference;
            }
            else if (amountDifference < 0) {
                const difference = (amountDifference * -1);
                lReceivementCardList[0].amountCard += difference;
            }
            this.receivementCardList = this.receivementCardList.concat(...lReceivementCardList);
            this.newReceivementCard();
        }
        catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }
    removeReceivementCard(receivementCard) {
        setTimeout(() => {
            const lReceivementCardList = ArrayUtil.clone(this.receivementCardList);
            const indexReceivementCard = lReceivementCardList.findIndex(rc => (rc.idLauchReference === receivementCard.idLauchReference) && (rc.numberInstallment === receivementCard.numberInstallment));
            lReceivementCardList.splice(indexReceivementCard, 1);
            this.refactorInstallments(lReceivementCardList, receivementCard);
            this.receivementCardList = [];
            this.receivementCardList = lReceivementCardList;
            this.calculateAmountReceivable();
        });
    }
    refactorInstallments(receivementCardList, receivementCard) {
        const lReceivementCardList = receivementCardList.filter(rc => rc.idLauchReference === receivementCard.idLauchReference);
        const date = new Date();
        for (let i = 0; i < lReceivementCardList.length; i++) {
            const lReceivementCard = lReceivementCardList[i];
            let daysAdd = 0;
            if (lReceivementCard.chargeType.type === 'CREDIT_CARD') {
                daysAdd = lReceivementCard.cardAdministrator.dayCreditReceipt;
            }
            else {
                daysAdd = lReceivementCard.cardAdministrator.dayDebitReceipt;
            }
            lReceivementCard.numberInstallment = i + 1;
            lReceivementCard.dueDate = new Date(date.getFullYear(), date.getMonth() + i, date.getDate() + daysAdd);
        }
    }
    newReceivementCard() {
        setTimeout(() => {
            this.receivementCard = new FinancialReceivementCard();
            this.receivementCard.chargeType = this.data.receivement.chargeType;
            this.receivementCard.cardAdministrator = new FinancialCardAdministrator();
            this.receivementCard.flagCard = new FinancialFlagCard();
            this.receivementCard.issuanceDate = new Date();
            this.receivementCard.dueDate = new Date();
            this.receivementCard.status = 'RECEIVABLE';
            this.receivementCard.bankAccount = this.data.receivement.bankAccount;
            this.quantityInstallment = 1;
            this.calculateAmountReceivable();
        });
    }
    calculateAmountReceivable() {
        this.amountAdded = this.receivementCardList.reduce((sum, rc) => sum += rc.amountCard, 0);
        this.amountRemaining = this.receivementAmount - this.amountAdded;
        this.receivementCard.amountCard = this.amountRemaining;
        this.setOptionsAmountCard();
    }
    confirm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.validateConfirm();
                this.loading = true;
                yield this._receivementService.receive(this.receivement, this.billInstallmentList, this.receivementCardList, this.data.useClientCredit).toPromise().then(() => {
                    this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                    this.close(true);
                }, error => {
                    this.loading = false;
                    this.handleError(error);
                });
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    validateConfirm() {
        const amountAdded = Number(this.receivementCardList.reduce((sum, rc) => sum += rc.amountCard, 0).toFixed(2));
        if (amountAdded < this.receivementAmount) {
            const amountReceivable = Number((this.receivementAmount - amountAdded).toFixed(2));
            throw new Error(`Ainda falta lançar R$ ${CurrencyUtil.transform(amountReceivable, '1.2-2')} para corresponder o valor total do recebimento`);
        }
        if (amountAdded > this.receivementAmount) {
            throw new Error(`O valor lançado R$ ${CurrencyUtil.transform(Number((amountAdded).toFixed(2)), '1.2-2')} está ultrapassando o valor total do recebimento`);
        }
        if (ArrayUtil.isEmpty(this.receivementCardList)) {
            throw new Error('É necessário adicionar no mínimo uma parcela, para salvar o recebimento');
        }
    }
    validateAddNewReceivementCard() {
        if (ObjectUtil.isNewModel(this.receivementCard.cardAdministrator) || ObjectUtil.isNull(this.receivementCard.cardAdministrator)) {
            throw new Error('Campo administradora de cartão é obrigatório e não foi informado');
        }
        if (ObjectUtil.isNewModel(this.receivementCard.flagCard) || ObjectUtil.isNull(this.receivementCard.flagCard)) {
            throw new Error('Campo bandeira do cartão é obrigatório e não foi informado');
        }
        if (!NumberUtil.isPositive(this.quantityInstallment)) {
            throw new Error('Campo quantidade de parcelas é obrigatório e não foi informado');
        }
        const amountAdded = this.receivementCardList.reduce((sum, rc) => sum += rc.amountCard, 0);
        if (amountAdded === this.receivementAmount) {
            throw new Error(`O valor lançado R$ ${(amountAdded).toFixed(2)} já corresponde ao valor total do recebimento`);
        }
        if ((this.receivementCard.amountCard + amountAdded) > this.receivementAmount) {
            throw new Error(`O valor que você está tentando lançar R$ ${(amountAdded).toFixed(2)} ultprassa o valor total do recebimento`);
        }
        if (!NumberUtil.isPositive(this.receivementCard.amountCard)) {
            throw new Error('Campo valor é obrigatório e não foi informado');
        }
        if (!DateUtil.isValid(this.receivementCard.issuanceDate)) {
            throw new Error('Campo data da emissão é obrigatório e não foi informado');
        }
        if ((this.receivementCard.amountCard / this.quantityInstallment) < 1) {
            throw new Error('Não é possível gerar parcelas com valor abaixo de R$ 1,00');
        }
    }
    createTableColumns() {
        return [
            TableColumnBuilder.instance()
                .property('numberInstallment')
                .name('N°')
                .value((obj) => ('00' + obj.numberInstallment).slice(-2))
                .width(5)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('issuanceDate')
                .name('Emissão')
                .value((obj) => DateUtil.format(obj.issuanceDate, DateUtil.DATE_FORMAT))
                .width(8)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('dueDate')
                .name('Vencimento')
                .value((obj) => DateUtil.format(obj.dueDate, DateUtil.DATE_FORMAT))
                .width(8)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('cardAdministrator')
                .name('Administradora de cartão')
                .value((obj) => obj.cardAdministrator.description)
                .marginLeft(15)
                .build(),
            TableColumnBuilder.instance()
                .property('flagCard')
                .name('Bandeira do cartão')
                .value((obj) => obj.flagCard.description)
                .build(),
            TableColumnBuilder.instance()
                .property('amountCard')
                .name('Valor')
                .value((obj) => CurrencyUtil.transform(obj.amountCard, '1.2-2'))
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('actions')
                .name('Ações')
                .template(this.actions)
                .align('center center')
                .width(10)
                .build(),
        ];
    }
    getIdLaunchReference() {
        const IdLaunchReferenceList = [];
        this.receivementCardList.forEach((receivementCard) => {
            IdLaunchReferenceList.push(receivementCard.idLauchReference);
        });
        if (IdLaunchReferenceList.length <= 0) {
            return 1;
        }
        else {
            return Math.max(...IdLaunchReferenceList) + 1;
        }
    }
    setOptionsAmountCard() {
        if (NumberUtil.isPositive(this.amountRemaining)) {
            this.optionAmountCard = { allowNegative: false, min: 0, max: this.amountRemaining };
        }
        else {
            this.optionAmountCard = { allowNegative: false, min: 0 };
        }
    }
    close(isSaved) {
        this.dialogRef.close(isSaved);
    }
}
