<gipi-abstract-form id="dialogRegisterPaymentMethod"
                    name="dialogRegisterPaymentMethod"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogRegisterPaymentMethod">
        Cadastrar forma de pagamento
    </gipi-toolbar>
    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <itss-input label="Descrição"
                        id="paymentMethodDescription"
                        name="paymentMethodDescription"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="paymentMethod.description"
                        [min]="3"
                        [max]="80"
                        [maxlength]="80"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <gipi-input-select-enum label="Situação"
                                    id="paymentMethodEnabled"
                                    name="paymentMethodEnabled"
                                    [required]="true"
                                    [disabled]="loading || (typeOperation !== 'EDIT')"
                                    [valuesExclud]="[]"
                                    [enum]="optionActiveSituationEnum"
                                    [(ngModel)]="optionActiveSituationValue"
                                    fxFlex="32"
                                    fxFlex.lt-md="100">
            </gipi-input-select-enum>
        </div>
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <gipi-input-select-paged label="Tipo de cobrança"
                                     id="paymentMethodChargeType"
                                     name="paymentMethodChargeType"
                                     [required]="true"
                                     [disabled]="loading || (typeOperation === 'VIEW')"
                                     [(model)]="paymentMethod.chargeType"
                                     [nextBatchFn]="chargeTypeFindByValueFn"
                                     [property]="'description'"
                                     fxFlex
                                     fxFlex.lt-md="100">
            </gipi-input-select-paged>
        </div>
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <itss-input-currency label="Entrada (%)"
                                 id="paymentMethodEntryPercentage"
                                 name="paymentMethodEntryPercentage"
                                 styleClassFormField="input-checkbox-in-input"
                                 [required]="paymentMethod.hasEntry"
                                 [disabled]="loading || (typeOperation === 'VIEW')"
                                 [placeholder]="'0,00 %'"
                                 [(ngModel)]="paymentMethod.entryPercentage"
                                 [options]="{ min: 0, max: 100, nullable: false, prefix: '', suffix: ' %' }"
                                 fxFlex
                                 fxFlex.lt-md="100">
                <ng-template #suffix>
                    <div class="hover-has-entry">
                        <gipi-input-checkbox label="Possui entrada"
                                             id="hasEntry"
                                             name="hasEntry"
                                             [disabled]="loading || (typeOperation === 'VIEW')"
                                             [(ngModel)]="paymentMethod.hasEntry">
                        </gipi-input-checkbox>
                    </div>
                </ng-template>
            </itss-input-currency>


            <!-- <itss-input-currency label="Entrada (%)"
                                 id="paymentMethodEntryPercentage"
                                 name="paymentMethodEntryPercentage"
                                 [required]="true"
                                 [disabled]="loading || (typeOperation === 'VIEW')"
                                 [placeholder]="'0,00 %'"
                                 [(ngModel)]="paymentMethod.entryPercentage"
                                 [options]="{ min: 0, max: 100, nullable: false, prefix: '', suffix: ' %' }"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency> -->
            <itss-input-currency label="Acréscimo (%)"
                                 id="paymentMethodAdditionPercentage"
                                 name="paymentMethodAdditionPercentage"
                                 [required]="true"
                                 [disabled]="loading || (typeOperation === 'VIEW') || paymentMethod?.discount"
                                 [placeholder]="'0,00 %'"
                                 [(ngModel)]="paymentMethod.addition"
                                 [options]="{ min: 0, max: 100, nullable: false, prefix: '', suffix: ' %' }"
                                 (ngModelChange)="paymentMethod.discount = 0"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-input-currency label="Desconto (%)"
                                 id="paymentMethodDiscountPercentage"
                                 name="paymentMethodDiscountPercentage"
                                 [required]="true"
                                 [disabled]="loading || (typeOperation === 'VIEW') || paymentMethod?.addition"
                                 [placeholder]="'0,00 %'"
                                 [(ngModel)]="paymentMethod.discount"
                                 [options]="{ min: 0, max: 100, nullable: false, prefix: '', suffix: ' %' }"
                                 (ngModelChange)="paymentMethod.addition = 0"
                                 fxFlex="32"
                                 fxFlex.lt-md="100">
            </itss-input-currency>
        </div>
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <itss-input label="Quantidade de parcela"
                        id="paymentMethodInstallmentsNumber"
                        name="paymentMethodInstallmentsNumber"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [placeholder]="'0'"
                        [mask]="'0*'"
                        [dropSpecialCharacters]="true"
                        [(ngModel)]="paymentMethod.installmentsNumber"
                        [maxlength]="3"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Dias da primeira parcela"
                        id="paymentMethodNumberDaysWinsFirstInstallment"
                        name="paymentMethodNumberDaysWinsFirstInstallment"
                        [mask]="'0*'"
                        [placeholder]="'0'"
                        [dropSpecialCharacters]="true"
                        [disabled]="loading || (typeOperation === 'VIEW') || (paymentMethod.installmentsNumber && (paymentMethod.installmentsNumber <= 1))"
                        [maxlength]="3"
                        [(ngModel)]="paymentMethod.numberDaysWinsFirstInstallment"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Dias entre parcelas"
                        id="paymentMethodDaysBetweenInstallments"
                        name="paymentMethodDaysBetweenInstallments"
                        [mask]="'0*'"
                        [placeholder]="'0'"
                        [dropSpecialCharacters]="true"
                        [disabled]="loading || (typeOperation === 'VIEW') || (paymentMethod.installmentsNumber && (paymentMethod.installmentsNumber <= 1))"
                        [maxlength]="3"
                        [(ngModel)]="paymentMethod.daysBetweenInstallments"
                        fxFlex="32"
                        fxFlex.lt-md="100">
            </itss-input>
        </div>
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <gipi-input-checkbox label="Abrir gaveta"
                                 id="paymentMethodOpenDrawer"
                                 name="paymentMethodOpenDrawer"
                                 [disabled]="loading || (typeOperation === 'VIEW')"
                                 [(ngModel)]="paymentMethod.openDrawer"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </gipi-input-checkbox>
            <gipi-input-checkbox label="Mostrar no PDV"
                                 id="paymentMethodViewAtPDV"
                                 name="paymentMethodViewAtPDV"
                                 [disabled]="loading || (typeOperation === 'VIEW')"
                                 [(ngModel)]="paymentMethod.viewAtPDV"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </gipi-input-checkbox>
            <gipi-input-checkbox label="Mostrar em dados adicionais DANFe"
                                 id="paymentMethodDisplayAdditionalDANFData"
                                 name="paymentMethodDisplayAdditionalDANFData"
                                 [disabled]="loading || (typeOperation === 'VIEW')"
                                 [(ngModel)]="paymentMethod.displayAdditionalDANFData"
                                 fxFlex="32"
                                 fxFlex.lt-md="100">
            </gipi-input-checkbox>

        </div>
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <gipi-input-checkbox label="TEF integrado"
                                 id="paymentMethodIntegratedTEF"
                                 name="paymentMethodIntegratedTEF"
                                 [disabled]="loading || (typeOperation === 'VIEW')"
                                 [(ngModel)]="paymentMethod.integratedTEF"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </gipi-input-checkbox>
        </div>
        <div *ngIf="paymentMethod?.integratedTEF"
             fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0"
             fxFlexFill>
            <gipi-input-select-paged label="Administradora do cartão"
                                     id="paymentMethodCardAdministrator"
                                     name="paymentMethodCardAdministrator"
                                     [required]="true"
                                     [disabled]="loading || (typeOperation === 'VIEW')"
                                     [(model)]="cardAdministratorSelected"
                                     [nextBatchFn]="cardAdministratorFindByValueFn"
                                     [property]="'name'"
                                     fxFlex
                                     fxFlex.lt-md="100">
            </gipi-input-select-paged>
        </div>
    </div>
    <div mat-dialog-actions
         fxLayout="row wrap"
         fxLayout.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     name="btnSave"
                     *ngIf="(typeOperation !== 'VIEW')"
                     (click)="confirm()"
                     [disabled]="loading"
                     [loading]="loading"
                     fxFlex="32"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     name="btnReturn"
                     (click)="close('NONE')"
                     fxFlex="32"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>
