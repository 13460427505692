import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthUserService } from '@gipi-financial/user/services/user.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent } from '@gipisistemas/ng-core';
export class AuthorizationDialogComponent extends AbstractComponent {
    constructor(dialogRef, _userService, messageService, router, activatedRoute) {
        super(messageService, router, activatedRoute);
        this.dialogRef = dialogRef;
        this._userService = _userService;
        this.isViewPassword = false;
    }
    validateAuthorization() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.loading = true;
                this.loadingChange.emit(this.loading);
                yield this._userService.authorize(this.username, this.password).toPromise().then(authorized => {
                    this.dialogRef.close(authorized);
                }, error => {
                    throw new Error(error);
                });
            }
            catch (e) {
                this.loading = false;
                this.loadingChange.emit(this.loading);
                this.handleError(e);
            }
        });
    }
    close() {
        this.dialogRef.close(false);
    }
}
