import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class RegistrationIntermediaryService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(RegistrationPaths.intermediary, 'v1', baseService);
        this.baseService = baseService;
    }
}
RegistrationIntermediaryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationIntermediaryService_Factory() { return new RegistrationIntermediaryService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: RegistrationIntermediaryService, providedIn: "root" });
