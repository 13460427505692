import { BaseModel } from '@gipi-shared/models/base.model';
import { RegistrationPhoneTypePhone } from '../enums/phone-type-phone.enum';

export class RegistrationPhoneType extends BaseModel {

    description: string;

    type: RegistrationPhoneTypePhone;

}
