import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService, GIPIPageModel, GIPISortModel, GIPIUuid, ObjectUtil, StringUtil, UUIDUtil } from '@gipisistemas/ng-core';
import { RegistrationProductGroupingType } from '../enums/product-grouping-type.enum';
import { RegistrationProductGroupingFilterDTO } from '../models/dto/product-grouping-filter.dto';
import { RegistrationProductGrouping } from '../models/product-grouping.model';

@Injectable({ providedIn: 'root' })
export class RegistrationProductGroupingService extends GIPIAbstractCrudService<RegistrationProductGrouping, RegistrationProductGroupingFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(RegistrationPaths.productGrouping, 'v1', baseService);
    }

    findByValue<T = any>(value: string, page: number, size?: number, sort?: GIPISortModel, version?: string, type?: RegistrationProductGroupingType, upperProductGroupId?: GIPIUuid): Observable<GIPIPageModel<T>> {
        let lValue: string = value;
        if (value) {
            lValue = StringUtil.removeAccents(value).trim();
        } else {
            lValue = '';
        }
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        let lSort: string = '';
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.field)) {
            lSort = `&sort=${sort.field},${sort.direction}`;
        }
        let lType: string = '';
        if (!StringUtil.isEmpty(type)) {
            lType = `&type=${type}`;
        }
        let lUpperProductGroupId: string = '';
        if (!ObjectUtil.isNull(upperProductGroupId) && UUIDUtil.isValid(upperProductGroupId)) {
            lUpperProductGroupId = `&upperProductGroupId=${upperProductGroupId}`;
        }

        return this.baseService.httpClient.get(this.url(`find-by-value?page=${page}&size=${size}${lSort}&value=${lValue}${lType}${lUpperProductGroupId}`, version), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

}
