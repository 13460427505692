import { FinancialClient } from '@gipi-financial/client/models/client.model';
import { FinancialPostingCategory } from '@gipi-financial/posting-category/models/posting-category.model';
import { BaseModel } from '@gipi-shared/models/base.model';
import { GIPIUuid } from '@gipisistemas/ng-core';
import { FinancialUnlockBlockedClientEnum } from '../enums/unlock-blocked-client.enum';

export class FinancialConfiguration extends BaseModel {

    automateCreditRebate: boolean;

    blockNegativeClients: boolean;

    defaultClientCreditCategoryToCredit: FinancialPostingCategory;

    defaultClientCreditCategoryToDebit: FinancialPostingCategory;

    defaultClientForSales: FinancialClient;

    financialConfigurationId: GIPIUuid;

    fine: number;

    graceDays: number;

    interest: number;

    postingCategoryForAdditionToCredit: FinancialPostingCategory;

    postingCategoryForAdditionToDebit: FinancialPostingCategory;

    printPendingInstallmentsOnReceipt: boolean;

    showBusinessNameInReceiptQuery: boolean;

    showsDisabledCustomerInSearch: boolean;

    unlockBlockedClient: FinancialUnlockBlockedClientEnum | string;

    daysToNegative: number;

    postingCategoryForReceivementCardAdministrator: FinancialPostingCategory;

}
