<gipi-abstract-form id="dialogClientSummary"
                    name="dialogClientSummary"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogClientSummary">
        Ficha do Cliente
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row wrap"
             fxLayoutAlign="end end"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0">
            <itss-input label="CPF"
                        *ngIf="client?.cpf"
                        mask="000.000.000-00"
                        [disabled]="true"
                        [ngModel]="client.cpf"
                        fxFlex="22"
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="CNPJ"
                        *ngIf="client?.cnpj"
                        mask="00.000.000/0000-00"
                        [disabled]="true"
                        [ngModel]="client.cnpj"
                        fxFlex="22"
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Cliente"
                        [disabled]="true"
                        [ngModel]="client?.name"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <gipi-datepicker label="Cliente desde"
                             [disabled]="true"
                             [ngModel]="client?.createDate"
                             fxFlex="22"
                             fxFlex.lt-md="100">
            </gipi-datepicker>
        </div>

        <div class="card-information card"
             fxLayoutGap="40px">
            <div fxFlex
                 fxLayout="column"
                 fxFlex.lt-md="100"
                 fxFlex.lt-sm="100">
                <div class="information">
                    <span> Bloqueado: </span>
                    <strong> {{ client?.blocked ? 'Sim' : 'Não' }} </strong>
                </div>
                <div class="information">
                    <span> Compras: </span>
                    <strong> {{ client?.shopping }} </strong>
                </div>
                <div class="information">
                    <span> Atraso médio: </span>
                    <strong> {{ client?.averageDelay }} </strong>
                </div>
            </div>

            <div fxFlex
                 fxLayout="column"
                 fxFlex.lt-md="100"
                 fxFlex.lt-sm="100">
                <div class="information">
                    <span> Saldo crédito/haver: </span>
                    <strong [style.color]="getColorByAmount(client.creditBalance)">
                        {{ (client?.creditBalance || 0) | currency:'BRL':'symbol':'1.2-2' }}
                    </strong>
                </div>
                <div class="information">
                    <span> Pendências promissória: </span>
                    <strong [style.color]="getColorByAmount(client.pendingStoreCredit)">
                        {{ (client?.pendingStoreCredit || 0) | currency:'BRL':'symbol':'1.2-2' }}
                    </strong>
                </div>
                <div class="information">
                    <span> Pendências boleto: </span>
                    <strong [style.color]="getColorByAmount(client.pendingBillet)">
                        {{ (client?.pendingBillet || 0) | currency:'BRL':'symbol':'1.2-2' }}
                    </strong>
                </div>
            </div>

            <div fxFlex
                 fxLayout="column"
                 fxFlex.lt-md="100"
                 fxFlex.lt-sm="100">
                <div class="information">
                    <span> Nº de cheques: </span>
                    <strong> {{ client.numberChecks }} </strong>
                </div>
                <div class="information">
                    <span> Cheques a depositar: </span>
                    <strong [style.color]="getColorByAmount(client.checksToDeposit)">
                        {{ (client?.checksToDeposit || 0) | currency:'BRL':'symbol':'1.2-2' }}
                    </strong>
                </div>
                <div class="information">
                    <span> Cheques devolvidos: </span>
                    <strong [style.color]="getColorByAmount(client.bouncedChecks)">
                        {{ (client?.bouncedChecks || 0) | currency:'BRL':'symbol':'1.2-2' }}
                    </strong>
                </div>
            </div>
        </div>

        <div class="card-information"
             fxLayoutGap="16px"
             fxLayoutAlign="space-between stretch">
            <div class="card information bill-information"
                 fxFlex>
                <span> A receber atrasado: </span>
                <strong [style.color]="getColorByAmount(client.amountReceivableInOverdue)">
                    {{ (client?.amountReceivableInOverdue || 0) | currency:'BRL':'symbol':'1.2-2' }}
                </strong>
            </div>
            <div class="card information bill-information"
                 fxFlex>
                <span> A receber hoje: </span>
                <strong [style.color]="getColorByAmount(client.amountReceivable)">
                    {{ (client?.amountReceivableToday || 0) | currency:'BRL':'symbol':'1.2-2' }}
                </strong>
            </div>
        </div>
        <div class="card-information"
             fxLayoutGap="16px"
             fxLayoutAlign="space-between stretch">
            <div class="card information bill-information"
                 fxFlex>
                <span> Recebimentos futuros: </span>
                <strong [style.color]="getColorByAmount(client.amountReceivableToday)">
                    {{ (client?.amountReceivable || 0) | currency:'BRL':'symbol':'1.2-2' }}
                </strong>
            </div>
            <div class="card information bill-information"
                 fxFlex>
                <span> Total a receber: </span>
                <strong [style.color]="getColorByAmount(client.totalAmountReceivable)">
                    {{ (client?.totalAmountReceivable || 0) | currency:'BRL':'symbol':'1.2-2' }}
                </strong>
            </div>
        </div>

        <div class="card-information card card-top-five"
             fxLayoutGap="16px"
             fxLayoutAlign="space-between stretch">
            <strong> Top 5 produtos mais comprados </strong>

            <div *ngIf="client?.topFiveMostPurchasedProductsList?.length > 0"
                 fxLayout="row"
                 fxLayoutGap="16px">
                <div class="product-information left">
                    <div class="card-icon">
                        <mat-icon svgIcon="product_top_five"> </mat-icon>
                    </div>

                    <strong> {{ mostPurchasedProduct }} </strong>
                    <span> Item mais comprado </span>
                </div>

                <div class="product-information">
                    <div *ngFor="let product of client?.topFiveMostPurchasedProductsList"
                         class="information">
                        <strong> {{ product?.quantityPurchase }} </strong>
                        <span> {{ product?.description }} </span>
                    </div>
                </div>
            </div>

            <div *ngIf="client?.topFiveMostPurchasedProductsList?.length <= 0"
                 class="no-records">
                <mat-icon>sentiment_very_dissatisfied</mat-icon>
                <span>Nenhum registro foi encontrado</span>
            </div>
        </div>
    </div>

    <div mat-dialog-actions
         fxLayout="row wrap"
         fxFlex.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     mat-dialog-close
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>