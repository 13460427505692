import { BaseModel } from '@gipi-shared/models/base.model';
import { GIPIUuid } from '@gipisistemas/ng-core';

export class FinancialFlagCard extends BaseModel {

    description: string;

    code: string;

    globalFlagCardId: GIPIUuid;

    /** @Transient */
    img: string;

}
