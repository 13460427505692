import { FinancialPerson } from '@gipi-financial/person/models/person.model';
import { BaseModel } from '@gipi-shared/models/base.model';
import { GIPIUuid, UUIDUtil } from '@gipisistemas/ng-core';
import { FinancialCreditType } from './credit-type.model';

export class FinancialClient extends BaseModel {

    person: FinancialPerson;

    maximumDelay: number;

    blocked: boolean = false;

    controlClientLimit: boolean = false;

    creditTypeList: FinancialCreditType[];

    comments: string;

    ruralProducer: boolean;

    registrationClientId: GIPIUuid;

    /** @Transient */
    selected: boolean;

    /** @Transient */
    storeCreditLimit: number;

    /** @Transient */
    bankSlipLimit: number;

    /** @Transient */
    checkLimit: number;

    constructor(id?: GIPIUuid) {
        super();
        if (UUIDUtil.isValid(id)) {
            this.id = id;
        }
    }

}
