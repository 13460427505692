import { RegistrationCity } from '@gipi-registration/city/models/city.model';
import { RegistrationClientStatus } from '@gipi-registration/client/enums/client-status.enum';
import { RegistrationCountry } from '@gipi-registration/country/models/country.model';
import { RegistrationState } from '@gipi-registration/state/models/state.model';
import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';

export class RegistrationClientFilterDTO extends GIPIBaseAbstractFilterModel {

    blocked: boolean;

    enabled: boolean;

    /** @Transient */
    city: RegistrationCity;
    cityIbgeCode: number;

    /** @Transient */
    country: RegistrationCountry;
    countryCode: string;

    /** @Transient */
    state: RegistrationState;
    stateIbgeCode: number;

    /** @Transient */
    enabledAndDisabledStatus: RegistrationClientStatus;

    /** @Transient */
    blockedAndUnblockedStatus: RegistrationClientStatus;

}
