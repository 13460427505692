import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { BaseModel } from '@gipi-shared/models/base.model';

export class FinancialCancelPaymentDTO extends BaseModel {

    cancelDate: Date;

    description: String;

    billInstallmentList: FinancialBillInstallment[]

}
