import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';

export class SaleReasonDevolution extends GIPIBaseAbstractModel {

    /**
     * @Required Descrição
     * @Min 0 characters
     * @Max 200 characters
     */
    description: string;

}
