<div fxLayout="column"
     fxFlex
     fxLayoutGap="16px">
    <ng-container *ngFor="let address of addressList; let i = index; trackBy: ">
        <div [attr.id]="'groupAddress' + i"
             *ngIf="addressList && (addressList.length > 0) && !loading"
             class="group-panel-register"
             fxLayout="column"
             fxLayoutGap="16px"
             fxFlex>
            <gipi-button tooltip="Excluir"
                         gipi-icon
                         class="btn-delete-group"
                         id="buttonDeleteAddress"
                         name="buttonDeleteAddress"
                         icon="close"
                         *ngIf="!loading && !isViewing"
                         (click)="deleteAddress(address)">
            </gipi-button>

            <div fxLayout="row"
                 fxLayoutAlign="start"
                 fxLayoutGap="16px"
                 fxFlex>
                <itss-select-enum label="Tipo de endereço"
                                  id="typeAddress"
                                  name="typeAddress"
                                  [(ngModel)]="address.type"
                                  [enum]="typeAddressEnum"
                                  [disabled]="loading || isViewing || disabledTypeAddress"
                                  required="true"
                                  fxFlex
                                  fxFlex.lt-sm="100">
                </itss-select-enum>

                <itss-checkbox label="Endereço padrão"
                               id="useInInvoiceAddress"
                               name="useInInvoiceAddress"
                               [disabled]="loading || isViewing"
                               [ngModel]="address.useInInvoice"
                               (change)="validateUseInInvoice(address)"
                               class="checkbox-center"
                               color="primary"
                               fxFlex="20"
                               fxFlex.lt-sm="25">
                </itss-checkbox>
            </div>
            <div fxLayout="row"
                 fxLayout.lt-md="column"
                 fxLayoutAlign="start"
                 fxLayoutGap="16px">
                <itss-select-entity label="País"
                                    id="countryCityAddress"
                                    [required]="true"
                                    [disabled]="loading || isViewing"
                                    [entities]="countryList"
                                    [(model)]="address.country"
                                    (selectionChange)="setCountryCode(address)"
                                    [property]="'name'"
                                    fxFlex="20"
                                    fxFlex.lt-sm="100">
                </itss-select-entity>
                <itss-input label="CEP"
                            id="cepAddress"
                            [required]="true"
                            [disabled]="loading || isViewing"
                            [mask]="(person.typePerson !== 'FOREIGN_PERSON') ? '00.000-000' : ''"
                            [(ngModel)]="address.zipCode"
                            [min]="1"
                            [max]="(person.typePerson === 'FOREIGN_PERSON') ? 9 : 10"
                            [maxlength]="(person.typePerson === 'FOREIGN_PERSON') ? 9 : 10"
                            (keydown.enter)="findCep(address)"
                            fxFlex="20"
                            fxFlex.lt-sm="100">
                    <ng-template #suffix
                                 *ngIf="person.typePerson !== 'FOREIGN_PERSON'">
                        <div fxLayout="row"
                             fxLayoutGap="5px">
                            <gipi-button tooltip="Buscar CEP"
                                         gipi-icon
                                         id="btnSearchCepAddress"
                                         svgIcon="search"
                                         [forInput]="true"
                                         [disabled]="loadingFindCep || loading || isViewing"
                                         (click)="findCep(address)">
                            </gipi-button>
                        </div>
                    </ng-template>
                </itss-input>
                <itss-input label="Endereço"
                            id="streetAddress"
                            [min]="3"
                            [max]="60"
                            [maxlength]="60"
                            [required]="true"
                            [disabled]="loading || isViewing"
                            [(ngModel)]="address.street"
                            fxFlex
                            fxFlex.lt-sm="100">
                </itss-input>
                <itss-input label="Número"
                            id="streetNumberAddress"
                            styleClassFormField="input-checkbox-in-input"
                            *ngIf="person.typePerson !== 'FOREIGN_PERSON'"
                            [disabled]="loading || isViewing || address.withoutStreetNumber"
                            [required]="true"
                            [(ngModel)]="address.streetNumber"
                            [min]="2"
                            [max]="6"
                            [maxlength]="6"
                            (ngModelChange)="validateStreetNumber($event, address)"
                            fxFlex="20"
                            fxFlex.lt-sm="100">
                    <ng-template #suffix
                                 *ngIf="!loading && !isViewing">
                        <div class="hover-street-number">
                            <gipi-input-checkbox label="Sem número"
                                                 id="withoutStreetNumberAddress"
                                                 name="withoutStreetNumberAddress"
                                                 [disabled]="loading || isViewing"
                                                 [(ngModel)]="address.withoutStreetNumber"
                                                 (change)="onChangeStreetNumber($event, address)">
                            </gipi-input-checkbox>
                        </div>
                    </ng-template>
                </itss-input>
            </div>
            <div fxLayout="row"
                 fxLayout.lt-md="column"
                 fxLayoutAlign="start"
                 fxLayoutGap="16px"
                 fxFlex>
                <itss-select-entity label="Estado"
                                    id="stateCityAddress"
                                    name="stateCityAddress"
                                    *ngIf="person.typePerson !== 'FOREIGN_PERSON'"
                                    [entities]="stateList"
                                    [(model)]="address.state"
                                    (selectionChange)="getCity(address)"
                                    [disabled]="loading || isViewing"
                                    [showClear]="true"
                                    [required]="true"
                                    property="acronym"
                                    fxFlex="20"
                                    fxFlex.lt-sm="100">
                </itss-select-entity>
                <itss-select-entity-paged label="Cidade"
                                          id="cityAddress"
                                          name="cityAddress"
                                          *ngIf="person.typePerson !== 'FOREIGN_PERSON'"
                                          required="true"
                                          property="name"
                                          [(model)]="address.city"
                                          [disabled]="loading || isViewing || (!address?.state)"
                                          [paramNextBatch]="address"
                                          [nextBatchFn]="cityFindByValueFn"
                                          fxFlex
                                          fxFlex.lt-sm="100">
                </itss-select-entity-paged>
                <itss-input label="Bairro"
                            id="neighborhoodAddress"
                            [min]="3"
                            [max]="50"
                            [maxlength]="50"
                            [required]="true"
                            [disabled]="loading || isViewing"
                            [(ngModel)]="address.neighborhood"
                            fxFlex
                            fxFlex.lt-sm="100">
                </itss-input>
            </div>
            <div fxLayout="row"
                 fxLayout.lt-md="column"
                 fxLayoutAlign="start"
                 fxLayoutGap="16px"
                 *ngIf="person.typePerson !== 'FOREIGN_PERSON'">
                <itss-textarea label="Complemento"
                               id="complementAddress"
                               name="complementAddress"
                               [maxlength]="80"
                               [rows]="4"
                               [disabled]="loading || isViewing"
                               [(ngModel)]="address.complement"
                               fxFlex
                               fxFlex.lt-sm="100">
                </itss-textarea>
            </div>
        </div>
    </ng-container>
    <div class="group-panel-register new-group-panel-register"
         id="buttonNewAddress"
         *ngIf="!loading && !isViewing"
         (click)="addNewAddress()">
        <label> + Adicionar endereço </label>
    </div>
</div>

<ng-template #useInInvoice
             let-address>
    <itss-checkbox label="Endereço padrão"
                   id="useInInvoiceAddress"
                   class="checkbox-center"
                   color="primary"
                   [disabled]="loading || isViewing"
                   [ngModel]="address.useInInvoice"
                   (change)="validateUseInInvoice(address)"
                   fxFlex="20"
                   fxFlex.lt-sm="30">
    </itss-checkbox>
</ng-template>