import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { AbstractCrudService, ArrayUtil, FilterDTO, ObjectUtil } from '@gipisistemas/ng-core';
import { RegistrationCountry } from '../models/country.model';

@Injectable({ providedIn: 'root' })
export class RegistrationCountryService extends AbstractCrudService<RegistrationCountry, FilterDTO> {

    constructor(http: HttpClient) {
        super(RegistrationPaths.country, http);
    }

    findByCode(code: string): Observable<RegistrationCountry> {
        return this.findByValue(code, 0, 10, { property: 'name', direction: 'asc' }).pipe(
            map(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    return page.content[0];
                }

                return null;
            }),
            catchError(this.handleError)
        );
    }

}
