<gipi-abstract-form id="dialogCancelPayment"
                    name="dialogCancelPayment">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogCancelPayment">
        Cancelar {{ (typeBill === 'PAYABLE') ? 'pagamento' : 'recebimento' }}
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px"
         style="overflow: hidden !important;">
        <div fxLayout="row"
             fxLayoutAlign="start"
             fxLayoutGap="16px"
             fxLayout.lt-sm="column">
            <gipi-datepicker label="Data do cancelamento"
                             [disabled]="true"
                             [required]="true"
                             [(ngModel)]="cancelDate"
                             fxFlex
                             fxFlex.lt-md="100">
            </gipi-datepicker>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="start"
             fxLayoutGap="16px"
             fxLayout.lt-sm="column">
            <itss-textarea label="Observação"
                           #firstInput
                           [required]="true"
                           [disabled]="isLoad || loading"
                           [rows]="5"
                           [(ngModel)]="description"
                           fxFlex
                           fxFlex.lt-md="100">
            </itss-textarea>
        </div>
    </div>
    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutGap="16px"
         fxLayoutAlign="end end">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnSave"
                     [disabled]="isLoad || loading"
                     [loading]="loading"
                     (click)="confirm()"
                     fxFlex.xl="30"
                     fxFlex.gt-xs>
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close('REMARK_SELECTED')"
                     fxFlex.xl="30"
                     fxFlex.gt-xs>
        </gipi-button>
    </div>
</gipi-abstract-form>