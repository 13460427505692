import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { BaseModel } from '@gipi-shared/models/base.model';
import { GIPIUuid } from '@gipisistemas/ng-core';

export class FinancialBankAccountTransfer extends BaseModel {

    origin: FinancialBankAccount;

    destiny: FinancialBankAccount;

    description: string;

    amount: number;

    date: Date;

    originPreviousBankAccountId: GIPIUuid;

    destinationPreviousBankAccountId: GIPIUuid;

    previousAmount: number;

    createdByConciliation: boolean;

    createdByMoves: boolean;

    /** @Transient */
    selected: boolean = false;

}
