import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { SaleConfiguration } from '@gipi-sale/configuration/models/configuration.model';
import { ArrayUtil, GIPIAbstractCrudService, GIPIBaseService, GIPIPageModel, GIPIUuid, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import { SalePaths } from '../../../paths/sale.paths';
import { SaleClient } from '../models/client.model';
import { SaleClientFilterDTO } from '../models/dto/client-filter.dto';
import { SaleClientSelectDTO } from '../models/dto/client-select.dto';

@Injectable({ providedIn: 'root' })
export class SaleClientService extends GIPIAbstractCrudService<SaleClient, SaleClientFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(SalePaths.client, 'v1', baseService);
    }

    getDescription(entity: SaleClientSelectDTO): string {
        let name: string = entity.name;

        const configuration: SaleConfiguration = this.baseService.sessionStorageService.get('configuration');
        if (!ObjectUtil.isNull(configuration)) {
            name = configuration.showBusinessNameInReceiptQuery ? (entity.fantasyName ? entity.fantasyName : entity.name) : entity.name;
        }

        if (entity.type === 'NATURAL_PERSON') {
            const cpf: string = !StringUtil.isEmpty(entity.cpfOrCnpj) ? StringUtil.format(entity.cpfOrCnpj, '000.000.000-00') + ' - ' : '';
            return `${cpf}${name}`;
        } else {
            const cnpj: string = !StringUtil.isEmpty(entity.cpfOrCnpj) ? StringUtil.format(entity.cpfOrCnpj, '00.000.000/0000-00') + ' - ' : '';
            return `${cnpj}${name}`;
        }
    }

    findByCpfOrCnpj(cpfOrCnpj: string): Observable<SaleClient[]> {
        return this.baseService.httpClient.get(this.url(`find-by-cpf-or-cnpj/${cpfOrCnpj}?page=0&size=1`), this.options()).pipe(
            map((json) => {
                const page: GIPIPageModel<SaleClient> = this.handleMapper(json);
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    return page.content;
                }
                return [];
            }),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    // getCard(id: number): Observable<ClientDTO> {
    getCard(id: GIPIUuid): Observable<any> {
        return this.baseService.httpClient.get(this.url(`card/${id}`), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

}
