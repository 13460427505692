import { Injectable } from '@angular/core';

import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import { RegistrationDepartment } from '../models/department.model';
import { RegistrationDepartmentFilterDTO } from '../models/dto/department-filter.dto';

@Injectable({ providedIn: 'root' })
export class RegistrationDepartmentService extends GIPIAbstractCrudService<RegistrationDepartment, RegistrationDepartmentFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(RegistrationPaths.department, 'v1', baseService)
    }

}
