import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { environment } from '@gipi-environment/environment';

import { GeneralIcons, MenuIcons, NewIcons } from '@gipi-assets/assets.enum';
import { FinancialCompany } from '@gipi-financial/company/models/company.model';
import { FinancialCompanyService } from '@gipi-financial/company/services/company.service';
import { ConfigurationDialogComponent } from '@gipi-financial/configuration/components/configuration-dialog/configuration-dialog.component';
import { FinancialConfigurationService } from '@gipi-financial/configuration/services/configuration.service';
import { OAuthUser } from '@gipi-financial/user/models/user.model';
import { OAuthUserService } from '@gipi-financial/user/services/user.service';
import { GlobalFileService } from '@gipi-global/file/services/file.service';
import { CustomerNotificationConfigDialogComponent } from '@gipi-notification/customer-notification-config/components/customer-notification-config-dialog/customer-notification-config-dialog.component';
import { NotificationInvoiceIntegrationsService } from '@gipi-notification/invoice-integrations/services/invoice-integrations.service';
import { GIPINoveltiesDialogComponent } from '@gipi-shared/components/novelties-dialog/novelties-dialog.component';
import { UserInformationDialogComponent } from '@gipi-shared/components/user-information-dialog/user-information-dialog.component';
import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { SessionStorageService } from '@gipi-shared/services/session-storage.service';
import { AbstractComponent, ArrayUtil, DialogService, GIPIUuid, MenuDTO, NavService, ObjectUtil, StringUtil, SvgRegisterService, TokenDTO } from '@gipisistemas/ng-core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends AbstractComponent implements OnInit, AfterViewInit, OnDestroy {

    public companyList: FinancialCompany[] = [];

    public company: FinancialCompany;

    public userLogged: OAuthUser;

    public userPhotoSrc: string = '';

    public hasNotification$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public isLoad: boolean = false;

    public widgetChat: boolean = false;

    compnayFn = (obj: FinancialCompany) => (!ObjectUtil.isNull(obj) && !ObjectUtil.isNull(obj.person)) ? obj.person.name : '';

    constructor(
        protected messageService: CustomMessageService,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        private _authenticationService: CustomAuthenticationService,
        private _userService: OAuthUserService,
        private _companyService: FinancialCompanyService,
        private _configurationService: FinancialConfigurationService,
        private _invoiceIntegrationsService: NotificationInvoiceIntegrationsService,
        private _dialogService: DialogService,
        private _navService: NavService,
        private _svgRegisterService: SvgRegisterService,
        private _fileService: GlobalFileService,
        private _sessionStorageService: SessionStorageService<any>,
    ) {
        super(messageService, router, activatedRoute);
    }

    async ngOnInit(): Promise<void> {
        this._registerSvgs();

        this.router.events.pipe(
            filter(e => e instanceof NavigationEnd && e.url.includes('login'))
        ).subscribe(() => this.reloadCurrentRoute());

        const token: TokenDTO<OAuthUser> = this._authenticationService.tokenValue;
        if (this._authenticationService.isValidToken(token)) {
            this.loading = true;
            this._loadScriptHotjar();
            this._setConfigurationInLocalStorage();

            this._getCompany(token);
            this._getUserLogged(token);
            this.getNotifications();
            this.loading = false;
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this._showWidgetChat();
        }, 1000);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.hasNotification$.next(false);
        this.hasNotification$.complete();
    }

    private async _loadScriptHotjar(): Promise<void> {
        const hotjarId: number = environment.hotjarId;
        if (hotjarId > 0) {
            let scriptElement: HTMLScriptElement = document.createElement('script');
            scriptElement.textContent = `
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:${hotjarId},hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `;

            document.getElementsByTagName('head')[0].appendChild(scriptElement);
        }
    }

    private _showWidgetChat(): void {
        const btnWidgetChat: HTMLButtonElement = (document.getElementById('widgetChat') as HTMLButtonElement);
        if (!ObjectUtil.isNull(btnWidgetChat)) {
            btnWidgetChat.style.display = 'flex';
        }
    }

    private _getUserLogged(token: TokenDTO<OAuthUser>): void {
        this._userService.getByUserName(token.user.username).toPromise().then(user => {
            this.userLogged = ObjectUtil.clone(user);
            this._getUserPhoto();
        }, error => {
            this.handleError(error);
        });
    }

    private _getCompany(token: TokenDTO<OAuthUser>): void {
        let count: number = 1;

        const filter: GIPIBaseAbstractFilterModel = new GIPIBaseAbstractFilterModel();
        filter.pageNumber = 0;
        filter.pageSize = 300;
        filter.researchField = '';

        this._companyService.findAll<GIPIBaseAbstractFilterModel, FinancialCompany>(filter).toPromise().then(page => {
            if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                if ((token.user.companyList.length !== page.content.length) && count <= 2) {
                    this._getCompany(token);
                    count++;
                } else {
                    this.companyList = ArrayUtil.clone(page.content);
                    const currentCompany: FinancialCompany = page.content.find(company => company.id === token.user.currentCompany);
                    this.company = ObjectUtil.clone(currentCompany);
                }

                if (
                    !ObjectUtil.isNull(this.company) &&
                    !ObjectUtil.isNull(this.company.person) &&
                    !ObjectUtil.isNull(this.company.person.legalPerson) &&
                    !ObjectUtil.isNull(this.company.person.legalPerson.lineOfBusiness)
                ) {
                    this._sessionStorageService.set('companyLineOfBusiness', this.company.person.legalPerson.lineOfBusiness);
                }
            }
        }, error => {
            this.handleError(error);
        });
    }

    public getNotifications(): void {
        if (
            ObjectUtil.isNull(this.hasNotification$) ||
            (!ObjectUtil.isNull(this.hasNotification$) && this.hasNotification$.closed)
        ) {
            this.hasNotification$ = new BehaviorSubject(false);
        }

        this._invoiceIntegrationsService.findIfHaveNotificationToReceive().toPromise().then(hasNotification => {
            this.hasNotification$.next(hasNotification);
        }).catch(e => {
            this.loading = false;
            this.addErrorMessage(e);
            this.hasNotification$.next(false);
        })
    }

    public reloadCurrentRoute(): void {
        const currentUrl: string = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([currentUrl]));
    }

    public toggle(): void {
        this._navService.toggle();
    }

    public isOpened(): boolean {
        return this._navService.isOpened;
    }

    private _registerSvgs(): void {
        this._svgRegisterService.registerListSvgIcons(GeneralIcons, '../assets/images/icons');
        this._svgRegisterService.registerListSvgIcons(MenuIcons, '../assets/images/menu');
        this._svgRegisterService.registerListSvgIcons(NewIcons, '../assets/images/new-icons');
    }

    public changeCompany(company: FinancialCompany): void {
        try {
            this.loading = true;
            this._authenticationService.changeCompany(company.id).toPromise().then(_ => {
                location.replace('/');
                this.loading = false;
            }).catch(error => {
                this.loading = false;
                this.handleError(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public get isAuthenticated(): Observable<boolean> {
        if (!ObjectUtil.isNull(this._authenticationService.tokenValueLocalStorage)) {
            return this._authenticationService.token.pipe(map(token => this._authenticationService.isValidToken(token)));
        }
        return of(false);
    }

    public get firstName(): Observable<string> {
        return this._authenticationService.token.pipe(map(token => !ObjectUtil.isNull(token) ? token.user.name.split(' ')[0] : StringUtil.EMPTY));
    }

    public get username(): Observable<string> {
        return this._authenticationService.token.pipe(map(token => !ObjectUtil.isNull(token) ? token.user.name : StringUtil.EMPTY));
    }

    public get email(): Observable<string> {
        return this._authenticationService.token.pipe(map(token => !ObjectUtil.isNull(token) ? token.user.username : StringUtil.EMPTY));
    }

    public get lastAccess(): Observable<Date> {
        return this._authenticationService.token.pipe(map(token => !ObjectUtil.isNull(token) ? token.user.lastAccess : null));
    }

    public get menuList(): Observable<MenuDTO[]> {
        return this._authenticationService.token.pipe(
            map(token => !ObjectUtil.isNull(token) ? token.user.menuList : [])
        );
    }

    public get hasNovelties(): boolean {
        // ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"]
        var date = new Date();
        var weekday: number = date.getDay();
        return ((weekday) && (weekday === 3));
    }

    public get hasNotification(): Observable<boolean> {
        return !ObjectUtil.isNull(this.hasNotification$)
            ? this.hasNotification$.asObservable()
            : of(false);
    }

    public get isCompanyGIPI(): Observable<boolean> {
        if (!environment.production) {
            return of(true);
        } else {
            const id: GIPIUuid = !ObjectUtil.isNull(this.company) ? this.company.id : null;
            const companyIdGIPI: GIPIUuid = environment.companyIdGIPI || -1;
            return of(
                this.UUIDIsValid(id) &&
                this.UUIDIsValid(companyIdGIPI) &&
                (id === environment.companyIdGIPI)
            );
        }
    }

    public logout(): void {
        this._authenticationService.logout();
        this.clearSessionStorage();
    }

    public openNoveltiesDialog(): void {
        this._dialogService.open({
            componentOrTemplateRef: GIPINoveltiesDialogComponent,
            width: '55%',
            height: '90%',
            panelClass: 'no-scrolls-dialog'
        });
    }

    public openUserInformationDialog(): void {
        this._dialogService.open({
            componentOrTemplateRef: UserInformationDialogComponent,
            data: ObjectUtil.clone(this.userLogged),
            width: '50%'
        }).afterClosed().toPromise().then((user: OAuthUser) => {
            if (!ObjectUtil.isNewModel(user)) {
                this.userLogged.name = user.name;
                this.userLogged.photo = user.photo;
                this._getUserPhoto();
            }
        });
    }

    private _getUserPhoto(): void {
        try {
            if (!ObjectUtil.isNewModel(this.userLogged) && !ObjectUtil.isNull(this.userLogged.photo) && !StringUtil.isEmpty(this.userLogged.photo)) {
                this.loading = true;
                this._fileService.download('authenticator', this.userLogged.photo).toPromise().then(resp => {
                    const fileReader: FileReader = new FileReader();
                    fileReader.readAsDataURL(resp.body);
                    fileReader.onloadend = () => this.userPhotoSrc = fileReader.result as string;
                    this.loading = false;
                }, error => {
                    throw new Error(error);
                });
            }
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    clearSessionStorage(): void {
        sessionStorage.clear();
    }

    public onMenuItemIntegrationChange(): void {
        this._dialogService.open({
            componentOrTemplateRef: CustomerNotificationConfigDialogComponent,
            data: null,
            width: '60%',
            height: '65rem'
        });
    }

    public onMenuItemConfigurationChange(): void {
        this._dialogService.open({
            componentOrTemplateRef: ConfigurationDialogComponent,
            data: null,
            width: '60%',
            height: '65rem'
        });
    }

    private _setConfigurationInLocalStorage(): void {
        this._configurationService.findEnabled().subscribe(configuration => this._sessionStorageService.set('configuration', configuration));
    }

}
