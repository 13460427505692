import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { AbstractCrudService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FinancialConfigurationService extends AbstractCrudService {
    constructor(http) {
        super(FinancialPaths.configuration, http);
    }
    findEnabled() {
        return this.http.get(this.url('enabled'), this.options()).pipe(map(this.mapper), catchError(this.handleError));
    }
}
FinancialConfigurationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialConfigurationService_Factory() { return new FinancialConfigurationService(i0.ɵɵinject(i1.HttpClient)); }, token: FinancialConfigurationService, providedIn: "root" });
