import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { RegistrationPersonConsultDTO } from '@gipi-registration/person/models/dto/person-consult.dto';
import { GIPIAbstractComponent, GIPIBaseService, StringUtil, TableColumnBuilder, TableColumnDTO } from '@gipisistemas/ng-core';

export interface PersonListData {
    entityList: RegistrationPersonConsultDTO[];
    titleDialog: string;
}

@Component({
    templateUrl: './person-list-dialog.component.html',
    styleUrls: ['./person-list-dialog.component.scss']
})
export class PersonListDialogComponent extends GIPIAbstractComponent implements OnInit, OnDestroy {

    @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;

    public entityList: RegistrationPersonConsultDTO[] = [];
    public titleDialog: string = '';

    public columns: TableColumnDTO[] = [];

    constructor(
        public dialogRef: MatDialogRef<PersonListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PersonListData = { entityList: [], titleDialog: '' },
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
    ) {
        super(baseService, activatedRoute);
        this.basePermissionList = {
            MAKE: '',
            UPDATE: '',
            READ: '',
            DELETE: '',
            ENABLE_DISABLE: ''
        };
    }

    ngOnInit() {
        super.ngOnInit();
        this.entityList = [...this.data.entityList];
        this.titleDialog = this.data.titleDialog;

        this.columns = this.createTableColumns();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public createTableColumns(): TableColumnDTO[] {
        return [
            TableColumnBuilder.instance()
                .property('cpf_cnpj')
                .name('CPF / CNPJ')
                .value((obj: RegistrationPersonConsultDTO) =>
                    !StringUtil.isEmpty(obj.cpfOrCnpj)
                        ? ((obj.type === 'LEGAL_PERSON') ? StringUtil.format(obj.cpfOrCnpj, '00.000.000/0000-00') : StringUtil.format(obj.cpfOrCnpj, '000.000.000-00'))
                        : ''
                )
                .action((obj: RegistrationPersonConsultDTO) => {
                    obj.selected = !obj.selected;
                    this.close(true, obj);
                })
                .width(20)
                .align('center center')
                .marginRight(15)
                .build(),
            TableColumnBuilder.instance()
                .property('name')
                .name('Nome')
                .marginLeft(15)
                .value((obj: RegistrationPersonConsultDTO) => obj.name ? obj.name : '')
                .action((obj: RegistrationPersonConsultDTO) => {
                    obj.selected = !obj.selected;
                    this.close(true, obj);
                })
                .build(),
            TableColumnBuilder.instance()
                .property('fantasyName')
                .name('Nome fantasia')
                .marginLeft(15)
                .value((obj: RegistrationPersonConsultDTO) => obj.fantasyName ? obj.fantasyName : '')
                .action((obj: RegistrationPersonConsultDTO) => {
                    obj.selected = !obj.selected;
                    this.close(true, obj);
                })
                .build(),
            TableColumnBuilder.instance()
                .property('city')
                .name('Cidade')
                .marginLeft(15)
                .value((obj: RegistrationPersonConsultDTO) => obj.city ? obj.city : '')
                .action((obj: RegistrationPersonConsultDTO) => {
                    obj.selected = !obj.selected;
                    this.close(true, obj);
                })
                .build(),
            TableColumnBuilder.instance()
                .property('status')
                .name('Status')
                .align('center center')
                .width(13)
                .marginLeft(5)
                .marginRight(5)
                .template(this.statusTemplate)
                .action((obj: RegistrationPersonConsultDTO) => {
                    obj.selected = !obj.selected;
                    this.close(true, obj);
                })
                .build(),
        ];
    }

    public close(isConfirm: boolean, personSelected: RegistrationPersonConsultDTO): void {
        try {
            if (isConfirm) {
                this.dialogRef.close(personSelected);
            } else {
                this.dialogRef.close(null);
            }
        } catch (e) {
            this.handleError(e);
        }
    }

    public setTextStatus(entity: RegistrationPersonConsultDTO): string {
        return entity.enabled ? 'Ativo' : 'Inativo';
    }

    public setColorStatus(entity: RegistrationPersonConsultDTO): string {
        return entity.enabled ? '#02840f' : '#c24245';
    }

}
