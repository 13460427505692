import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class RegistrationEmployeeRoleService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(RegistrationPaths.employeeRole, 'v1', baseService);
        this.baseService = baseService;
    }
}
RegistrationEmployeeRoleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationEmployeeRoleService_Factory() { return new RegistrationEmployeeRoleService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: RegistrationEmployeeRoleService, providedIn: "root" });
