import { HttpClient } from '@angular/common/http';
import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { AbstractCrudService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class RegistrationSocialNetworkTypeService extends AbstractCrudService {
    constructor(http) {
        super(RegistrationPaths.socialNetworkType, http);
    }
}
RegistrationSocialNetworkTypeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationSocialNetworkTypeService_Factory() { return new RegistrationSocialNetworkTypeService(i0.ɵɵinject(i1.HttpClient)); }, token: RegistrationSocialNetworkTypeService, providedIn: "root" });
