import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialBankAccountService } from '@gipi-financial/bank-account/services/bank-account.service';
import { FinancialCheckReceived } from '@gipi-financial/check-received/models/check-received.model';
import { FinancialCheckReceivedService } from '@gipi-financial/check-received/services/check-received.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, DateUtil, ObjectUtil, PageDTO } from '@gipisistemas/ng-core';

export interface ActionsCheckReceivedData {
    entityList: FinancialCheckReceived[];
    action: 'DEPOSIT_CHECK' | 'CUSTODY_CHECK' | 'COMPENSATE_DEPOSIT_CHECK';
}

@Component({
    templateUrl: './actions-check-received-dialog.component.html',
})
export class ActionsCheckReceivedDialogComponent extends AbstractComponent implements OnInit {

    checkReceivedList: FinancialCheckReceived[] = [];

    bankAccount: FinancialBankAccount;

    action: 'DEPOSIT_CHECK' | 'CUSTODY_CHECK' | 'COMPENSATE_DEPOSIT_CHECK';

    moveDate: Date;

    bankAccountFindByTypeFn = async (value: string, page: number) => {
        const result: PageDTO<FinancialBankAccount> = await this._bankAccountService.findByValue(value, page, 10).toPromise();
        return result;
    };

    constructor(
        public dialogRef: MatDialogRef<ActionsCheckReceivedDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ActionsCheckReceivedData,
        private _checkReceivedService: FinancialCheckReceivedService,
        private _bankAccountService: FinancialBankAccountService,
        messageService: CustomMessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();
        this.checkReceivedList = Object.keys(this.data.entityList).map(key => this.data.entityList[key]);
        this.action = this.data.action;
        this.moveDate = new Date();
    }

    setTitle(): string {
        switch (this.action) {
            case 'DEPOSIT_CHECK': {
                return 'Depositar';
            }
            case 'CUSTODY_CHECK': {
                return 'Enviar para custódia';
            }
            case 'COMPENSATE_DEPOSIT_CHECK': {
                return 'Compensar';
            }
        }
    }

    setLabelDate(): string {
        switch (this.action) {
            case 'DEPOSIT_CHECK': {
                return 'Data depósito';
            }
            case 'CUSTODY_CHECK': {
                return 'Data envio custódia';
            }
            case 'COMPENSATE_DEPOSIT_CHECK': {
                return 'Data compensação';
            }
        }
    }

    private validateMovement(): void {
        if (!DateUtil.isValid(this.moveDate)) {
            throw new Error(`Campo ${this.setLabelDate()} foi informado incorretamente`);
        }

        if ((ObjectUtil.isNewModel(this.bankAccount)) && ((this.action === 'DEPOSIT_CHECK') || (this.action === 'CUSTODY_CHECK'))) {
            throw new Error('Campo conta bancária é obrigatório e não informado');
        }
    }

    async confirm(): Promise<void> {
        try {
            this.validateMovement();
            this.loading = true;

            for (let i = 0; i < this.checkReceivedList.length; i++) {
                this.checkReceivedList[i].check.moveDate = this.moveDate;

                switch (this.action) {
                    case 'DEPOSIT_CHECK': {
                        this.checkReceivedList[i].bankAccountIdReversal = Number(this.checkReceivedList[i].bankAccount.id);
                        this.checkReceivedList[i].bankAccount = this.bankAccount;
                        this.checkReceivedList[i].check.status = 'DEPOSITED';
                        break;
                    }
                    case 'CUSTODY_CHECK': {
                        this.checkReceivedList[i].bankAccountIdReversal = Number(this.checkReceivedList[i].bankAccount.id);
                        this.checkReceivedList[i].bankAccount = this.bankAccount;
                        this.checkReceivedList[i].check.status = 'IN_CUSTODY';
                        break;
                    }
                    case 'COMPENSATE_DEPOSIT_CHECK': {
                        this.checkReceivedList[i].check.status = 'COMPENSATED';
                        break;
                    }
                }
            }

            this._checkReceivedService.moveCheckReceived(this.checkReceivedList, this.action).toPromise().then(() => {
                this.close('RELOAD_TABLE');
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE'): void {
        this.dialogRef.close(operation);
    }

}
