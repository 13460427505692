import { RegistrationCity } from '@gipi-registration/city/models/city.model';
import { AbstractDTO } from '@gipisistemas/ng-core';
import { RegistrationIntegrationConsultCnpjType, RegistrationIntegrationConsultCnpjTypeEnum } from '../../enums/integration-consult-cnpj-type.enum';
import { RegistrationIntegrationConsultCnpjCorporateActivityDTO } from './integration-consult-cnpj-corporate-activity.dto';
import { RegistrationIntegrationConsultCnpjCorporateStructureDTO } from './integration-consult-cnpj-corporate-structure.dto';

export class RegistrationIntegrationConsultCnpjDTO extends AbstractDTO {

    city: RegistrationCity;

    cnpj: string;

    complement: string;

    corporateActivityList: RegistrationIntegrationConsultCnpjCorporateActivityDTO[];

    corporateStructureList: RegistrationIntegrationConsultCnpjCorporateStructureDTO[];

    dateOfLastGIPIUpdate: Date;

    dateSpecialSituation: Date;

    dateStatus: Date;

    email: string;

    fantasy: string;

    lastUpdate: Date;

    legalNature: string;

    name: string;

    neighborhood: string;

    openDate: Date;

    phoneNumber: string;

    responsibleForTheCompany: string;

    shareCapital: number;

    situation: string;

    situationReason: string;

    sizeCompany: string;

    specialSituation: string;

    status: string;

    street: string;

    streetNumber: string;

    type: RegistrationIntegrationConsultCnpjTypeEnum | RegistrationIntegrationConsultCnpjType;

    zipCode: string;

}
