import { catchError, map, takeUntil } from 'rxjs/operators';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class FinancialSaleService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(FinancialPaths.sale, 'v1', baseService);
        this.baseService = baseService;
    }
    findByBillInstallment(billInstallmentId) {
        return this.baseService.httpClient.get(this.url(`find-by-bill-installment/${billInstallmentId}`), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    updateSaleItemList(saleItemList) {
        return this.baseService.httpClient.post(this.url('update-sale-item-list'), saleItemList, this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    findAllSaleConciliation(filter) {
        return this.baseService.httpClient.post(this.url('find-all-sale-conciliation'), filter, this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
FinancialSaleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialSaleService_Factory() { return new FinancialSaleService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: FinancialSaleService, providedIn: "root" });
