import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as moment_ from 'moment';
const moment = moment_;
import { FinancialBankAccountService } from '@gipi-financial/bank-account/services/bank-account.service';
import { FinancialBankSlipConfigurationService } from '@gipi-financial/bank-account/services/bank-slip-configuration.service';
import { FinancialBilletFinancialService } from '@gipi-financial/bank-slip/services/billet-financial.service';
import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialBill } from '@gipi-financial/bill/models/bill.model';
import { FinancialBillInstallmentService } from '@gipi-financial/bill/services/bill-installment.service';
import { FinancialChargeTypeService } from '@gipi-financial/charge-type/services/charge-type.service';
import { FinancialClientService } from '@gipi-financial/client/services/client.service';
import { FinancialPostingCategoryService } from '@gipi-financial/posting-category/services/posting-category.service';
import { NotificationNotificationClosingMonthFilterDTO } from '@gipi-notification/notification-closing-month/models/dto/notification-closing-month-filter.dto';
import { NotificationNotificationClosingMonthService } from '@gipi-notification/notification-closing-month/services/notification-closing-month.service';
import { BankCodeUtil } from '@gipi-shared/utils/bank-codes-billet.util';
import { APP_MESSAGES, ArrayUtil, AuthenticationService, GIPIAbstractComponent, GIPIBaseService, INJECTOR, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
export class GenerateBillReceivableDialogComponent extends GIPIAbstractComponent {
    constructor(service, baseService, activatedRoute, _authenticationService, _postingCategoryService, _clientService, _chargeTypeService, _bankAccountService, _bankSlipConfigurationService, _billetFinancialService, _notificationClosingMonthService, data = { notificationStatement: null }, dialogRef) {
        super(baseService, activatedRoute);
        this.service = service;
        this.baseService = baseService;
        this.activatedRoute = activatedRoute;
        this._authenticationService = _authenticationService;
        this._postingCategoryService = _postingCategoryService;
        this._clientService = _clientService;
        this._chargeTypeService = _chargeTypeService;
        this._bankAccountService = _bankAccountService;
        this._bankSlipConfigurationService = _bankSlipConfigurationService;
        this._billetFinancialService = _billetFinancialService;
        this._notificationClosingMonthService = _notificationClosingMonthService;
        this.data = data;
        this.dialogRef = dialogRef;
        this.billInstallment = this._newEntity();
        this.chargeTypeList = [];
        this.clientList = [];
        this.bankAccountFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._bankAccountService.findByValue(value, page, 10, { property: 'description', direction: 'asc' }).toPromise();
            return result;
        });
        this.postingCategoryFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._postingCategoryService.findByValue(value, page, 10, { property: 'fullCode', direction: 'asc' }, 'CREDIT').toPromise();
            if (!ObjectUtil.isNull(result) && !ArrayUtil.isEmpty(result.content)) {
                result.content = result.content.filter(category => category.enabled && !category.hasChildren);
                return result;
            }
            else {
                result.content = [];
            }
        });
        this.postingCategoryFn = (obj) => `${obj.fullCode} - ${obj.description}`;
        this.clientFn = (obj) => this._clientService.getDescription(obj);
        this.dialogRef.disableClose = true;
    }
    ngOnInit() {
        super.ngOnInit();
    }
    _findChargeType(billInstallment) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.chargeTypeList = yield this._chargeTypeService.findByTypes(['BANK_SLIP', 'STORE_CREDIT',]).toPromise();
            if (!ObjectUtil.isNull(billInstallment) && !ArrayUtil.isEmpty(this.chargeTypeList)) {
                billInstallment.chargeType = this.chargeTypeList.find(e => e.type === 'BANK_SLIP');
            }
        });
    }
    _findClient(billInstallment) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!ObjectUtil.isNull(this.data) &&
                !ObjectUtil.isNull(this.data.notificationStatement) &&
                !StringUtil.isEmpty(this.data.notificationStatement.cnpj)) {
                this.clientList = yield this._clientService.findByCpfOrCnpj(this.data.notificationStatement.cnpj).toPromise();
            }
            if (!ObjectUtil.isNull(billInstallment) && !ArrayUtil.isEmpty(this.clientList)) {
                billInstallment.bill.client = this.clientList[0];
            }
        });
    }
    _newEntity() {
        const billInstallment = new FinancialBillInstallment();
        this._userLoggedToken = this._authenticationService.tokenValue.user;
        this._findChargeType(billInstallment);
        this._findClient(billInstallment);
        billInstallment.bill = new FinancialBill();
        billInstallment.bill.type = 'RECEIVABLE';
        billInstallment.userId = this._userLoggedToken.id;
        billInstallment.status = 'RECEIVABLE';
        billInstallment.conciled = false;
        billInstallment.createdByConciliation = false;
        billInstallment.description = `COBRANÇA REF. A INTEGRAÇÃO COM WHATSAPP (TWILIO)`;
        billInstallment.issuanceDate = new Date();
        billInstallment.dueDate = new Date();
        billInstallment.paymentDate = null;
        billInstallment.dischargeDate = null;
        billInstallment.dischargeAmount = 0;
        billInstallment.amount = 0;
        if (!ObjectUtil.isNull(this.data) && !ObjectUtil.isNull(this.data.notificationStatement)) {
            billInstallment.amount = this.data.notificationStatement.price;
        }
        return billInstallment;
    }
    saveBillInstallment() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.loading = true;
                const messageValidate = this.service.isValid(this.billInstallment);
                if (!StringUtil.isEmpty(messageValidate)) {
                    this.addWarningMessage(messageValidate);
                    this.loading = false;
                    return;
                }
                if (this.billInstallment.chargeType.type === 'BANK_SLIP') {
                    if (this.billInstallment.bankAccount.type !== 'CHECKING_ACCOUNT') {
                        this.addWarningMessage('Não é possível gerar boleto usando uma conta bancária que não seja do tipo "Conta corrente"');
                        this.loading = false;
                        return;
                    }
                    const allowedBankAccount = BankCodeUtil.isValidBankCode(this.billInstallment.bankAccount.bank.code);
                    if (!allowedBankAccount) {
                        this.addWarningMessage('Não é possível gerar boleto usando uma conta bancária que não seja válida');
                        this.loading = false;
                        return;
                    }
                    const bankSlipConfiguration = yield this._bankSlipConfigurationService.findByBankAccountId(this.billInstallment.bankAccount.id).toPromise();
                    if (ObjectUtil.isNull(bankSlipConfiguration)) {
                        this.addWarningMessage('Boleto não configurado para a conta bancária selecionada');
                        this.loading = false;
                        return;
                    }
                }
                this.billInstallment.originalAmount = this.billInstallment.amount;
                this.billInstallment.ourNumber = null;
                this.service.save(this.billInstallment).toPromise().then(entity => {
                    this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                    this.billInstallment = entity;
                    if (this.billInstallment.chargeType.type === 'BANK_SLIP') {
                        this._confirmGenerateBankSlip();
                    }
                    else {
                        this._closingMonth();
                    }
                }, error => {
                    throw new Error(error);
                });
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    _confirmGenerateBankSlip() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.loading = true;
                const isConfirmed = yield this.baseService.confirmationService.confirm({
                    title: 'Confirmação',
                    message: 'Deseja imprimir o boleto?',
                });
                this._billetFinancialService.generateBillet(this.billInstallment, isConfirmed).toPromise().then(resp => {
                    if (isConfirmed) {
                        const file = new Blob([resp.body], { type: 'application/pdf' });
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    }
                    this._closingMonth();
                }, error => {
                    this.loading = false;
                    throw new Error(error);
                });
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    _closingMonth() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.loading = true;
                const closingMonth = new NotificationNotificationClosingMonthFilterDTO();
                closingMonth.companyIdList = [this.billInstallment.bill.client.id];
                closingMonth.startDate = this.data.notificationStatement.initialDate;
                closingMonth.endDate = this.data.notificationStatement.endDate;
                const listSaved = yield this._notificationClosingMonthService.closeMonth(closingMonth).toPromise().catch(error => {
                    this.loading = false;
                    this.addErrorMessage(error);
                    return [];
                });
                if (!ArrayUtil.isEmpty(listSaved)) {
                    this.close('RELOAD_TABLE');
                }
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation) {
        this.dialogRef.close(operation);
    }
}
