import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';

export class RegistrationEmployeeRole extends GIPIBaseAbstractModel {

    /**
     * @Required Descrição
     * @Min 5 characters
     * @Max 80 characters
     */
    description: string;

}
