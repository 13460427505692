import { FinancialAccounting } from '@gipi-financial/accounting/models/accounting.model';
import { FinancialPerson } from '@gipi-financial/person/models/person.model';
import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { GIPIUuid } from '@gipisistemas/ng-core';

export class FinancialCompany extends GIPIBaseAbstractModel {

    person: FinancialPerson;

    responsibleName: string;

    brand: string;

    accounting: FinancialAccounting;

    registrationCompanyId: GIPIUuid;

}
