import { StringUtil } from "@gipisistemas/ng-core";

export type BankCodes = (
    '001' | // Banco do brasil
    '003' | // Banco Amazônia
    '004' | // Banco do Nordeste do Brasil S.A.
    '021' | // BANESTES S.A. Banco do Estado do Espírito Santo
    '033' | // Banco Santander (Brasil) S.A.
    '041' | // Banco Banrisul
    '077' | // Banco Inter S.A.
    '097' | // Banco CrediSIS
    '104' | // Caixa Econômica Federal.
    '208' | // Banco BTGPactual
    '212' | // Banco Original
    '237' | // Bradesco
    '246' | // Banco Abc Brasil
    '336' | // Banco C6
    '341' | // Banco Itaú S.A.
    '389' | // Banco Mercantil do Brasil S.A.
    '399' | // Banco HSBC S.A.
    '422' | // Banco Safra S.A.
    '453' | // Banco Rendimento S.A.
    '745' | // Banco Citibank S.A.
    '748' | // Banco Cooperativo Sicredi S.A.
    '756' | // Siccob do vale
    '999' // StarkBank
);

export const bankCodeList: BankCodes[] = [
    '001', // Banco do brasil
    '003', // Banco Amazônia
    '004', // Banco do Nordeste do Brasil S.A.
    '021', // BANESTES S.A. Banco do Estado do Espírito Santo
    '033', // Banco Santander (Brasil) S.A.
    '041', // Banco Banrisul
    '077', // Banco Inter S.A.
    '097', // Banco CrediSIS
    '104', // Caixa Econômica Federal.
    '208', // Banco BTGPactual
    '212', // Banco Original
    '237', // Bradesco
    '246', // Banco Abc Brasil
    '336', // Banco C6
    '341', // Banco Itaú S.A.
    '389', // Banco Mercantil do Brasil S.A.
    '399', // Banco HSBC S.A.
    '422', // Banco Safra S.A.
    '453', // Banco Rendimento S.A.
    '745', // Banco Citibank S.A.
    '748', // Banco Cooperativo Sicredi S.A.
    '756', // Siccob do vale
    '999', // StarkBank
];

export class BankCodeUtil {

    public static isValidBankCode(bankCode: BankCodes) {
        if (StringUtil.isEmpty(bankCode)) {
            return false;
        }

        return bankCodeList.findIndex(code => code === bankCode) >= 0;
    }

    public static isValidGeneratePix(bankCode: BankCodes): boolean {
        const validBankCode: string[] = [
            '001', // Banco do brasil
            '033', // Santander
            '077', // Inter
            '104', // Caixa econômica
            '237', // Bradesco
            '341', // Itaú
            '748', // Sicredi
            '756', // Sicoob
        ];
        return validBankCode.findIndex(code => code === bankCode) >= 0;
    }

}
