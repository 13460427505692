import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';

import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialBankAccountService } from '@gipi-financial/bank-account/services/bank-account.service';
import { FinancialFilterByTypeDateEnum } from '@gipi-financial/bill/enums/filter-by-type-date.enum';
import { FinancialBillInstallmentConsultDTO } from '@gipi-financial/bill/models/dto/bill-installment-consult.dto';
import { FinancialBillFilterDTO } from '@gipi-financial/bill/models/dto/bill.filter.dto';
import { FinancialBillInstallmentService } from '@gipi-financial/bill/services/bill-installment.service';
import { FinancialClientSelectDTO } from '@gipi-financial/client/models/dto/client-select.dto';
import { FinancialClientService } from '@gipi-financial/client/services/client.service';
import { FinancialConfiguration } from '@gipi-financial/configuration/models/configuration.model';
import { FinancialPostingCategory } from '@gipi-financial/posting-category/models/posting-category.model';
import { FinancialPostingCategoryService } from '@gipi-financial/posting-category/services/posting-category.service';
import { FinancialProviderSelectDTO } from '@gipi-financial/provider/models/dto/provider-select.dto';
import { FinancialProviderService } from '@gipi-financial/provider/services/provider.service';
import { ArrayUtil, CurrencyUtil, DateUtil, GIPIAbstractComponent, GIPIAppliedFilter, GIPIBaseService, GIPISortModel, GIPIUuid, ObjectUtil, PageDTO, StringUtil, TableColumnBuilder, TableColumnDTO } from '@gipisistemas/ng-core';

export interface FindBillData {
    typeConciliation: 'BILLET_CONCILIATION' | 'TRANSACTION_CONCILIATION';
    billType: 'RECEIVABLE' | 'PAYABLE';
    installmentListSelected: FinancialBillInstallmentConsultDTO[];
    billInstallmentListUsed?: GIPIUuid[];
}

export type FilterTypes = 'begin_and_end_date' | 'begin_date' | 'end_date' | 'client' | 'provider' | 'bank_account' | 'document_number' | 'amount' | 'category';

@Component({
    templateUrl: './find-bill-dialog.component.html',
})
export class FindBillDialogComponent extends GIPIAbstractComponent implements OnInit {

    @ViewChild('checkboxTemplate', { static: true }) checkboxTemplate: TemplateRef<any>;

    @ViewChild('checkAllTemplate', { static: true }) checkAllTemplate: TemplateRef<any>;

    @ViewChild('displayButton', { static: true }) displayButton: TemplateRef<any>;

    @ViewChild('displayPayableButton', { static: true }) displayPayableButton: TemplateRef<any>;

    @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;

    public filter: FinancialBillFilterDTO = new FinancialBillFilterDTO();

    public page: PageDTO<FinancialBillInstallmentConsultDTO> = new PageDTO<FinancialBillInstallmentConsultDTO>();

    public columns: TableColumnDTO[] = [];

    public billInstallmentListSelected: FinancialBillInstallmentConsultDTO[] = [];

    public allTypesChecked: boolean = true;
    public indeterminateType: boolean = false;
    // Contas a receber
    public receivableChecked: boolean = false;
    public receivedChecked: boolean = false;
    public receivableOverdueChecked: boolean = false;
    public receivedPartialChecked: boolean = false;
    // Contas a pagar
    public payableOverdueChecked: boolean = false;
    public payableChecked: boolean = false;
    public paidChecked: boolean = false;

    public selected: boolean = false;
    public indeterminate: boolean = false;

    public filterByTypeDateEnum: typeof FinancialFilterByTypeDateEnum = FinancialFilterByTypeDateEnum;

    public appliedFilters: GIPIAppliedFilter<FilterTypes>[] = [];

    clientFindByValueFn = async (value: string, page: number) => {
        const configuration: FinancialConfiguration = this.baseService.sessionStorageService.get('configuration');
        let propertySort: string = 'person.name';
        if (!ObjectUtil.isNull(configuration) && configuration.showBusinessNameInReceiptQuery) {
            propertySort = 'person.fantasyName';
        }
        const result: PageDTO<FinancialClientSelectDTO> = await this._clientService.findByValue<FinancialClientSelectDTO>(value, page, 10, new GIPISortModel(propertySort, 'ASC')).toPromise();
        return result;
    };

    clientFn = (obj: FinancialClientSelectDTO) => this._clientService.getDescription(obj);

    providerFindByValueFn = async (value: string, page: number) => {
        const configuration: FinancialConfiguration = this.baseService.sessionStorageService.get('configuration');
        let propertySort: string = 'person.name';
        if (!ObjectUtil.isNull(configuration) && configuration.showBusinessNameInReceiptQuery) {
            propertySort = 'person.fantasyName';
        }
        const result: PageDTO<FinancialProviderSelectDTO> = await this._providerService.findByValue<FinancialProviderSelectDTO>(value, page, 10, new GIPISortModel(propertySort, 'ASC')).toPromise();
        return result;
    };

    providerFn = (obj: FinancialProviderSelectDTO) => this._providerService.getDescription(obj);

    bankAccountFindByValueFn = async (value: string, page: number) => {
        const result: PageDTO<FinancialBankAccount> = await this._bankAccountService.findByValue(value, page, 10, { property: 'description', direction: 'asc' }).toPromise();
        return result;
    };

    postingCategoryFindByValueFn = async (value: string, page: number) => {
        const typeOperation: 'DEBIT' | 'CREDIT' = !(this.data.billType === 'RECEIVABLE') ? 'DEBIT' : 'CREDIT';
        const result: PageDTO<FinancialPostingCategory> = await this._postingCategoryService.findByValue(value, page, 10, { property: 'fullCode', direction: 'asc' }, typeOperation).toPromise();

        if (!ObjectUtil.isNull(result) && !ArrayUtil.isEmpty(result.content)) {
            result.content = result.content.filter(category => category.enabled && !category.hasChildren);
            return result;
        } else {
            result.content = [];
        }
    };

    postingCategoryFn = (obj: FinancialPostingCategory) => `${obj.fullCode} - ${obj.description}`;

    constructor(
        protected service: FinancialBillInstallmentService,
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _clientService: FinancialClientService,
        private _providerService: FinancialProviderService,
        private _bankAccountService: FinancialBankAccountService,
        private _postingCategoryService: FinancialPostingCategoryService,
        public dialogRef: MatDialogRef<FindBillDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FindBillData,
    ) {
        super(baseService, activatedRoute);
    }

    async ngOnInit(): Promise<void> {
        this.columns = this._createTableBillInstallmentColumns();
        super.ngOnInit();
        this.filter.filterByTypeDate = 'DUE_DATE';

        for (let i = 0; i < this.data.installmentListSelected.length; i++) {
            this.data.installmentListSelected[i].selected = true;
            this.select(this.data.installmentListSelected[i], false);
        }
    }

    private _createTableBillInstallmentColumns(): TableColumnDTO[] {
        return [
            TableColumnBuilder.instance()
                .property('checkAll')
                .templateHeader(this.checkAllTemplate)
                .template(this.checkboxTemplate)
                .width(5)
                .build(),
            TableColumnBuilder.instance()
                .property('dueDate')
                .name('Vencimento')
                .sortable(true)
                .align('center center')
                .width(12)
                .value((obj: FinancialBillInstallmentConsultDTO) => DateUtil.format(obj.dueDate, DateUtil.DATE_FORMAT))
                .action((obj: FinancialBillInstallmentConsultDTO) => {
                    obj.selected = !obj.selected;
                    this.select(obj, true);
                })
                .build(),
            TableColumnBuilder.instance()
                .property('description')
                .name('Descrição')
                .sortable(true)
                .value((obj: FinancialBillInstallmentConsultDTO) => obj.description)
                .action((obj: FinancialBillInstallmentConsultDTO) => {
                    obj.selected = !obj.selected;
                    this.select(obj, true);
                })
                .build(),
            TableColumnBuilder.instance()
                .property('person.name')
                .name(this.data.billType === 'RECEIVABLE' ? 'Cliente' : 'Fornecedor')
                .sortable(true)
                .value((obj: FinancialBillInstallmentConsultDTO) => {
                    const configuration: FinancialConfiguration = this.baseService.sessionStorageService.get('configuration');
                    if (ObjectUtil.isNull(configuration)) {
                        return obj.name;
                    }
                    if (!configuration.showBusinessNameInReceiptQuery) {
                        return obj.name;
                    }
                    if (!StringUtil.isEmpty(obj.fantasyName)) {
                        return obj.fantasyName;
                    }
                    return obj.name;
                })
                .action((obj: FinancialBillInstallmentConsultDTO) => {
                    obj.selected = !obj.selected;
                    this.select(obj, true);
                })
                .build(),
            TableColumnBuilder.instance()
                .property('documentNumber')
                .name('Nº Documento')
                .sortable(true)
                .value((obj: FinancialBillInstallmentConsultDTO) => !StringUtil.isEmpty(obj.documentNumber) ? obj.documentNumber : '')
                .align('center center')
                .width(10)
                .action((obj: FinancialBillInstallmentConsultDTO) => {
                    obj.selected = !obj.selected;
                    this.select(obj, true);
                })
                .build(),
            TableColumnBuilder.instance()
                .property('amount')
                .name('Valor')
                .sortable(true)
                .align('center center')
                .width(10)
                .value((obj: FinancialBillInstallmentConsultDTO) => CurrencyUtil.transform(obj.amount, '1.2-2'))
                .action((obj: FinancialBillInstallmentConsultDTO) => {
                    obj.selected = !obj.selected;
                    this.select(obj, true);
                })
                .build(),
            TableColumnBuilder.instance()
                .property('status')
                .name('Status')
                .align('center center')
                .width(10)
                .template(this.statusTemplate)
                .action((obj: FinancialBillInstallmentConsultDTO) => {
                    obj.selected = !obj.selected;
                    this.select(obj, true);
                })
                .build(),
        ];
    }

    public setColorStatus(entity: FinancialBillInstallmentConsultDTO) {
        switch (entity.status) {
            case 'RECEIVABLE':
                return '#b4b4b4';
            case 'RECEIVED':
                return '#56b910';
            case 'RECEIVED_PARTIAL':
                return '#62757f';
            case 'GROUPED':
                return '#8256D0';
            case 'RENEGOTIATED':
                return '#2bd7d9';
            case 'RECEIVABLE_OVERDUE':
                return '#f5db00';
            case 'PAYABLE_OVERDUE':
                return '#f5db00';
            case 'PAYABLE':
                return '#b4b4b4';
            case 'PAID':
                return '#56b910';
            default:
                return '#b4b4b4';
        }
    }

    public setHintStatus(entity: FinancialBillInstallmentConsultDTO): string {
        switch (entity.status) {
            case 'RECEIVABLE':
                return 'A receber';
            case 'RECEIVED':
                return 'Recebida';
            case 'RECEIVED_PARTIAL':
                return 'Recebida parcialmente';
            case 'GROUPED':
                return 'Agrupada';
            case 'RENEGOTIATED':
                return 'Renegociada';
            case 'RECEIVABLE_OVERDUE':
                return 'A receber atrasada';
            case 'PAYABLE_OVERDUE':
                return 'A pagar atrasada';
            case 'PAYABLE':
                return 'A pagar';
            case 'PAID':
                return 'Paga';
            default:
                return 'Não identificado';
        }
    }

    protected clearAppliedFilters(): void {
        this.appliedFilters = [];
    }

    protected setAppliedFilters(): void {
        if (ObjectUtil.isNull(this.filter)) {
            return;
        }
        this.clearAppliedFilters();

        if (!ObjectUtil.isNull(this.filter.begin) && !ObjectUtil.isNull(this.filter.end)) {
            const description: string = `${DateUtil.format(this.filter.begin, DateUtil.DATE_FORMAT)} á ${DateUtil.format(this.filter.end, DateUtil.DATE_FORMAT)}`;
            this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>(description, 'begin_and_end_date'));
        } else {
            if (!ObjectUtil.isNull(this.filter.begin)) {
                const description: string = `Data inicial: ${DateUtil.format(this.filter.begin, DateUtil.DATE_FORMAT)}`;
                this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>(description, 'begin_date'));
            }
            if (!ObjectUtil.isNull(this.filter.end)) {
                const description: string = `Data final: ${DateUtil.format(this.filter.end, DateUtil.DATE_FORMAT)}`;
                this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>(description, 'end_date'));
            }
        }
        if (!ObjectUtil.isNull(this.filter.client)) {
            this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>('Cliente', 'client'));
        }
        if (!ObjectUtil.isNull(this.filter.provider)) {
            this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>('Fornecedor', 'provider'));
        }
        if (!ObjectUtil.isNull(this.filter.bankAccount)) {
            this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>('Conta bancária', 'bank_account'));
        }
        if (!StringUtil.isEmpty(this.filter.documentNumber)) {
            this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>('Número do documento', 'document_number'));
        }
        if (this.filter.amount) {
            this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>('Valor', 'amount'));
        }
        if (!ObjectUtil.isNull(this.filter.postingCategory)) {
            this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>('Categoria', 'category'));
        }
    }

    public removeAppliedFilter(filter: { chip: GIPIAppliedFilter<FilterTypes>, index: number }): void {
        switch (filter.chip.key) {
            case 'begin_and_end_date': {
                this.filter.begin = null;
                this.filter.end = null;
                break;
            }
            case 'begin_date': {
                this.filter.begin = null;
                break;
            }
            case 'end_date': {
                this.filter.end = null;
                break;
            }
            case 'client': {
                this.filter.client = null;
                this.filter.clientId = null;
                break;
            }
            case 'provider': {
                this.filter.provider = null;
                this.filter.providerId = null;
                break;
            }
            case 'bank_account': {
                this.filter.bankAccount = null;
                this.filter.bankAccountId = null;
                break;
            }
            case 'document_number': {
                this.filter.documentNumber = '';
                break;
            }
            case 'amount': {
                this.filter.amount = null;
                break;
            }
            case 'category': {
                this.filter.postingCategory = null;
                this.filter.postingCategoryId = null;
                break;
            }
        }

        this.find(null);
    }

    public find(pageEvent?: PageEvent): void {
        try {
            this.loading = true;
            this.filter.conciledOnly = this.data.typeConciliation === 'TRANSACTION_CONCILIATION';
            this.filter.billetConciliation = this.data.typeConciliation === 'BILLET_CONCILIATION';
            this.filter.type = this.data.billType;

            this._setStatusEnumList();
            if (this.allTypesChecked) {
                this.filter.billStatusList = [];
            }

            if (this.filter.begin && !DateUtil.isValid(new Date(this.filter.begin))) {
                this.loading = false;
                this.addWarningMessage('Data inicial não é uma data válida');
                return;
            }

            if (this.filter.end && !DateUtil.isValid(new Date(this.filter.end))) {
                this.loading = false;
                this.addWarningMessage('Data final não é uma data válida');
                return;
            }

            if (this.filter.begin && !this.filter.end) {
                this.loading = false;
                this.addWarningMessage('Data final não informada');
                return;
            }

            if (!this.filter.begin && this.filter.end) {
                this.loading = false;
                this.addWarningMessage('Data inicial não informada');
                return;
            }

            if (DateUtil.isLessThan(new Date(this.filter.end), new Date(this.filter.begin))) {
                this.loading = false;
                this.addWarningMessage('Data final não pode ser menor que data inicial');
                return;
            }
            if (!ObjectUtil.isNull(this.filter.client) && !ObjectUtil.isNewModel(this.filter.client)) {
                this.filter.clientId = Number(this.filter.client.id);
            }
            if (!ObjectUtil.isNull(this.filter.bankAccount) && !ObjectUtil.isNewModel(this.filter.bankAccount)) {
                this.filter.bankAccountId = Number(this.filter.bankAccount.id);
            }
            if (!ObjectUtil.isNull(this.filter.postingCategory) && !ObjectUtil.isNewModel(this.filter.postingCategory)) {
                this.filter.postingCategoryId = Number(this.filter.postingCategory.id);
            }

            // const existNumber: number = Number(this.filter.researchField ? this.filter.researchField.replace(',', '.') : 0);
            // if (!StringUtil.isEmpty(this.filter.researchField)) {
            //     this.filter.amount = NumberUtil.isPositive(existNumber) ? existNumber : null;
            //     this.filter.researchField = NumberUtil.isPositive(existNumber) ? null : this.filter.researchField;
            // }
            if (pageEvent) {
                this.filter.pageNumber = pageEvent.pageIndex;
                this.filter.pageSize = 5;
                this.filter.offset = pageEvent.pageIndex * 5;
            } else {
                this.filter.pageNumber = 0;
                this.filter.pageSize = 5;
                this.filter.offset = 0;
            }

            this.setAppliedFilters();

            this.service.findAll<FinancialBillFilterDTO, FinancialBillInstallmentConsultDTO>(this.filter).toPromise().then(page => {
                const listContentAux: FinancialBillInstallmentConsultDTO[] = page.content.filter(b => ((b.status === 'RECEIVED_PARTIAL') || (!b.conciled)
                    || (!b.createdByConciliation)) && !this._installemtHasUsed(b));

                page.content = [];
                page.content = listContentAux;

                page.content.forEach((installment: FinancialBillInstallmentConsultDTO) => {
                    const installmentSelected: FinancialBillInstallmentConsultDTO = this.billInstallmentListSelected.find(i => i.id === installment.id);
                    if (!ObjectUtil.isNewModel(installmentSelected)) {
                        installment.selected = true;
                        this.select(installment, false);
                    }
                });

                this.page = page;

                this.validateAllSelected();

                if (this.filter.filterByTypeDate === 'DISCHARGE_DATE') {
                    this.filter.filterByTypeDate = (this.data.billType === 'RECEIVABLE') ? 'DISCHARGE_DATE_RECEIVEMENT' : 'DISCHARGE_DATE_PAYMENT';
                }

                this.loading = false;
            }, error => {
                this.loading = false;
                throw new Error(error);
            });

        } catch (e) {
            this.handleError(e);
        }
    }

    private _installemtHasUsed(billInstallment: FinancialBillInstallmentConsultDTO): boolean {
        return this.data.billInstallmentListUsed.filter(installmentId => installmentId === billInstallment.id).length > 0;
    }

    private _setStatusEnumList(): void {
        this.filter.billStatusList = [];
        if (this.receivableChecked) {
            this.filter.billStatusList.push('RECEIVABLE');
        }
        if (this.receivedChecked) {
            this.filter.billStatusList.push('RECEIVED');
        }
        if (this.payableChecked) {
            this.filter.billStatusList.push('PAYABLE');
        }
        if (this.paidChecked) {
            this.filter.billStatusList.push('PAID');
        }
        if (this.receivableOverdueChecked) {
            this.filter.billStatusList.push('RECEIVABLE_OVERDUE');
        }
        if (this.payableOverdueChecked) {
            this.filter.billStatusList.push('PAYABLE_OVERDUE');
        }
    }

    public clear(): void {
        this.filter = new FinancialBillFilterDTO();
        this.clearAppliedFilters();
        this.find();
    }

    public getLabelFilterBankAccount(): string {
        if (this.data.billType === 'RECEIVABLE') {
            return this.filter.filterByTypeDate === 'DISCHARGE_DATE_RECEIVEMENT' ? 'Conta bancária do recebimento' : 'Conta bancária';
        } else {
            return this.filter.filterByTypeDate === 'DISCHARGE_DATE_PAYMENT' ? 'Conta bancária do pagamento' : 'Conta bancária';
        }
    }

    public checkAllStatus(type: string): void {
        if (type === 'RECEIVABLE') {
            this.receivableChecked = false;
            this.receivedChecked = false;
            this.receivedPartialChecked = false;
            this.receivableOverdueChecked = false;
        } else if (type === 'PAYABLE') {
            this.payableOverdueChecked = false;
            this.payableChecked = false;
            this.paidChecked = false;
        }
        this.indeterminateType = false;
    }

    public validateAllReceivableStatusSelected(): void {
        if (
            this.receivableChecked
            && this.receivedChecked
            && this.receivableOverdueChecked
            && this.receivedPartialChecked
        ) {
            this.allTypesChecked = true;
            this.indeterminateType = false;
        } else if (
            this.receivableChecked
            || this.receivedChecked
            || this.receivableOverdueChecked
            || this.receivedPartialChecked
        ) {
            this.indeterminateType = true;
            this.allTypesChecked = false;
        } else {
            this.indeterminateType = false;
            this.allTypesChecked = false;
        }
    }

    public validateAllPayableStatusSelected(): void {
        if (
            this.payableOverdueChecked
            && this.payableChecked
            && this.paidChecked) {
            this.allTypesChecked = true;
            this.indeterminateType = false;
        } else if (
            this.payableOverdueChecked
            || this.payableChecked
            || this.paidChecked
        ) {
            this.indeterminateType = true;
            this.allTypesChecked = false;
        } else {
            this.indeterminateType = false;
            this.allTypesChecked = false;
        }
    }

    public checkAll(): void {
        this.page.content.forEach(billInstallment => {
            billInstallment.selected = this.selected;
            this.select(billInstallment, false);
        });
        this.validateAllSelected();
    }

    public select(billInstallment: FinancialBillInstallmentConsultDTO, validate: boolean): void {
        if (billInstallment.selected && !this._installemtHasUsed(billInstallment)) {
            const alreadySelected: FinancialBillInstallmentConsultDTO = this.billInstallmentListSelected.find(i => i.id === billInstallment.id);
            if (ObjectUtil.isNewModel(alreadySelected)) {
                this.billInstallmentListSelected.push(billInstallment);
            }
        } else {
            this.billInstallmentListSelected.splice(this.billInstallmentListSelected.findIndex(p => p.id === billInstallment.id), 1);
        }
        if (validate) {
            this.validateAllSelected();
        }
    }

    public validateAllSelected(): void {
        const list: FinancialBillInstallmentConsultDTO[] = this.page.content.filter(billInstallment => billInstallment.selected);
        this.selected = list.length > 0;
        this.indeterminate = (list.length > 0 && list.length !== this.page.content.length) || this.billInstallmentListSelected.length > 0;
    }

    public confirm(): void {
        this.dialogRef.close(this.billInstallmentListSelected);
    }

    public close(): void {
        this.dialogRef.close(this.data.installmentListSelected);
    }

}
