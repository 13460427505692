import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialBankAccountService } from '@gipi-financial/bank-account/services/bank-account.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, APP_MESSAGES, INJECTOR, ObjectUtil } from '@gipisistemas/ng-core';
export class ImportExtractDialogComponent extends AbstractComponent {
    constructor(dialogRef, data = null, _bankAccountService, messageService, router, activatedRoute) {
        super(messageService, router, activatedRoute);
        this.dialogRef = dialogRef;
        this.data = data;
        this._bankAccountService = _bankAccountService;
        this.bankAccount = new FinancialBankAccount();
        this.isBillConciliation = false;
        this.isLoad = false;
        this.bankAccountFindByTypeFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.isBillConciliation) {
                const result = yield this._bankAccountService.findByValue(value, page, 10, { property: 'description', direction: 'asc' }, 'CHECKING_ACCOUNT').toPromise();
                return result;
            }
            else {
                return this.data;
            }
        });
    }
    ngOnInit() {
        const _super = Object.create(null, {
            ngOnInit: { get: () => super.ngOnInit }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            _super.ngOnInit.call(this);
            // Se this.data está null é porque veio da billConciliation
            this.isBillConciliation = ObjectUtil.isNull(this.data);
        });
    }
    get disableBtnConfirmation() {
        return ObjectUtil.isNull(this.bankAccount) || ObjectUtil.isNewModel(this.bankAccount) || ObjectUtil.isNull(this.ofxFile);
    }
    selectionChangeArchive(file) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.isLoad = true;
                this.file = file;
                this._bankAccountService.readOfxFile(file).toPromise().then(ofxFile => {
                    this.ofxFile = ofxFile;
                    this.isLoad = false;
                }, error => {
                    throw new Error(error);
                });
            }
            catch (e) {
                this.isLoad = false;
                this.handleError(e);
            }
        });
    }
    confirm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (ObjectUtil.isNull(this.bankAccount) || ObjectUtil.isNewModel(this.bankAccount)) {
                    throw new Error('Selecione uma conta bancária');
                }
                if (ObjectUtil.isNull(this.ofxFile)) {
                    throw new Error('Selecione o arquivo OFX / Money 2000');
                }
                this.isLoad = true;
                this.loading = true;
                yield this._bankAccountService.importTransactions(this.bankAccount.id, this.file).toPromise().then(() => {
                    this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                    this.isBillConciliation ? this.close('RELOAD_TABLE') : this.close('NONE');
                }, error => {
                    throw new Error(error);
                });
            }
            catch (e) {
                this.loading = false;
                this.isLoad = false;
                this.handleError(e);
            }
        });
    }
    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation) {
        this.dialogRef.close(operation);
    }
}
