import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SaleAddressService {

    constructor(protected http: HttpClient) { }

    async getAddressByCep(cep: string): Promise<string> {
        const response: Response = await fetch(`https://viacep.com.br/ws/${cep}/json`, { method: 'GET' });
        const address = await response.json();
        if (address) {
            return Promise.resolve(address.ibge);
        } else {
            return Promise.resolve('');
        }
    }

}
