<gipi-abstract-form id="dialogNovelties"
                    name="dialogNovelties"
                    class="form-content-novelties"
                    [dialog]="true">
    <div mat-dialog-title
         class="novelties-header">
        <span role="text">
            Novidades do sistema
        </span>
        <span role="textbox"
              class="novelties-actions">
            <gipi-button gipi-icon
                         id="btnClose"
                         icon="close"
                         tooltip="Fechar"
                         (click)="close()">
            </gipi-button>
        </span>
    </div>
    <div mat-dialog-content
         class="novelties-content">
        <iframe #iframeRef
                frameborder="0"
                height="100%"
                width="100%">
        </iframe>
    </div>
</gipi-abstract-form>
