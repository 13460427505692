import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { NotificationCustomMessagingSubType } from '../enums/custom-messaging-subtype.enum';
import { NotificationCustomMessagingType } from '../enums/custom-messaging-type.enum';

export class NotificationCustomMessagingConfiguration extends GIPIBaseAbstractModel {

    /**
     * @Required Texto da mensagem
     * @Min 3 characters
     * @Max 1000 characters
     */
    message: string;

    /** Sub tipo da notificação */
    notificationSubType: NotificationCustomMessagingSubType;

    /** @Required Tipo da notificação */
    notificationType: NotificationCustomMessagingType;

}
