import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import { SalePaths } from '../../../paths/sale.paths';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class SaleStateService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(SalePaths.state, 'v1', baseService);
        this.baseService = baseService;
    }
}
SaleStateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SaleStateService_Factory() { return new SaleStateService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: SaleStateService, providedIn: "root" });
