import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
export class RegistrationClient extends GIPIBaseAbstractModel {
    constructor() {
        super(...arguments);
        this.blocked = false;
        this.controlClientLimit = false;
        // /** @Transient */
        // storeCreditLimit: number;
        // /** @Transient */
        // bankSlipLimit: number;
        // /** @Transient */
        // checkLimit: number;
    }
}
