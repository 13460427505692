import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';

import * as moment_ from 'moment';
const moment = moment_;

import { FinancialClientSelectDTO } from '@gipi-financial/client/models/dto/client-select.dto';
import { FinancialClientService } from '@gipi-financial/client/services/client.service';
import { FinancialConfiguration } from '@gipi-financial/configuration/models/configuration.model';
import { FinancialSaleConciliationFilterDTO } from '@gipi-financial/sale/models/dto/sale-conciliation-filter.dto';
import { FinancialSaleConciliationDTO } from '@gipi-financial/sale/models/dto/sale-conciliation.dto';
import { FinancialSalePayment } from '@gipi-financial/sale/models/sale-payment.model';
import { FinancialSale } from '@gipi-financial/sale/models/sale.model';
import { FinancialSaleService } from '@gipi-financial/sale/services/sale.service';
import { CurrencyUtil, DateUtil, GIPIAbstractComponent, GIPIAppliedFilter, GIPIBaseService, GIPIPageModel, GIPISortModel, GIPIUuid, ObjectUtil, StringUtil, TableColumnBuilder, TableColumnDTO } from '@gipisistemas/ng-core';

export interface FindSaleData {
    salePaymentListUsed: GIPIUuid[];
    bankAccountId: GIPIUuid;
}

export type FilterTypes = 'begin_and_end_date' | 'begin_date' | 'end_date' | 'client' | 'document' | 'total';

@Component({
    templateUrl: './find-sale-dialog.component.html',
    styleUrls: ['./find-sale-dialog.component.scss']
})
export class FindSaleDialogComponent extends GIPIAbstractComponent implements OnInit {

    public filter: FinancialSaleConciliationFilterDTO = this._newFilter();
    public page: GIPIPageModel<FinancialSaleConciliationDTO> = new GIPIPageModel();
    public columns: TableColumnDTO[] = [];

    public salePaymentListSelected: FinancialSalePayment[] = [];

    public appliedFilters: GIPIAppliedFilter<FilterTypes>[] = [];

    public selected: boolean = false;
    public indeterminate: boolean = false;

    clientFindByValueFn = async (value: string, page: number) => {
        const configuration: FinancialConfiguration = this.baseService.sessionStorageService.get('configuration');
        let propertySort: string = 'person.name';
        if (!ObjectUtil.isNull(configuration) && configuration.showBusinessNameInReceiptQuery) {
            propertySort = 'person.fantasyName';
        }
        const result: GIPIPageModel<FinancialClientSelectDTO> = await this._clientService.findByValue<FinancialClientSelectDTO>(value, page, 10, new GIPISortModel(propertySort, 'ASC')).toPromise();
        return result;
    };
    clientFn = (obj: FinancialClientSelectDTO) => this._clientService.getDescription(obj);

    constructor(
        protected service: FinancialSaleService,
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private _clientService: FinancialClientService,
        public dialogRef: MatDialogRef<FindSaleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FindSaleData,
    ) {
        super(baseService, activatedRoute);
    }

    async ngOnInit(): Promise<void> {
        this.columns = this._createTableBillInstallmentColumns();
        super.ngOnInit();
    }

    private _newFilter(): FinancialSaleConciliationFilterDTO {
        const filter: FinancialSaleConciliationFilterDTO = new FinancialSaleConciliationFilterDTO();
        filter.begin = moment().startOf('month').startOf('day').toDate();
        filter.end = moment().toDate();
        filter.researchField = '';
        filter.document = '';
        filter.clientId = null;
        filter.total = null;
        filter.salePaymentIdsExcluded = [];
        return filter;
    }

    private _createTableBillInstallmentColumns(): TableColumnDTO[] {
        return [
            TableColumnBuilder.instance()
                .property('date')
                .name('Data')
                .sortable(true)
                .value((obj: FinancialSaleConciliationDTO) => DateUtil.format(obj.date, DateUtil.DATE_FORMAT))
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('documentNumber')
                .name('Nº Documento')
                .sortable(true)
                .value((obj: FinancialSaleConciliationDTO) => !StringUtil.isEmpty(obj.documentNumber) ? obj.documentNumber : '')
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('amountNet')
                .name('Valor')
                .sortable(true)
                .value((obj: FinancialSaleConciliationDTO) => CurrencyUtil.transform(obj.amount, '1.2-2'))
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('client.person.name')
                .name('Cliente')
                .sortable(true)
                .value((obj: FinancialSaleConciliationDTO) => !StringUtil.isEmpty(obj.clientName) ? obj.clientName : '')
                .build(),
        ];
    }

    protected clearAppliedFilters(): void {
        this.appliedFilters = [];
    }

    protected setAppliedFilters(): void {
        if (ObjectUtil.isNull(this.filter)) {
            return;
        }
        this.clearAppliedFilters();

        if (!ObjectUtil.isNull(this.filter.begin) && !ObjectUtil.isNull(this.filter.end)) {
            const description: string = `${DateUtil.format(this.filter.begin, DateUtil.DATE_FORMAT)} á ${DateUtil.format(this.filter.end, DateUtil.DATE_FORMAT)}`;
            this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>(description, 'begin_and_end_date'));
        } else {
            if (!ObjectUtil.isNull(this.filter.begin)) {
                const description: string = `Data inicial: ${DateUtil.format(this.filter.begin, DateUtil.DATE_FORMAT)}`;
                this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>(description, 'begin_date'));
            }
            if (!ObjectUtil.isNull(this.filter.end)) {
                const description: string = `Data final: ${DateUtil.format(this.filter.end, DateUtil.DATE_FORMAT)}`;
                this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>(description, 'end_date'));
            }
        }
        if (!ObjectUtil.isNull(this.filter.clientId)) {
            this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>('Cliente', 'client'));
        }
        if (!StringUtil.isEmpty(this.filter.document)) {
            this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>('N° Documento', 'document'));
        }
        if (this.filter.total) {
            this.appliedFilters.push(new GIPIAppliedFilter<FilterTypes>('Valor', 'total'));
        }
    }

    public removeAppliedFilter(filter: { chip: GIPIAppliedFilter<FilterTypes>, index: number }): void {
        switch (filter.chip.key) {
            case 'begin_and_end_date': {
                this.filter.begin = null;
                this.filter.end = null;
                break;
            }
            case 'begin_date': {
                this.filter.begin = null;
                break;
            }
            case 'end_date': {
                this.filter.end = null;
                break;
            }
            case 'client': {
                this.filter.client = null;
                this.filter.clientId = null;
                break;
            }
            case 'document': {
                this.filter.document = '';
                break;
            }
            case 'total': {
                this.filter.total = null;
                break;
            }
        }

        this.find(null);
    }

    public find(pageEvent?: PageEvent): void {
        try {
            this.loading = true;
            if (ObjectUtil.isNull(this.filter)) {
                this.filter = this._newFilter();
            }

            if (this.filter.begin && !DateUtil.isValid(new Date(this.filter.begin))) {
                this.loading = false;
                this.addWarningMessage('Data inicial não é uma data válida');
                return;
            }
            if (this.filter.end && !DateUtil.isValid(new Date(this.filter.end))) {
                this.loading = false;
                this.addWarningMessage('Data final não é uma data válida');
                return;
            }
            if (this.filter.begin && !this.filter.end) {
                this.loading = false;
                this.addWarningMessage('Data final não informada');
                return;
            }
            if (!this.filter.begin && this.filter.end) {
                this.loading = false;
                this.addWarningMessage('Data inicial não informada');
                return;
            }
            if (DateUtil.isLessThan(new Date(this.filter.end), new Date(this.filter.begin))) {
                this.loading = false;
                this.addWarningMessage('Data final não pode ser menor que data inicial');
                return;
            }
            if (!ObjectUtil.isNull(this.filter.client) && !ObjectUtil.isNewModel(this.filter.client)) {
                this.filter.clientId = Number(this.filter.client.id);
            }

            // this.filter.bankAccountId = this.data.bankAccountId;
            this.filter.salePaymentIdsExcluded = this.data.salePaymentListUsed.map(id => new FinancialSale(id));

            // const existNumber: number = Number(this.filter.researchField ? this.filter.researchField.replace(',', '.') : 0);
            // if (!StringUtil.isEmpty(this.filter.researchField)) {
            //     this.filter.researchField = NumberUtil.isPositive(existNumber) ? null : this.filter.researchField;
            // }
            if (pageEvent) {
                this.filter.pageNumber = pageEvent.pageIndex;
                this.filter.pageSize = 5;
                this.filter.offset = pageEvent.pageIndex * 5;
            } else {
                this.filter.pageNumber = 0;
                this.filter.pageSize = 5;
                this.filter.offset = 0;
            }

            this.setAppliedFilters();

            this.service.findAllSaleConciliation(this.filter).toPromise().then(page => {
                this.page = page;
                this.loading = false;
            }, error => {
                this.loading = false;
                throw new Error(error);
            });

        } catch (e) {
            this.handleError(e);
        }
    }

    public clear(): void {
        this.filter = this._newFilter();
        this.clearAppliedFilters();
        this.find();
    }

    private _paymentHasUsed(salePayment: FinancialSalePayment): boolean {
        return this.data.salePaymentListUsed.filter(installmentId => installmentId === salePayment.id).length > 0;
    }

    public checkAll(sale: FinancialSaleConciliationDTO): void {
        sale.salePaymentList.forEach(salePayment => {
            salePayment.selected = this.selected;
            this.select(sale, salePayment, false);
        });
        this.validateAllSelected(sale);
    }

    public select(sale: FinancialSaleConciliationDTO, salePayment: FinancialSalePayment, validate: boolean): void {
        salePayment.selected = !salePayment.selected;

        if (salePayment.selected && !this._paymentHasUsed(salePayment)) {
            const alreadySelected: FinancialSalePayment = this.salePaymentListSelected.find(e => e.id === salePayment.id);
            if (ObjectUtil.isNull(alreadySelected)) {
                salePayment.saleAmount = sale.amount;
                salePayment.saleClientName = sale.clientName;
                salePayment.saleDate = sale.date;
                salePayment.saleDocumentNumber = sale.documentNumber;
                this.salePaymentListSelected.push(salePayment);
            }
        } else {
            this.salePaymentListSelected.splice(this.salePaymentListSelected.findIndex(e => e.id === salePayment.id), 1);
        }
        if (validate) {
            this.validateAllSelected(sale);
        }

        this._changeDetectorRef.detectChanges();
    }

    public validateAllSelected(sale: FinancialSaleConciliationDTO): void {
        const list: FinancialSalePayment[] = sale.salePaymentList.filter(billInstallment => billInstallment.selected);
        this.selected = list.length > 0;
        this.indeterminate = (list.length > 0 && list.length !== sale.salePaymentList.length) || (this.salePaymentListSelected.length > 0);
    }

    public confirm(): void {
        this.dialogRef.close(this.salePaymentListSelected);
    }

    public close(): void {
        this.dialogRef.close([]);
    }

}
