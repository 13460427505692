<div fxLayout="column"
     fxFlex
     fxLayoutGap="16px">
    <ng-container *ngFor="let email of emailList; let i = index">
        <div fxLayout="column"
             fxFlex
             fxLayoutGap="16px"
             class="group-panel-register"
             *ngIf="emailList && (emailList.length > 0) && !loading"
             [attr.id]="'groupEmail' + i">

            <gipi-button tooltip="Excluir"
                         gipi-icon
                         class="btn-delete-group"
                         id="buttonDeleteEmail"
                         icon="close"
                         *ngIf="!loading && !isViewing"
                         (click)="deleteEmail(email)">
            </gipi-button>

            <div fxLayout="row"
                 fxLayoutAlign="start"
                 fxLayoutGap="16px"
                 fxLayout.lt-sm="column"
                 fxLayout.lt-sm="column">
                <itss-input label="E-mail"
                            id="inputEmail"
                            [(ngModel)]="email.email"
                            [placeholder]="'exemplo@email.com'"
                            [disabled]="loading || isViewing"
                            [lowerCase]="true"
                            [required]="true"
                            (focusout)="validateEmail(email)"
                            fxFlex
                            fxFlex.lt-sm="100">
                </itss-input>
            </div>
        </div>
    </ng-container>

    <div class="group-panel-register new-group-panel-register"
         id="buttonNewEmail"
         *ngIf="!loading && !isViewing"
         (click)="addNewEmail()">
        <label> + Adicionar e-mail </label>
    </div>
</div>