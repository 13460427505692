import { FilterDTO } from '@gipisistemas/ng-core';
import { OriginSessionStorage } from '../../enums/origin-session-storage.enum';

export class BaseFilterDTO extends FilterDTO {

    researchField: string;

    /** @Transient */
    originScreen: OriginSessionStorage;

    /** @Transient */
    itemsSelected: any[];

}
