export class AppliedFilter<T>{

    private _description: string;

    private _key: T;

    constructor(description: string, key: T) {
        this.description = description;
        this.key = key;
    }

    public set description(description: string) {
        this._description = description;
    }
    public get description(): string {
        return this._description;
    }

    public set key(key: T) {
        this._key = key;
    }
    public get key(): T {
        return this._key;
    }

}
