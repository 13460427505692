import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class RegistrationCardAdministratorService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(RegistrationPaths.cardAdministrator, 'v1', baseService);
        this.baseService = baseService;
    }
}
RegistrationCardAdministratorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationCardAdministratorService_Factory() { return new RegistrationCardAdministratorService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: RegistrationCardAdministratorService, providedIn: "root" });
