import { BaseModel } from '@gipi-shared/models/base.model';
import { RegistrationCity } from '../../../registration/city/models/city.model';
import { RegistrationCountry } from '../../../registration/country/models/country.model';
import { RegistrationState } from '../../../registration/state/models/state.model';
import { RegistrationAddressType, RegistrationAddressTypeEnum } from '../enums/address-type.enum';

export class RegistrationAddress extends BaseModel {

    /** Cidade */
    city: RegistrationCity;

    /**
     * Complemento
     * @Min 0 characters
     * @Max 80 characters
     */
    complement: string;

    /**
     * Código do país (Atenção: Informar somente para clientes estrangeiros)
     * @Min 0 characters
     * @Max 4 characters
     */
    countryCode: string;

    /**
     * Bairro
     * @Min 3 characters
     * @Max 50 characters
     */
    neighborhood: string;

    /**
     * Rua
     * @Min 3 characters
     * @Max 60 characters
     */
    street: string;

    /**
     * Número da rua
     * @Min 2 characters
     * @Max 6 characters
     */
    streetNumber: string;

    /** Tipo de endereço --- [ COLLECTION, COMMERCIAL, DELIVERY, RESIDENTIAL ] */
    type: RegistrationAddressTypeEnum | RegistrationAddressType;

    /** Determina se é o endereço padrão */
    useInInvoice: boolean;

    /**
     * CEP
     * @Min 1 characters
     * @Max 9 characters
     */
    zipCode: string;

    /** @Transient */
    key: number;

    /** @Transient */
    state: RegistrationState;

    /** @Transient */
    country: RegistrationCountry;

    /** @Transient */
    cityList: RegistrationCity[];

    /** @Transient */
    withoutStreetNumber: boolean;

}
