import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class RegistrationStateService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(RegistrationPaths.state, 'v1', baseService);
        this.baseService = baseService;
    }
}
RegistrationStateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationStateService_Factory() { return new RegistrationStateService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: RegistrationStateService, providedIn: "root" });
