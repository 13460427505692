<div fxLayout="column"
     fxLayoutGap="16px">

    <div *ngIf="person.typePerson === 'LEGAL_PERSON'"
         fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="start"
         fxLayoutGap="16px">
        <gipi-input-select-enum label="Tipo de pessoa"
                                id="typePerson"
                                name="typePerson"
                                [required]="true"
                                [valuesExclud]="(typeRegister !== 'CLIENT') ? ['FOREIGN_PERSON'] : []"
                                [enum]="typePersonEnum"
                                [disabled]="disabled || loading || isViewing()"
                                [(ngModel)]="person.typePerson"
                                (ngModelChange)="validateTypePerson($event)"
                                fxFlex
                                fxFlex.lt-sm="100">
        </gipi-input-select-enum>

        <itss-input label="CNPJ"
                    #inputCnpj
                    id="cnpjLegalPerson"
                    [(ngModel)]="person.legalPerson.cnpj"
                    mask="00.000.000/0000-00"
                    [disabled]="disabled || loading || isViewing()"
                    *ngIf="person.typePerson === 'LEGAL_PERSON'"
                    [required]="!(typeRegister === 'REPRESENTATIVE_PROVIDER')"
                    (keydown.enter)="isCreating() ? consultCnpj() : (isEditing() ? refreshPersonByPersonConsultedCnpj() : null)"
                    (blur)="isCreating() ? consultCnpj(true) : null"
                    fxFlex
                    fxFlex.lt-sm="100">
            <ng-template #suffix
                         *ngIf="((typeRegister === 'CLIENT') || (typeRegister === 'PROVIDER') || (typeRegister === 'REPRESENTATIVE_PROVIDER'))">
                <div fxLayout="row"
                     fxLayoutGap="5px">
                    <gipi-button tooltip="Atualizar informações"
                                 gipi-icon
                                 id="btnRefreshCnpjLegalPerson"
                                 svgIcon="refresh"
                                 *ngIf="isEditing()"
                                 [forInput]="true"
                                 [disabled]="disabled || loading || isViewing() || isCreating() || !hasPermissionForOperation()"
                                 (click)="refreshPersonByPersonConsultedCnpj()">
                    </gipi-button>
                    <gipi-button tooltip="Buscar CNPJ"
                                 gipi-icon
                                 id="btnSearchCnpjLegalPerson"
                                 svgIcon="search"
                                 *ngIf="isCreating()"
                                 [forInput]="true"
                                 [disabled]="disabled || loading || isViewing() || isEditing() || !hasPermissionForOperation()"
                                 (click)="consultCnpj()">
                    </gipi-button>
                </div>
            </ng-template>
        </itss-input>
        <gipi-button label="Dados do cliente"
                     gipi-primary
                     class="btn-client-info"
                     *ngIf="typeRegister === 'CLIENT'"
                     [disabled]="disabled || loading || isViewing() || !person?.legalPerson?.cnpj"
                     (click)="openClientSheetCnpjDialog()">
            Dados do cliente
        </gipi-button>

        <gipi-datepicker label="Data abertura"
                         id="birthDateLegalPerson"
                         [(ngModel)]="person.birthDate"
                         [disabled]="disabled || loading || isViewing()"
                         [required]="!(typeRegister === 'REPRESENTATIVE_PROVIDER')"
                         [min]="minDate"
                         fxFlex>
        </gipi-datepicker>
    </div>

    <div *ngIf="person.typePerson === 'LEGAL_PERSON'"
         fxLayout="row"
         fxLayout.lt-sm="column"
         fxLayoutAlign="start"
         fxLayoutGap="16px">
        <gipi-input-select-enum label="Ativo"
                                fxFlex
                                fxFlex.lt-sm="100"
                                id="personEnabled"
                                name="personEnabled"
                                *ngIf="!isCreating()"
                                [enum]="optionYesNoEnum"
                                [valuesExclud]="[]"
                                [disabled]="disabled || loading || isViewing()"
                                [required]="true"
                                [(ngModel)]="personEnabled">
        </gipi-input-select-enum>

        <gipi-input-select-enum label="Bloqueado"
                                fxFlex
                                fxFlex.lt-sm="100"
                                id="personBlocked"
                                name="personBlocked"
                                *ngIf="typeRegister === 'CLIENT'"
                                [enum]="optionYesNoEnum"
                                [valuesExclud]="[]"
                                [disabled]="disabled || loading || isViewing()"
                                [required]="true"
                                [(ngModel)]="personBlocked">
        </gipi-input-select-enum>

        <itss-input label="Inscrição estadual"
                    id="stateRegistrationLegalPerson"
                    [(ngModel)]="person.legalPerson.stateRegistration"
                    [dropSpecialCharacters]="false"
                    [disabled]="disabled || loading || isViewing()"
                    *ngIf="person.typePerson === 'LEGAL_PERSON'"
                    (focusout)="removeSpecialCharactersStateRegistration()"
                    mask="0*"
                    fxFlex
                    maxlength="14"
                    fxFlex.lt-sm="100">
        </itss-input>

        <itss-input label="Inscrição municipal"
                    id="municipalRegistrationLegalPerson"
                    [(ngModel)]="person.legalPerson.municipalRegistration"
                    mask="0*"
                    [dropSpecialCharacters]="false"
                    [disabled]="disabled || loading || isViewing()"
                    *ngIf="person.typePerson === 'LEGAL_PERSON'"
                    maxlength="30"
                    fxFlex
                    fxFlex.lt-sm="100">
        </itss-input>
    </div>

    <div *ngIf="person.typePerson === 'NATURAL_PERSON'"
         fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="start"
         fxLayoutGap="16px">
        <gipi-input-select-enum label="Tipo de pessoa"
                                id="typePerson"
                                name="typePerson"
                                [required]="true"
                                [valuesExclud]="(typeRegister !== 'CLIENT') ? ['FOREIGN_PERSON'] : []"
                                [enum]="typePersonEnum"
                                [disabled]="disabled || loading || isViewing()"
                                [(ngModel)]="person.typePerson"
                                (ngModelChange)="validateTypePerson($event)"
                                fxFlex
                                fxFlex.lt-sm="100">
        </gipi-input-select-enum>

        <itss-input label="CPF"
                    id="cpfNaturalPerson"
                    [(ngModel)]="person.naturalPerson.cpf"
                    mask="000.000.000-00"
                    [disabled]="disabled || loading || isViewing()"
                    [required]="!(typeRegister === 'REPRESENTATIVE_PROVIDER')"
                    (focusout)="consultCpf()"
                    fxFlex
                    fxFlex.lt-sm="100">
        </itss-input>

        <itss-input [label]="(ruralProducer) && (typeRegister === 'CLIENT') ? 'Inscrição Estadual' : 'Documento'"
                    id="documentNumberNaturalPerson"
                    name="documentNumberNaturalPerson"
                    styleClassFormField="input-checkbox-in-input"
                    [disabled]="disabled || loading || isViewing()"
                    [dropSpecialCharacters]="true"
                    [(ngModel)]="person.naturalPerson.documentNumber"
                    [max]="20"
                    [maxlength]="20"
                    fxFlex
                    fxFlex.lt-sm="100">
            <ng-template #suffix
                         *ngIf="!loading && !isViewing() && (typeRegister === 'CLIENT')">
                <div class="hover-document">
                    <gipi-input-checkbox label="Produtor rural"
                                         id="ruralProducer"
                                         name="ruralProducer"
                                         [disabled]="disabled || loading || isViewing()"
                                         [(ngModel)]="ruralProducer">
                    </gipi-input-checkbox>
                </div>
            </ng-template>
        </itss-input>

        <gipi-input-checkbox label="Contribuinte do ICMS"
                             id="icmsTaxpayer"
                             name="icmsTaxpayer"
                             *ngIf="(typeRegister === 'CLIENT')"
                             [disabled]="disabled || loading || isViewing()"
                             ngClass.gt-md="checkbox-person">
        </gipi-input-checkbox>
    </div>

    <div *ngIf="person.typePerson === 'NATURAL_PERSON'"
         fxLayout="row"
         fxLayout.lt-sm="column"
         fxLayoutAlign="start"
         fxLayoutGap="16px">
        <gipi-datepicker label="Data nascimento"
                         id="birthDateNaturalPerson"
                         [(ngModel)]="person.birthDate"
                         [disabled]="disabled || loading || isViewing()"
                         [required]="!(typeRegister === 'REPRESENTATIVE_PROVIDER')"
                         [min]="minDate"
                         fxFlex>
        </gipi-datepicker>

        <gipi-input-select-enum label="Ativo"
                                fxFlex
                                fxFlex.lt-sm="100"
                                id="personEnabled"
                                name="personEnabled"
                                *ngIf="!isCreating()"
                                [enum]="optionYesNoEnum"
                                [valuesExclud]="[]"
                                [disabled]="disabled || loading || isViewing()"
                                [required]="true"
                                [(ngModel)]="personEnabled">
        </gipi-input-select-enum>

        <gipi-input-select-enum label="Bloqueado"
                                fxFlex
                                fxFlex.lt-sm="100"
                                id="personBlocked"
                                name="personBlocked"
                                *ngIf="typeRegister === 'CLIENT'"
                                [enum]="optionYesNoEnum"
                                [valuesExclud]="[]"
                                [disabled]="disabled || loading || isViewing()"
                                [required]="true"
                                [(ngModel)]="personBlocked">
        </gipi-input-select-enum>
    </div>

    <div *ngIf="person.typePerson === 'FOREIGN_PERSON'"
         fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="start"
         fxLayoutGap="16px">
        <gipi-input-select-enum label="Tipo de pessoa"
                                id="typePerson"
                                name="typePerson"
                                [required]="true"
                                [enum]="typePersonEnum"
                                [disabled]="disabled || loading || isViewing()"
                                [(ngModel)]="person.typePerson"
                                (ngModelChange)="validateTypePerson($event)"
                                fxFlex
                                fxFlex.lt-sm="100">
        </gipi-input-select-enum>

        <gipi-input-select-enum label="Ativo"
                                fxFlex
                                fxFlex.lt-sm="100"
                                id="personEnabled"
                                name="personEnabled"
                                *ngIf="!isCreating()"
                                [enum]="optionYesNoEnum"
                                [valuesExclud]="[]"
                                [disabled]="disabled || loading || isViewing()"
                                [required]="true"
                                [(ngModel)]="personEnabled">
        </gipi-input-select-enum>

        <gipi-input-select-enum label="Bloqueado"
                                fxFlex
                                fxFlex.lt-sm="100"
                                id="personBlocked"
                                name="personBlocked"
                                *ngIf="typeRegister === 'CLIENT'"
                                [enum]="optionYesNoEnum"
                                [valuesExclud]="[]"
                                [disabled]="disabled || loading || isViewing()"
                                [required]="true"
                                [(ngModel)]="personBlocked">
        </gipi-input-select-enum>

        <itss-input label="Documento"
                    id="documentNumberNaturalPerson"
                    [(ngModel)]="person.naturalPerson.documentNumber"
                    [dropSpecialCharacters]="true"
                    [disabled]="disabled || loading || isViewing()"
                    fxFlex
                    fxFlex.lt-sm="100">
        </itss-input>
    </div>

    <div *ngIf="typeRegister === 'COMPANY'"
         fxLayout="row"
         fxLayout.lt-sm="column"
         fxLayoutAlign="start"
         fxLayoutGap="16px">
        <itss-input label="CNAE"
                    id="cnaeLegalPerson"
                    *ngIf="person.typePerson === 'LEGAL_PERSON'"
                    mask="0000-0/00"
                    [required]="true"
                    [dropSpecialCharacters]="false"
                    [disabled]="disabled || loading || isViewing()"
                    [(ngModel)]="person.legalPerson.cnae"
                    fxFlex
                    maxlength="14"
                    fxFlex.lt-sm="100">
        </itss-input>
    </div>

    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="start"
         fxLayoutGap="16px">
        <itss-input [label]="person.typePerson === 'LEGAL_PERSON' ? 'Razão social' : 'Nome'"
                    id="namePerson"
                    [(ngModel)]="person.name"
                    [disabled]="disabled || loading || isViewing()"
                    required="true"
                    fxFlex
                    fxFlex.lt-sm="100"
                    (focusout)="setNameInFantasyName()">
        </itss-input>
        <itss-input [label]="person.typePerson === 'LEGAL_PERSON' ? 'Nome fantasia' : 'Como você quer ser chamado(a)?'"
                    id="fantasyNamePerson"
                    [(ngModel)]="person.fantasyName"
                    [disabled]="disabled || loading || isViewing()"
                    fxFlex
                    fxFlex.lt-sm="100">
        </itss-input>
    </div>

    <div fxLayout="row"
         fxLayout.lt-sm="column"
         fxLayoutAlign="start"
         fxLayoutGap="16px"
         *ngIf="typeRegister === 'CLIENT'">
        <gipi-input-select-paged label="Classificação do cliente"
                                 id="clientClassification"
                                 name="clientClassification"
                                 [property]="'description'"
                                 [disabled]="disabled || loading || isViewing()"
                                 [(model)]="clientClassification"
                                 [nextBatchFn]="clientClassificationFindByValueFn"
                                 fxFlex
                                 fxFlex.lt-sm="100">
        </gipi-input-select-paged>
        <gipi-input-select label="Clube de benefícios"
                           #selectBenefitClubs
                           id="benefitClubs"
                           name="benefitClubs"
                           [disabled]="disabled || loading || isViewing()"
                           [infiniteScroll]="false"
                           [showSearch]="false"
                           [showSelectAll]="true"
                           [multiple]="true"
                           [property]="'description'"
                           [options]="_optionsBenefitClubList"
                           [(ngModel)]="benefitClubList"
                           fxFlex
                           fxFlex.lt-sm="100">
        </gipi-input-select>
    </div>

    <div fxLayout="row"
         fxLayout.lt-sm="column"
         fxLayoutAlign="start"
         fxLayoutGap="16px"
         *ngIf="typeRegister === 'COMPANY'">
        <itss-select-entity label="Linha de negócio"
                            id="lineOfBusinessLegalPerson"
                            [entities]="_lineOfBusinessList"
                            [(model)]="person.legalPerson.lineOfBusiness"
                            property="description"
                            [disabled]="disabled || loading || isViewing()"
                            required="true"
                            *ngIf="person.typePerson === 'LEGAL_PERSON'"
                            fxFlex
                            fxFlex.lt-sm="100">
        </itss-select-entity>
        <itss-select-enum label="Regime tributário"
                          id="taxRegimeLegalPerson"
                          [(ngModel)]="person.legalPerson.type"
                          [enum]="legalPersonTypeEnum"
                          [disabled]="disabled || loading || isViewing()"
                          *ngIf="person.typePerson === 'LEGAL_PERSON'"
                          required="true"
                          fxFlex
                          fxFlex.lt-sm="100">
        </itss-select-enum>
    </div>

    <div fxLayout="row"
         fxLayout.lt-sm="column"
         fxLayoutAlign="start"
         fxLayoutGap="16px">
        <itss-input label="Site"
                    id="sitePerson"
                    [(ngModel)]="person.site"
                    [disabled]="disabled || loading || isViewing()"
                    fxFlex
                    fxFlex.lt-sm="100">
        </itss-input>
    </div>

    <div fxLayout="column"
         fxLayoutGap="25px">
        <ng-container [ngTemplateOutlet]="specificComponentsRef"> </ng-container>

        <gipi-expansion-panel id="panelComments"
                              panelTitle="Observações"
                              svgIcon="person_comments"
                              [iconSize]="24">
            <div fxLayout="column"
                 fxLayoutGap="16px"
                 fxFlex>
                <itss-textarea label="Observações da venda"
                               id="commentsClient"
                               *ngIf="typeRegister === 'CLIENT'"
                               [(ngModel)]="commentsClient"
                               [rows]="4"
                               [disabled]="disabled || loading || isViewing()"
                               fxFlex
                               fxFlex.lt-sm="100">
                </itss-textarea>
                <itss-textarea label="Observações diversas"
                               id="commentsPerson"
                               [(ngModel)]="person.comments"
                               [rows]="4"
                               [disabled]="disabled || loading || isViewing()"
                               fxFlex
                               fxFlex.lt-sm="100">
                </itss-textarea>
            </div>
        </gipi-expansion-panel>
    </div>
</div>
