import { Injectable } from '@angular/core';

import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import { RegistrationApplication } from '../models/application.model';
import { RegistrationApplicationFilterDTO } from '../models/dto/application-filter.dto';

@Injectable({ providedIn: 'root' })
export class RegistrationApplicationService extends GIPIAbstractCrudService<RegistrationApplication, RegistrationApplicationFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(RegistrationPaths.application, 'v1', baseService)
    }

}
