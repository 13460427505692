import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { ObjectUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/services/custom-authentication.service";
export class FinancialBankAccountTransferService extends BaseCrudService {
    constructor(http, authenticationService) {
        super(FinancialPaths.bankAccountTransfer, http, authenticationService);
    }
    validate(entity) {
        super.validate(entity);
        if (ObjectUtil.isNewModel(entity.origin)
            || ObjectUtil.isNewModel(entity.destiny)
            || ObjectUtil.isNull(entity.amount)
            || ObjectUtil.isNull(entity.date)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
        if (entity.origin.id === entity.destiny.id) {
            throw new Error('A conta de destino deve ser diferente da conta de origem');
        }
        if (entity.amount > entity.origin.currentBalance) {
            throw new Error('Transferência não autorizada. O valor que está tentando transferir excede o saldo da conta');
        }
    }
    transfer(entity) {
        return this.http.post(this.url('transfer'), entity, this.options()).pipe(map(this.mapper), catchError(this.handleError));
    }
}
FinancialBankAccountTransferService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialBankAccountTransferService_Factory() { return new FinancialBankAccountTransferService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CustomAuthenticationService)); }, token: FinancialBankAccountTransferService, providedIn: "root" });
