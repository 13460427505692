import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AddressReceitaWsDTO } from '../models/dto/address-receita-ws.dto';

@Injectable({ providedIn: 'root' })
export class RegistrationAddressService {

    constructor(protected http: HttpClient) { }

    async getAddressByCep(cep: string): Promise<AddressReceitaWsDTO> {
        const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`, { method: 'GET' });
        return Promise.resolve(response.json());
    }

}
