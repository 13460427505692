import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { FinancialPaths } from '@gipi-paths/financial.paths';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { NumberUtil, ObjectUtil, PageDTO, StringUtil } from '@gipisistemas/ng-core';
import { FinancialOperationEnum } from '../enums/operation.enum';
import { FinancialPostingCategoryFilterDTO } from '../models/dto/posting-category-filter.dto';
import { FinancialPostingCategory } from '../models/posting-category.model';

@Injectable({ providedIn: 'root' })
export class FinancialPostingCategoryService extends BaseCrudService<FinancialPostingCategory, FinancialPostingCategoryFilterDTO> {

    constructor(
        http: HttpClient,
        authenticationService: CustomAuthenticationService
    ) {
        super(FinancialPaths.postingCategory, http, authenticationService);
    }

    validate(entity: FinancialPostingCategory): void {
        super.validate(entity);
        if (!NumberUtil.isPositive(entity.code)
            || StringUtil.isEmpty(entity.description)
            || ObjectUtil.isNull(entity.operation)
            || (entity.enabled && !ObjectUtil.isNewModel(entity.upperCategory) && ObjectUtil.isNull(entity.postingCategoryGroup))) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
    }

    getNextCode(upperCategory: FinancialPostingCategory): Observable<number> {
        return this.http.get(this.url(`next-code?upperCategoryId=${ObjectUtil.isNewModel(upperCategory) ? 0 : upperCategory.id}`), this.options()).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

    findByOperation(operation: FinancialOperationEnum | string, page: number, size: number = 10): Observable<PageDTO<FinancialPostingCategory>> {
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        return this.http.get(this.url(`find-by-operation/${operation}?page=${page}&size=${size}`), this.options()).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

    findByValue(value: string, page: number, size?: number, sort?: { property: string; direction: 'asc' | 'desc'; }, operation?: FinancialOperationEnum | string | 'CREDIT' | 'DEBIT'): Observable<PageDTO<FinancialPostingCategory>> {
        let lValue: string = value;
        if (value) {
            lValue = StringUtil.removeAccents(value).trim();
        } else {
            lValue = '';
        }
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        let lSort: string = '';
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.property)) {
            lSort = `&sort=${sort.property},${sort.direction}`
        }
        if (StringUtil.isEmpty(operation)) {
            operation = 'CREDIT';
        }

        return this.http.get(this.url(`find-by-value?page=${page}&size=${size}&operation=${operation}${lSort}&value=${lValue}`), this.options()).pipe(
            takeUntil(this.onDestroy),
            map(this.mapper),
            catchError(this.handleError)
        );
    }

}
