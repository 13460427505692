import { ExtraOptions, Routes } from '@angular/router';
import { AuthGuard } from '@gipisistemas/ng-core';
const ɵ0 = () => import("./dashboard/dashboard.module.ngfactory").then(m => m.DashboardModuleNgFactory), ɵ1 = () => import("./financial/financial.module.ngfactory").then(m => m.FinancialModuleNgFactory), ɵ2 = () => import("./registration/registration.module.ngfactory").then(m => m.RegistrationModuleNgFactory), ɵ3 = () => import("./sale/sale.module.ngfactory").then(m => m.SaleModuleNgFactory), ɵ4 = () => import("./notification/notification.module.ngfactory").then(m => m.NotificationModuleNgFactory), ɵ5 = () => import("./global/global.module.ngfactory").then(m => m.GlobalModuleNgFactory), ɵ6 = () => import("./errors/errors.module.ngfactory").then(m => m.ErrorsModuleNgFactory), ɵ7 = () => import("./oauth/oauth.module.ngfactory").then(m => m.OauthModuleNgFactory);
const routes = [
    {
        path: 'dashboard',
        loadChildren: ɵ0,
        canActivate: [AuthGuard],
    },
    {
        path: 'financial',
        loadChildren: ɵ1,
        canActivate: [AuthGuard],
    },
    {
        path: 'financial/registers',
        loadChildren: ɵ2,
        canActivate: [AuthGuard],
    },
    {
        path: 'financial/sales',
        loadChildren: ɵ3,
        canActivate: [AuthGuard],
    },
    {
        path: 'financial/notifications',
        loadChildren: ɵ4,
        canActivate: [AuthGuard],
    },
    {
        path: 'global',
        loadChildren: ɵ5,
        canActivate: [AuthGuard],
    },
    {
        path: 'errors',
        loadChildren: ɵ6
    },
    {
        path: 'oauth',
        loadChildren: ɵ7,
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'errors/404'
    }
];
const config = {
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
    initialNavigation: true,
    useHash: true
};
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
