export class GlobalPaths {
    public static audit: string = 'global/audit/v1';
    public static bank: string = 'global/bank/v1';
    public static chargeType: string = 'global/charge-type/v1';
    public static city: string = 'global/city/v1';
    public static country: string = 'global/country/v1';
    public static file: string = 'global/file';
    public static flagCard: string = 'global/flag-card/v1';
    public static health: string = 'global/public/check/health';
    public static lineOfBusiness: string = 'global/line-of-business/v1';
    public static phoneType: string = 'global/phone-type';
    public static reasonRefund: string = 'global/reason-refund/v1';
    public static socialNetworkType: string = 'global/social-network-type/v1';
    public static state: string = 'global/state/v1';
}

