export enum NotificationNotificationSubTypeEnum {
    ACCOUNT_RECEIVABLE_RECEIVABLE = 'Contas a receber',
    ACCOUNT_RECEIVABLE_RECEIVED = 'Contas recebidas',
    ACCOUNT_RECEIVABLE_GROUPED = 'Contas agrupadas',
    ACCOUNT_RECEIVABLE_RENEGOTIATED = 'Contas renegociadas',
    ACCOUNT_RECEIVABLE_OVERDUE = 'Contas atrasadas',
    ACCOUNT_RECEIVABLE_RECEIVED_PARTIAL = 'Recebimento parcial',
}

export type NotificationNotificationSubType = (
    'ACCOUNT_RECEIVABLE_RECEIVABLE' |
    'ACCOUNT_RECEIVABLE_RECEIVED' |
    'ACCOUNT_RECEIVABLE_GROUPED' |
    'ACCOUNT_RECEIVABLE_RENEGOTIATED' |
    'ACCOUNT_RECEIVABLE_OVERDUE' |
    'ACCOUNT_RECEIVABLE_RECEIVED_PARTIAL'
);
