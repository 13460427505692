import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertComponent, MessageService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
export class CustomMessageService extends MessageService {
    constructor(snackBar) {
        super(snackBar);
    }
    add(message) {
        this.snackBar.openFromComponent(AlertComponent, {
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: 5000,
            data: message
        });
    }
}
CustomMessageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomMessageService_Factory() { return new CustomMessageService(i0.ɵɵinject(i1.MatSnackBar)); }, token: CustomMessageService, providedIn: "root" });
