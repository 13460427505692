import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { SalePaths } from '@gipi-paths/sale.paths';
import { SaleState } from '@gipi-sale/state/models/state.model';
import { BaseFilterDTO } from '@gipi-shared/models/dto/base-filter.dto';
import { GIPIAbstractCrudService, GIPIBaseService, GIPIPageModel, GIPISortModel, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import { SaleCity } from '../models/city.model';

@Injectable({ providedIn: 'root' })
export class SaleCityService extends GIPIAbstractCrudService<SaleCity, BaseFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(SalePaths.city, 'v1', baseService);
    }

    findByState(state: SaleState): Observable<SaleCity[]> {
        return this.baseService.httpClient.get(this.url(`find-by-state/${state.id}`), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    findByIbgeCode(ibgeCode: string): Observable<SaleCity> {
        return this.baseService.httpClient.get(this.url(`find-by-ibge-code/${ibgeCode}`), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    findByValue<T = any>(value: string, page: number, size?: number, sort?: GIPISortModel, version?: string, ibgeCodeState?: number): Observable<GIPIPageModel<T>> {
        let lValue: string = value;
        if (value) {
            lValue = StringUtil.removeAccents(value).trim();
        } else {
            lValue = '';
        }
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        let lSort: string = '';
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.field)) {
            lSort = `&sort=${sort.field},${sort.direction}`;
        }
        if ((!ibgeCodeState) || (ibgeCodeState && (ibgeCodeState <= 0))) {
            ibgeCodeState = 0;
        }

        return this.baseService.httpClient.get(this.url(`find-by-value?ibgeCodeState=${ibgeCodeState}&page=${page}&size=${size}${lSort}&value=${lValue}`, version), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

}
