import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialCashierShift } from '@gipi-financial/cashier-shift/models/cashier-shift.model';
import { FinancialClient } from '@gipi-financial/client/models/client.model';
import { FinancialEmployee } from '@gipi-financial/employe/models/employee.model';
import { FinancialPaymentMethod } from '@gipi-financial/payment-method/models/payment-method.model';
import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { GIPIUuid, UUIDUtil } from '@gipisistemas/ng-core';
import { FinancialSaleStatusEnum } from '../enums/sale-status.enum';
import { FinancialSaleTaxInvoiceStatusEnum } from '../enums/sale-tax-invoice-status.enum';
import { FinancialSaleClient } from './sale-client.model';
import { FinancialSaleItem } from './sale-item.model';
import { FinancialSalePayment } from './sale-payment.model';

export class FinancialSale extends GIPIBaseAbstractModel {

    cashierShift: FinancialCashierShift;

    client: FinancialClient;

    seller: FinancialEmployee;

    comments: string;

    userId: number;

    taxCouponNumber: number;

    taxCouponSeries: number;

    date: Date;

    documentNumber: string;

    billInstallmentList: FinancialBillInstallment[];

    saleItemList: FinancialSaleItem[];

    salePaymentList: FinancialSalePayment[];

    saleClient: FinancialSaleClient;

    saleSaleId: GIPIUuid;

    /** @Transient */
    grossAmount: number;

    /** @Transient */
    addition: number;

    /** @Transient */
    discount: number;

    /** @Transient */
    freight: number;

    amountNet: number;

    /** @Transient */
    selected: boolean = false;

    /** @Transient */
    children: FinancialSaleItem[];

    paymentMethod: FinancialPaymentMethod;

    status: FinancialSaleStatusEnum | string;

    taxInvoiceStatus: FinancialSaleTaxInvoiceStatusEnum | string;

    constructor(id?: GIPIUuid) {
        super();
        if (UUIDUtil.isValid(id)) {
            this.id = id;
        }
    }

}
