import { Injectable } from '@angular/core';

import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import { RegistrationBenefitClub } from '../models/benefit-club.model';
import { RegistrationBenefitClubFilterDTO } from '../models/dto/benefit-club-filter.dto';

@Injectable({ providedIn: 'root' })
export class RegistrationBenefitClubService extends GIPIAbstractCrudService<RegistrationBenefitClub, RegistrationBenefitClubFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(RegistrationPaths.benefitClub, 'v1', baseService)
    }

}
