import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialBankAccountService } from '@gipi-financial/bank-account/services/bank-account.service';
import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialBillInstallmentService } from '@gipi-financial/bill/services/bill-installment.service';
import { FinancialCashierShift } from '@gipi-financial/cashier-shift/models/cashier-shift.model';
import { FinancialCashierShiftService } from '@gipi-financial/cashier-shift/services/cashier-shift.service';
import { FinancialPaymentDTO } from '@gipi-financial/payment/models/dto/payment.dto';
import { FinancialPayment } from '@gipi-financial/payment/models/payment.model';
import { FinancialPaymentService } from '@gipi-financial/payment/services/payment.service';
import { OAuthUser } from '@gipi-financial/user/models/user.model';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, APP_MESSAGES, ArrayUtil, AuthenticationService, ConfirmationService, DateUtil, INJECTOR, InputComponent, NumberUtil, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';

@Component({
    templateUrl: './payment-dialog.component.html',
    styles: []
})
export class PaymentDialogComponent extends AbstractComponent implements OnInit {

    @ViewChild('firstInput', { static: true }) firstInput: InputComponent;

    bankAccountList: FinancialBankAccount[] = [];

    billInstallmentList: FinancialBillInstallment[] = [];

    billInstallmentListPayment: FinancialBillInstallment[] = [];

    payment: FinancialPayment = new FinancialPayment();

    date: Date = new Date();

    compareDate: Date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 0, 0, 0);

    isCashier: boolean = false;

    isLoad: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { billInstallmentList: FinancialBillInstallment[], cashierShift?: FinancialCashierShift },
        public dialogRef: MatDialogRef<PaymentDialogComponent>,
        private _paymentService: FinancialPaymentService,
        private _bankAccountService: FinancialBankAccountService,
        private _billInstallmentService: FinancialBillInstallmentService,
        private _authenticationService: AuthenticationService<OAuthUser>,
        private _cashierShiftService: FinancialCashierShiftService,
        private _confirmationService: ConfirmationService,
        messageService: CustomMessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();
        this.isLoad = true;

        this.payment.paymentDate = new Date();
        this.payment.fine = 0;
        this.payment.discount = 0;
        this.payment.interest = 0;

        this.isCashier = this.userIsCashier();
        if (this.isCashier) {
            if (ObjectUtil.isNewModel(this.data.cashierShift)) {
                await this.getCashierShiftOpenedByUser(true).then(shift => {
                    if (shift) {
                        this.payment.shift = shift;
                    } else {
                        this.close('REMARK_SELECTED');
                    }
                });
            } else {
                this.payment.shift = this.data.cashierShift;
            }

            this.bankAccountList = this.payment.shift.cashier.cashierBankAccountList.map(cba => cba.bankAccount);
            const bankAccount: FinancialBankAccount = this.payment.shift.cashier.cashierBankAccountList
                .filter(cba => cba.chargeType.type === 'OTHER')
                .map(cba => cba.bankAccount)[0];
            if (!ObjectUtil.isNewModel(bankAccount)) {
                this.payment.bankAccount = bankAccount;
            }

        } else {

            await this._bankAccountService.findAllEnabled(0, 50).toPromise().then(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    this.bankAccountList = page.content;
                }
            });

            this.payment.shift = null;
            this.payment.bankAccount = null;
        }

        await this._billInstallmentService.findByIdIn(this.data.billInstallmentList.map(b => b.id)).toPromise().then(installmentList => {
            this.billInstallmentList = installmentList;
            this.payment.amount = this.billInstallmentList.reduce((sum, billInstallment) => sum += billInstallment.amount, 0);
            this.payment.amountPaid = this.payment.amount;
            this.payment.chargeType = this.billInstallmentList[0].chargeType;
            if (!ObjectUtil.isNewModel(this.bankAccountList.find(ba => ba.id === this.billInstallmentList[0].bankAccount.id))) {
                this.payment.bankAccount = this.billInstallmentList[0].bankAccount;
            }
        });

        this.isLoad = false;
        this.setFocus();
    }

    private setFocus(): void {
        setTimeout(() => this.firstInput._elementRef.nativeElement.focus());
    }

    protected newPayment(): FinancialPayment {
        return new FinancialPayment();
    }

    protected getPath(): string {
        return 'financial/bills/payable';
    }

    private userIsCashier(): boolean {
        let isCashier: boolean = false;
        this._authenticationService.token.subscribe(token => {
            isCashier = token.user.cashier;
        });
        return isCashier;
    }

    private async getCashierShiftOpenedByUser(closeModal: boolean): Promise<FinancialCashierShift> {
        return new Promise(async (resolve, reject) => {
            if (this.isCashier) {
                await this._cashierShiftService.getOpenedByCurrentUser().toPromise().then(cashierShift => {
                    if (ObjectUtil.isNewModel(cashierShift)) {
                        resolve(null);
                        if (closeModal) {
                            this.close('REMARK_SELECTED');
                        }
                        this.handleError('Não é possivel realizar o recebimento. Operador de caixa deve abrir um turno');
                    } else {
                        resolve(cashierShift);
                    }
                }, (error) => {
                    resolve(null);
                    if (error.toLowerCase().includes('a consulta não retornou resultado único')) {
                        this.handleError(`Erro ao buscar turnos. ${error}`);
                    } else {
                        this.handleError(error);
                    }
                });
            }
        });
    }

    async validateCashierOpened(): Promise<boolean> {
        if (this.isCashier) {
            const cashierShift: FinancialCashierShift = await this.getCashierShiftOpenedByUser(false).then(shift => shift);
            return Promise.resolve(!ObjectUtil.isNewModel(cashierShift));
        } else {
            return Promise.resolve(true);
        }
    }

    amountPaid(): void {
        if (!this.payment.fine) {
            this.payment.fine = 0;
        }
        if (!this.payment.interest) {
            this.payment.interest = 0;
        }
        if (!this.payment.discount) {
            this.payment.discount = 0;
        }
        this.payment.amountPaid = ((this.payment.amount + this.payment.fine + this.payment.interest) - this.payment.discount);
    }

    async confirm(): Promise<void> {
        try {
            this.isLoad = true;
            this.loading = true;

            const cashierShiftOpened: boolean = await this.validateCashierOpened();
            if (!cashierShiftOpened) {
                throw new Error('Não é possivel realizar o pagamento. Operador de caixa deve abrir um turno');
            }

            const isValid: boolean = await this.validate();

            if (isValid) {
                const paymentDto: FinancialPaymentDTO = new FinancialPaymentDTO();
                paymentDto.bankAccount = this.payment.bankAccount;
                paymentDto.chargeType = this.payment.chargeType;
                paymentDto.discount = this.payment.discount;
                paymentDto.fine = this.payment.fine;
                paymentDto.interest = this.payment.interest;
                paymentDto.shift = this.payment.shift;
                paymentDto.paymentDate = new Date(this.payment.paymentDate);
                paymentDto.amountPaid = this.payment.amountPaid;
                paymentDto.amount = this.payment.amount;
                paymentDto.billInstallmentList = this.billInstallmentList;

                await this._paymentService.pay(paymentDto).toPromise().then(() => {
                    this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                    this.close('RELOAD_TABLE');
                }, error => {
                    throw new Error(error);
                });
            } else {
                this.isLoad = false;
                this.loading = false;
            }
        } catch (e) {
            this.isLoad = false;
            this.loading = false;
            this.handleError(e);
        }
    }

    async validate(): Promise<boolean> {
        if (ObjectUtil.isNewModel(this.payment.bankAccount) || ObjectUtil.isNull(this.payment.bankAccount)) {
            this.addErrorMessage('Campo conta bancária é obrigatório e não foi informado');
            return false;
        }
        if (ObjectUtil.isNewModel(this.payment.chargeType) || ObjectUtil.isNull(this.payment.chargeType)) {
            this.addErrorMessage('Campo tipo de cobrança é obrigatório e não foi informado');
            return false;
        }
        if (!NumberUtil.isPositive(this.payment.amountPaid)) {
            this.addErrorMessage('Campo valor do pagamento é obrigatório e não foi informado');
            return false;
        }
        const paymentDate: Date = new Date(this.payment.paymentDate);
        if (!DateUtil.isValid(paymentDate)) {
            this.addErrorMessage('Campo data do pagamento é obrigatório e não foi informado');
            return false;
        }
        const comparePaymentDate = new Date(paymentDate.getFullYear(), paymentDate.getMonth(), paymentDate.getDate(), 0, 0, 0);
        if (!DateUtil.isGreaterThanOrEqual(this.compareDate, comparePaymentDate)) {
            this.addErrorMessage('Não é possível efetuar pagamento com data posterior a data atual');
            return false;
        }
        if (this.isCashier && ObjectUtil.isNewModel(this.payment.shift)) {
            this.addErrorMessage('Operador de caixa deve abrir um turno');
            return false;
        }

        let message: string = '';
        if (this.payment.bankAccount.currentBalance < 0) {
            message = 'Conta bancária selecionada está com saldo negativo';
        } else if (this.payment.bankAccount.currentBalance === 0) {
            message = 'Conta bancária selecionada está com saldo R$ 0,00';
        } else if (this.payment.amountPaid > this.payment.bankAccount.currentBalance) {
            message = 'Valor do pagamento é superior ao saldo da conta bancária selecionada';
        }

        if (!StringUtil.isEmpty(message)) {
            const isConfirmed: boolean = await this._confirmationService.confirm({
                title: 'Confirmação',
                width: '25%',
                message: `${message}. Deseja continuar?`,
            });

            if (!isConfirmed) {
                return false;
            }
        }

        return true;
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE'): void {
        this.dialogRef.close(operation);
    }

}
