import { catchError, map, takeUntil } from 'rxjs/operators';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { ArrayUtil, GIPIAbstractCrudService, GIPIBaseService, ObjectUtil } from '@gipisistemas/ng-core';
import { FinancialReceivementDTO } from '../models/dto/receivement.dto';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class FinancialReceivementService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(FinancialPaths.receivement, 'v1', baseService);
        this.baseService = baseService;
    }
    findByBillInstallment(billInstallmentId) {
        return this.baseService.httpClient.get(this.url(`find-by-bill-installment/${billInstallmentId}?page=${0}&size=300`), this.options()).pipe(map((json) => {
            const page = this.handleMapper(json);
            if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                return page.content;
            }
            return [];
        }), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    receive(receivement, billInstallmentList, receivementCardList, useClientCredit = false) {
        if (!ArrayUtil.isEmpty(receivementCardList)) {
            receivement.receivementCardList = receivementCardList;
        }
        const receivementDTO = FinancialReceivementDTO.transformInReceivementDTO(receivement, billInstallmentList, useClientCredit);
        return this.baseService.httpClient.post(this.url('receive', 'v2'), receivementDTO, this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    receiveAll(receivementRequestDTO) {
        const receivementListDTO = [];
        for (let i = 0; i < receivementRequestDTO.length; i++) {
            const receivementDTO = FinancialReceivementDTO.transformInReceivementDTO(receivementRequestDTO[i].receivement, receivementRequestDTO[i].billInstallmentList, receivementRequestDTO[i].useClientCredit);
            receivementListDTO.push(receivementDTO);
        }
        return this.baseService.httpClient.post(this.url('receive-all', 'v2'), receivementListDTO, this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    partialCancellation(receivementId) {
        return this.baseService.httpClient.delete(this.url(`partial-cancellation/${receivementId}`), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    findLinkedReceivements(receivementId) {
        return this.baseService.httpClient.get(this.url(`find-linked-receivements/${receivementId}?page=0&size=300`), this.options()).pipe(map(json => {
            const page = this.handleMapper(json);
            if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                return page.content;
            }
            return [];
        }), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
FinancialReceivementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialReceivementService_Factory() { return new FinancialReceivementService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: FinancialReceivementService, providedIn: "root" });
