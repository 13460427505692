export enum FinancialCheckStatusEnum {
    PASSED = 'Aceito',
    RECEIVED = 'Recebido',
    OPENED = 'Em aberto',
    DEPOSITED = 'Depositado',
    REFUND1 = 'Devolvido 1',
    REFUND2 = 'Devolvido 2',
    IN_CUSTODY = 'Em custodia',
    CUSTODIAN = 'Custodiado',
    COMPENSATED = 'Compensado',
    PASSED_ON = 'Repassado',
}

export type FinancialCheckStatus = (
    'PASSED' |
    'RECEIVED' |
    'OPENED' |
    'DEPOSITED' |
    'REFUND1' |
    'REFUND2' |
    'IN_CUSTODY' |
    'CUSTODIAN' |
    'COMPENSATED' |
    'PASSED_ON'
);
