import * as tslib_1 from "tslib";
import { AfterViewInit, OnInit, TemplateRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { RegistrationCityService } from '@gipi-registration/city/services/city.service';
import { RegistrationClientStatusEnum } from '@gipi-registration/client/enums/client-status.enum';
import { RegistrationClientFilterDTO } from '@gipi-registration/client/models/dto/client-filter.dto';
import { RegistrationClientService } from '@gipi-registration/client/services/client.service';
import { RegistrationCountryService } from '@gipi-registration/country/services/country.service';
import { RegistrationStateService } from '@gipi-registration/state/services/state.service';
import { ArrayUtil, GIPIAbstractFindComponent, GIPIAppliedFilter, GIPIBaseService, GIPISortModel, ObjectUtil, PhoneUtil, SortDirectionEnum, SortDTO, StringUtil, TableColumnBuilder } from '@gipisistemas/ng-core';
export class FindClientDialogComponent extends GIPIAbstractFindComponent {
    constructor(dialogRef, data = { byConfiguration: false }, service, baseService, activatedRoute, _countryService, _stateService, _cityService) {
        super(service, baseService, activatedRoute);
        this.dialogRef = dialogRef;
        this.data = data;
        this.service = service;
        this.baseService = baseService;
        this.activatedRoute = activatedRoute;
        this._countryService = _countryService;
        this._stateService = _stateService;
        this._cityService = _cityService;
        this.clientStatusEnum = RegistrationClientStatusEnum;
        this.enabledAndDisabledStatus = 'ENABLED';
        this.blockedAndUnblockedStatus = 'ALL';
        this._countryList = [];
        this._stateList = [];
        this.cityFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!ObjectUtil.isNull(this.filter) && !ObjectUtil.isNull(this.filter.state)) {
                const result = yield this._cityService.findByValue(value, page, 10, new GIPISortModel('person.name', 'ASC'), 'v1', this.filter.state.ibgeCode).toPromise();
                return result;
            }
            else {
                return [];
            }
        });
        this.basePermissionList = {
            MAKE: 'CLIENT_MAKE',
            UPDATE: 'CLIENT_UPDATE',
            READ: 'CLIENT_READ',
            DELETE: 'CLIENT_DELETE',
            ENABLE_DISABLE: 'CLIENT_ENABLE_DISABLE',
        };
        this._findCountryList();
        this._findStateList();
    }
    ngOnInit() {
        super.ngOnInit();
    }
    ngAfterViewInit() {
        super.ngAfterViewInit();
    }
    createTableSorts() {
        return [new SortDTO('createdDate', SortDirectionEnum.DESC)];
    }
    getPath() {
        return '/financial/registers/clients/clients';
    }
    setFilterSessionStorage() { }
    handleFilterSessionStorage() { }
    removeAppliedFilters(filter) {
        switch (filter.chip.key) {
            case 'country': {
                this.filter.country = null;
                this.filter.countryCode = null;
                break;
            }
            case 'state': {
                this.filter.state = null;
                this.filter.stateIbgeCode = null;
                break;
            }
            case 'city': {
                this.filter.city = null;
                this.filter.cityIbgeCode = null;
                break;
            }
        }
        this.find(null);
    }
    setAppliedFilters() {
        if (!StringUtil.isEmpty(this.filter.countryCode)) {
            this.appliedFilters.push(new GIPIAppliedFilter('País', 'country'));
        }
        if (this.filter.stateIbgeCode && (this.filter.stateIbgeCode > 0)) {
            this.appliedFilters.push(new GIPIAppliedFilter('Estado', 'state'));
        }
        if (this.filter.cityIbgeCode && (this.filter.cityIbgeCode > 0)) {
            this.appliedFilters.push(new GIPIAppliedFilter('Cidade', 'city'));
        }
    }
    clearAppliedFilters() {
        this.appliedFilters = [];
    }
    newFilter() {
        const filter = new RegistrationClientFilterDTO();
        filter.blocked = false;
        filter.enabled = true;
        filter.city = null;
        filter.cityIbgeCode = null;
        filter.country = null;
        filter.countryCode = null;
        filter.state = null;
        filter.stateIbgeCode = null;
        filter.researchField = '';
        filter.sorts = this.createTableSorts();
        return filter;
    }
    clear() {
        const _super = Object.create(null, {
            clear: { get: () => super.clear }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.page = this.newPage();
            this.filter = this.newFilter();
            _super.clear.call(this);
        });
    }
    createTableColumns() {
        return [
            TableColumnBuilder.instance()
                .property('person.cpf_cnpj')
                .name('CPF / CNPJ')
                .value((obj) => !StringUtil.isEmpty(obj.cpfOrCnpj)
                ? ((obj.type === 'LEGAL_PERSON') ? StringUtil.format(obj.cpfOrCnpj, '00.000.000/0000-00') : StringUtil.format(obj.cpfOrCnpj, '000.000.000-00'))
                : '')
                .action((obj) => this.close(obj))
                .width(15)
                .align('center center')
                .marginRight(15)
                .build(),
            TableColumnBuilder.instance()
                .property('person.name')
                .name('Nome')
                .value((obj) => !StringUtil.isEmpty(obj.name) ? obj.name : '')
                .action((obj) => this.close(obj))
                .sortable(true)
                .marginRight(15)
                .build(),
            TableColumnBuilder.instance()
                .property('person.fantasyName')
                .name('Nome fantasia')
                .value((obj) => !StringUtil.isEmpty(obj.fantasyName) ? obj.fantasyName : '')
                .action((obj) => this.close(obj))
                .sortable(true)
                .marginRight(15)
                .build(),
            TableColumnBuilder.instance()
                .property('person.city')
                .name('Cidade')
                .value((obj) => !StringUtil.isEmpty(obj.city) ? obj.city : '')
                .action((obj) => this.close(obj))
                .sortable(true)
                .marginLeft(15)
                .build(),
            TableColumnBuilder.instance()
                .property('person.phone')
                .name('Telefone')
                .value((obj) => !StringUtil.isEmpty(obj.phoneNumber) ? PhoneUtil.format(obj.phoneNumber) : '')
                .action((obj) => this.close(obj))
                .sortable(true)
                .width(10)
                .align('center center')
                .marginLeft(15)
                .build(),
            TableColumnBuilder.instance()
                .property('enabled')
                .name('Ativo')
                .value((obj) => obj.enabled ? 'Sim' : 'Não')
                .action((obj) => this.close(obj))
                .width(5)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('blocked')
                .name('Status')
                .template(this.statusTemplate)
                .action((obj) => this.close(obj))
                .width(7)
                .align('center center')
                .build(),
        ];
    }
    find(pageEvent) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.loading = true;
                this.page.content = [];
                if (ObjectUtil.isNull(this.page)) {
                    this.page = this.newPage();
                }
                if (ObjectUtil.isNull(this.filter)) {
                    this.filter = this.newFilter();
                }
                if (pageEvent) {
                    this.tablePageEventDTO = pageEvent;
                    if (pageEvent.sort) {
                        this.filter.sorts = [
                            new SortDTO(pageEvent.sort.active, StringUtil.isEmpty(pageEvent.sort.direction) ? SortDirectionEnum.DESC : pageEvent.sort.direction.toUpperCase()),
                        ];
                    }
                    this.filter.pageNumber = pageEvent.pageIndex;
                    this.filter.pageSize = 5;
                    this.filter.offset = pageEvent.pageIndex * 5;
                }
                else {
                    this.filter.pageNumber = 0;
                    this.filter.offset = 0;
                    this.filter.pageSize = 5;
                }
                if (this.enabledAndDisabledStatus === 'ALL') {
                    this.filter.enabled = null;
                }
                else if (this.enabledAndDisabledStatus === 'ENABLED') {
                    this.filter.enabled = true;
                }
                else if (this.enabledAndDisabledStatus === 'DISABLED') {
                    this.filter.enabled = false;
                }
                else {
                    this.filter.enabled = null;
                }
                if (this.blockedAndUnblockedStatus === 'ALL') {
                    this.filter.blocked = null;
                }
                else if (this.blockedAndUnblockedStatus === 'BLOCKED') {
                    this.filter.blocked = true;
                }
                else if (this.blockedAndUnblockedStatus === 'UNBLOCKED') {
                    this.filter.blocked = false;
                }
                else {
                    this.filter.blocked = null;
                }
                if (!ObjectUtil.isNull(this.filter.country)) {
                    this.filter.countryCode = this.filter.country.code;
                }
                else {
                    this.filter.countryCode = null;
                }
                if (!ObjectUtil.isNull(this.filter.state)) {
                    this.filter.stateIbgeCode = this.filter.state.ibgeCode;
                }
                else {
                    this.filter.stateIbgeCode = null;
                }
                if (!ObjectUtil.isNull(this.filter.city)) {
                    this.filter.cityIbgeCode = this.filter.city.ibgeCode;
                }
                else {
                    this.filter.cityIbgeCode = null;
                }
                yield this.service.findAll(this.filter).toPromise().then((page) => {
                    this.page = page;
                    this.setAppliedFilters();
                    this.loading = false;
                }, (error) => this.handleError(error));
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    _findCountryList() {
        try {
            this._countryService.findAllEnabled(0, 300).toPromise().then(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    this._countryList = page.content;
                }
            }, error => {
                throw new Error(error);
            });
        }
        catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }
    _findStateList() {
        try {
            this._stateService.findAllEnabled(0, 50).toPromise().then(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    this._stateList = page.content;
                }
            }, error => {
                throw new Error(error);
            });
        }
        catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }
    close(client) {
        if (!ObjectUtil.isNull(this.data) && this.data.byConfiguration) {
            this.dialogRef.close(!ObjectUtil.isNull(client) ? client : null);
        }
        else {
            this.dialogRef.close(!ObjectUtil.isNull(client) ? client.id : null);
        }
    }
}
