<gipi-abstract-form id="dialogFindClient"
                    name="dialogFindClient"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogFindClient">
        Consulta de clientes
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="column"
             fxLayoutGap="16px">
            <div fxLayout="row wrap"
                 fxLayout.lt-sm="column"
                 fxLayoutAlign="space-between center"
                 fxLayoutGap="16px">
                <div fxFlex="40"
                     style="width: 100%;"
                     fxLayout="row"
                     fxLayoutGap="16px">
                    <gipi-form-field id="inputSearch"
                                     #search
                                     fxFlex
                                     type="search"
                                     placeholder="Pesquisar"
                                     showSearch="true"
                                     [disabled]="loading"
                                     [(ngModel)]="filter.researchField"
                                     (onKeydownEnter)="find(null)"
                                     (onSearch)="find(null)">
                    </gipi-form-field>

                    <gipi-button tooltip="Filtrar"
                                 gipi-primary-icon
                                 #searchButton
                                 id="btnOptionsSearch"
                                 svgIcon="filter"
                                 [notification]="appliedFilters && (appliedFilters.length > 0)"
                                 [popover]="true"
                                 [disabled]="loading">
                        <div fxLayout="column"
                             fxLayoutGap="12px"
                             style="max-width: 36rem; padding: 8px;">
                            <gipi-input-select-paged label="País"
                                                     id="inputFilterCountryAddress"
                                                     [disabled]="loading"
                                                     [showClear]="false"
                                                     [infiniteScroll]="false"
                                                     [options]="_countryList"
                                                     [(model)]="filter.country"
                                                     [property]="'name'"
                                                     fxFlex>
                            </gipi-input-select-paged>

                            <gipi-input-select-paged label="Estado"
                                                     id="inputFilterStateAddress"
                                                     [disabled]="loading || (!filter?.country) || (filter?.country?.code !== '1058')"
                                                     [showClear]="false"
                                                     [infiniteScroll]="false"
                                                     [options]="_stateList"
                                                     [(model)]="filter.state"
                                                     [property]="'acronym'"
                                                     fxFlex>
                            </gipi-input-select-paged>

                            <gipi-input-select-paged label="Cidade"
                                                     id="inputFilterCityAddress"
                                                     [disabled]="loading || (!filter.state)"
                                                     [showClear]="false"
                                                     [(model)]="filter.city"
                                                     [nextBatchFn]="cityFindByValueFn"
                                                     [property]="'name'"
                                                     fxFlex>
                            </gipi-input-select-paged>

                            <div style="margin-top: 12px;"
                                 fxLayout="row"
                                 fxLayoutGap="12px"
                                 fxFlex>
                                <gipi-button label="Pesquisar"
                                             gipi-primary
                                             id="btnFind"
                                             icon="search"
                                             [disabled]="loading"
                                             (click)="find(null); searchButton.popoverTrigger.togglePopover()"
                                             fxFlex>
                                </gipi-button>
                                <gipi-button label="Limpar"
                                             gipi-secondary
                                             id="btnClear"
                                             icon="clear"
                                             [disabled]="loading"
                                             (click)="clear(); searchButton.popoverTrigger.togglePopover()"
                                             fxFlex>
                                </gipi-button>
                            </div>
                        </div>
                    </gipi-button>
                </div>
                <div fxFlex="15"
                     style="width: 100%;"
                     fxLayout="row"
                     fxLayoutGap="16px">
                    <gipi-button label="Exibir"
                                 gipi-tertiary
                                 id="btnDisplay"
                                 #displayButton
                                 icon="expand_more"
                                 [disabled]="loading"
                                 [popover]="true"
                                 fxFlex
                                 fxFlex.lt-md="100">
                        <div class="btn-display-content"
                             style="flex-direction: column !important; min-width: 140px !important; gap: 8px;">
                            <itss-radio-group-enum label="Ativo | Inativo"
                                                   id="enabledAndDisabledStatus"
                                                   [(ngModel)]="enabledAndDisabledStatus"
                                                   [enum]="clientStatusEnum"
                                                   [valuesExclud]="['BLOCKED', 'UNBLOCKED']"
                                                   [disabled]="loading"
                                                   groupbox="true"
                                                   fxFlex>
                            </itss-radio-group-enum>
                            <itss-radio-group-enum label="Bloqueado | Desbloqueado"
                                                   id="blockedAndUnblockedStatus"
                                                   [(ngModel)]="blockedAndUnblockedStatus"
                                                   [enum]="clientStatusEnum"
                                                   [valuesExclud]="['ENABLED', 'DISABLED']"
                                                   [disabled]="loading"
                                                   groupbox="true"
                                                   fxFlex>
                            </itss-radio-group-enum>
                            <div>
                                <gipi-button label="Aplicar"
                                             gipi-primary
                                             id="btnApplyFilter"
                                             [disabled]="loading"
                                             (click)="find(null); displayButton.closePopover()"
                                             fxFlex>
                                </gipi-button>
                            </div>
                        </div>
                    </gipi-button>
                </div>
            </div>
            <gipi-chips label="Filtros aplicados:"
                        style="max-width: 70%;"
                        [(ngModel)]="appliedFilters"
                        [showRemovable]="true"
                        [showRemovableAll]="true"
                        [disabled]="loading"
                        (removeChange)="removeAppliedFilters($event)"
                        (removeAllChange)="clear()">
            </gipi-chips>
        </div>

        <itss-table #table
                    [paginator]="true"
                    [showPageSizeOptions]="false"
                    [loading]="loading"
                    [columns]="columns"
                    [paginatorPageSize]="5"
                    [paginatorLength]="page.totalElements"
                    [paginatorPageIndex]="page.number"
                    [data]="page.content"
                    (page)="findAll($event)">
        </itss-table>
    </div>

    <div mat-dialog-actions
         fxLayout="row wrap"
         fxLayout.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px"
         class="actions">
        <label>
            Para selecionar o cliente clique sobre ele
        </label>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     fxFlex="20"
                     (click)="close(null)"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>

<ng-template #statusTemplate
             let-entity>
    <itss-icon icon="fiber_manual_record"
               [tooltip]="entity.blocked ? 'Bloqueado' : 'Desbloqueado'"
               [color]="entity.blocked ? '#c24245' : '#02840f'">
    </itss-icon>
</ng-template>