import { Injectable } from '@angular/core';

import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import { RegistrationUnitMeasurementFilterDTO } from '../models/dto/unit-measurement-filter.dto';
import { RegistrationUnitMeasurement } from '../models/unit-measurement.model';

@Injectable({ providedIn: 'root' })
export class RegistrationUnitMeasurementService extends GIPIAbstractCrudService<RegistrationUnitMeasurement, RegistrationUnitMeasurementFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(RegistrationPaths.unitMeasurement, 'v1', baseService);
    }

    public getDescription(entity: RegistrationUnitMeasurement): string {
        return `(${entity.initials}) - ${(entity.description.length > 50) ? (entity.description.slice(0, 47) + '...') : entity.description}`;
    }

}
