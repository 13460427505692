import { Injectable } from '@angular/core';

import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import { RegistrationCardAdministrator } from '../models/card-administrador.model';
import { RegistrationCardAdministratorFilterDTO } from '../models/dto/card-administrator-filter.dto';

@Injectable({ providedIn: 'root' })
export class RegistrationCardAdministratorService extends GIPIAbstractCrudService<RegistrationCardAdministrator, RegistrationCardAdministratorFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(RegistrationPaths.cardAdministrator, 'v1', baseService);
    }

}
