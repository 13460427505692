import { FinancialClient } from '@gipi-financial/client/models/client.model';
import { FinancialSaleStatus } from '@gipi-financial/sale/enums/sale-status.enum';
import { FinancialSaleType } from '@gipi-financial/sale/enums/sale-type.enum';
import { RegistrationClient } from '@gipi-registration/client/models/client.model';
import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';
import { FinancialSale } from '../sale.model';

export class FinancialSaleFilterDTO extends GIPIBaseAbstractFilterModel {

    begin: Date;

    client: FinancialClient | RegistrationClient;

    document: string;

    end: Date;

    saleStatusList: FinancialSaleStatus[];

    saleTypeList: FinancialSaleType;

    salesExcluded: FinancialSale[];

    taxCouponNumber: number;

    taxCouponSeries: number;

    total: number;

}
