import { Injectable } from '@angular/core';

import { ObjectUtil } from '@gipisistemas/ng-core';

@Injectable({ providedIn: 'root' })
export class SessionStorageService<F> {

    private _storage: Storage;

    constructor() {
        this._storage = window.sessionStorage;
    }

    set(key: string, value: F): boolean {
        if (this._storage) {
            this._storage.setItem(key, JSON.stringify(value));
            return true;
        }
        return false;
    }

    get(key: string): F {
        if (this._storage) {
            const json: F = JSON.parse(this._storage.getItem(key));
            return !ObjectUtil.isNull(json) ? json : null;
        }
        return null;
    }

    remove(key: string): boolean {
        if (this._storage) {
            this._storage.removeItem(key);
            return true;
        }
        return false;
    }

    clear(): boolean {
        if (this._storage) {
            this._storage.clear();
            return true;
        }
        return false;
    }

}
