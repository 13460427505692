import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';

export class RegistrationClientClassification extends GIPIBaseAbstractModel {

    /**
     * @Required Descrição
     * @Min 3 characters
     * @Max 30 characters
     */
    description: string;

    /**
     * Mensagem financeira
     * @Min 0 characters
     * @Max 100 characters
     */
    financialMessage: string;

    /**
     * Observação financeira
     * @Min 0 characters
     * @Max 100 characters
     */
    financialObservation: string;

    /** @Transient */
    key: number;

    /** @Transient */
    selected: boolean;

    constructor() {
        super();
        this.enabled = true;
    }

}
