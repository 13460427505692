import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { RegistrationEmail } from '@gipi-registration/person/models/email.model';
import { RegistrationPerson } from '@gipi-registration/person/models/person.model';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ArrayUtil, EmailUtil, StringUtil, UUIDUtil } from '@gipisistemas/ng-core';

@Component({
    selector: 'itss-email-form',
    templateUrl: './email-form.component.html',
    styleUrls: ['./email-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EmailFormComponent),
            multi: true
        }
    ],
})
export class EmailFormComponent extends AbstractComponent implements OnInit {

    emailListValue: RegistrationEmail[] = [];
    @Input() get emailList(): RegistrationEmail[] {
        return this.emailListValue;
    }

    set emailList(emailList: RegistrationEmail[]) {
        this.emailListValue = emailList;
        this.emailListChange.emit(this.emailListValue);
    }

    @Output() emailListChange: EventEmitter<RegistrationEmail[]> = new EventEmitter<RegistrationEmail[]>();

    @Input() person: RegistrationPerson = new RegistrationPerson();

    @Input() isCreating: boolean = false;

    @Input() isViewing: boolean = false;

    constructor(
        messageService: CustomMessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.loading = true;
        setTimeout(async () => {
            if (ArrayUtil.isEmpty(this.emailList)) {
                this.emailList = [];
                // this.addNewEmail();
            }
        });
        this.loading = false;
    }

    deleteEmail(email: RegistrationEmail): void {
        if (UUIDUtil.isValid(email.id)) {
            this.emailList.splice(this.emailList.findIndex(e => e.id === email.id), 1);
        } else {
            this.emailList.splice(this.emailList.findIndex(e => e.key === email.key), 1);
        }
    }

    addNewEmail(): void {
        const email = new RegistrationEmail();
        email.key = this.emailList.length;
        this.emailList.push(email);
    }

    validateEmail(email: RegistrationEmail): void {
        if (!StringUtil.isEmpty(email.email) && !EmailUtil.isValid(email.email)) {
            this.addErrorMessage('E-mail inválido');
        }
    }

}
