import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { OAuthPaths } from '@gipi-paths/oauth.paths';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { GIPIUuid, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import { OAuthAccessControl } from '../models/access-control.model';
import { OAuthAccessControlFilterDTO } from '../models/dto/access-control-filter.dto';

@Injectable({ providedIn: 'root' })
export class OAuthAccessControlService extends BaseCrudService<OAuthAccessControl, OAuthAccessControlFilterDTO> {

    constructor(
        http: HttpClient,
        authenticationService: CustomAuthenticationService
    ) {
        super(OAuthPaths.accessControl, http, authenticationService);
    }

    validate(entity: OAuthAccessControl): void {
        super.validate(entity);
        if (StringUtil.isEmpty(entity.description)
            || ObjectUtil.isNull(entity.enabled)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
    }

    existsByDescriptionAndCompany(accessControl: OAuthAccessControl, companyId: GIPIUuid) {
        return this.http.post(this.url(`exist-by-description-and-company/${companyId}`), accessControl, this.options()).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

    existByAccessControl(accessControlId: GIPIUuid): Observable<boolean> {
        const httpParams = new HttpParams()
            .append('accessControlId', String(accessControlId));
        return this.http.get(this.url('get-user-by-accessControl'), this.options(httpParams)).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

    existByAccessControlList(accessControlIdList: GIPIUuid[]): Observable<boolean> {
        return this.http.post(this.url('get-user-by-accessControl-list'), accessControlIdList, this.options()).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

}
