import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@gipi-environment/environment';
import { AbstractComponent, MessageService, StringUtil } from '@gipisistemas/ng-core';
export class GIPINoveltiesDialogComponent extends AbstractComponent {
    constructor(dialogRef, data, messageService, router, activatedRoute) {
        super(messageService, router, activatedRoute);
        this.dialogRef = dialogRef;
        this.data = data;
    }
    ngOnInit() {
        this.dialogRef.disableClose = true;
    }
    ngAfterViewInit() {
        const noveltiesUrl = environment.noveltiesUrl;
        if (!StringUtil.isEmpty(noveltiesUrl)) {
            this.iframe.nativeElement.src = noveltiesUrl;
        }
    }
    close() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.dialogRef.close(null);
        });
    }
}
