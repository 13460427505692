import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SalePaths } from '@gipi-paths/sale.paths';
import { BaseFilterDTO } from '@gipi-shared/models/dto/base-filter.dto';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { GIPIUuid } from '@gipisistemas/ng-core';
import { SaleSaleItem } from '../models/sale-item.model';
import { SaleSale } from '../models/sale.model';

@Injectable({ providedIn: 'root' })
export class SaleService extends BaseCrudService<SaleSale, BaseFilterDTO> {

    constructor(
        http: HttpClient,
        authenticationService: CustomAuthenticationService
    ) {
        super(SalePaths.sale, http, authenticationService);
    }

    findByBillInstallment(billInstallmentId: GIPIUuid): Observable<SaleSale> {
        return this.http.get(this.url(`find-by-bill-installment/${billInstallmentId}`), this.options()).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

    updateSaleItemList(saleItemList: SaleSaleItem[]): Observable<SaleSaleItem[]> {
        return this.http.post(this.url('update-sale-item-list'), saleItemList, this.options()).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }
}
