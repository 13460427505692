import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { NotificationPaths } from '@gipi-paths/notification.paths';
import { GIPIAbstractCrudService, GIPIBaseService, GIPIUuid } from '@gipisistemas/ng-core';
import { NotificationNotificationClosingMonthFilterDTO } from '../models/dto/notification-closing-month-filter.dto';
import { NotificationNotificationClosingMonth } from '../models/notification-closing-month.model';

@Injectable({ providedIn: 'root' })
export class NotificationNotificationClosingMonthService extends GIPIAbstractCrudService<NotificationNotificationClosingMonth, NotificationNotificationClosingMonthFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(NotificationPaths.notificationClosingMonth, 'v1', baseService);
    }

    closeMonth(entity: NotificationNotificationClosingMonthFilterDTO, version?: string): Observable<GIPIUuid[]> {
        return this.baseService.httpClient.post(this.url(`close-month`, version), entity, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

}
