import { FinancialTypePerson, FinancialTypePersonEnum } from '@gipi-financial/person/enums/type-person.enum';
import { GIPIAbstractDTO, GIPIUuid, ObjectUtil } from '@gipisistemas/ng-core';
import { FinancialClient } from '../client.model';

export class FinancialClientSelectDTO extends GIPIAbstractDTO {

    id: GIPIUuid;

    cpfOrCnpj: string;

    fantasyName: string;

    name: string;

    type: FinancialTypePersonEnum | FinancialTypePerson;

    public static transformToDTO(client: FinancialClient): FinancialClientSelectDTO {
        const clientConsultDTO: FinancialClientSelectDTO = new FinancialClientSelectDTO();

        clientConsultDTO.id = client.id;

        if (!ObjectUtil.isNull(client.person)) {
            clientConsultDTO.type = !ObjectUtil.isNull(client.person.legalPerson) ? 'LEGAL_PERSON' : 'NATURAL_PERSON';
            clientConsultDTO.cpfOrCnpj = !ObjectUtil.isNull(client.person.legalPerson) ? client.person.legalPerson.cnpj : client.person.naturalPerson.cpf;
            clientConsultDTO.fantasyName = client.person.fantasyName;
            clientConsultDTO.name = client.person.name;
        }

        return clientConsultDTO;
    }

}
