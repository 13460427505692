import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialBankAccountService } from '@gipi-financial/bank-account/services/bank-account.service';
import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialFileService } from '@gipi-financial/file/services/file.service';
import { FinancialReceivement } from '@gipi-financial/receivement/models/receivement.model';
import { FinancialReceivementService } from '@gipi-financial/receivement/services/receivement.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, APP_MESSAGES, ArrayUtil, INJECTOR, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';

export interface ReceivementDepositData {
    receivement: FinancialReceivement;
    installmentList: FinancialBillInstallment[];
    isCashier: boolean;
    useClientCredit: boolean;
}

@Component({
    templateUrl: './receivement-deposit-dialog.component.html',
    styleUrls: ['./receivement-deposit-dialog.component.scss']
})
export class ReceivementDepositDialogComponent extends AbstractComponent implements OnInit {

    @ViewChild('fileInput', { static: false }) fileInput: ElementRef<HTMLInputElement>;

    bankAccountList: FinancialBankAccount[] = [];

    extensions: string[] = ['.png', '.jpg', '.jpeg', '.pdf'];

    maximumSize: number = 3;

    attachmentName: string = '';

    receivement: FinancialReceivement;

    installmentList: FinancialBillInstallment[] = [];

    isLoadUploadAttachment: boolean = false;

    isLoadDeleteAttachment: boolean = false;

    isLoadViewAttachment: boolean = false;

    isLoad: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ReceivementDepositDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ReceivementDepositData = { receivement: null, installmentList: [], isCashier: false, useClientCredit: false },
        private _receivementService: FinancialReceivementService,
        private _bankAccountService: FinancialBankAccountService,
        private _fileService: FinancialFileService,
        messageService: CustomMessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
    }

    async ngOnInit(): Promise<void> {
        this.isLoad = true;
        super.ngOnInit();

        await this.initializeBankAccount();

        this.receivement = this.data.receivement;
        this.installmentList = this.data.installmentList;

        this.initializeHistoric();

        this.isLoad = false;
    }

    private initializeHistoric(): void {
        try {
            const historicList: string[] = [];
            const initText: string = 'Recebimento via depósito da(s) conta(s):\n';

            for (let i: number = 0; i < this.installmentList.length; i++) {
                const element: FinancialBillInstallment = this.installmentList[i];
                const historic: string = `${element.documentNumber} - ${element.description}`;
                historicList.push(historic);
            }
            this.receivement.historic = `${initText}${historicList.join('\n')}`;
        } catch (e) {
            this.isLoad = false;
            this.handleError(e);
        }
    }

    private async initializeBankAccount(): Promise<void> {
        try {
            if (this.data.isCashier) {
                this.bankAccountList.push(this.data.receivement.bankAccount);
            } else {
                await this._bankAccountService.findAllEnabled(0, 50).toPromise().then(page => {
                    if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                        this.bankAccountList = page.content;
                    }
                });
            }
        } catch (e) {
            this.isLoad = false;
            this.handleError(e);
        }
    }

    async confirm(): Promise<void> {
        try {
            this.isLoad = true;
            this.loading = true;

            if (!ObjectUtil.isNull(this.receivement.fileAttachment)) {
                await this.uploadFile(this.receivement.fileAttachment);
            }

            await this._receivementService.receive(this.receivement, this.installmentList, [], this.data.useClientCredit).toPromise().then(() => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.close(true);
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.isLoad = false;
            this.loading = false;
            this.handleError(e);
        }
    }

    loadFile(): void {
        try {
            const htmlInputElement: HTMLInputElement = this.fileInput.nativeElement;

            htmlInputElement.onchange = async () => {
                const file: File = htmlInputElement.files[0];
                if (file === null || file === undefined) {
                    this.fileInput.nativeElement.value = '';
                    return;
                }
                this.isLoadUploadAttachment = true;
                this.isLoad = true;
                if (!ObjectUtil.isNull(file) && this.validateExtensions(file) && this.validateMaximumSize(file)) {
                    this.receivement.fileAttachment = file;
                    this.attachmentName = file.name;

                    this.fileInput.nativeElement.value = '';
                    this.isLoadUploadAttachment = false;
                    this.isLoad = false;
                } else {
                    this.fileInput.nativeElement.value = '';
                    this.isLoadUploadAttachment = false;
                    this.isLoad = false;
                }
            };
            htmlInputElement.click();
        } catch (e) {
            this.isLoadUploadAttachment = false;
            this.isLoad = false;
            this.handleError(e);
        }
    }

    async uploadFile(file: File): Promise<void> {
        try {
            this.isLoadUploadAttachment = true;
            await this._fileService.upload('receivement-deposits', file, 'Private').toPromise().then(async (url) => {
                this.receivement.attachment = url;
                this.getAttachmentName();
                this.isLoadUploadAttachment = false;
            }, () => {
                throw new Error('Não foi possível anexar o arquivo');
            });
        } catch (e) {
            this.isLoadUploadAttachment = false;
            this.handleError(e);
        }
    }

    async deleteFile(): Promise<void> {
        try {
            this.isLoadDeleteAttachment = true;
            this.isLoad = true;

            const htmlInputElement: HTMLInputElement = this.fileInput.nativeElement;
            await this._fileService.delete(this.receivement.attachment).toPromise().then(async () => {
                this.receivement.attachment = '';
                this.receivement.fileAttachment = null;
                htmlInputElement.remove();
                this.isLoadDeleteAttachment = false;
                this.isLoad = false;
            }, () => {
                this.fileInput.nativeElement.value = '';
                this.addErrorMessage('Não foi possível excluir o arquivo');
                this.isLoadUploadAttachment = false;
                this.isLoad = false;
            });
        } catch (e) {
            this.isLoadUploadAttachment = false;
            this.isLoad = false;
            this.handleError(e);
        }
    }

    async viewAttachment(): Promise<void> {
        try {
            this.isLoadViewAttachment = true;
            if (!ObjectUtil.isNewModel(this.receivement) && !StringUtil.isEmpty(this.receivement.attachment)) {
                await this._fileService.download(this.receivement.attachment).toPromise().then(resp => {
                    const file: Blob = new Blob([resp.body], { type: resp.body.type });
                    const fileURL: string = URL.createObjectURL(file);
                    if (!StringUtil.isEmpty(fileURL)) {
                        window.open(fileURL);
                    }
                    this.isLoadViewAttachment = false;
                }, error => {
                    throw new Error(error);
                });
            } else {
                const file: Blob = new Blob([this.receivement.fileAttachment], { type: this.receivement.fileAttachment.type });
                const fileURL: string = URL.createObjectURL(file);
                if (!StringUtil.isEmpty(fileURL)) {
                    window.open(fileURL);
                    this.isLoadViewAttachment = false;
                }
            }
        } catch (e) {
            this.isLoadViewAttachment = false;
            this.handleError(e);
        }
    }

    private getAttachmentName(): void {
        const attachmentName: string[] = (this.receivement.attachment ? this.receivement.attachment.split('/') : []);
        this.attachmentName = attachmentName[1];
    }

    private validateExtensions(file: File): boolean {
        if (this.extensions.filter(extension => file.name.endsWith(extension)).length === 0) {
            super.addErrorMessage(`São permitidos somente arquivos do tipo: ${this.extensions.reduce((previous, current) => previous + ', ' + current)}`);
            return false;
        }
        return true;
    }

    private validateMaximumSize(file: File): boolean {
        if (file.size > this.maximumSize * 1000000) {
            super.addErrorMessage(`O arquivo deve ter no máxmio ${this.maximumSize} MB.`);
            return false;
        }
        return true;
    }

    close(isSaved: boolean): void {
        this.dialogRef.close(isSaved);
    }

}
