<div fxLayout="column"
     fxLayoutAlign="center center">
    <div fxLayout="row"
         fxLayoutAlign="center center"
         fxLayoutGap="150px"
         fxFlex="100vh">
        <itss-card class="request-password">
            <div fxLayout="column"
                 fxLayoutAlign="center center">
                <img width="180"
                     src="../../../assets/logo/logo_and_name.png" />
            </div>

            <div fxLayout="column"
                 fxLayoutGap="50px">
                <p>Para recuperar sua senha, digite abaixo o e-mail que você usa para acessar o sistema</p>
                <div fxLayout="column"
                     fxLayoutGap="16px">
                    <itss-input label="E-mail cadastrado"
                                class="input-rounded"
                                [(ngModel)]="username"
                                [disabled]="loading"
                                fxFlex="100"
                                (keydown.enter)="keydownSend()">
                    </itss-input>

                    <div fxLayoutAlign="center center"
                         fxLayout="row"
                         fxLayoutGap="20px">
                        <button class="btn-rounded confirm"
                                aria-label="Confirmar"
                                [disabled]="loading"
                                (click)="send()"
                                fxFlex="25"
                                fxFlex.lt-md="100">
                            Confirmar
                        </button>
                        <button class="btn-rounded cancel"
                                aria-label="Cancelar"
                                (click)="returnToLogin()"
                                fxFlex="25"
                                fxFlex.lt-md="100">
                            Cancelar
                        </button>
                    </div>
                </div>
                <p>
                    Enviaremos um e-mail com as instruções. Se você não utiliza mais o e-mail cadastrado, fale com
                    seu supervisor.
                </p>
            </div>
        </itss-card>
    </div>
</div>