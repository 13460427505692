import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { SalePaths } from '@gipi-paths/sale.paths';
import { GIPIAbstractCrudService, GIPIBaseService, GIPIPageModel, GIPISortModel, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import { SalePaymentMethodFilterDTO } from '../models/dto/payment-method-filter.dto';
import { SalePaymentMethod } from '../models/payment-method.model';


@Injectable({ providedIn: 'root' })
export class SalePaymentMethodService extends GIPIAbstractCrudService<SalePaymentMethod, SalePaymentMethodFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(SalePaths.paymentMethod, 'v1', baseService);
    }

    findByChargeType(chargeType: string): Observable<SalePaymentMethod> {
        return this.baseService.httpClient.get(this.url(`find-by-charge-type/${chargeType}`), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    findAllEnabled<T = any>(page: number, size: number, version?: string, sort?: GIPISortModel): Observable<GIPIPageModel<T>> {
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        let lSort: string = '';
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.field)) {
            lSort = `&sort=${sort.field},${sort.direction}`;
        }

        return this.baseService.httpClient.get(this.url(`find-all-enabled?page=${page}&size=${size}${lSort}`, version), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

}
