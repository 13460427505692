import { HttpClient } from '@angular/common/http';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { NumberUtil, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/services/custom-authentication.service";
export class FinancialPostingCategoryService extends BaseCrudService {
    constructor(http, authenticationService) {
        super(FinancialPaths.postingCategory, http, authenticationService);
    }
    validate(entity) {
        super.validate(entity);
        if (!NumberUtil.isPositive(entity.code)
            || StringUtil.isEmpty(entity.description)
            || ObjectUtil.isNull(entity.operation)
            || (entity.enabled && !ObjectUtil.isNewModel(entity.upperCategory) && ObjectUtil.isNull(entity.postingCategoryGroup))) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
    }
    getNextCode(upperCategory) {
        return this.http.get(this.url(`next-code?upperCategoryId=${ObjectUtil.isNewModel(upperCategory) ? 0 : upperCategory.id}`), this.options()).pipe(map(this.mapper), catchError(this.handleError));
    }
    findByOperation(operation, page, size = 10) {
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        return this.http.get(this.url(`find-by-operation/${operation}?page=${page}&size=${size}`), this.options()).pipe(map(this.mapper), catchError(this.handleError));
    }
    findByValue(value, page, size, sort, operation) {
        let lValue = value;
        if (value) {
            lValue = StringUtil.removeAccents(value).trim();
        }
        else {
            lValue = '';
        }
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        let lSort = '';
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.property)) {
            lSort = `&sort=${sort.property},${sort.direction}`;
        }
        if (StringUtil.isEmpty(operation)) {
            operation = 'CREDIT';
        }
        return this.http.get(this.url(`find-by-value?page=${page}&size=${size}&operation=${operation}${lSort}&value=${lValue}`), this.options()).pipe(takeUntil(this.onDestroy), map(this.mapper), catchError(this.handleError));
    }
}
FinancialPostingCategoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialPostingCategoryService_Factory() { return new FinancialPostingCategoryService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CustomAuthenticationService)); }, token: FinancialPostingCategoryService, providedIn: "root" });
