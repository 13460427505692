import { Injectable } from '@angular/core';

import { SalePaths } from '@gipi-paths/sale.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import { SaleProductFilterDTO } from '../models/dto/product-filter.dto';
import { SaleProduct } from '../models/product.model';

@Injectable({ providedIn: 'root' })
export class SaleProductService extends GIPIAbstractCrudService<SaleProduct, SaleProductFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(SalePaths.product, 'v1', baseService);
    }

}
