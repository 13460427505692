import { SaleCardAdministrator } from '@gipi-sale/card-administrator/models/card-administrador.model';
import { SaleChargeType } from '@gipi-sale/charge-type/models/charge-type.model';
import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { GIPIUuid } from '@gipisistemas/ng-core';

export class SalePaymentMethod extends GIPIBaseAbstractModel {

    /** @Required Acréscimo */
    addition: number;

    /**
     * Administradora de cartão
     *  @Required integratedTEF = true;
     */
    cardAdministrator: SaleCardAdministrator;

    /** @Required Tipo de cobrança */
    chargeType: SaleChargeType;

    /** Dias entre parcelas */
    daysBetweenInstallments: number;

    /**
     * @Required Descrição
     * @Min 3 characters
     * @Max 80 characters
     */
    description: string;

    /** @Required Desconto */
    discount: number;

    /** @Required Mostrar em dados adicionais DANFe */
    displayAdditionalDANFData: boolean;

    /** Porcentagem de entrada */
    entryPercentage: number;

    /** @Required Possui entrada */
    hasEntry: boolean;

    /** Número de parcelas */
    installmentsNumber: number;

    /** @Required TEF integrado */
    integratedTEF: boolean;

    /** Número de dias da primeira prestação */
    numberDaysWinsFirstInstallment: number;

    /** @Required Abrir gaveta */
    openDrawer: boolean;

    /** @Required Mostrar no PDV */
    viewAtPDV: boolean;

    salePaymentMethodId: GIPIUuid;

    constructor() {
        super();
        this.addition = 0;
        this.daysBetweenInstallments = 0;
        this.discount = 0;
        this.entryPercentage = 0;
        this.hasEntry = false;
        this.installmentsNumber = 0;
        this.numberDaysWinsFirstInstallment = 0;
        this.displayAdditionalDANFData = false;
        this.integratedTEF = false;
        this.openDrawer = false;
        this.viewAtPDV = false;
    }

}
