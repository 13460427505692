import { SalePaths } from '@gipi-paths/sale.paths';
import { ArrayUtil, GIPIAbstractCrudService, GIPIBaseService, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import { catchError, map, takeUntil } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class SaleChargeTypeService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(SalePaths.chargeType, 'v1', baseService);
        this.baseService = baseService;
    }
    findByValue(value, page, size, sort, version, ignoreTypes) {
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        let lSort = '';
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.field)) {
            lSort = `&sort=${sort.field},${sort.direction}`;
        }
        let lIgnoreType = '';
        if (!ArrayUtil.isEmpty(ignoreTypes)) {
            if (ignoreTypes.length === 1) {
                lIgnoreType = `&ignoreType=${ignoreTypes[0]}`;
            }
            else if (ignoreTypes.length > 1) {
                lIgnoreType = `&ignoreType=${ignoreTypes.join('&ignoreType=')}`;
            }
        }
        return this.baseService.httpClient.get(this.url(`find-all-enabled?page=${page}&size=${size}${lSort}${lIgnoreType}`, version), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
SaleChargeTypeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SaleChargeTypeService_Factory() { return new SaleChargeTypeService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: SaleChargeTypeService, providedIn: "root" });
