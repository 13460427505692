import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialBank } from '@gipi-financial/bank/models/bank.model';
import { FinancialPostingCategory } from '@gipi-financial/posting-category/models/posting-category.model';
import { FinancialReasonRefund } from '@gipi-financial/reason-refund/models/reason-refund.model';
import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { GIPIUuid } from '@gipisistemas/ng-core';
import { FinancialCheckStatus } from '../enums/check-status.enum';

export class FinancialCheck extends GIPIBaseAbstractModel {

    /**
     * Conta
     * @Min 0 characters
     * @Max 20 characters
     */
    account: string;

    /**
     * Digito da conta
     * @Min 0 characters
     * @Max 5 characters
     */
    accountDigit: string;

    /**
     * Agência
     * @Min 0 characters
     * @Max 20 characters
     */
    agency: string;

    /**
     * Digito da agência
     * @Min 0 characters
     * @Max 5 characters
     */
    agencyDigit: string;

    /** @Required Valor */
    amount: number;

    /** Banco */
    bank: FinancialBank;

    /** @Required Conta bancária */
    bankAccount: FinancialBankAccount;
    bankAccountIdReversal: number;

    /** @Required Categoria */
    category: FinancialPostingCategory;

    /** @Required Número do cheque */
    checkNumber: number;

    /** Observação */
    comments: string;

    /**
     * @Required Cpf ou Cnpj do emitente
     * @Min 11 characters
     * @Max 14 characters
     */
    cpfOrCnpjIssuer: string;

    /** @Required Data de vencimento */
    dueDate: Date;

    /** @Required Data de emissão */
    issuanceDate: Date;

    /**
     * @Required Nome do emitente
     * @Min 3 characters
     * @Max 150 characters
     */
    issuerName: string;

    /** Data da movimentação */
    moveDate: Date;

    /** Status anterior do cheque */
    previeousStatus: FinancialCheckStatus;

    /** Razão da devolução */
    reasonRefund: FinancialReasonRefund;

    saleCheckId: GIPIUuid;

    /** Status do cheque */
    status: FinancialCheckStatus;

}
