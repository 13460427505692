import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { FinancialChargeTypeEnum } from '@gipi-financial/charge-type/enums/charge-type.enum';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { FilterDTO } from '@gipisistemas/ng-core';
import { FinancialCashierShift } from '../models/cashier-shift.model';

@Injectable({ providedIn: 'root' })
export class FinancialCashierShiftService extends BaseCrudService<FinancialCashierShift, FilterDTO> {

    constructor(
        http: HttpClient,
        authenticationService: CustomAuthenticationService
    ) {
        super(FinancialPaths.cashierShift, http, authenticationService);
    }

    getOpenedByCurrentUser(): Observable<FinancialCashierShift> {
        return this.http.get(this.url('opened'), this.options()).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

    existCashierBankAccountByChargeType(cashierShift: FinancialCashierShift, typeChargeType: FinancialChargeTypeEnum | string): Promise<boolean> {
        return new Promise(resolve => {
            let lExist: boolean = false;
            let lTypeChargeType: FinancialChargeTypeEnum | string = typeChargeType;

            if ((typeChargeType !== 'CREDIT_CARD') && (typeChargeType !== 'DEBIT_CARD') && (typeChargeType !== 'CHECK')) {
                if ((typeChargeType !== 'PIX') && (typeChargeType !== 'DEPOSIT') && (typeChargeType !== 'TRANSFER')) {
                    lTypeChargeType = 'OTHER';
                }
                lExist = cashierShift.cashier.cashierBankAccountList.filter(cba => cba.chargeType.type === lTypeChargeType).length > 0;
            }
            resolve(lExist);
        });
    }

}
