import { of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { FinancialClientService } from '@gipi-financial/client/services/client.service';
import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService, ObjectUtil, StringUtil, UUIDUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
import * as i2 from "../../../financial/client/services/client.service";
export class RegistrationClientService extends GIPIAbstractCrudService {
    constructor(baseService, _financialClientService) {
        super(RegistrationPaths.client, 'v1', baseService);
        this.baseService = baseService;
        this._financialClientService = _financialClientService;
    }
    getDescription(entity) {
        let name = entity.name;
        const configuration = this.baseService.sessionStorageService.get('configuration');
        if (!ObjectUtil.isNull(configuration)) {
            name = configuration.showBusinessNameInReceiptQuery ? (entity.fantasyName ? entity.fantasyName : entity.name) : entity.name;
        }
        if (entity.type === 'NATURAL_PERSON') {
            const cpf = !StringUtil.isEmpty(entity.cpfOrCnpj) ? StringUtil.format(entity.cpfOrCnpj, '000.000.000-00') + ' - ' : '';
            return `${cpf}${name}`;
        }
        else {
            const cnpj = !StringUtil.isEmpty(entity.cpfOrCnpj) ? StringUtil.format(entity.cpfOrCnpj, '00.000.000/0000-00') + ' - ' : '';
            return `${cnpj}${name}`;
        }
    }
    findByCpfOrCnpj(cpfOrCnpj) {
        return this.baseService.httpClient.get(this.url(`find-by-cpf-or-cnpj/${cpfOrCnpj}?page=0&size=50`), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    getCard(id) {
        return this._financialClientService.getCard(id);
    }
    /**
     * Refers to the block-or-unblock endpoint
     * @type PATCH
     * @path resource/version/block-or-unblock/{id}/{action}
     */
    blockOrUnblock(id, action, version) {
        if (!UUIDUtil.isValid(id)) {
            return of(false);
        }
        return this.baseService.httpClient.patch(this.url(`/block-or-unblock/${id}/${action}`, version), {}, this.options()).pipe(map(() => { return true; }), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    /**
     * Refers to the block-or-unblock-all endpoint
     * @type PATCH
     * @path resource/version/block-or-unblock-all/{action}
     */
    blockOrUnblockAll(idList, action, version) {
        for (let i = 0; i < idList.length; i++) {
            if (!UUIDUtil.isValid(idList[i])) {
                return of(false);
            }
        }
        return this.baseService.httpClient.patch(this.url(`/block-or-unblock-all/${action}`, version), idList, this.options()).pipe(map(() => { return true; }), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
RegistrationClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationClientService_Factory() { return new RegistrationClientService(i0.ɵɵinject(i1.GIPIBaseService), i0.ɵɵinject(i2.FinancialClientService)); }, token: RegistrationClientService, providedIn: "root" });
