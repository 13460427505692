import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/services/custom-authentication.service";
export class FinancialBilletFinancialService extends BaseCrudService {
    constructor(http, authenticationService) {
        super(FinancialPaths.billet, http, authenticationService);
    }
    download(installmentIds, generatePix) {
        const params = new HttpParams().set('generatePix', String(generatePix));
        return this.http.post(this.url('download'), installmentIds, Object.assign({}, this.options(params), { observe: 'response', responseType: 'blob' })).pipe(map(this.mapper), catchError(this.handleError));
    }
    generateBillet(billInstallment, generatePdf) {
        const httpParams = new HttpParams().append('generatePdf', String(generatePdf));
        return this.http.post(this.url('generate-billet'), billInstallment, Object.assign({}, this.options(httpParams), { observe: 'response', responseType: 'blob' })).pipe(map(this.mapper), catchError(this.handleError));
    }
    generateBilletAll(billInstallmentList, generatePdf) {
        const httpParams = new HttpParams().append('generatePdf', String(generatePdf));
        return this.http.post(this.url('generate-billet-all'), billInstallmentList, Object.assign({}, this.options(httpParams), { observe: 'response', responseType: 'blob' })).pipe(map(this.mapper), catchError(this.handleError));
    }
    generateBilletForBillInstallmentWithoutBillet() {
        return this.http.post(this.url('generate-billet-for-bill-installment-without-billet'), null, this.options()).pipe(map(this.mapper), catchError(this.handleError));
    }
    downloadBillet(billInstallmentList) {
        return this.http.post(this.url('download'), billInstallmentList, { observe: 'response', responseType: 'blob' }).pipe(map(this.mapper), catchError(this.handleError));
    }
}
FinancialBilletFinancialService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialBilletFinancialService_Factory() { return new FinancialBilletFinancialService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CustomAuthenticationService)); }, token: FinancialBilletFinancialService, providedIn: "root" });
