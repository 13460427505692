import { HttpParams } from '@angular/common/http';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { NotificationPaths } from '@gipi-paths/notification.paths';
import { GIPIAbstractCrudService, GIPIBaseService, StringUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class NotificationCustomMessagingConfigurationService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(NotificationPaths.customMessagingConfiguration, 'v1', baseService);
        this.baseService = baseService;
    }
    findByNotificationType(notificationType, notificationSubType) {
        const params = !StringUtil.isEmpty(notificationSubType)
            ? new HttpParams().set('notificationSubType', notificationSubType)
            : new HttpParams();
        return this.baseService.httpClient.get(this.url(`find-by-notification-type/${notificationType}`), this.options(params)).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
NotificationCustomMessagingConfigurationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationCustomMessagingConfigurationService_Factory() { return new NotificationCustomMessagingConfigurationService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: NotificationCustomMessagingConfigurationService, providedIn: "root" });
