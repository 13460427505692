import { BaseModel } from '@gipi-shared/models/base.model';
import { GIPIUuid } from '@gipisistemas/ng-core';
import { FinancialPersonStatusEnum } from '../enums/person-status.enum';
import { FinancialTypePersonEnum } from '../enums/type-person.enum';
import { FinancialAddress } from './address.model';
import { FinancialEmail } from './email.model';
import { FinancialLegalPerson } from './legal-person.model';
import { FinancialNaturalPerson } from './natural-person.model';
import { FinancialPhone } from './phone.model';
import { FinancialSocialNetwork } from './social-network.model';

export class FinancialPerson extends BaseModel {

    name: string;

    fantasyName: string;

    birthDate: Date;

    naturalPerson: FinancialNaturalPerson;

    legalPerson: FinancialLegalPerson;

    phoneList: FinancialPhone[];

    addressList: FinancialAddress[];

    emailList: FinancialEmail[];

    site: string;

    socialNetworkList: FinancialSocialNetwork[];

    comments: string;

    registrationPersonId: GIPIUuid;

    /** @Transient */
    typePerson: FinancialTypePersonEnum | string;

    /** @Transient */
    personStatus: FinancialPersonStatusEnum | string;

    /** @Transient */
    key: number;

    constructor() {
        super();
        this.phoneList = [];
        this.addressList = [];
        this.emailList = [];
        this.socialNetworkList = [];
    }

}
