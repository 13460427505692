import { GIPIBaseAbstractModel } from "@gipi-shared/models/base-abstract.model";

export class RegistrationIntermediary extends GIPIBaseAbstractModel {

    /**
     * CNPJ
     * @Min 14 characters
     * @Max 14 characters
     */
    cnpj: string;

    /**
     * @Required Nome
     * @Min 3 characters
     * @Max 60 characters
     */
    name: string;

    /**
     * @Required Nome de usuário
     * @Min 3 characters
     * @Max 60 characters
     */
    username: string;

}
