import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';
import { GIPIUuid } from '@gipisistemas/ng-core';

export class NotificationNotificationClosingMonthFilterDTO extends GIPIBaseAbstractFilterModel {

    companyIdList: GIPIUuid[];

    endDate: Date;

    startDate: Date;

}
