import { registerLocaleData } from '@angular/common';
import localePtExtra from '@angular/common/locales/extra/pt';
import localePt from '@angular/common/locales/pt';
import { AppMessages } from '@gipisistemas/ng-core';
import { getPortuguesePaginatorIntl } from './portuguese-paginator-intl';
registerLocaleData(localePt, localePtExtra);
export const GIPI_MESSAGES = {
    SUCCESS: 'Operação realizada com sucesso',
    RETURN_CONFIRMATION: 'Deseja retornar para à tela de consulta?',
    DELETE_CONFIRMATION: 'Deseja realmente excluir este registro?',
    DELETE_ALL_CONFIRMATION: 'Deseja realmente excluir os registros selecionados?',
    BUTTON_OK_CONFIRMATION: 'OK',
    BUTTON_CANCEL_CONFIRMATION: 'Cancelar',
    NOT_PERMISSION: 'Infelizmente, esta ação não é permitida para o seu perfil',
};
const ɵ0 = getPortuguesePaginatorIntl(), ɵ1 = {
    parse: {
        dateInput: ['l', 'LL', 'DD/MM/YYYY'],
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
export class AppModule {
}
export { ɵ0, ɵ1 };
