import { Injectable } from '@angular/core';

import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import { RegistrationEmployeeRoleFilterDTO } from '../models/dto/employee-role-filter.dto';
import { RegistrationEmployeeRole } from '../models/employee-roles.model';

@Injectable({ providedIn: 'root' })
export class RegistrationEmployeeRoleService extends GIPIAbstractCrudService<RegistrationEmployeeRole, RegistrationEmployeeRoleFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(RegistrationPaths.employeeRole, 'v1', baseService)
    }

}
